import React, { FC } from 'react';

import { Flex, Text } from '@chakra-ui/react';

import { useNonProdBanner } from '../hooks/useNonProdBanner';

interface INonProdBanner {
  searchBaseUrl: string;
}

export const NonProdBanner: FC<INonProdBanner> = ({ searchBaseUrl }) => {
  const { banner } = useNonProdBanner(searchBaseUrl);

  if (!banner) {
    return null;
  }

  return (
    <Flex
      background={banner.background}
      color={banner.color}
      height="24px"
      justifyContent="center"
      textAlign="center"
      textTransform="uppercase"
    >
      <Text fontWeight={700}>{banner.env} site &mdash;&nbsp;</Text>
      <Text>this is not live</Text>
    </Flex>
  );
};
