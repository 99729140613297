import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../../../ApiClient';
import { tracker } from '../../../utilities/tracker';

export const useAddPastSearch = (pastSearchBaseUrl: string) => {
  const apiClient = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['addPastSearch'],
    mutationFn: async (text: string) =>
      apiClient?.post(`${pastSearchBaseUrl}/saved-search/v1`, {
        term: text,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getPastSearch'] });
    },
    onError: (error) => {
      tracker.track('Add Past Search Mutation Failed', {
        errorString: JSON.stringify(error),
      });
    },
  });
};
