import { parsePhoneNumberFromString } from 'libphonenumber-js/min';

interface IFormatOptions {
  isRequired?: boolean;
}

export const formatProps = <T extends { placeholder?: string }>(
  props: T,
  options: IFormatOptions
): T => {
  const { isRequired } = options;

  if (props.placeholder && isRequired) {
    // eslint-disable-next-line no-param-reassign
    props.placeholder = `${props.placeholder}*`;
  }

  return props;
};

export const formatPhoneNumber = (
  currentValue: string,
  newValue: string
): string => {
  // Remove all non-digit characters and get length of values
  const currentValueLength = currentValue.replace(/\D/g, '').length;
  const newValueLength = newValue.replace(/\D/g, '').length;

  // New value differs with previous one by more than one character, let's assume that it was copied or autofilled
  if (Math.abs(newValueLength - currentValueLength) > 1) {
    // Try to parse raw value as an international number
    let phoneNumber = parsePhoneNumberFromString(newValue);

    if (phoneNumber && !phoneNumber.isValid()) {
      // Try to parse whole value as a national number with `US` country code
      phoneNumber = parsePhoneNumberFromString(
        `${phoneNumber.countryCallingCode}${phoneNumber.nationalNumber}`,
        'US'
      );
    }

    if (phoneNumber?.isValid()) {
      return phoneNumber.number;
    }
  }

  return newValue;
};
