import React, { FC } from 'react';

import { NOT_FOUND_ERROR_MESSAGE } from '../constants';
import { ErrorPage, IErrorPageProps } from '../Error';

export const CUSTOMER_SUPPORT_URL = 'mailto:cs@fanaticscollect.com';

export interface INotFoundPageProps extends IErrorPageProps {
  fanaticsCollectBaseUrl: string;
}

export const NotFoundPage: FC<INotFoundPageProps> = ({
  fanaticsCollectBaseUrl,
  ...restProps
}) => {
  return (
    <ErrorPage
      fanaticsCollectBaseUrl={fanaticsCollectBaseUrl}
      message={NOT_FOUND_ERROR_MESSAGE}
      statusCode={404}
      {...restProps}
    />
  );
};
