import React, { FC, ReactNode, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { QueryClientProvider } from '@tanstack/react-query';

import { ApolloProviderMaybe } from './ApolloProvider';
import { ModalContextProvider } from './ContextProvider';
import { getStripeKey } from '../../gql/queries/getStripeKey';
import { constructManageAccountUrl } from '../../Navigation/utils/fanaticsUrls';
import {
  defaultQueryClient,
  getLocalStorage,
  removeLocalStorageItem,
} from '../../utilities';
import {
  isMarketplace,
  isPremierAuction,
  isSalesHistory,
} from '../../utilities/pageCheck';
import { tracker } from '../../utilities/tracker';
import { OPEN_COMPLETE_PROFILE_AFTER_LOGIN_KEY } from '../lib/storage';
import { Modal } from '../Modal';
import {
  CollectCompleteProfile,
  CollectLogin,
  CollectVerifyPhone,
  CollectAccountCreated,
} from '../states/Collect';
import { CollectVerifyEmail } from '../states/Collect/CollectVerifyEmail';
import { BottomBar, CompleteProfile } from '../states/CompleteProfile';
import {
  AddCreditCard,
  AddUsBankAccount,
  ChoosePaymentMethod,
  MyWallet,
} from '../states/MyWallet';
import { DeleteMethodConfirmation } from '../states/MyWallet/DeleteMethodConfirmation';
import {
  PaymentMethodAdded,
  PaymentMethodForm,
} from '../states/PaymentMethods';
import {
  ShippingAddressForm,
  ShippingAddressAdded,
} from '../states/ShippingAddress';
import { useBottomBarStore } from '../stores/bottom-bar';
import { useModalStore } from '../stores/modal';
import { useProfileStore } from '../stores/profile';
import { EModalContent } from '../types/modalContent';

export interface IModalProviderProps {
  membersApiUrl: string;
  fanaticsCollectBaseUrl: string;
  checkoutApiUrl: string;
  graphqlApiUrl: string;
  fanIdBaseUrl: string;
  fanIdClientId: string;
  fanIdPreview?: string;
  // Made this optional to allow for the stripe key to be fetched from the server but keeping the default value for the time being until every platform
  // makes the change.
  stripeMarketplaceKey?: string;
  addPaymentMethodLoginFlow: boolean;
  children: ReactNode;
  enableFetchPublishableKey?: boolean;
  onStripeKeyError?: (errorMessage: string, error?: Error) => void;
  profileId?: string;
  /**
   * @collect-web
   */
  skipApolloProvider?: boolean;
}

type ModalContentProps = Omit<
  IModalProviderProps,
  'graphqlApiUrl' | 'skipApolloProvider' | 'profileId'
> & {
  isLoggedIn: boolean;
};

const ModalContent = ({
  children,
  stripeMarketplaceKey,
  enableFetchPublishableKey,
  onStripeKeyError,
  fanaticsCollectBaseUrl,
  membersApiUrl,
  checkoutApiUrl,
  fanIdBaseUrl,
  fanIdClientId,
  fanIdPreview,
  addPaymentMethodLoginFlow,
  isLoggedIn,
}: ModalContentProps) => {
  const { data: stripeKeyData } = useQuery(getStripeKey, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    onCompleted: (data) => {
      if (!data?.collectCurrentUserV2?.stripePublishableKey) {
        if (onStripeKeyError) {
          onStripeKeyError('Fetch Stripe Key Error', new Error('No key'));
        } else {
          tracker.track('Fetch Stripe Key Error', { error: 'No key' });
        }
      }
    },
    onError: (error) => {
      if (onStripeKeyError) {
        onStripeKeyError('Fetch Stripe Key Error', error);
      } else {
        tracker.track('Fetch Stripe Key Error', { error });
      }
    },
    skip: !enableFetchPublishableKey || !isLoggedIn,
  });

  return (
    <ModalContextProvider
      value={{
        membersApiUrl,
        fanaticsCollectBaseUrl,
        checkoutApiUrl,
        stripeMarketplaceKey: enableFetchPublishableKey
          ? stripeKeyData?.collectCurrentUserV2?.stripePublishableKey
          : stripeMarketplaceKey,
        addPaymentMethodLoginFlow,
        manageAccountUrl: constructManageAccountUrl(
          fanaticsCollectBaseUrl,
          fanIdBaseUrl,
          fanIdClientId,
          fanIdPreview
        ),
      }}
    >
      {children}
    </ModalContextProvider>
  );
};

export const ModalProvider: FC<IModalProviderProps> = ({
  membersApiUrl,
  fanaticsCollectBaseUrl,
  checkoutApiUrl,
  graphqlApiUrl,
  fanIdBaseUrl,
  fanIdClientId,
  fanIdPreview,
  stripeMarketplaceKey,
  addPaymentMethodLoginFlow = false,
  children,
  skipApolloProvider,
  enableFetchPublishableKey,
  profileId,
  onStripeKeyError,
}) => {
  const modalStore = useModalStore();
  const bottomBarStore = useBottomBarStore();
  const profileStore = useProfileStore();

  useEffect(() => {
    profileStore.setAddPaymentMethodLoginFlow(addPaymentMethodLoginFlow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPaymentMethodLoginFlow]);

  useEffect(() => {
    if (profileStore.profile) {
      const { firstName, lastName, phone, isPhoneVerified } =
        profileStore.profile ?? {};

      if (!firstName || !lastName || !phone || !isPhoneVerified) {
        tracker.track('Navigate to Collect Complete Profile', {
          firstName,
          lastName,
          hasPhone: !!phone,
          isPhoneVerified,
        });
        modalStore.navigate({ name: EModalContent.COLLECT_COMPLETE_PROFILE });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileStore.profile]);

  useEffect(() => {
    if (!modalStore.stack.length && profileStore.profile) {
      const shouldOpenHub = getLocalStorage(
        OPEN_COMPLETE_PROFILE_AFTER_LOGIN_KEY
      );

      if (shouldOpenHub) {
        setTimeout(() => {
          modalStore.navigate({ name: EModalContent.COMPLETE_PROFILE });
          removeLocalStorageItem(OPEN_COMPLETE_PROFILE_AFTER_LOGIN_KEY);
        }, 1000);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileStore.profile]);

  useEffect(() => {
    if (!bottomBarStore.isOpen && !modalStore.stack.length) {
      const shouldOpenBottomBar =
        isMarketplace() && !isSalesHistory() && !isPremierAuction();

      const isProfileCompleted =
        profileStore.profile?.isEmailVerified &&
        profileStore.profile?.isPhoneVerified &&
        profileStore.profile?.hasAddress;

      const isProfileHasPaymentMethods =
        profileStore.profile?.hasPaymentMethods;
      const finalProfileCompletion = addPaymentMethodLoginFlow
        ? isProfileCompleted && isProfileHasPaymentMethods
        : isProfileCompleted;

      if (
        shouldOpenBottomBar &&
        profileStore.profile &&
        !finalProfileCompletion
      ) {
        setTimeout(() => {
          bottomBarStore.open();
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileStore.profile]);

  useEffect(() => {
    const eventName = 'common-components:action';
    const eventHandler = (event: Event) => {
      const data = (
        event as CustomEvent<
          | { type: 'open-login'; redirectUrl?: string }
          | { type: 'open-create-account' }
          | {
              type: 'open-complete-profile';
              state?: 'email' | 'address' | 'paymentMethod';
            }
        >
      ).detail;

      switch (data.type) {
        case 'open-login':
        case 'open-create-account':
          modalStore.navigate({
            name: EModalContent.COLLECT_LOGIN,
            redirectUrl:
              data.type === 'open-login' ? data.redirectUrl : undefined,
          });
          break;
        case 'open-complete-profile': {
          const getName = () => {
            switch (data.state) {
              case 'email':
                return EModalContent.COLLECT_VERIFY_EMAIL_ADDRESS;
              case 'address':
                return EModalContent.ADD_SHIPPING_ADDRESS;
              case 'paymentMethod':
                return EModalContent.ADD_PAYMENT_METHOD;
              default:
                return EModalContent.COMPLETE_PROFILE;
            }
          };

          modalStore.navigate({ name: getName() });
          break;
        }
        default:
          break;
      }
    };

    window.addEventListener(eventName, eventHandler);

    return () => {
      window.removeEventListener(eventName, eventHandler);
    };
  }, [modalStore]);

  const renderModalContent = () => {
    switch (modalStore.lookup()?.name) {
      case EModalContent.COMPLETE_PROFILE:
        return <CompleteProfile />;
      case EModalContent.ADD_SHIPPING_ADDRESS:
        return <ShippingAddressForm />;
      case EModalContent.SHIPPING_ADDRESS_ADDED:
        return <ShippingAddressAdded />;
      case EModalContent.ADD_PAYMENT_METHOD:
        return <PaymentMethodForm />;
      case EModalContent.PAYMENT_METHOD_ADDED:
        return <PaymentMethodAdded />;
      case EModalContent.MY_WALLET:
        return <MyWallet />;
      case EModalContent.CHOOSE_PAYMENT_METHOD:
        return <ChoosePaymentMethod />;
      case EModalContent.ADD_CREDIT_CARD:
        return <AddCreditCard />;
      case EModalContent.ADD_US_BANK_ACCOUNT:
        return <AddUsBankAccount />;
      case EModalContent.DELETE_METHOD_CONFIRMATION:
        return <DeleteMethodConfirmation />;

      case EModalContent.COLLECT_LOGIN:
        return <CollectLogin />;
      case EModalContent.COLLECT_COMPLETE_PROFILE:
        return <CollectCompleteProfile />;
      case EModalContent.COLLECT_VERIFY_PHONE_NUMBER:
        return <CollectVerifyPhone />;
      case EModalContent.COLLECT_ACCOUNT_CREATED:
        return <CollectAccountCreated />;
      case EModalContent.COLLECT_VERIFY_EMAIL_ADDRESS:
        return <CollectVerifyEmail />;
      default:
        return null;
    }
  };

  const handleCloseModal = () => {
    if (profileStore.profileCompleted) {
      window.dispatchEvent(new CustomEvent('profile:completed'));
    }

    modalStore.clear();
  };

  return (
    <QueryClientProvider client={defaultQueryClient}>
      <ApolloProviderMaybe
        graphqlApiUrl={graphqlApiUrl}
        skipProvider={skipApolloProvider}
      >
        <ModalContent
          addPaymentMethodLoginFlow={addPaymentMethodLoginFlow}
          checkoutApiUrl={checkoutApiUrl}
          enableFetchPublishableKey={enableFetchPublishableKey}
          fanIdBaseUrl={fanIdBaseUrl}
          fanIdClientId={fanIdClientId}
          fanIdPreview={fanIdPreview}
          fanaticsCollectBaseUrl={fanaticsCollectBaseUrl}
          isLoggedIn={Boolean(profileStore.profile) || !!profileId}
          membersApiUrl={membersApiUrl}
          stripeMarketplaceKey={stripeMarketplaceKey}
          onStripeKeyError={onStripeKeyError}
        >
          <Modal
            isOpen={modalStore.stack.length > 0}
            onClose={handleCloseModal}
          >
            {renderModalContent()}
          </Modal>
          <BottomBar />
          {children}
        </ModalContent>
      </ApolloProviderMaybe>
    </QueryClientProvider>
  );
};
