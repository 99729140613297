import React, { FC, useCallback, useMemo } from 'react';

import { ModalContent, ModalHeader } from '../../../components';
import { AddPaymentMethod } from '../../../components/AddPaymentMethod';
import { useModalStore } from '../../../stores';
import { EModalContent } from '../../../types';
import { EPaymentType } from '../../MyWallet/types';

export const PaymentMethodForm: FC = () => {
  const modalStore = useModalStore();

  const paymentTypes = useMemo(() => [EPaymentType.CARD], []);

  const handleBack = () => {
    modalStore.pop();
  };

  const handleSuccess = useCallback(() => {
    modalStore.navigate({ name: EModalContent.PAYMENT_METHOD_ADDED });
  }, [modalStore]);

  return (
    <ModalContent
      header={
        <ModalHeader className="shipping-address" onClick={handleBack}>
          Add Your Payment Method
        </ModalHeader>
      }
    >
      <AddPaymentMethod paymentTypes={paymentTypes} onSuccess={handleSuccess} />
    </ModalContent>
  );
};
