import { useMemo } from 'react';

import { EColor } from '../../Theme';
import { ENonProdEnvs } from '../types';

export const useNonProdBanner = (searchBaseUrl: string) => {
  const banner = useMemo(() => {
    if (searchBaseUrl.includes(`api.${ENonProdEnvs.DEV}.pwccmp.com`)) {
      return {
        background: EColor.LightGreen,
        color: EColor.Black,
        env: ENonProdEnvs.DEV,
      };
    }
    if (searchBaseUrl.includes(`api.${ENonProdEnvs.QA}.pwccmp.com`)) {
      return {
        background: EColor.LightBlue,
        color: EColor.White,
        env: ENonProdEnvs.QA,
      };
    }
    if (searchBaseUrl.includes(`api.${ENonProdEnvs.UAT}.pwccmp.com`)) {
      return {
        background: EColor.Purple,
        color: EColor.White,
        env: ENonProdEnvs.UAT,
      };
    }
    return undefined;
  }, [searchBaseUrl]);

  return { banner };
};
