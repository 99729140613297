import { gql } from '../../../artifacts/gql';

export const getStripeKey = gql(`
  query webStripeKeyCCQuery {
    collectCurrentUserV2 {
      stripePublishableKey
      tenantId
    }
  }
`);
