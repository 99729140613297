import { useContext } from 'react';

import { useBreakpoint, UseBreakpointOptions } from '@chakra-ui/react';

import { NavigationContext } from '../../Navigation/context/NavigationContext';

const breakpoints = ['base', 'sm', 'md', 'lg', 'xl'];

export const useIsMobile = (
  upperBound = 'sm',
  options?: UseBreakpointOptions
) => {
  try {
    const { isSSR } = useContext(NavigationContext);
    const ssrOption = options?.ssr ?? isSSR ?? false;

    const breakpoint = useBreakpoint({ ssr: ssrOption });
    const upperBoundIndex = breakpoints.findIndex(
      (value) => value === upperBound
    );
    const validBreakpoints = breakpoints.slice(0, upperBoundIndex + 1);
    return validBreakpoints.includes(breakpoint);
  } catch (e) {
    console.log('isMobile Hook Error:', e);
  }
};
