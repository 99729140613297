import React, { ReactNode } from 'react';

import { Box } from '@chakra-ui/react';

interface IAddressLineProps {
  children: ReactNode;
}

export function AddressLine({ children }: IAddressLineProps) {
  return <Box>{children}</Box>;
}
