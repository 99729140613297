import { EColor } from '../colors';
import type { ComponentSingleStyleConfig } from '@chakra-ui/react';

export const RadioConfig: ComponentSingleStyleConfig = {
  baseStyle: {
    control: {
      borderColor: EColor.BrandCorduroy,
      borderWidth: '1px',
      _checked: {
        borderColor: EColor.BrandDark,
      },
    },
  },
};
