import { useQuery } from '@tanstack/react-query';

import { useApi } from '../../../../ApiClient';
import { useModalContext } from '../../../hooks/useModalContext';
import { IPaymentMethod } from '../types';

interface IPaymentMethodInDto {
  data: IPaymentMethod[];
}

export const usePaymentMethods = () => {
  const apiClient = useApi();
  const { checkoutApiUrl } = useModalContext();

  return useQuery<IPaymentMethodInDto, unknown>({
    queryKey: ['get-payment-methods'],
    queryFn: async () => {
      const data = await apiClient!.get<IPaymentMethodInDto>(
        `${checkoutApiUrl}/checkout/payment-methods`
      );

      return data;
    },
    // TODO: investigate why invalidateQueries is not working. Right now use refetchOnMount instead
    refetchOnMount: true,
  });
};
