import React, { forwardRef } from 'react';

import { Select as ChakraSelect, SelectProps } from '@chakra-ui/react';

import { EColor } from '../Theme';

type TOption = {
  label: string;
  value: string | number;
};

export interface ISelectProps extends SelectProps {
  options: TOption[];
}

export const Select = forwardRef<HTMLSelectElement, ISelectProps>(
  (props, ref) => {
    const { options, ...restProps } = props;

    return (
      <ChakraSelect
        ref={ref}
        {...(!restProps.color && {
          style: !restProps.value
            ? {
                color: EColor.Gray500,
                fontWeight: 400,
              }
            : undefined,
        })}
        {...restProps}
      >
        {options.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </ChakraSelect>
    );
  }
);

Select.displayName = 'Select';
