import React, { FC, Fragment, useEffect } from 'react';

import {
  Box,
  Button,
  HStack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';

import { ProgressCircle } from './ProgressCircle';
import { VerificationSection } from './VerificationSection';
import { EGTMEvents, useGTMDataLayer } from '../../../hooks';
import { EButtonVariant, EColor, ETextVariant } from '../../../Theme';
import { ModalContent } from '../../components';
import { useModalContext } from '../../hooks/useModalContext';
import { useUpdateInfo } from '../../hooks/useUpdateInfo';
import { useModalStore } from '../../stores/modal';
import { useProfileStore } from '../../stores/profile';
import { EModalContent } from '../../types/modalContent';

export const CompleteProfile: FC = () => {
  const { profile, profileCompleted } = useProfileStore();
  const { addPaymentMethodLoginFlow } = useModalContext();
  const pushGTMData = useGTMDataLayer();

  const {
    isPhoneVerified = false,
    isEmailVerified = false,
    hasAddress = false,
    hasPaymentMethods = false,
  } = profile ?? {};
  const modalStore = useModalStore();

  useUpdateInfo();

  const handleClose = () => {
    if (profileCompleted) {
      window.dispatchEvent(new CustomEvent('profile:completed'));
    }

    modalStore.clear();
  };

  const signUpFlowConfig = [
    {
      label: 'Phone number',
      isVerified: isPhoneVerified,
      onClick: () => null,
    },
    {
      label: 'Email address',
      isVerified: isEmailVerified,
      onClick: () => {
        modalStore.navigate({
          name: EModalContent.COLLECT_VERIFY_EMAIL_ADDRESS,
        });
      },
    },
    {
      label: 'Shipping address',
      isVerified: hasAddress,
      onClick: () => {
        modalStore.navigate({ name: EModalContent.ADD_SHIPPING_ADDRESS });
      },
      lastItem: true,
    },
  ];

  const addPaymentSectionsConfig = [
    ...signUpFlowConfig,
    {
      label: 'Payment method',
      isVerified: hasPaymentMethods,
      onClick: () => {
        modalStore.navigate({ name: EModalContent.ADD_PAYMENT_METHOD });
      },
    },
  ];

  const sectionsConfig = addPaymentMethodLoginFlow
    ? addPaymentSectionsConfig
    : signUpFlowConfig;

  const isCompleted = isPhoneVerified && isEmailVerified && hasAddress;

  const isFinalCompleted = addPaymentMethodLoginFlow
    ? isCompleted && hasPaymentMethods
    : isCompleted;

  useEffect(() => {
    pushGTMData({
      event: isFinalCompleted
        ? EGTMEvents.PROFILE_COMPLETE_VIEW
        : EGTMEvents.COMPLETE_PROFILE_VIEW,
    });
  }, [isFinalCompleted, pushGTMData]);

  return (
    <ModalContent
      header={
        <HStack>
          {!isFinalCompleted ? (
            <ProgressCircle />
          ) : (
            <Text fontSize="34px" lineHeight="34px" mr="8px">
              &#127881;
            </Text>
          )}
          <Text variant={ETextVariant.LG}>
            {!isFinalCompleted ? 'Complete your profile' : 'Profile Complete'}
          </Text>
        </HStack>
      }
      p="16px 24px 24px"
    >
      <VStack spacing={4}>
        <Text
          alignSelf="start"
          color={EColor.Black}
          lineHeight="26px"
          variant={ETextVariant.BaseParagraph}
        >
          {!isFinalCompleted
            ? 'You’re almost ready to '
            : 'Thanks! You’re all set to '}
          <Text as="span" fontWeight="700">
            buy.
          </Text>
        </Text>

        <Box w="100%">
          {sectionsConfig.map((config) => (
            <Fragment key={config.label}>
              <StackDivider bg={EColor.Neutral10} h="1px" mx="-24px" />
              <VerificationSection {...config} />
            </Fragment>
          ))}
        </Box>

        {isFinalCompleted && (
          <Button
            fontFamily="ABC Diatype Mono"
            fontSize="16px"
            fontWeight={700}
            height="54px"
            lineHeight="16px"
            variant={EButtonVariant.BRAND_PRIMARY}
            w="100%"
            onClick={handleClose}
          >
            Start!
          </Button>
        )}
      </VStack>
    </ModalContent>
  );
};
