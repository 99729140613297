import { EColor } from '../colors';
import type { ComponentSingleStyleConfig } from '@chakra-ui/react';

export enum EButtonVariant {
  Black = 'black',
  White = 'white',
  BRAND_PRIMARY = 'brand-primary',
  BRAND_SECONDARY = 'brand-secondary',
}

export const ButtonConfig: ComponentSingleStyleConfig = {
  baseStyle: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '100px',
    textTransform: 'uppercase',
  },
  variants: {
    link: {
      borderWidth: '0px',
      textTransform: 'none',
      color: EColor.MediumBlue,
    },
    [EButtonVariant.Black]: {
      color: EColor.White,
      backgroundColor: EColor.BrandDark,
      borderColor: EColor.BrandDark,
      _hover: {
        _disabled: {
          backgroundColor: EColor.BrandLightGray,
        },
      },
      _disabled: {
        color: EColor.White,
        backgroundColor: EColor.BrandLightGray,
        borderColor: EColor.BrandLightGray,
        _loading: {
          color: EColor.White,
          backgroundColor: EColor.BrandDark,
          borderColor: EColor.BrandDark,
          opacity: 1,
          _hover: {
            backgroundColor: EColor.BrandDark,
          },
        },
      },
    },
    [EButtonVariant.White]: {
      color: EColor.BrandDark,
      backgroundColor: EColor.White,
      borderColor: EColor.BrandDark,
    },
    [EButtonVariant.BRAND_PRIMARY]: {
      fontFamily: 'ABC Diatype Mono',
      color: EColor.White,
      backgroundColor: EColor.Black,
      borderColor: EColor.Black,
      borderWidth: '1px',
      borderRadius: '100px',
      _hover: {
        backgroundColor: EColor.Neutral80,
        _disabled: {
          cursor: 'not-allowed',
          transform: 'none',
          backgroundColor: EColor.Neutral15,
        },
      },
      _disabled: {
        color: EColor.Neutral35,
        backgroundColor: EColor.Neutral15,
        borderColor: EColor.Neutral15,
        opacity: 1,
        _hover: { cursor: 'not-allowed' },
        _loading: {
          color: EColor.Black,
        },
      },
      _active: {
        backgroundColor: EColor.Neutral75,
        borderColor: EColor.Neutral75,
        _disabled: {
          color: EColor.Neutral35,
          backgroundColor: EColor.Neutral15,
          borderColor: EColor.Neutral15,
        },
        _loading: {
          color: EColor.Black,
        },
      },
    },
    [EButtonVariant.BRAND_SECONDARY]: {
      fontFamily: 'ABC Diatype Mono',
      color: EColor.Black,
      backgroundColor: EColor.White,
      borderColor: EColor.Black,
      p: '12px 24px',
      borderWidth: '1px',
      borderRadius: '100px',
      _hover: {
        backgroundColor: EColor.Neutral5,
        _disabled: {
          cursor: 'not-allowed',
          transform: 'none',
          backgroundColor: EColor.Neutral15,
        },
      },
      _disabled: {
        color: EColor.Neutral35,
        backgroundColor: EColor.Neutral15,
        borderColor: EColor.Neutral15,
        opacity: 1,
        _hover: { cursor: 'not-allowed' },
        _active: {
          backgroundColor: EColor.Neutral15,
        },
        _loading: {
          color: EColor.Black,
        },
      },
      _active: {
        color: 'black',
        backgroundColor: EColor.Neutral10,
        borderColor: EColor.BrandDark,
        _disabled: {
          color: EColor.Neutral35,
          borderColor: EColor.Neutral15,
        },
        _loading: {
          color: EColor.Black,
        },
      },
    },
  },
  sizes: {
    sm: {
      h: '32px',
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '16px',
      letterSpacing: '2%',
      px: '12px',
    },
    md: {
      h: '40px',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '16px',
      letterSpacing: '3%',
      px: '24px',
    },
    lg: {
      h: '54px',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '16px',
      letterSpacing: '3%',
      px: '36px',
    },
  },
  defaultProps: {
    size: 'md',
  },
};
