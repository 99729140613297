import React, { FC, useMemo, useState } from 'react';

import { Box, Button, Flex, Text } from '@chakra-ui/react';

import { AuctionBadge } from './AuctionBadge';
import { ExtendedBiddingClock } from '../../Icons';
import { EButtonVariant, EColor, ETextVariant } from '../../Theme';
import { EAuctionStatus } from '../types/auction';
import { TPremierAuction } from '../types/premierAuction';
import { TWeeklyAuction } from '../types/weeklyAuction';
import { PREMIER_AUCTION_PATH, WEEKLY_AUCTION_PATH } from '../utils/constants';
import { EBadgeLabels, getAuctionConfig } from '../utils/getAuctionConfig';
import { isWeeklyAuction } from '../utils/helpers';

interface IAuctionItem {
  data: TPremierAuction | TWeeklyAuction;
  baseUrl: string;
}

export const AuctionItem: FC<IAuctionItem> = ({ data, baseUrl }) => {
  const { name, imageUrl, status, id } = data;
  const [mouseDownPositionX, setMouseDownPositionX] = useState<number>(0);
  const { topBadgeConfig, bottomConfig } = getAuctionConfig(data);
  const { startDate, endDate, countdown, isExtended, buttonText } =
    bottomConfig;

  const isDisabled = useMemo(
    () =>
      status === EAuctionStatus.NOT_STARTED &&
      (isWeeklyAuction(data) ? !data.preview : true),
    [data, status]
  );

  const linkProps = useMemo(() => {
    const path = isWeeklyAuction(data)
      ? WEEKLY_AUCTION_PATH
      : PREMIER_AUCTION_PATH;
    return isDisabled ? {} : { href: `${baseUrl}/${path}${id}` };
  }, [baseUrl, data, id, isDisabled]);

  const itemTag = useMemo(() => (isDisabled ? 'div' : 'a'), [isDisabled]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setMouseDownPositionX(e.screenX);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const delta = Math.abs(e.screenX - mouseDownPositionX);
    if (delta > 10) {
      e.preventDefault();
    }
  };

  return (
    <Box onClick={handleClick} onMouseDown={handleMouseDown}>
      <Box
        as={itemTag}
        cursor={isDisabled ? 'default' : 'pointer'}
        draggable={false}
        {...linkProps}
      >
        <Box marginBottom="8px" position="relative">
          <Box
            _hover={{
              boxShadow: isDisabled ? 'none' : '0 4px 17px rgba(0,0,0,.2)',
            }}
            backgroundImage={`url(${imageUrl})`}
            backgroundPosition="center"
            backgroundSize="cover"
            display="flex"
            flexDirection="column"
            height={{ base: '239px', md: '279px' }}
            justifyContent="space-between"
            padding="8px"
            top={0}
            width={{ base: '289px', md: '330px' }}
          >
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <AuctionBadge config={topBadgeConfig} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Flex
        alignItems="center"
        height="32px"
        justifyContent="space-between"
        my="16px"
      >
        <Text
          backgroundColor={EColor.Black}
          color={EColor.White}
          fontFamily="ABC Diatype Mono"
          p="8px"
          textTransform="uppercase"
          variant={ETextVariant.XSBold}
        >
          {`${startDate} – ${endDate}`}
        </Text>
        {isExtended ? (
          <Flex
            alignItems="center"
            backgroundColor={EColor.Cherry}
            flexGrow={1}
            gap="8px"
            justifyContent="center"
            p="8px"
          >
            <ExtendedBiddingClock />
            <Text
              color={EColor.Black}
              textAlign="center"
              textTransform="capitalize"
              variant={ETextVariant.XSBold}
            >
              {EBadgeLabels.IN_EXTENDED}
            </Text>
          </Flex>
        ) : (
          <Text
            fontFamily="ABC Diatype Mono"
            mr="16px"
            textTransform="uppercase"
            variant={ETextVariant.BaseMono}
          >
            {countdown}
          </Text>
        )}
      </Flex>

      <Text color={EColor.BrandDark} variant={ETextVariant.BaseBold}>
        {name}
      </Text>
      {buttonText && (
        <Button
          as="a"
          cursor={isDisabled ? 'default' : 'pointer'}
          mt="16px"
          variant={EButtonVariant.BRAND_SECONDARY}
          {...linkProps}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
};
