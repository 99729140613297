export const countries = [
  { value: 'US', id: 1, label: 'United States' },
  { value: 'CA', id: 2, label: 'Canada' },
  { value: 'AA', id: 4, label: 'APO/FPO' },
  { value: 'AF', id: 7, label: 'Afghanistan' },
  { value: 'AL', id: 10, label: 'Albania' },
  { value: 'DZ', id: 62, label: 'Algeria' },
  { value: 'AS', id: 16, label: 'American Samoa' },
  { value: 'AD', id: 5, label: 'Andorra' },
  { value: 'AO', id: 13, label: 'Angola' },
  { value: 'AI', id: 9, label: 'Anguilla' },
  { value: 'AQ', id: 14, label: 'Antarctica' },
  { value: 'AG', id: 8, label: 'Antigua and Barbuda' },
  { value: 'AR', id: 15, label: 'Argentina' },
  { value: 'AM', id: 11, label: 'Armenia' },
  { value: 'AW', id: 19, label: 'Aruba' },
  { value: 'AU', id: 18, label: 'Australia' },
  { value: 'AT', id: 17, label: 'Austria' },
  { value: 'AZ', id: 20, label: 'Azerbaijan' },
  { value: 'BS', id: 34, label: 'Bahamas' },
  { value: 'BH', id: 27, label: 'Bahrain' },
  { value: 'BD', id: 23, label: 'Bangladesh' },
  { value: 'BB', id: 22, label: 'Barbados' },
  { value: 'BY', id: 38, label: 'Belarus' },
  { value: 'BE', id: 24, label: 'Belgium' },
  { value: 'BZ', id: 39, label: 'Belize' },
  { value: 'BJ', id: 29, label: 'Benin' },
  { value: 'BM', id: 30, label: 'Bermuda' },
  { value: 'BT', id: 35, label: 'Bhutan' },
  { value: 'BO', id: 32, label: 'Bolivia' },
  { value: 'BA', id: 21, label: 'Bosnia and Herzegovina' },
  { value: 'BW', id: 37, label: 'Botswana' },
  { value: 'BV', id: 36, label: 'Bouvet Island' },
  { value: 'BR', id: 33, label: 'Brazil' },
  { value: 'IO', id: 105, label: 'British Indian Ocean Territory' },
  { value: 'BN', id: 31, label: 'Brunei Darussalam' },
  { value: 'BG', id: 26, label: 'Bulgaria' },
  { value: 'BF', id: 25, label: 'Burkina Faso' },
  { value: 'BI', id: 28, label: 'Burundi' },
  { value: 'KH', id: 116, label: 'Cambodia' },
  { value: 'CM', id: 48, label: 'Cameroon' },
  { value: 'CV', id: 53, label: 'Cape Verde' },
  { value: 'KY', id: 123, label: 'Cayman Islands' },
  { value: 'CF', id: 42, label: 'Central African Republic' },
  { value: 'TD', id: 211, label: 'Chad' },
  { value: 'CL', id: 47, label: 'Chile' },
  { value: 'CN', id: 49, label: 'China' },
  { value: 'CX', id: 54, label: 'Christmas Island' },
  { value: 'CC', id: 40, label: 'Cocos (Keeling) Islands' },
  { value: 'CO', id: 50, label: 'Colombia' },
  { value: 'KM', id: 118, label: 'Comoros' },
  { value: 'CG', id: 43, label: 'Congo' },
  { value: 'CD', id: 41, label: 'Congo, The Democratic Republic of the' },
  { value: 'CK', id: 46, label: 'Cook Islands' },
  { value: 'CR', id: 51, label: 'Costa Rica' },
  { value: 'CI', id: 45, label: "Cote d'Ivoire" },
  { value: 'HR', id: 98, label: 'Croatia' },
  { value: 'CU', id: 52, label: 'Cuba' },
  { value: 'CY', id: 55, label: 'Cyprus' },
  { value: 'CZ', id: 56, label: 'Czech Republic' },
  { value: 'KP', id: 120, label: "Democratic People's Republic of Korea" },
  { value: 'DK', id: 59, label: 'Denmark' },
  { value: 'DJ', id: 58, label: 'Djibouti' },
  { value: 'DM', id: 60, label: 'Dominica' },
  { value: 'DO', id: 61, label: 'Dominican Republic' },
  { value: 'EC', id: 63, label: 'Ecuador' },
  { value: 'EG', id: 65, label: 'Egypt' },
  { value: 'SV', id: 207, label: 'El Salvador' },
  { value: 'GQ', id: 88, label: 'Equatorial Guinea' },
  { value: 'ER', id: 67, label: 'Eritrea' },
  { value: 'EE', id: 64, label: 'Estonia' },
  { value: 'ET', id: 69, label: 'Ethiopia' },
  { value: 'FK', id: 72, label: 'Falkland Islands (Malvinas)' },
  { value: 'FO', id: 74, label: 'Faroe Islands' },
  { value: 'FM', id: 73, label: 'Federated States of Micronesia' },
  { value: 'FJ', id: 71, label: 'Fiji' },
  { value: 'FI', id: 70, label: 'Finland' },
  { value: 'FR', id: 75, label: 'France' },
  { value: 'GF', id: 80, label: 'French Guiana' },
  { value: 'PF', id: 173, label: 'French Polynesia. Includes Tahiti' },
  { value: 'TF', id: 212, label: 'French Southern Territories' },
  { value: 'GA', id: 76, label: 'Gabon' },
  { value: 'GM', id: 85, label: 'Gambia' },
  { value: 'GE', id: 79, label: 'Georgia' },
  { value: 'DE', id: 57, label: 'Germany' },
  { value: 'GH', id: 82, label: 'Ghana' },
  { value: 'GI', id: 83, label: 'Gibraltar' },
  { value: 'GR', id: 89, label: 'Greece' },
  { value: 'GL', id: 84, label: 'Greenland' },
  { value: 'GD', id: 78, label: 'Grenada' },
  { value: 'GP', id: 87, label: 'Guadeloupe' },
  { value: 'GU', id: 92, label: 'Guam' },
  { value: 'GT', id: 91, label: 'Guatemala' },
  { value: 'GG', id: 81, label: 'Guernsey' },
  { value: 'GN', id: 86, label: 'Guinea' },
  { value: 'GW', id: 93, label: 'Guinea-Bissau' },
  { value: 'GY', id: 94, label: 'Guyana' },
  { value: 'HT', id: 99, label: 'Haiti' },
  { value: 'HM', id: 96, label: 'Heard Island and McDonald Islands' },
  { value: 'VA', id: 230, label: 'Holy See (Vatican City state)' },
  { value: 'HN', id: 97, label: 'Honduras' },
  { value: 'HK', id: 95, label: 'Hong Kong' },
  { value: 'HU', id: 100, label: 'Hungary' },
  { value: 'IS', id: 108, label: 'Iceland' },
  { value: 'IN', id: 104, label: 'India' },
  { value: 'ID', id: 101, label: 'Indonesia' },
  { value: 'IQ', id: 106, label: 'Iraq' },
  { value: 'IE', id: 102, label: 'Ireland' },
  { value: 'IR', id: 107, label: 'Islamic Republic of Iran' },
  { value: 'IL', id: 103, label: 'Israel' },
  { value: 'IT', id: 109, label: 'Italy' },
  { value: 'JM', id: 111, label: 'Jamaica' },
  { value: 'JP', id: 113, label: 'Japan' },
  { value: 'JE', id: 110, label: 'Jersey' },
  { value: 'JO', id: 112, label: 'Jordan' },
  { value: 'KZ', id: 124, label: 'Kazakhstan' },
  { value: 'KE', id: 114, label: 'Kenya' },
  { value: 'KI', id: 117, label: 'Kiribati' },
  { value: 'KW', id: 122, label: 'Kuwait' },
  { value: 'KG', id: 115, label: 'Kyrgyzstan' },
  { value: 'LA', id: 125, label: "Lao People's Democratic Republic" },
  { value: 'LV', id: 134, label: 'Latvia' },
  { value: 'LB', id: 126, label: 'Lebanon' },
  { value: 'LS', id: 131, label: 'Lesotho' },
  { value: 'LR', id: 130, label: 'Liberia' },
  { value: 'LY', id: 135, label: 'Libyan Arab Jamahiriya' },
  { value: 'LI', id: 128, label: 'Liechtenstein' },
  { value: 'LT', id: 132, label: 'Lithuania' },
  { value: 'LU', id: 133, label: 'Luxembourg' },
  { value: 'MO', id: 146, label: 'Macao' },
  { value: 'MG', id: 140, label: 'Madagascar' },
  { value: 'MW', id: 154, label: 'Malawi' },
  { value: 'MY', id: 156, label: 'Malaysia' },
  { value: 'MV', id: 153, label: 'Maldives' },
  { value: 'ML', id: 143, label: 'Mali' },
  { value: 'MT', id: 151, label: 'Malta' },
  { value: 'MH', id: 141, label: 'Marshall Islands' },
  { value: 'MQ', id: 148, label: 'Martinique' },
  { value: 'MR', id: 149, label: 'Mauritania' },
  { value: 'MU', id: 152, label: 'Mauritius' },
  { value: 'YT', id: 240, label: 'Mayotte' },
  { value: 'MX', id: 155, label: 'Mexico' },
  { value: 'MC', id: 137, label: 'Monaco' },
  { value: 'MN', id: 145, label: 'Mongolia' },
  { value: 'ME', id: 139, label: 'Montenegro' },
  { value: 'MS', id: 150, label: 'Montserrat' },
  { value: 'MA', id: 136, label: 'Morocco' },
  { value: 'MZ', id: 157, label: 'Mozambique' },
  { value: 'MM', id: 144, label: 'Myanmar' },
  { value: 'NA', id: 158, label: 'Namibia' },
  { value: 'NR', id: 167, label: 'Nauru' },
  { value: 'NP', id: 166, label: 'Nepal' },
  { value: 'NL', id: 164, label: 'Netherlands' },
  { value: 'AN', id: 12, label: 'Netherlands Antilles' },
  { value: 'NC', id: 159, label: 'New Caledonia' },
  { value: 'NZ', id: 169, label: 'New Zealand' },
  { value: 'NI', id: 163, label: 'Nicaragua' },
  { value: 'NE', id: 160, label: 'Niger' },
  { value: 'NG', id: 162, label: 'Nigeria' },
  { value: 'NU', id: 168, label: 'Niue' },
  { value: 'TP', id: 220, label: 'No longer in use' },
  { value: 'NF', id: 161, label: 'Norfolk Island' },
  { value: 'MP', id: 147, label: 'Northern Mariana Islands' },
  { value: 'NO', id: 165, label: 'Norway' },
  { value: 'OM', id: 170, label: 'Oman' },
  { value: 'PK', id: 176, label: 'Pakistan' },
  { value: 'PW', id: 183, label: 'Palau' },
  { value: 'PS', id: 181, label: 'Palestinian territory, Occupied' },
  { value: 'PA', id: 171, label: 'Panama' },
  { value: 'PG', id: 174, label: 'Papua New Guinea' },
  { value: 'PY', id: 184, label: 'Paraguay' },
  { value: 'PE', id: 172, label: 'Peru' },
  { value: 'PH', id: 175, label: 'Philippines' },
  { value: 'PN', id: 179, label: 'Pitcairn' },
  { value: 'PL', id: 177, label: 'Poland' },
  { value: 'PT', id: 182, label: 'Portugal' },
  { value: 'PR', id: 180, label: 'Puerto Rico' },
  { value: 'QA', id: 185, label: 'Qatar' },
  { value: 'KR', id: 121, label: 'Republic of Korea' },
  { value: 'MD', id: 138, label: 'Republic of Moldova' },
  { value: 'RE', id: 186, label: 'Reunion' },
  { value: 'RO', id: 187, label: 'Romania' },
  { value: 'RU', id: 189, label: 'Russian Federation' },
  { value: 'RW', id: 190, label: 'Rwanda' },
  { value: 'SH', id: 197, label: 'Saint Helena' },
  { value: 'KN', id: 119, label: 'Saint Kitts and Nevis' },
  { value: 'LC', id: 127, label: 'Saint Lucia' },
  { value: 'PM', id: 178, label: 'Saint Pierre and Miquelon' },
  { value: 'VC', id: 231, label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', id: 238, label: 'Samoa' },
  { value: 'SM', id: 202, label: 'San Marino' },
  { value: 'ST', id: 206, label: 'Sao Tome and Principe' },
  { value: 'SA', id: 191, label: 'Saudi Arabia' },
  { value: 'SN', id: 203, label: 'Senegal' },
  { value: 'RS', id: 188, label: 'Serbia' },
  { value: 'SC', id: 193, label: 'Seychelles' },
  { value: 'SL', id: 201, label: 'Sierra Leone' },
  { value: 'SG', id: 196, label: 'Singapore' },
  { value: 'SK', id: 200, label: 'Slovakia' },
  { value: 'SI', id: 198, label: 'Slovenia' },
  { value: 'SB', id: 192, label: 'Solomon Islands' },
  { value: 'SO', id: 204, label: 'Somalia' },
  { value: 'ZA', id: 241, label: 'South Africa' },
  {
    value: 'GS',
    id: 90,
    label: 'South Georgia and the South Sandwich Islands',
  },
  { value: 'ES', id: 68, label: 'Spain' },
  { value: 'LK', id: 129, label: 'Sri Lanka' },
  { value: 'SD', id: 194, label: 'Sudan' },
  { value: 'SR', id: 205, label: 'Suriname' },
  { value: 'SJ', id: 199, label: 'Svalbard and Jan Mayen' },
  { value: 'SZ', id: 209, label: 'Swaziland' },
  { value: 'SE', id: 195, label: 'Sweden' },
  { value: 'CH', id: 44, label: 'Switzerland' },
  { value: 'SY', id: 208, label: 'Syrian Arab Republic' },
  { value: 'TW', id: 224, label: 'Taiwan, Province of China' },
  { value: 'TJ', id: 215, label: 'Tajikistan' },
  { value: 'TZ', id: 225, label: 'Tanzania, United Republic of' },
  { value: 'TH', id: 214, label: 'Thailand' },
  { value: 'MK', id: 142, label: 'The Former Yugoslav Republic of Macedonia' },
  { value: 'TG', id: 213, label: 'Togo' },
  { value: 'TK', id: 216, label: 'Tokelau' },
  { value: 'TO', id: 219, label: 'Tonga' },
  { value: 'TT', id: 222, label: 'Trinidad and Tobago' },
  { value: 'TN', id: 218, label: 'Tunisia' },
  { value: 'TM', id: 217, label: 'Turkmenistan' },
  { value: 'TC', id: 210, label: 'Turks and Caicos Islands' },
  { value: 'TR', id: 221, label: 'Turvalue' },
  { value: 'TV', id: 223, label: 'Tuvalu' },
  { value: 'UG', id: 227, label: 'Uganda' },
  { value: 'UA', id: 226, label: 'Ukraine' },
  { value: 'AE', id: 6, label: 'United Arab Emirates' },
  { value: 'GB', id: 77, label: 'United Kingdom' },
  { value: 'UY', id: 228, label: 'Uruguay' },
  { value: 'UZ', id: 229, label: 'Uzbekistan' },
  { value: 'VU', id: 236, label: 'Vanuatu' },
  { value: 'VE', id: 232, label: 'Venezuela' },
  { value: 'VN', id: 235, label: 'Vietnam' },
  { value: 'VG', id: 233, label: 'Virgin Islands, British' },
  { value: 'VI', id: 234, label: 'Virgin Islands, U.S' },
  { value: 'WF', id: 237, label: 'Wallis and Futuna' },
  { value: 'EH', id: 66, label: 'Western Sahara' },
  { value: 'YE', id: 239, label: 'Yemen' },
  { value: 'ZM', id: 242, label: 'Zambia' },
  { value: 'ZW', id: 243, label: 'Zimbabwe' },
];

export const UNKNOWN_COUNTRY_ID = 244;
