import React from 'react';

import { Link, Flex, Icon, Box, BoxProps } from '@chakra-ui/react';

import { LiveSuperscript } from '../../../components/LiveSuperscript';
import { EGTMEvents, useGTMDataLayer } from '../../../hooks';
import { EColor } from '../../../Theme';
import { INavigationItem } from '../../types/navigation';

export interface IAccordionNavLinkProps extends BoxProps {
  item: INavigationItem;
  showIcons?: boolean;
  align?: string;
  parents?: string[];
}

export function AccordionNavLink({
  item,
  showIcons = true,
  align = 'left',
  parents = [],
  ...rest
}: IAccordionNavLinkProps) {
  const pushGTMData = useGTMDataLayer();

  const handleClick = () => {
    const navBreakdownText = [...parents, item.name].join(' | ');
    pushGTMData({
      event: EGTMEvents.NAVIGATION_CLICK,
      navigationBreakdown: navBreakdownText,
      clickValue: item.name,
    });
  };

  return (
    <Box
      _hover={{
        textDecoration: 'none',
        bg: EColor.Neutral5,
      }}
      as={item.path ? Link : Box}
      display="flex"
      fontSize="20px"
      fontWeight={{ base: '700' }}
      href={item.path}
      justifyContent="space-between"
      key={item.name}
      lineHeight="24px"
      paddingY="8px"
      width="100%"
      onClick={handleClick}
      {...rest}
    >
      {align === 'center' && (
        <Box height="20px" mb="4px" mr="4px" width="13px" />
      )}
      <Flex
        align="center"
        justify="space-between"
        width={align === 'center' ? undefined : '200px'}
      >
        <Flex align="center">
          {item.Icon && showIcons ? (
            <Icon as={item.Icon} boxSize="24px" paddingRight="7px" />
          ) : null}
          {item.name}
        </Flex>
        {item.superscript && showIcons && (
          <LiveSuperscript marginRight="24px">
            {item.superscript}
          </LiveSuperscript>
        )}
      </Flex>
    </Box>
  );
}
