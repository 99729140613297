import { useState } from 'react';

import { useDebounce } from './useDebounce';

export function useContextualDisclosure() {
  const [states, setStates] = useState<Record<string, boolean>>({});
  const debouncedStates = useDebounce(states, 30);

  return {
    isOpen: (id: string) => !!debouncedStates[id],
    onOpen: (id: string) =>
      setStates((oldStates) => ({ ...oldStates, [id]: true })),
    onClose: (id: string) =>
      setStates((oldStates) => ({ ...oldStates, [id]: false })),
    anyOpen: Object.values(debouncedStates).some((state) => state),
  };
}
