import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  InternalAxiosRequestConfig,
} from 'axios';
import Cookies from 'js-cookie';

import { IApiClient } from './types/apiClient';
import { ACCESS_TOKEN_COOKIE, Auth } from '../Auth/Auth';
import { isClient } from '../Auth/utils/env';

export class ApiClient implements IApiClient {
  auth: Auth;

  service: AxiosInstance;

  isRefreshingToken = false;

  refreshPromise?: Promise<boolean>;

  baseUrl?: string;

  constructor(auth: Auth, baseUrl: string) {
    this.auth = auth;
    this.baseUrl = baseUrl;

    const headers = {
      'Content-Type': 'application/json',
    } as AxiosRequestHeaders;

    this.service = axios.create({
      baseURL: baseUrl || 'https://sales-history-api.services.pwccmp.com/',
      headers,
      withCredentials: true,
    });

    const pwccAccessToken = Cookies.get(ACCESS_TOKEN_COOKIE);
    this.service.defaults.headers.common.Authorization = pwccAccessToken
      ? `Bearer ${pwccAccessToken}`
      : '';

    this.service.interceptors.response.use(
      (config) => config,
      this.handleError
    );

    // Pause requests while refreshing tokens
    this.service.interceptors.request.use(async (config) => {
      if (this.isRefreshingToken && !config?.url?.includes('refresh')) {
        await this.waitForRefreshToComplete();
        return {
          ...config,
          headers: {
            Authorization: this.service.defaults.headers.common.Authorization,
          },
        } as InternalAxiosRequestConfig;
      }
      return config;
    });
  }

  async waitForRefreshToComplete() {
    return this.refreshPromise || true;
  }

  async attemptUserLogin() {
    await this.auth.fetchProfile(this);
  }

  async setToken(accessToken: string) {
    this.service.defaults.headers.common.Authorization = accessToken
      ? `Bearer ${accessToken}`
      : '';
  }

  handleError = async (error: AxiosError) => {
    if (!error.response) {
      return Promise.reject(error);
    }
    const url = error.response.config?.url;

    switch (error.response.status) {
      case 401:
        // If we get a 401 while refreshing, our tokens are bad
        if (url?.includes('refresh')) {
          delete this.service.defaults.headers.common.Authorization;
          this.auth.logout();
          break;
        }

        // Don't refresh if attempting to login
        if (url?.includes('login')) break;

        // Token expired
        if (isClient()) {
          if (await this.attemptTokenRefresh()) {
            return axios.request({
              ...error.config,
              headers: {
                ...error.config?.headers,
                Authorization:
                  this.service.defaults.headers.common.Authorization,
              },
            });
          }
          delete this.service.defaults.headers.common.Authorization;
          this.auth.logout();
        } else {
          delete this.service.defaults.headers.common.Authorization;
          this.auth.logout();
        }
        break;
      default:
        // Internal server error
        return Promise.reject(error);
    }
    return Promise.reject(error);
  };

  async refreshToken() {
    this.isRefreshingToken = true;

    try {
      const response = await this.service.post(
        `/security/api/${this.auth.authApiVersion}/auth/refresh`,
        {},
        {
          baseURL: this.auth.authBaseUrl,
          headers: {
            Authorization: '',
          },
        }
      );

      const newTokens = response.data;
      await this.setToken(newTokens.accessToken);

      this.isRefreshingToken = false;

      return true;
    } catch (e) {
      this.isRefreshingToken = false;

      return false;
    }
  }

  async attemptTokenRefresh() {
    if (this.isRefreshingToken && this.refreshPromise) {
      return this.waitForRefreshToComplete();
    }
    this.refreshPromise = this.refreshToken();
    return this.refreshPromise;
  }

  redirectTo = (url: string) => {
    if (isClient()) {
      window.location.replace(url);
    }
  };

  async get<T>(url: string, config?: AxiosRequestConfig<any>): Promise<T> {
    const response = await this.service.get(url, config);
    return response?.data;
  }

  async post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<any>
  ): Promise<T> {
    const response = await this.service.post(url, data, config);
    return response?.data;
  }

  async put<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<any>
  ): Promise<T> {
    const response = await this.service.put(url, data, config);
    return response?.data;
  }

  async patch<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<any>
  ): Promise<T> {
    const response = await this.service.patch(url, data, config);
    return response?.data;
  }

  async delete<T>(url: string, config?: AxiosRequestConfig<any>): Promise<T> {
    const response = await this.service.delete(url, config);
    return response?.data;
  }
}
