import React from 'react';

import { createIcon } from '@chakra-ui/react';

import { EColor } from '../Theme/components/colors';

export const CardVisaIcon = createIcon({
  displayName: 'CardVisaIcon',
  viewBox: '0 0 36 24',
  path: (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 36 24"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="white"
        height="23"
        rx="3.5"
        stroke={EColor.Neutral15}
        width="35"
        x="0.5"
        y="0.5"
      />
      <path
        clipRule="evenodd"
        d="M11.3003 15.7582H9.11936L7.48395 9.69237C7.40633 9.41334 7.24151 9.16666 6.99907 9.0504C6.39403 8.75823 5.72732 8.5257 5 8.40843V8.17489H8.51326C8.99814 8.17489 9.3618 8.5257 9.42241 8.93313L10.271 13.3086L12.4508 8.17489H14.5711L11.3003 15.7582ZM15.7833 15.7582H13.7236L15.4196 8.17489H17.4793L15.7833 15.7582ZM20.144 10.2757C20.2046 9.86728 20.5683 9.63374 20.9926 9.63374C21.6593 9.5751 22.3855 9.69238 22.9916 9.98354L23.3553 8.35081C22.7492 8.11727 22.0825 8 21.4775 8C19.4784 8 18.0237 9.05041 18.0237 10.5082C18.0237 11.6173 19.0541 12.1996 19.7814 12.5504C20.5683 12.9002 20.8714 13.1337 20.8107 13.4835C20.8107 14.0082 20.2046 14.2418 19.5996 14.2418C18.8723 14.2418 18.145 14.0669 17.4793 13.7747L17.1157 15.4085C17.843 15.6996 18.6298 15.8169 19.3572 15.8169C21.5987 15.8745 22.9916 14.8251 22.9916 13.25C22.9916 11.2665 20.144 11.1502 20.144 10.2757ZM30.2 15.7582L28.5646 8.17489H26.808C26.4443 8.17489 26.0806 8.40843 25.9594 8.75823L22.931 15.7582H25.0513L25.4745 14.6502H28.0797L28.3221 15.7582H30.2ZM27.111 10.2171L27.716 13.0751H26.02L27.111 10.2171Z"
        fill="#172B85"
        fillRule="evenodd"
      />
    </svg>
  ),
});

export const CardAmexIcon = createIcon({
  displayName: 'CardAmexIcon',
  viewBox: '0 0 36 24',
  path: (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 36 24"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#016FD0" height="24" rx="4" width="36" />
      <path
        d="M6.97095 19.0257V13.3877H12.9406L13.5811 14.2226L14.2427 13.3877H35.9111V18.6369C35.9111 18.6369 35.3444 19.0201 34.689 19.0257H22.6908L21.9687 18.137V19.0257H19.6024V17.5086C19.6024 17.5086 19.2791 17.7204 18.5803 17.7204H17.7749V19.0257H14.1921L13.5525 18.1729L12.9031 19.0257H6.97095Z"
        fill="white"
      />
      <path
        d="M0 9.13841L1.34618 6H3.67425L4.43823 7.75801V6H7.33224L7.78703 7.27064L8.22794 6H21.219V6.63879C21.219 6.63879 21.902 6 23.0243 6L27.2395 6.01473L27.9903 7.74973V6H30.4121L31.0787 6.99662V6H33.5228V11.638H31.0787L30.4399 10.6382V11.638H26.8816L26.5238 10.7493H25.5672L25.2152 11.638H22.8021C21.8364 11.638 21.219 11.0123 21.219 11.0123V11.638H17.5807L16.8586 10.7493V11.638H3.32936L2.97178 10.7493H2.01825L1.66319 11.638H0V9.13841Z"
        fill="white"
      />
      <path
        d="M1.8218 6.69495L0.00610352 10.9165H1.18822L1.52324 10.0712H3.47086L3.80415 10.9165H5.0123L3.19833 6.69495H1.8218ZM2.49358 7.67744L3.08724 9.15465H1.89818L2.49358 7.67744Z"
        fill="#016FD0"
      />
      <path
        d="M5.13721 10.9153V6.69373L6.81706 6.69997L7.79412 9.42178L8.74778 6.69373H10.4142V10.9153H9.3588V7.80467L8.24005 10.9153H7.31447L6.1926 7.80467V10.9153H5.13721Z"
        fill="#016FD0"
      />
      <path
        d="M11.1372 10.9154V6.69373H14.5812V7.63804H12.2037V8.36016H14.5256V9.24892H12.2037V9.99882H14.5812V10.9154H11.1372Z"
        fill="#016FD0"
      />
      <path
        d="M15.1912 6.69495V10.9166H16.2466V9.41679H16.691L17.9564 10.9166H19.2461L17.8575 9.36124C18.4274 9.31315 19.0153 8.82399 19.0153 8.06455C19.0153 7.17617 18.318 6.69495 17.5398 6.69495H15.1912ZM16.2466 7.63926H17.453C17.7424 7.63926 17.9529 7.86564 17.9529 8.08364C17.9529 8.36411 17.6802 8.52802 17.4686 8.52802H16.2466V7.63926Z"
        fill="#016FD0"
      />
      <path
        d="M20.5254 10.9154H19.4478V6.69373H20.5254V10.9154Z"
        fill="#016FD0"
      />
      <path
        d="M23.0802 10.9154H22.8476C21.7221 10.9154 21.0388 10.0287 21.0388 8.8219C21.0388 7.58531 21.7145 6.69373 23.1357 6.69373H24.3023V7.69358H23.0931C22.5161 7.69358 22.1081 8.14384 22.1081 8.83231C22.1081 9.64988 22.5747 9.99326 23.2468 9.99326H23.5246L23.0802 10.9154Z"
        fill="#016FD0"
      />
      <path
        d="M25.3777 6.69495L23.562 10.9165H24.7441L25.0791 10.0712H27.0268L27.3601 10.9165H28.5682L26.7542 6.69495H25.3777ZM26.0495 7.67744L26.6431 9.15465H25.4541L26.0495 7.67744Z"
        fill="#016FD0"
      />
      <path
        d="M28.6899 10.9154V6.69373H30.0318L31.7451 9.34613V6.69373H32.8005V10.9154H31.502L29.7453 8.19352V10.9154H28.6899Z"
        fill="#016FD0"
      />
      <path
        d="M7.69336 18.3037V14.082H11.1373V15.0263H8.75988V15.7485H11.0818V16.6372H8.75988V17.3871H11.1373V18.3037H7.69336Z"
        fill="#016FD0"
      />
      <path
        d="M24.5679 18.3037V14.082H28.0118V15.0263H25.6344V15.7485H27.9452V16.6372H25.6344V17.3871H28.0118V18.3037H24.5679Z"
        fill="#016FD0"
      />
      <path
        d="M11.2714 18.3036L12.9482 16.2189L11.2314 14.082H12.5611L13.5835 15.403L14.6094 14.082H15.887L14.1928 16.1928L15.8727 18.3036H14.5433L13.5505 17.0035L12.5819 18.3036H11.2714Z"
        fill="#016FD0"
      />
      <path
        d="M15.9968 14.0826V18.3043H17.08V16.9711H18.191C19.131 16.9711 19.8435 16.4724 19.8435 15.5026C19.8435 14.6992 19.2847 14.0826 18.3281 14.0826H15.9968ZM17.08 15.0374H18.25C18.5537 15.0374 18.7707 15.2235 18.7707 15.5234C18.7707 15.8052 18.5548 16.0095 18.2465 16.0095H17.08V15.0374Z"
        fill="#016FD0"
      />
      <path
        d="M20.3022 14.082V18.3037H21.3577V16.8039H21.802L23.0675 18.3037H24.3572L22.9685 16.7483C23.5385 16.7002 24.1264 16.2111 24.1264 15.4516C24.1264 14.5633 23.4291 14.082 22.6509 14.082H20.3022ZM21.3577 15.0263H22.5641C22.8535 15.0263 23.064 15.2527 23.064 15.4707C23.064 15.7512 22.7912 15.9151 22.5797 15.9151H21.3577V15.0263Z"
        fill="#016FD0"
      />
      <path
        d="M28.5014 18.3037V17.3871H30.6136C30.9262 17.3871 31.0615 17.2182 31.0615 17.033C31.0615 16.8555 30.9266 16.6761 30.6136 16.6761H29.6592C28.8295 16.6761 28.3674 16.1706 28.3674 15.4117C28.3674 14.7348 28.7906 14.082 30.0235 14.082H32.0787L31.6343 15.0319H29.8568C29.517 15.0319 29.4124 15.2102 29.4124 15.3805C29.4124 15.5555 29.5417 15.7485 29.8013 15.7485H30.8011C31.726 15.7485 32.1273 16.2731 32.1273 16.9601C32.1273 17.6987 31.6801 18.3037 30.7508 18.3037H28.5014Z"
        fill="#016FD0"
      />
      <path
        d="M32.374 18.3037V17.3871H34.4862C34.7987 17.3871 34.9341 17.2182 34.9341 17.033C34.9341 16.8555 34.7992 16.6761 34.4862 16.6761H33.5317C32.7021 16.6761 32.24 16.1706 32.24 15.4117C32.24 14.7348 32.6631 14.082 33.896 14.082H35.9093L35.5069 15.0319H33.7294C33.3896 15.0319 33.285 15.2102 33.285 15.3805C33.285 15.5555 33.4142 15.7485 33.6738 15.7485H34.6737C35.5986 15.7485 35.9999 16.2731 35.9999 16.9601C35.9999 17.6987 35.5527 18.3037 34.6233 18.3037H32.374Z"
        fill="#016FD0"
      />
    </svg>
  ),
});

export const CardMastercardIcon = createIcon({
  displayName: 'CardMastercardIcon',
  viewBox: '0 0 36 24',
  path: (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 36 24"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="white"
        height="23"
        rx="3.5"
        stroke={EColor.Neutral15}
        width="35"
        x="0.5"
        y="0.5"
      />
      <path
        clipRule="evenodd"
        d="M18.257 17.1569C17.0314 18.1852 15.4415 18.806 13.7043 18.806C9.82794 18.806 6.68555 15.7154 6.68555 11.903C6.68555 8.09057 9.82794 5 13.7043 5C15.4415 5 17.0314 5.62075 18.257 6.64903C19.4826 5.62075 21.0724 5 22.8097 5C26.686 5 29.8284 8.09057 29.8284 11.903C29.8284 15.7154 26.686 18.806 22.8097 18.806C21.0724 18.806 19.4826 18.1852 18.257 17.1569Z"
        fill="#ED0006"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M18.2568 17.1569C19.766 15.8908 20.7229 14.0068 20.7229 11.903C20.7229 9.79917 19.766 7.91516 18.2568 6.64903C19.4825 5.62075 21.0723 5 22.8095 5C26.6859 5 29.8283 8.09057 29.8283 11.903C29.8283 15.7154 26.6859 18.806 22.8095 18.806C21.0723 18.806 19.4825 18.1852 18.2568 17.1569Z"
        fill="#F9A000"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M18.257 17.1569C16.7479 15.8908 15.791 14.0068 15.791 11.903C15.791 9.79917 16.7479 7.91518 18.257 6.64905C19.7661 7.91518 20.723 9.79917 20.723 11.903C20.723 14.0068 19.7661 15.8908 18.257 17.1569Z"
        fill="#FF5E00"
        fillRule="evenodd"
      />
    </svg>
  ),
});

export const CardDiscoverIcon = createIcon({
  displayName: 'CardDiscoverIcon',
  viewBox: '0 0 36 24',
  path: (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 36 24"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="white"
        height="23"
        rx="3.5"
        stroke={EColor.Neutral15}
        width="35"
        x="0.5"
        y="0.5"
      />
      <path
        d="M4.39854 10.0655H3V14.9465H4.39037C5.12971 14.9465 5.66296 14.7731 6.13241 14.3838C6.40807 14.155 6.63029 13.8686 6.78345 13.5448C6.93661 13.2209 7.017 12.8675 7.01897 12.5093C7.01897 11.0698 5.94267 10.0655 4.39854 10.0655ZM5.51084 13.7311C5.2115 14.001 4.8222 14.1188 4.20226 14.1188H3.94872V10.8932H4.20389C4.81892 10.8932 5.18533 11.0028 5.51247 11.2874C5.67976 11.4423 5.81297 11.6304 5.90365 11.8397C5.99432 12.0489 6.04048 12.2747 6.03917 12.5027C6.0401 12.7328 5.99367 12.9606 5.90278 13.1719C5.81188 13.3832 5.67847 13.5736 5.51084 13.7311Z"
        fill="black"
      />
      <path
        d="M8.40805 10.0655H7.45605V14.9465H8.40805V10.0655Z"
        fill="black"
      />
      <path
        d="M10.7367 11.9316C10.1642 11.7272 10.0006 11.5881 10.0006 11.3231C10.0006 11.0173 10.2999 10.7834 10.7105 10.7834C10.8591 10.787 11.0049 10.8244 11.137 10.8927C11.269 10.961 11.3838 11.0584 11.4727 11.1776L11.9635 10.5233C11.5687 10.1716 11.0576 9.97874 10.5289 9.98185C9.67345 9.98512 9.01916 10.5838 9.01916 11.3837C9.01916 12.0559 9.32668 12.3994 10.2198 12.7217C10.4458 12.7944 10.6648 12.8875 10.8741 12.9998C10.9614 13.0519 11.0337 13.126 11.0836 13.2146C11.1335 13.3033 11.1594 13.4035 11.1587 13.5052C11.158 13.5998 11.138 13.6932 11.0999 13.7798C11.0619 13.8664 11.0065 13.9443 10.9373 14.0088C10.8681 14.0732 10.7865 14.1229 10.6974 14.1547C10.6084 14.1865 10.5137 14.1998 10.4193 14.1938C10.2015 14.1968 9.98744 14.1366 9.8031 14.0205C9.61876 13.9044 9.47202 13.7373 9.38066 13.5395L8.76562 14.1317C9.204 14.7762 9.7307 15.0608 10.4553 15.0608C11.4449 15.0608 12.1385 14.4065 12.1385 13.4578C12.1401 12.6857 11.8195 12.334 10.7367 11.9316Z"
        fill="black"
      />
      <path
        d="M12.4415 12.5095C12.4388 12.8473 12.5038 13.1822 12.6327 13.4944C12.7616 13.8066 12.9517 14.0899 13.1918 14.3274C13.432 14.565 13.7172 14.752 14.0309 14.8775C14.3445 15.003 14.68 15.0643 15.0178 15.058C15.4342 15.0587 15.845 14.9612 16.2167 14.7734V13.6529C15.8356 14.0324 15.4987 14.1861 15.0717 14.1861C14.1132 14.1861 13.436 13.491 13.436 12.503C13.4288 12.2877 13.4645 12.0732 13.5411 11.8719C13.6177 11.6705 13.7336 11.4865 13.882 11.3304C14.0305 11.1744 14.2085 11.0494 14.4058 10.9629C14.603 10.8763 14.8155 10.8299 15.0308 10.8264C15.4856 10.8264 15.8291 10.9899 16.2249 11.376V10.2555C15.8661 10.0584 15.4632 9.95537 15.0537 9.95616C14.714 9.95009 14.3764 10.0116 14.0606 10.137C13.7447 10.2624 13.457 10.4493 13.214 10.6869C12.9709 10.9244 12.7775 11.2078 12.6449 11.5207C12.5123 11.8336 12.4432 12.1697 12.4415 12.5095Z"
        fill="black"
      />
      <path
        d="M23.7611 13.3451L22.4591 10.0655H21.4204L23.4912 15.0741H24.0032L26.1117 10.0655H25.0812L23.7611 13.3451Z"
        fill="black"
      />
      <path
        d="M26.543 14.9481H29.2436V14.1205H27.495V12.8037H29.1781V11.9777H27.495V10.8932H29.2436V10.0655H26.543V14.9481Z"
        fill="black"
      />
      <path
        d="M33.0141 11.5066C33.0141 10.5922 32.3844 10.0655 31.2852 10.0655H29.8735V14.9465H30.8255V12.9836H30.9498L32.2666 14.9465H33.4378L31.9018 12.8904C32.6183 12.7497 33.0141 12.259 33.0141 11.5066ZM31.102 12.313H30.8239V10.8408H31.1167C31.7105 10.8408 32.0327 11.0895 32.0327 11.5638C32.0327 12.0382 31.7121 12.313 31.102 12.313Z"
        fill="black"
      />
      <path
        d="M33.7736 10.3222C33.7736 10.2355 33.7131 10.188 33.6101 10.188H33.4727V10.6232H33.5741V10.4596L33.6951 10.6232H33.8211L33.6788 10.4465C33.7069 10.4406 33.7319 10.4249 33.7494 10.402C33.7668 10.3792 33.7754 10.3508 33.7736 10.3222ZM33.5921 10.3794H33.5741V10.2682H33.5937C33.6444 10.2682 33.6706 10.2862 33.6706 10.3222C33.6706 10.3582 33.6428 10.3794 33.5921 10.3794Z"
        fill="black"
      />
      <path
        d="M33.6303 10.0277C33.5556 10.0264 33.4821 10.0474 33.4194 10.088C33.3566 10.1286 33.3074 10.187 33.2779 10.2557C33.2485 10.3245 33.2402 10.4004 33.2541 10.4738C33.268 10.5473 33.3035 10.6149 33.356 10.6681C33.4085 10.7213 33.4757 10.7577 33.5489 10.7726C33.6222 10.7874 33.6982 10.7801 33.7673 10.7516C33.8364 10.7231 33.8955 10.6746 33.9369 10.6124C33.9783 10.5502 34.0003 10.477 34 10.4023C34 10.3038 33.9612 10.2092 33.892 10.1391C33.8228 10.069 33.7288 10.029 33.6303 10.0277ZM33.6303 10.7114C33.5686 10.7141 33.5075 10.6981 33.455 10.6657C33.4024 10.6333 33.3607 10.5859 33.3354 10.5296C33.31 10.4733 33.3021 10.4106 33.3126 10.3498C33.3232 10.2889 33.3517 10.2326 33.3945 10.1881C33.4374 10.1437 33.4925 10.113 33.5529 10.1002C33.6134 10.0873 33.6762 10.0929 33.7335 10.1161C33.7907 10.1393 33.8396 10.1791 33.874 10.2304C33.9084 10.2817 33.9267 10.3422 33.9264 10.4039C33.927 10.4437 33.9198 10.4831 33.9052 10.5201C33.8906 10.5571 33.8689 10.5908 33.8412 10.6193C33.8136 10.6479 33.7806 10.6707 33.7441 10.6865C33.7076 10.7023 33.6684 10.7108 33.6287 10.7114H33.6303Z"
        fill="black"
      />
      <path
        d="M21.6889 12.5011C21.6889 13.0122 21.5373 13.5119 21.2533 13.9369C20.9694 14.3619 20.5657 14.6932 20.0935 14.8888C19.6212 15.0844 19.1016 15.1356 18.6003 15.0359C18.0989 14.9361 17.6384 14.69 17.277 14.3285C16.9155 13.9671 16.6694 13.5066 16.5697 13.0053C16.47 12.5039 16.5211 11.9843 16.7168 11.512C16.9124 11.0398 17.2436 10.6362 17.6686 10.3522C18.0936 10.0682 18.5933 9.91663 19.1045 9.91663C19.7899 9.91663 20.4473 10.1889 20.9319 10.6736C21.4166 11.1583 21.6889 11.8156 21.6889 12.5011Z"
        fill="url(#paint0_linear_565_2204)"
      />
      <path
        d="M21.6889 12.5011C21.6889 13.0122 21.5373 13.5119 21.2533 13.9369C20.9694 14.3619 20.5657 14.6932 20.0935 14.8888C19.6212 15.0844 19.1016 15.1356 18.6003 15.0359C18.0989 14.9361 17.6384 14.69 17.277 14.3285C16.9155 13.9671 16.6694 13.5066 16.5697 13.0053C16.47 12.5039 16.5211 11.9843 16.7168 11.512C16.9124 11.0398 17.2436 10.6362 17.6686 10.3522C18.0936 10.0682 18.5933 9.91663 19.1045 9.91663C19.7899 9.91663 20.4473 10.1889 20.9319 10.6736C21.4166 11.1583 21.6889 11.8156 21.6889 12.5011Z"
        fill="url(#paint1_linear_565_2204)"
        opacity="0.65"
        // style={{ 'mix-blend-mode': 'multiply' }}
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_565_2204"
          x1="20.4278"
          x2="18.6383"
          y1="14.5719"
          y2="11.7699"
        >
          <stop stopColor="#F9A020" />
          <stop offset="0.25" stopColor="#F89B20" />
          <stop offset="0.53" stopColor="#F78E20" />
          <stop offset="0.62" stopColor="#F68820" />
          <stop offset="0.73" stopColor="#F58120" />
          <stop offset="1" stopColor="#F47521" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_565_2204"
          x1="20.1448"
          x2="17.5293"
          y1="14.5343"
          y2="9.421"
        >
          <stop stopColor="#F68820" stopOpacity="0" />
          <stop offset="0.11" stopColor="#EF8023" stopOpacity="0.14" />
          <stop offset="0.31" stopColor="#E47327" stopOpacity="0.35" />
          <stop offset="0.5" stopColor="#DB682A" stopOpacity="0.52" />
          <stop offset="0.69" stopColor="#D5612C" stopOpacity="0.64" />
          <stop offset="0.85" stopColor="#D15D2E" stopOpacity="0.71" />
          <stop offset="0.98" stopColor="#D05B2E" stopOpacity="0.74" />
        </linearGradient>
      </defs>
    </svg>
  ),
});
