import { useQuery } from '@tanstack/react-query';

import { useApi } from '../../../../ApiClient';
import { useModalContext } from '../../../hooks/useModalContext';

interface IAccountFunds {
  totalAmount: number;
  usableAmount: number;
  underwritingAmount: number;
}

export type TAccountFundsInDto = {
  total: number;
  usableFunds: number;
  pendingUnderwriting: number;
};

const getAccountFundsAdapter = (dto: TAccountFundsInDto): IAccountFunds => {
  const { total, usableFunds, pendingUnderwriting } = dto;

  return {
    totalAmount: total / 100,
    usableAmount: usableFunds / 100,
    underwritingAmount: pendingUnderwriting / 100,
  };
};

export const useGetAccountFunds = () => {
  const apiClient = useApi();
  const { checkoutApiUrl } = useModalContext();

  return useQuery<IAccountFunds, unknown>({
    queryKey: ['account-funds'],
    queryFn: async () => {
      const data = await apiClient!.get<TAccountFundsInDto>(
        `${checkoutApiUrl}/checkout/account/funds`
      );

      return getAccountFundsAdapter(data);
    },
  });
};
