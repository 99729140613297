import React, { FC } from 'react';

import {
  Flex,
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  LayoutProps,
} from '@chakra-ui/react';
import {
  SingleDatepicker,
  SingleDatepickerProps,
} from 'chakra-dayzed-datepicker';
import { FieldValues, Path, useController } from 'react-hook-form';

import { formatProps } from './utils';
import { CalendarIcon } from '../Icons';
import { EColor } from '../Theme';

export interface IFormDatePickerInputProps<TFieldValues extends FieldValues>
  extends Omit<SingleDatepickerProps, 'date' | 'onDateChange' | 'usePortal'> {
  name: Path<TFieldValues>;
  inputHeight?: LayoutProps['height'];
  dateFormat?: string;
  minDate?: Date;
  isRequired?: boolean;
}

export function createFormDatePickerInput<TFieldValues extends FieldValues>() {
  const FormDatePickerInput: FC<IFormDatePickerInputProps<TFieldValues>> = (
    props
  ) => {
    const {
      name,
      inputHeight = '40px',
      dateFormat = 'MM/dd/yyyy',
      minDate = new Date(),
      isRequired = false,
      ...restProps
    } = props;

    const { field, fieldState } = useController<TFieldValues, typeof name>({
      name,
    });

    const { onChange, value, ...restField } = field;
    const { error } = fieldState;

    const inputProps = restProps.propsConfigs?.inputProps;

    if (
      inputProps?.placeholder &&
      !inputProps?.placeholder.endsWith('*') &&
      isRequired
    ) {
      formatProps(inputProps, { isRequired });
    }

    return (
      <FormControl isInvalid={Boolean(error)}>
        <InputGroup height={inputHeight}>
          <InputRightElement height={inputHeight} pointerEvents="none">
            <Flex
              alignItems="center"
              bg={EColor.BrandDark}
              borderRadius="3px"
              height="24px"
              justifyContent="center"
              width="24px"
            >
              <CalendarIcon height="15px" width="15px" />
            </Flex>
          </InputRightElement>
          <SingleDatepicker
            {...restProps}
            usePortal
            configs={{
              dateFormat,
            }}
            date={value}
            minDate={minDate}
            name={name}
            propsConfigs={{
              inputProps: {
                height: inputHeight,
                ...restField,
                ...restProps?.propsConfigs?.inputProps,
              },
              popoverCompProps: {
                popoverContentProps: {
                  color: EColor.BrandDark,
                  ...restProps?.propsConfigs?.popoverCompProps
                    ?.popoverContentProps,
                },
                ...restProps?.propsConfigs?.popoverCompProps?.popoverBodyProps,
              },
              dateNavBtnProps: {
                _hover: {
                  bg: EColor.Neutral5,
                },
                width: { base: '32px', md: '40px' },
                height: { base: '32px', md: '40px' },
                p: 0,
                borderColor: EColor.BrandDark,
                borderWidth: '1px',
                ...restProps?.propsConfigs?.dateNavBtnProps,
              },
              dayOfMonthBtnProps: {
                defaultBtnProps: {
                  _hover: {
                    bg: EColor.Neutral5,
                    color: EColor.BrandDark,
                  },
                  width: { base: '32px', md: '40px' },
                  height: { base: '32px', md: '40px' },
                  p: 0,
                  borderWidth: 0,
                  fontWeight: 700,
                  ...restProps?.propsConfigs?.dayOfMonthBtnProps
                    ?.defaultBtnProps,
                },
                selectedBtnProps: {
                  bg: EColor.BrandDark,
                  color: EColor.White,
                  ...restProps?.propsConfigs?.dayOfMonthBtnProps
                    ?.selectedBtnProps,
                },
                todayBtnProps: {
                  borderWidth: 2,
                  ...restProps?.propsConfigs?.dayOfMonthBtnProps?.todayBtnProps,
                },
              },
            }}
            onDateChange={onChange}
          />
        </InputGroup>
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    );
  };

  return FormDatePickerInput;
}
