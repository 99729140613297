import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { TreatmentParsersMap } from '~/utils/featureFlags';

const useFeature = (featureName) => {
  const { isReady, treatments, hasTimedout } = useSplitTreatments({
    names: [featureName],
  });
  if (hasTimedout) {
    const value = TreatmentParsersMap[featureName](treatments[featureName]);
    return { isReady: true, value, error: 'Could not fetch FFs correctly' };
  }
  if (!isReady) {
    return { isReady: false, value: undefined };
  }

  try {
    const value = TreatmentParsersMap[featureName](treatments[featureName]);
    return { isReady, value };
  } catch (error) {
    return {
      error: 'Split Schema Error',
      isReady: true,
      value: undefined,
    };
  }
};

export default useFeature;
