import React, { FC } from 'react';

import {
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
  Text,
  Wrap,
} from '@chakra-ui/react';
import { FieldPathByValue, FieldValues, useController } from 'react-hook-form';

export interface IOption extends RadioProps {
  label: string;
  value: string;
}

export interface IFormRadioGroupProps<TFieldValues extends FieldValues>
  extends Omit<RadioGroupProps, 'children' | 'value' | 'onChange'> {
  name: FieldPathByValue<TFieldValues, string>;
  options: IOption[];
}

export function createFormRadioGroup<TFieldValues extends FieldValues>() {
  const FormRadioGroup: FC<IFormRadioGroupProps<TFieldValues>> = (props) => {
    const { name, options, ...restProps } = props;
    const { field, fieldState } = useController<TFieldValues, typeof name>({
      name,
    });

    const { error } = fieldState;

    return (
      <FormControl isInvalid={Boolean(error)}>
        <RadioGroup colorScheme="dark" {...field} {...restProps}>
          <Wrap spacing={4}>
            {options.map(({ value, label, ...rest }) => (
              <Radio key={value} value={value} {...rest}>
                <Text>{label}</Text>
              </Radio>
            ))}
          </Wrap>
        </RadioGroup>
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    );
  };

  return FormRadioGroup;
}
