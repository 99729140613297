import React from 'react';

import { createIcon } from '@chakra-ui/react';

export const CautionErrorIcon = createIcon({
  displayName: 'CautionErrorIcon',
  viewBox: '0 0 25 24',
  path: (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22Z"
        fill="#D22600"
      />
      <path
        clipRule="evenodd"
        d="M12.5 6.25C12.9142 6.25 13.25 6.58579 13.25 7V13C13.25 13.4142 12.9142 13.75 12.5 13.75C12.0858 13.75 11.75 13.4142 11.75 13V7C11.75 6.58579 12.0858 6.25 12.5 6.25ZM13.0117 16.4414C13.3196 16.7185 13.3446 17.1927 13.0675 17.5006L13.0575 17.5117C12.7804 17.8196 12.3062 17.8446 11.9983 17.5675C11.6904 17.2904 11.6654 16.8162 11.9425 16.5083L11.9525 16.4972C12.2296 16.1893 12.7038 16.1643 13.0117 16.4414Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  ),
});
