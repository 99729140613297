import { from, ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';

import { ACCESS_TOKEN_COOKIE } from '../../Auth';

type PlatformHeaderType = 'WEB' | 'ANDROID';

const buildAuthHeaders = (existingHeaders: any, newAccessToken?: string) => {
  const pwccAccessToken = Cookies.get(ACCESS_TOKEN_COOKIE);

  const accessToken: string = newAccessToken || pwccAccessToken || '';

  const xPlatformHeaderValue: PlatformHeaderType = 'WEB';

  return {
    headers: {
      ...existingHeaders,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
      'X-Platform': xPlatformHeaderValue,
    },
  };
};

const authHeadersLink = setContext((_, { headers }) => {
  return buildAuthHeaders(headers);
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        collectCurrentUserV2: {
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          },
        },
      },
    },
  },
});

export const makeApolloClient = (graphqlApiUrl: string) => {
  const httpLink = new HttpLink({
    uri: graphqlApiUrl,
  });

  const link = from([authHeadersLink, httpLink]);

  return new ApolloClient({
    cache,
    connectToDevTools: true,
    link,
  });
};
