import React, { useEffect } from 'react';

import { Text, Button, Flex } from '@chakra-ui/react';

import { useGTMDataLayer } from '../../../../hooks';
import { FanaticsCollectModalLogo } from '../../../../Icons';
import { EButtonVariant, EColor, ETextVariant } from '../../../../Theme';
import { setLocalStorage } from '../../../../utilities';
import { ModalContent } from '../../../components';
import { OPEN_COMPLETE_PROFILE_AFTER_LOGIN_KEY } from '../../../lib/storage';
import { useModalStore } from '../../../stores/modal';

export const CollectAccountCreated = () => {
  const modalStore = useModalStore();
  const pushGTMData = useGTMDataLayer();

  const handleClose = () => {
    modalStore.clear();
    window.location.reload();
  };

  useEffect(() => {
    setLocalStorage(OPEN_COMPLETE_PROFILE_AFTER_LOGIN_KEY, true);
  }, [pushGTMData]);

  return (
    <ModalContent>
      <Flex flexDir="column">
        <FanaticsCollectModalLogo
          height="50px"
          mb="40px"
          mt="24px"
          width="50px"
        />
        <Text
          color={EColor.Black}
          fontSize="23px"
          mb="8px"
          variant={ETextVariant.XL}
        >
          Your Fanatics Collect account is created
        </Text>
        <Text
          color={EColor.Neutral65}
          mb="32px"
          variant={ETextVariant.BaseParagraph}
        >
          Before you can start buying or selling on our platform, please verify
          your email address using the verification email that was sent to you.
        </Text>
        <Button
          fontFamily="ABC Diatype Mono"
          fontSize="16px"
          fontWeight={400}
          height="54px"
          lineHeight="16px"
          variant={EButtonVariant.BRAND_PRIMARY}
          width="100%"
          onClick={handleClose}
        >
          Close
        </Button>
      </Flex>
    </ModalContent>
  );
};
