import React, { FC } from 'react';

import {
  Box,
  BoxProps,
  Text,
  useBreakpointValue,
  Wrap,
} from '@chakra-ui/react';

import { ETextVariant } from '../Theme';

export interface IFormSectionProps extends BoxProps {
  title: string;
  isRequired?: boolean;
}

export const FormSection: FC<IFormSectionProps> = (props) => {
  const { title, children, isRequired, ...restProps } = props;

  const spacing = useBreakpointValue(
    { base: '10px', md: '17px' },
    { ssr: false }
  );

  return (
    <Box w="100%" {...restProps}>
      <Text
        fontWeight="700"
        py={{ base: '10px', md: '20px' }}
        variant={ETextVariant.BaseParagraph}
      >
        {`${title}${isRequired ? '*' : ''}`}
      </Text>
      <Wrap pb={{ base: '5px', md: '10px' }} spacing={spacing}>
        {children}
      </Wrap>
    </Box>
  );
};
