import React, { useState, useContext } from 'react';

import { ModalBody, VStack, Button, Text, Link } from '@chakra-ui/react';

import { CollectOfferData } from '../../../artifacts/gql/graphql';
// Importing this way to avoid circular dependency
import { NavigationContext } from '../../Navigation/context/NavigationContext';
import { useMonolithUrls } from '../../Navigation/hooks/useMonolithUrls';
import { generateMonolithUrl } from '../../Navigation/utils/monolithUrls';

type SingleOfferModalBodyProps = {
  type: 'seller' | 'buyer' | 'seller-countered';
  viewHandler: (needAwait: boolean, needCloseModal: boolean) => Promise<void>;
  offer: CollectOfferData;
};

export const SingleOfferModalBody = ({
  type,
  viewHandler,
  offer,
}: SingleOfferModalBodyProps) => {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [
    isRedirectingToOtherNegotiations,
    setIsRedirectingToOtherNegotiations,
  ] = useState(false);
  const { myBuyingUrl, mySellingUrl } = useMonolithUrls();
  const { fanaticsCollectBaseUrl } = useContext(NavigationContext);

  const viewMySellingClickHandler = async () => {
    setIsRedirecting(true);
    if (type === 'buyer') {
      await viewHandler(true, false);

      if (offer.listingId) {
        window.location.assign(
          generateMonolithUrl(
            fanaticsCollectBaseUrl ?? '',
            `/fixed/${offer.listingId}/offer`
          )
        );
      } else {
        window.location.assign(myBuyingUrl);
      }
    } else {
      await viewHandler(true, false);

      if (offer.offerId) {
        window.location.assign(
          generateMonolithUrl(
            fanaticsCollectBaseUrl ?? '',
            `/fixed/sell/offer/${offer.offerId}`
          )
        );
      } else {
        window.location.assign(mySellingUrl);
      }
    }
  };

  const accessOtherNegotiationsClickHandler = async (
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    e.preventDefault();
    if (isRedirectingToOtherNegotiations) return;

    setIsRedirectingToOtherNegotiations(true);
    await viewHandler(true, false);
    if (type === 'buyer') {
      window.location.assign(myBuyingUrl);
    } else {
      window.location.assign(mySellingUrl);
    }
  };

  return (
    <ModalBody padding="0px 24px 24px 24px">
      <VStack spacing={8}>
        <VStack spacing={6}>
          {type === 'seller' && (
            <Text>
              A buyer made an offer on your{' '}
              <strong>{offer.listingTitle || ''}</strong>. Review and respond
              before it expires.
            </Text>
          )}
          {type === 'buyer' && (
            <Text>
              The seller made a counteroffer on their{' '}
              <strong>{offer.listingTitle || ''}</strong>. Review and respond
              before it expires.
            </Text>
          )}
          {type === 'seller-countered' && (
            <Text>
              The buyer made a counteroffer on your{' '}
              <strong>{offer.listingTitle || ''}</strong>. Review and respond
              before it expires.
            </Text>
          )}
          <Text color="#38393C" fontSize="12px" width="100%">
            Access this and other negotiations in{' '}
            {type === 'buyer' ? (
              <Link
                css={{ textDecoration: 'underline' }}
                href={myBuyingUrl}
                onClick={accessOtherNegotiationsClickHandler}
              >
                My Buying
              </Link>
            ) : (
              <Link
                css={{ textDecoration: 'underline' }}
                href={mySellingUrl}
                onClick={accessOtherNegotiationsClickHandler}
              >
                My Selling
              </Link>
            )}
            .
          </Text>
        </VStack>
        <Button
          colorScheme="dark"
          isLoading={isRedirecting}
          size="md"
          textTransform="uppercase"
          variant="solid"
          width="100%"
          onClick={viewMySellingClickHandler}
        >
          VIEW OFFER
        </Button>
      </VStack>
    </ModalBody>
  );
};
