import React, { forwardRef } from 'react';

import { BoxProps, Flex, Box } from '@chakra-ui/react';

import { useProfileStore } from '../../../ModalProvider';
import { EColor } from '../../../Theme';

interface IProfileButtonProps extends BoxProps {
  isInverted: boolean;
  hasOffers: boolean;
}

export const ProfileButton = forwardRef<HTMLDivElement, IProfileButtonProps>(
  ({ isInverted, hasOffers, ...props }, ref) => {
    const { profile } = useProfileStore();

    return (
      <Box
        ref={ref}
        {...props}
        aria-controls="member-navigation"
        cursor="pointer"
        data-testid="nav-profile-button"
        ml={{ base: '0.5rem', md: undefined }}
        mr="2px"
        position="relative"
      >
        <Flex alignItems="center">
          <Flex
            alignItems="center"
            backgroundColor={isInverted ? EColor.Black : EColor.White}
            borderColor={EColor.Black}
            borderRadius="50%"
            borderWidth="2px"
            boxShadow="0 0 1px transparent"
            boxSize="30px"
            color={isInverted ? EColor.White : EColor.Black}
            fontFamily="ABC Diatype Mono"
            fontSize="12px"
            fontWeight="700"
            justifyContent="center"
            lineHeight="16px"
            paddingTop="1px"
            pl="1px"
          >
            <Box>
              {profile?.firstName[0]}
              {profile?.lastName[0]}
            </Box>
          </Flex>
        </Flex>

        {profile && (!profile.hasPaymentMethods || hasOffers) && (
          <Box
            backgroundColor={EColor.Tomato}
            border={`2px solid ${EColor.White}`}
            borderRadius="50%"
            height="8px"
            padding="4px"
            position="absolute"
            right={0}
            top={0}
            width="8px"
          />
        )}
      </Box>
    );
  }
);
