import { gql } from '../../../artifacts/gql';

export const resendWelcomeEmail = gql(`
  mutation webResendWelcomeEmailCCMutation {
    collectResendWelcomeEmail {
    successful
    messages {
      field
      message
      code
    }
    result
  }
  }
`);
