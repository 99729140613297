import { EColor } from '../colors';
import type { ComponentSingleStyleConfig } from '@chakra-ui/react';
import type { SystemStyleInterpolation } from '@chakra-ui/theme-tools';

export enum ETextVariant {
  XL6 = 'XL6',
  XL5 = 'XL5',
  XL4 = 'XL4',
  XL3 = 'XL3',
  XL2 = 'XL2',
  XL = 'XL',
  LG = 'LG',
  BaseParagraph = 'BaseParagraph',
  BaseBold = 'BaseBold',
  BaseMono = 'BaseMono',
  Small = 'Small',
  SmallBold = 'SmallBold',
  SmallMono = 'SmallMono',
  XS = 'XS',
  XSBold = 'XSBold',
  XSMono = 'XSMono',
  XS2 = 'XS2',
  XS2Legal = 'XS2Legal',
  XS2Bold = 'XS2Bold',
  XS2Mono = 'XS2Mono',

  // Current variants
  Heading1 = 'Heading1',
  Heading2 = 'Heading2',
  Heading3 = 'Heading3',
  Heading4 = 'Heading4',
  Heading5 = 'Heading5',
  Heading6 = 'Heading6',
  Heading7 = 'Heading7',
  Heading8 = 'Heading8',
  Heading9 = 'Heading9',
  Heading10 = 'Heading10',
  BodyX = 'BodyX',
  Paragraph2 = 'Paragraph2',
  Paragraph3 = 'Paragraph3',
  Paragraph4 = 'Paragraph4',
  Paragraph5 = 'Paragraph5',
  Paragraph6 = 'Paragraph6',
}

export const TextConfig: ComponentSingleStyleConfig = {
  variants: Object.keys(ETextVariant).reduce(
    (acc, curr) => Object.assign(acc, { [curr]: { textStyle: curr } }),
    {} as Record<ETextVariant, SystemStyleInterpolation>
  ),
};

export const MonolithTextConfig: ComponentSingleStyleConfig = {
  baseStyle: {
    marginBottom: 0,
    color: EColor.BrandDark,
  },
};
