import { EColor } from '../colors';
import type { ComponentSingleStyleConfig } from '@chakra-ui/react';

export const CheckboxConfig: ComponentSingleStyleConfig = {
  baseStyle: {
    control: {
      borderColor: EColor.BrandCorduroy,
      borderWidth: '1px',
      alignSelf: 'start',
      mt: '2px',
    },
  },
};
