import { useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import { ApiClient } from '../../ApiClient';
import { IAuth, REFRESH_TOKEN_COOKIE } from '../../Auth';

/**
 * Holds auth object in state to trigger re-renders for context consumers
 */
export const useFetchProfile = (
  auth: IAuth,
  apiClient: ApiClient,
  navigateToLogin?: (data?: any) => void
) => {
  const [authState, setAuthState] = useState(auth);
  const [isInitializing, setIsInitializing] = useState<boolean>(true);

  const refetchProfile = async () => {
    const handleError = (data?: any) => {
      if (!window.location.pathname.includes('/login') && navigateToLogin) {
        navigateToLogin(data);
      }
    };

    const fetchProfile = async () => {
      const profile = await authState?.fetchProfile?.(apiClient);

      if (!profile) {
        handleError({
          message: 'Could not fetch profile',
          caller: 'common components',
          location: window?.location?.href,
        });
      }
    };

    try {
      const isAccessToken =
        !!apiClient.service.defaults.headers.common.Authorization;
      const isRefreshToken = !!Cookies.get(REFRESH_TOKEN_COOKIE);

      if (isAccessToken) {
        await fetchProfile();
      }

      if (!isAccessToken && isRefreshToken) {
        const isRefreshed = await apiClient.attemptTokenRefresh();

        if (isRefreshed) {
          await fetchProfile();
        } else {
          handleError({
            message: 'could not refresh token',
            caller: 'common components',
            location: window?.location?.href,
          });
        }
      }

      if (!isAccessToken && !isRefreshToken) {
        handleError({
          message: 'could not fetch profile',
          caller: 'common components',
          location: window?.location?.href,
        });
      }
    } catch (error) {
      handleError({
        message: 'could not fetch profile',
        caller: 'common components',
        location: window?.location?.href,
        error,
      });
    } finally {
      setAuthState(Object.create(authState));
    }
  };

  useEffect(() => {
    (async () => {
      await refetchProfile();

      setIsInitializing(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { authState, isInitializing, refetchProfile };
};
