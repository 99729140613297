import React, { FC, useCallback } from 'react';

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
} from '@chakra-ui/react';
import { FieldPathByValue, FieldValues, useController } from 'react-hook-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { formatProps } from './utils';

export interface IFormNumberInputProps<TFieldValues extends FieldValues>
  extends Omit<InputProps, 'type' | 'value' | 'defaultValue'>,
    Omit<
      NumericFormatProps,
      | 'customInput'
      | 'value'
      | 'onValueChange'
      | 'color'
      | 'width'
      | 'height'
      | 'size'
    > {
  name: FieldPathByValue<TFieldValues, number | undefined>;
  withErrorMessage?: boolean;
  controlProps?: Omit<FormControlProps, 'isInvalid'>;
}

export function createFormNumberInput<TFieldValues extends FieldValues>() {
  const FormNumberInput: FC<IFormNumberInputProps<TFieldValues>> = (props) => {
    const {
      name,
      isRequired,
      withErrorMessage = true,
      controlProps,
      variant,
      ...restProps
    } = props;
    const isBrandVariant = variant === 'brand';

    const { field, fieldState } = useController<TFieldValues, typeof name>({
      name,
    });

    const { ref, onChange, ...restField } = field;
    const { error } = fieldState;

    const handleValueChange = useCallback<
      NonNullable<NumericFormatProps['onValueChange']>
    >(
      (values) => {
        onChange(values.floatValue);
      },
      [onChange]
    );

    formatProps(restProps, { isRequired });

    return (
      <FormControl
        isInvalid={Boolean(error)}
        {...controlProps}
        variant={isBrandVariant ? 'floating' : 'base'}
      >
        <NumericFormat<InputProps>
          allowNegative={false}
          decimalScale={2}
          getInputRef={ref}
          inputMode="decimal"
          {...restField}
          {...restProps}
          customInput={Input}
          placeholder={isBrandVariant ? '' : restProps.placeholder}
          variant={variant ?? 'outline'}
          onValueChange={handleValueChange}
        />
        {isBrandVariant && restProps.placeholder && (
          <FormLabel>{restProps.placeholder}</FormLabel>
        )}
        {withErrorMessage && (
          <FormErrorMessage variant={variant ?? 'base'}>
            {error?.message}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  };

  return FormNumberInput;
}
