import { formatNumber } from 'libphonenumber-js';

import { EChannel } from './types';
import { EVerificationMethod } from './VerificationMethodStep/types';
import { IRequestCodeArgs } from '../api';

export const maskEmail = (userEmail: string): string => {
  const pattern = /\B./g;

  const [email, domain] = userEmail.split('@');
  const [mainDomain, ...restArgs] = domain.split('.');

  const maskedEmail = email.replace(pattern, '•');
  const maskedDomain = [mainDomain.replace(pattern, '•'), ...restArgs].join(
    '.'
  );
  return [maskedEmail, maskedDomain].join('@');
};

export const maskPhone = (phone: string, prefix?: boolean): string => {
  const intlPhone = formatNumber(phone, 'INTERNATIONAL').split(' ');
  const intlPrefix = prefix ? `${intlPhone[0]} ` : '';

  return (
    intlPrefix +
    intlPhone
      .slice(-3)
      .map((chunk: string, index: number, arr: string | any[]) =>
        index < arr.length - 1 ? chunk.replace(/./g, '•') : chunk
      )
      .join(' - ')
  );
};

export const channelTypeMap: Record<EVerificationMethod, EChannel> = {
  [EVerificationMethod.EMAIL]: EChannel.EMAIL,
  [EVerificationMethod.PHONE]: EChannel.SMS,
};

export const getRequestCodeArgs = (
  activeMethod: EVerificationMethod | null,
  phone: string | undefined,
  email: string | undefined
): IRequestCodeArgs | undefined => {
  const toValue = activeMethod === EVerificationMethod.EMAIL ? email : phone;

  if (activeMethod && toValue) {
    return {
      to: toValue,
      channel: channelTypeMap[activeMethod],
    };
  }
};
