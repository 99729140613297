import { parsePhoneNumber } from 'libphonenumber-js/min';

export const parsePhone = (phone: string) => {
  try {
    const { nationalNumber, country } = parsePhoneNumber(phone);

    return {
      phoneNumber: nationalNumber,
      countryCode: country,
    };
  } catch (error) {
    console.error('Failed to parse phone number:', error);
  }
};
