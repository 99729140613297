import React, { FC, useMemo } from 'react';

import { Button, Flex, Text } from '@chakra-ui/react';

import {
  CardAmexIcon,
  CardDiscoverIcon,
  CardMastercardIcon,
  CardVisaIcon,
  BankAccountIcon,
  CreditCardIcon,
} from '../../../../Icons';
import { EColor, ETextVariant } from '../../../../Theme';
import { useModalStore } from '../../../stores';
import { EModalContent } from '../../../types';
import { ECardBrand, EPaymentType, IPaymentMethod } from '../types';
import { getIsExpiredCard } from '../utils';

interface IPaymentMethodProps {
  item: IPaymentMethod;
  isDefault: boolean;
  setDefaultPaymentMethod: (paymentMethod: IPaymentMethod) => Promise<void>;
}

const getPaymentMethodConfig = (method: IPaymentMethod) => {
  const iconSizes = {
    height: '24px',
    width: '36px',
  };

  const cardIconMap = {
    [ECardBrand.VISA]: <CardVisaIcon {...iconSizes} />,
    [ECardBrand.MASTERCARD]: <CardMastercardIcon {...iconSizes} />,
    [ECardBrand.AMEX]: <CardAmexIcon {...iconSizes} />,
    [ECardBrand.DISCOVER]: <CardDiscoverIcon {...iconSizes} />,
    [ECardBrand.UNKNOWN]: <CreditCardIcon {...iconSizes} />,
  };

  if (
    method.type === EPaymentType.CARD ||
    method.type === EPaymentType.BANK_ACCOUNT
  ) {
    const paymentTypeMap = {
      [EPaymentType.CARD]: {
        icon: (method.card?.brand && cardIconMap[method.card.brand]) ?? (
          <CreditCardIcon {...iconSizes} />
        ),
        name: '',
        last4: method.card?.last4,
      },
      [EPaymentType.BANK_ACCOUNT]: {
        icon: (
          <BankAccountIcon
            {...iconSizes}
            border="1px solid"
            borderColor={EColor.Neutral15}
            borderRadius="3.5px"
          />
        ),
        name: method.us_bank_account?.bank_name,
        last4: method.us_bank_account?.last4,
      },
    };

    return paymentTypeMap[method.type];
  }
};

export const PaymentMethod: FC<IPaymentMethodProps> = ({
  item,
  isDefault,
  setDefaultPaymentMethod,
}) => {
  const paymentMethodConfig = getPaymentMethodConfig(item);
  const modalStore = useModalStore();

  const isExpired = useMemo(() => {
    if (item.card) {
      const expYear = item.card.exp_year;
      const expMonth = item.card.exp_month;

      return getIsExpiredCard(expMonth, expYear);
    }

    return false;
  }, [item.card]);

  const handleDeleteMethod = () => {
    modalStore.navigate({
      name: EModalContent.DELETE_METHOD_CONFIRMATION,
      paymentMethodId: item.id,
    });
  };

  const handleSetDefault = async () => {
    if (!item.default && !isExpired) {
      await setDefaultPaymentMethod(item);
    }
  };

  return (
    <Flex
      alignItems="center"
      backgroundColor={isDefault && !isExpired ? EColor.Neutral2 : EColor.White}
      borderColor={isDefault && !isExpired ? EColor.Black : EColor.Neutral15}
      borderRadius="8px"
      borderWidth="1px"
      height="44px"
      justifyContent="space-between"
    >
      <Flex
        alignItems="center"
        as="button"
        cursor={isDefault || isExpired ? 'default' : 'pointer'}
        gap="8px"
        height="100%"
        minWidth="85%"
        pl="8px"
        width="100%"
        onClick={handleSetDefault}
      >
        {paymentMethodConfig && paymentMethodConfig.icon}

        {paymentMethodConfig && paymentMethodConfig.name && (
          <Text
            overflow="hidden"
            sx={{ textWrap: 'nowrap' }}
            textOverflow="ellipsis"
            textTransform="capitalize"
            variant={ETextVariant.SmallBold}
          >
            {paymentMethodConfig.name.toLowerCase()}
          </Text>
        )}

        {paymentMethodConfig && (
          <Text
            color={isExpired ? EColor.Corsa : EColor.Black}
            variant={ETextVariant.SmallBold}
          >
            ••••{paymentMethodConfig.last4}
          </Text>
        )}

        {(isDefault || isExpired) && (
          <Text
            backgroundColor={isExpired ? EColor.Tomato : EColor.Lime}
            color={EColor.Black}
            mr="5px"
            p="0 4px"
            variant={ETextVariant.XS2Bold}
          >
            {isExpired ? 'Expired' : 'Default'}
          </Text>
        )}
      </Flex>
      {(!isDefault || isExpired) && (
        <Button
          borderRadius="5px"
          color={EColor.Neutral55}
          fontSize="10px"
          fontWeight={700}
          height="100%"
          letterSpacing="0.1px"
          lineHeight="12px"
          pr="24px"
          textTransform="uppercase"
          variant="link"
          onClick={handleDeleteMethod}
        >
          Delete
        </Button>
      )}
    </Flex>
  );
};
