import {
  getTenantId,
  isTestEnv,
} from '../../hooks/useGTMDataLayer/useGTMDataLayer';

export const tracker = {
  track: async (label: string, metadata: Record<string, unknown>) => {
    if (typeof window !== 'undefined' && window.analytics) {
      const meta = {
        origination: window.location.href,
        userTenantId: getTenantId(),
        isCCEvent: true,
        ...metadata,
      };

      window.analytics.track(label, meta);

      if (isTestEnv()) {
        // eslint-disable-next-line no-console
        console.log('Common Components Segment.io data:', {
          label,
          ...meta,
        });
      }
    }
  },
};
