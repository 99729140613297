import { useEffect } from 'react';

import { useQuery } from '@apollo/client';

import { getCurrentUserV2 } from '../../gql';
import { useProfileStore } from '../stores';

export const useUpdateInfo = () => {
  const { updateProfile, profile } = useProfileStore();

  const { data: currentUserV2Data } = useQuery(getCurrentUserV2, {
    skip: !profile || profile?.isEmailVerified,
  });

  const isEmailVerified =
    currentUserV2Data?.collectCurrentUserV2?.email?.verified;

  useEffect(() => {
    if (isEmailVerified && !profile?.isEmailVerified) {
      updateProfile({ isEmailVerified: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailVerified]);
};
