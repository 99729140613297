import React, { useContext } from 'react';

import { Center, Flex, FlexProps, Link } from '@chakra-ui/react';

import { useIsMobile } from '../../../hooks';
import {
  FanaticsCollectLogoFull,
  FanaticsCollectLogoSmall,
} from '../../../Icons';
import { EColor } from '../../../Theme';
import { NavigationContext } from '../../context/NavigationContext';
import { useMonolithUrls } from '../../hooks/useMonolithUrls';

export interface ILogoSectionProps extends FlexProps {
  isInHeader?: boolean;
}

export function LogoSection({
  isInHeader = true,
  ...restProps
}: ILogoSectionProps) {
  const { homeUrl, adminUrl } = useMonolithUrls();
  const { isAdmin } = useContext(NavigationContext);
  const isAdminLogo = isAdmin && window.location.href.includes('admin');
  const isMobile = useIsMobile('md');

  return (
    <Flex
      justify={{
        base: isInHeader ? 'start' : 'center',
        md: isInHeader ? 'unset' : 'center',
      }}
      {...restProps}
    >
      <Link
        data-testid={`${isInHeader ? 'header' : 'footer'}-nav-logo`}
        href={isAdminLogo ? adminUrl : homeUrl}
      >
        <Center>
          {isMobile ? (
            <FanaticsCollectLogoSmall
              height={{ base: '26px', lg: '38px' }}
              width="auto"
            />
          ) : (
            <FanaticsCollectLogoFull
              _hover={{
                color: isInHeader ? EColor.BrandDark : EColor.White,
              }}
              color={isInHeader ? EColor.BrandDark : EColor.White}
              height={{ base: '26px', lg: '38px' }}
              width="auto"
            />
          )}
        </Center>
      </Link>
    </Flex>
  );
}
