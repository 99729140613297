import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '../../../ApiClient';

export const useDeletePastSearch = (pastSearchBaseUrl: string) => {
  const apiClient = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deletePastSearch'],
    mutationFn: async (searchText: string) =>
      apiClient?.delete(
        `${pastSearchBaseUrl}/saved-search/v1?term=${encodeURIComponent(
          searchText
        )}`
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getPastSearch'] });
    },
  });
};
