import React, { useContext } from 'react';

import { Divider, Flex, Stack, Text } from '@chakra-ui/react';

import { getFooterLinks } from './config';
import { FooterAppLinks } from './FooterAppLinks';
import { FooterCopyright } from './FooterCopyright';
import { FooterLink } from './FooterLink';
import { useIsMobile } from '../../../hooks';
import { EColor } from '../../../Theme';
import { maxWidths } from '../../../utilities';
import { NavigationContext } from '../../context/NavigationContext';
import { useMonolithUrls } from '../../hooks/useMonolithUrls';

export function Footer() {
  const isSmMobile = useIsMobile('sm');
  const isMdMobile = useIsMobile('md');
  const { fanaticsCollectUrl } = useMonolithUrls();
  const { fanaticsCollectBaseUrl } = useContext(NavigationContext);
  const footerLinks = getFooterLinks(
    fanaticsCollectBaseUrl ?? fanaticsCollectUrl
  );

  return (
    <Flex
      align="center"
      bg={EColor.Neutral2}
      flexDirection="column"
      justifyContent="flex-end"
      overflowX="hidden"
    >
      <Divider orientation="horizontal" />

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        divider={
          isSmMobile ? (
            <Divider orientation={isSmMobile ? 'horizontal' : 'vertical'} />
          ) : undefined
        }
        gap={0}
        maxW={maxWidths}
        w="100%"
      >
        <Stack direction={{ base: 'column', md: 'row' }} flex={3}>
          {footerLinks.map((footerLink) => (
            <Flex
              flex={1}
              flexDirection="column"
              key={footerLink.name}
              px={{ base: '30px', md: '16px' }}
              py="32px"
              rowGap="16px"
            >
              <Text fontSize="18px" fontWeight="700">
                {footerLink.name}
              </Text>

              <Flex flexDirection="column" transitionDuration="0.2s">
                {footerLink.items.map((link, index, arr) => (
                  <FooterLink
                    data-testid={`footer-link-${link.dataTestId}`}
                    href={link.path}
                    key={`inner-nav-${link.name}`}
                    name={link.name}
                    paddingBottom={index + 1 !== arr.length ? '12px' : ''}
                  />
                ))}
              </Flex>
            </Flex>
          ))}
        </Stack>

        {!isSmMobile && isMdMobile && (
          <Divider
            mt={0}
            orientation="horizontal"
            position="relative"
            sx={{ transform: 'translate(-50%)' }}
            w="200vw"
          />
        )}

        <FooterAppLinks />
      </Stack>

      <FooterCopyright />
    </Flex>
  );
}
