import React, { FC, useMemo } from 'react';

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { FieldValues, useController, FieldPathByValue } from 'react-hook-form';

import { ISelectProps, Select } from './Select';
import { formatProps } from './utils';
import { EColor } from '../Theme';

export interface IFormSelectProps<TFieldValues extends FieldValues>
  extends ISelectProps {
  name: FieldPathByValue<TFieldValues, string | number>;
  controlProps?: Omit<FormControlProps, 'isInvalid'>;
}

export function createFormSelect<TFieldValues extends FieldValues>() {
  const FormSelect: FC<IFormSelectProps<TFieldValues>> = (props) => {
    const { name, isRequired, controlProps, variant, ...restProps } = props;
    const isBrandVariant = variant === 'brand';

    const { field, fieldState } = useController<TFieldValues, typeof name>({
      name,
    });

    const { value, ...restField } = field;
    const { error } = fieldState;

    const placeholderColor = useMemo(() => {
      return value ? EColor.Black : EColor.Neutral35;
    }, [value]);

    formatProps(restProps, { isRequired });

    const isInvalid = Boolean(error);

    return (
      <FormControl
        isInvalid={isInvalid}
        {...controlProps}
        variant={isBrandVariant ? 'floating' : 'base'}
      >
        <Select
          {...restField}
          {...restProps}
          borderRadius="8px"
          color={placeholderColor}
          h="40px"
          iconColor={EColor.Black}
          placeholder={restProps.placeholder}
          sx={{
            option: {
              color: EColor.Black,
            },
            ':[value=""], :checked': {
              borderColor: EColor.Black,
            },
          }}
          value={value ?? ''}
          variant={variant}
        />
        {isBrandVariant && !!restProps.placeholder && value && (
          <FormLabel>{restProps.placeholder}</FormLabel>
        )}
        <FormErrorMessage variant={variant ?? 'base'}>
          {error?.message}
        </FormErrorMessage>
      </FormControl>
    );
  };

  return FormSelect;
}
