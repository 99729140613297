import { gql } from '../../../artifacts/gql';

export const markOfferNotificationAsViewedMutation = gql(`
  mutation webMarkOfferNotificationAsViewedCCMutation($input: CollectMarkOfferNotificationAsViewedInput!) {
    collectMarkOfferNotificationAsViewed(input: $input) {
      successful
      messages {
        field
        message
        code
      }
    }
  }
`);
