import React, { forwardRef, ReactNode, useEffect } from 'react';

import { Box, Grid } from '@chakra-ui/react';
import 'focus-visible/dist/focus-visible';
import { css, Global } from '@emotion/react';
import Cookies from 'js-cookie';

import { Footer } from './Footer/Footer';
import { Header } from './Header';
import { ACCESS_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE } from '../../Auth';
import { IAuth } from '../../Auth/types/auth';
import { CollectProvider } from '../../collect/context';
import { decodeJwt } from '../../utilities/decodeJwt';
import { isCheckout, isLocal, isSalesHistory } from '../../utilities/pageCheck';
import { NavigationProvider } from '../context/NavigationContext';
import { useNavItems } from '../hooks/useNavItems';
import { INavigationItem } from '../types/navigation';

const styles = css`
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

export interface INavigationProps {
  children?: ReactNode;
  auth?: IAuth;
  navItems?: INavigationItem[];
  fanaticsCollectBaseUrl?: string;
  searchBaseUrl: string;
  fanIdBaseUrl: string;
  fanIdClientId: string;
  fanIdPreview?: string;
  pastSearchBaseUrl?: string;
  showPencilBanner?: boolean;
  showNonProdBanner?: boolean;
  isInitializing?: boolean;
  isSSR?: boolean;
  showCartButton?: boolean;
  enablePlaidPAApproval?: boolean;
  enableOfferNotificationBadges?: boolean;
  offersNotificationDialogEnabled?: boolean;
  /**
   * @collect-web
   */
  isInCollectApp?: boolean;
  /**
   * @collect-web
   */
  stickyTop?: string;
  /**
   * @collect-web
   */
  onSearch?: (searchText: string) => void;
}

export const Navigation = forwardRef<HTMLDivElement, INavigationProps>(
  (
    {
      children,
      auth,
      navItems: navItemsOverride,
      fanaticsCollectBaseUrl,
      searchBaseUrl,
      fanIdBaseUrl,
      fanIdClientId,
      fanIdPreview,
      pastSearchBaseUrl = '',
      showPencilBanner = true,
      showNonProdBanner = false,
      isInitializing = false,
      isSSR,
      showCartButton,
      enablePlaidPAApproval = false,
      enableOfferNotificationBadges,
      offersNotificationDialogEnabled = false,
      ...collectProps
    },
    ref
  ) => {
    const headerNavItems = useNavItems(
      fanaticsCollectBaseUrl,
      navItemsOverride,
      collectProps.isInCollectApp
    );

    useEffect(() => {
      if (isLocal() || isSalesHistory() || isCheckout()) {
        const searchParams = new URLSearchParams(window.location.search);

        const trySetTokenCookieFromSearchParam = (name: string) => {
          const token = searchParams.get(name);

          if (token) {
            const payload = decodeJwt<{
              exp: number | undefined;
              aud: string | undefined;
            }>(token);

            Cookies.set(name, token, {
              expires: new Date((payload.exp ?? Date.now()) * 1000),
              domain: payload.aud,
            });

            return true;
          }
        };

        const accessTokenSet =
          trySetTokenCookieFromSearchParam(ACCESS_TOKEN_COOKIE);
        const refreshTokenSet =
          trySetTokenCookieFromSearchParam(REFRESH_TOKEN_COOKIE);

        if (accessTokenSet || refreshTokenSet) {
          const url = new URL(window.location.href);
          url.searchParams.delete(ACCESS_TOKEN_COOKIE);
          url.searchParams.delete(REFRESH_TOKEN_COOKIE);

          window.history.replaceState({}, '', url);
          window.location.reload();
        }
      }
    }, []);

    return (
      <CollectProvider {...collectProps}>
        <NavigationProvider
          value={{
            fanaticsCollectBaseUrl,
            navItems: headerNavItems,
            isAdmin: auth?.profile?.isAdmin,
            isSSR,
          }}
        >
          <Global styles={styles} />
          <Grid
            gridTemplateColumns="100%"
            gridTemplateRows="auto auto 1fr auto"
            minHeight="100vh"
            ref={ref}
          >
            <Header
              auth={auth}
              enableOfferNotificationBadges={enableOfferNotificationBadges}
              enablePlaidPAApproval={enablePlaidPAApproval}
              fanIdBaseUrl={fanIdBaseUrl}
              fanIdClientId={fanIdClientId}
              fanIdPreview={fanIdPreview}
              isInitializing={isInitializing}
              navItems={headerNavItems}
              offersNotificationDialogEnabled={offersNotificationDialogEnabled}
              pastSearchBaseUrl={pastSearchBaseUrl}
              searchBaseUrl={searchBaseUrl}
              showCartButton={showCartButton}
              showNonProdBanner={showNonProdBanner}
              showPencilBanner={showPencilBanner}
            />
            <Box>{children}</Box>
            <Footer />
          </Grid>
        </NavigationProvider>
      </CollectProvider>
    );
  }
);
