import React, { useMemo, useRef, useState } from 'react';

import {
  DrawerBody,
  DrawerFooter,
  DrawerContent,
  Box,
  Flex,
  Link,
  Text,
} from '@chakra-ui/react';

import { AddressLine } from './AddressLine';
import { ProfileDrawerSection } from './ProfileDrawerSection';
import { IProfile } from '../../../Auth/types/auth';
import { EGTMEvents, useGTMDataLayer } from '../../../hooks';
import { CopyIcon } from '../../../Icons';
import { useModalContext } from '../../../ModalProvider/hooks/useModalContext';
import { useModalStore } from '../../../ModalProvider/stores/modal';
import { useProfileStore } from '../../../ModalProvider/stores/profile';
import { EModalContent } from '../../../ModalProvider/types/modalContent';
import { EColor, ETextVariant } from '../../../Theme';
import { collectLogout } from '../../../utilities/logout';
import { useMonolithUrls } from '../../hooks/useMonolithUrls';
import { usePlaidRegistrationStatus } from '../../hooks/usePlaidRegistrationStatus';
import { useRegistrationStatus } from '../../hooks/useRegistrationStatus';

interface IProfileDrawerContentsProps {
  profile: IProfile;
  onClose: () => void;
  manageAccountUrl: string | undefined;
  isSellerOffers: boolean;
  isBuyerOffers: boolean;
  enablePlaidPAApproval: boolean;
}

export function ProfileDrawerContents({
  profile,
  onClose,
  manageAccountUrl,
  isSellerOffers,
  isBuyerOffers,
  enablePlaidPAApproval,
}: IProfileDrawerContentsProps) {
  const urls = useMonolithUrls();
  const modalStore = useModalStore();
  const { addPaymentMethodLoginFlow, fanaticsCollectBaseUrl } =
    useModalContext();
  const { profile: modalProfile } = useProfileStore();

  const pushGTMData = useGTMDataLayer();

  const {
    isEmailVerified = false,
    isPhoneVerified = false,
    hasAddress = false,
    hasPaymentMethods = false,
  } = modalProfile ?? {};

  const [isCopied, setIsCopied] = useState<boolean>(false);

  const registrationStatus = useRegistrationStatus(
    profile.extendedRegistrationStatus
  );
  const plaidRegistrationStatus = usePlaidRegistrationStatus(
    enablePlaidPAApproval
  );

  const currentRegistrationStatus = enablePlaidPAApproval
    ? plaidRegistrationStatus
    : registrationStatus;

  const intersectionRef = useRef<HTMLDivElement>(null);
  const vaultAddressRef = useRef<HTMLDivElement>(null);

  const profileSteps = useMemo(() => {
    const baseProgressArr = [isEmailVerified, isPhoneVerified, hasAddress];

    return addPaymentMethodLoginFlow
      ? [...baseProgressArr, hasPaymentMethods]
      : baseProgressArr;
  }, [
    addPaymentMethodLoginFlow,
    hasAddress,
    hasPaymentMethods,
    isEmailVerified,
    isPhoneVerified,
  ]);

  const uncompletedProfileSteps = useMemo(() => {
    return profileSteps.reduce(
      (acc: number, value: boolean) => (!value ? acc + 1 : acc),
      0
    );
  }, [profileSteps]);

  const onCompleteProfileClickHandler = () => {
    modalStore.navigate({ name: EModalContent.COMPLETE_PROFILE });
    onClose();
  };

  const onMyWalletClickHandler = () => {
    pushGTMData({ event: EGTMEvents.PROFILE_WALLET_BUTTON_CLICKED });
    modalStore.navigate({ name: EModalContent.MY_WALLET });
    onClose();
  };

  const copyVaultAddress = () => {
    setIsCopied(true);
    if (vaultAddressRef.current) {
      const addressData = vaultAddressRef.current.innerText;
      navigator.clipboard.writeText(addressData);
    }
  };

  return (
    <DrawerContent
      aria-label="member navigation"
      background="transparent"
      borderRadius="10px"
      boxShadow="0px 8px 36px 0px rgba(0, 0, 0, 0.17)"
      height="fit-content"
      id="member-navigation"
      marginX={{ base: '15px', md: '30px' }}
      marginY="20px"
      maxWidth={{ base: '75%', sm: '230px', md: '280px' }}
      overflow="hidden"
      right={{ base: undefined, md: '6% !important' }}
      role="navigation"
      top="50px !important"
      zIndex={2001}
    >
      <DrawerBody
        background={EColor.White}
        color={EColor.Black}
        flex="unset"
        padding={0}
      >
        <Flex flexDirection="column" paddingX={0} paddingY={2}>
          {uncompletedProfileSteps > 0 && (
            <ProfileDrawerSection
              as="button"
              backgroundColor={EColor.Neutral5}
              borderBottom="none"
              borderRadius="4px"
              data-testid="complete-your-profile"
              fontFamily="ABC Diatype"
              height="30px"
              lineHeight="16px"
              marginBottom={2}
              marginX="auto"
              paddingX={2}
              role="group"
              text="Complete your profile"
              textTransform="none"
              width="95%"
              onClick={onCompleteProfileClickHandler}
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Flex alignItems="center" gap="11px">
                  <Text>Complete your profile</Text>
                </Flex>
                <Flex
                  alignItems="center"
                  backgroundColor={EColor.Tomato}
                  color={EColor.Black}
                  fontSize="10px"
                  fontWeight="700"
                  height="14px"
                  justifyContent="center"
                  lineHeight="12px"
                  width="18px"
                >
                  {uncompletedProfileSteps}
                </Flex>
              </Flex>
            </ProfileDrawerSection>
          )}
          {profile.unpaidInvoiceCount > 0 ? (
            <ProfileDrawerSection
              backgroundColor={EColor.Neutral5}
              borderBottom="none"
              borderRadius="4px"
              data-testid="invoices"
              fontFamily="ABC Diatype"
              height="30px"
              marginBottom={2}
              marginX="auto"
              paddingX={2}
              text="New Invoices"
              textTransform="none"
              url={urls.payInvoiceUrl}
              width="95%"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Flex alignItems="center" gap="11px">
                  <Text>Pay Invoice</Text>
                </Flex>
                <Flex
                  alignItems="center"
                  backgroundColor={EColor.Tomato}
                  color={EColor.Black}
                  fontFamily="ABC Diatype Mono"
                  fontSize="10px"
                  fontWeight="700"
                  height="14px"
                  justifyContent="center"
                  lineHeight="12px"
                  width="18px"
                >
                  {profile.unpaidInvoiceCount}
                </Flex>
              </Flex>
            </ProfileDrawerSection>
          ) : null}
          <ProfileDrawerSection
            data-testid="vault"
            text="Vault"
            url={urls.memberVaultUrl}
          >
            My Vault
          </ProfileDrawerSection>
          <ProfileDrawerSection
            data-testid="submissions"
            text="Submissions"
            url={urls.memberMySubmissionsUrl}
          >
            My Submissions
          </ProfileDrawerSection>
          <ProfileDrawerSection
            data-testid="selling"
            showBadge={isSellerOffers}
            text="Selling"
            url={urls.memberMySellingUrl}
          >
            Selling
          </ProfileDrawerSection>
          <ProfileDrawerSection
            data-testid="buying"
            showBadge={isBuyerOffers}
            text="Buying"
            url={urls.memberMyBuyingUrl}
          >
            Buying
          </ProfileDrawerSection>
          <ProfileDrawerSection
            as="button"
            showBadge={!hasPaymentMethods}
            onClick={onMyWalletClickHandler}
          >
            My Wallet
          </ProfileDrawerSection>
          <ProfileDrawerSection
            data-testid="summary"
            text="Summary"
            url={urls.memberSummaryUrl}
          >
            Summary
          </ProfileDrawerSection>
          <ProfileDrawerSection
            data-testid="accounting"
            text="Accounting"
            url={urls.memberMyAccountingUrl}
          >
            Accounting
          </ProfileDrawerSection>
          <ProfileDrawerSection
            data-testid="preferences"
            text="Preferences"
            url={urls.memberMyPreferencesUrl}
            onClick={() =>
              pushGTMData({
                event: EGTMEvents.PROFILE_MANAGE_ACCOUNT_BUTTON_CLICKED,
              })
            }
          >
            Preferences
          </ProfileDrawerSection>
          {currentRegistrationStatus.label !== 'Denied' && (
            <ProfileDrawerSection
              cursor="pointer"
              data-testid="premier-status"
              text="Premier Auction"
              {...(uncompletedProfileSteps === 0
                ? {
                    url: currentRegistrationStatus.href,
                  }
                : {
                    onClick: () => {
                      modalStore.navigate({
                        name: EModalContent.COMPLETE_PROFILE,
                      });
                      onClose();
                    },
                  })}
            >
              <Flex justifyContent="space-between" width="100%">
                <Text as="span">Premier Auction</Text>
                <Text
                  _hover={{
                    textDecoration:
                      currentRegistrationStatus.label === 'Apply'
                        ? 'underline'
                        : '',
                  }}
                  as="span"
                  backgroundColor={currentRegistrationStatus.color}
                  color={
                    currentRegistrationStatus.label === 'Apply'
                      ? EColor.Neutral55
                      : EColor.Black
                  }
                  fontFamily="ABC Diatype"
                  fontSize="12px"
                  fontWeight="700"
                  lineHeight="16px"
                  paddingX={2}
                  textTransform="none"
                >
                  {currentRegistrationStatus.label}
                </Text>
              </Flex>
            </ProfileDrawerSection>
          )}
        </Flex>
        <Flex
          backgroundColor={EColor.Neutral2}
          color={EColor.Neutral65}
          display="flex"
          flexDirection="column"
          fontSize="12px"
          fontWeight="400"
          justifyContent="center"
          lineHeight="16px"
          paddingX={4}
          pb={4}
          pt={2}
          ref={intersectionRef}
        >
          <Flex justifyContent="space-between">
            <Text
              color={EColor.Black}
              paddingY="10px"
              variant={ETextVariant.XS2Bold}
            >
              My Vault Address
            </Text>
            <Flex
              alignItems="center"
              cursor="pointer"
              onClick={() => copyVaultAddress()}
            >
              <CopyIcon />
              <Text
                color={EColor.Neutral55}
                paddingLeft="5px"
                paddingY="10px"
                variant={ETextVariant.XS2Bold}
              >
                {isCopied ? 'Copied!' : 'Copy'}
              </Text>
            </Flex>
          </Flex>
          <Box ref={vaultAddressRef}>
            <AddressLine>
              {`${profile.firstName} ${profile.lastName}`}
            </AddressLine>
            <AddressLine>
              {profile.vaultAddress?.street}
              {profile.vaultAccountId ? `, ID ${profile.vaultAccountId}` : ''}
            </AddressLine>
            <AddressLine>{profile.vaultAddress?.cityStateZip}</AddressLine>
          </Box>
        </Flex>
      </DrawerBody>

      <DrawerFooter
        background={EColor.White}
        borderRadius="0 0 10px 10px"
        paddingX={4}
      >
        <Flex direction="column" rowGap="8px" width="100%">
          {!!manageAccountUrl && (
            <Link
              aria-label="manage account"
              color={EColor.Neutral55}
              fontSize="12px"
              fontWeight="400"
              lineHeight="16px"
              target="_blank"
              width="100%"
              onClick={() => {
                pushGTMData({
                  event: EGTMEvents.MANAGE_ACCOUNT_BUTTON_CLICKED,
                });
                window.location.href = manageAccountUrl;
              }}
            >
              Manage Account
            </Link>
          )}

          <Link
            aria-label="sign out"
            color={EColor.Neutral55}
            fontSize="12px"
            fontWeight="400"
            lineHeight="16px"
            width="100%"
            onClick={() => collectLogout(fanaticsCollectBaseUrl)}
          >
            Log Out
          </Link>
        </Flex>
      </DrawerFooter>
    </DrawerContent>
  );
}
