import React from 'react';

import { Box, Flex } from '@chakra-ui/react';

import { FooterLink } from './FooterLink';
import { INavigationItem } from '../../types/navigation';

export interface IFooterProps {
  links?: INavigationItem[];
}

export function FooterSocialLinks({ links = [] }: IFooterProps) {
  return (
    <Box transitionDuration="0.2s">
      {links.map(({ items, name }) => (
        <Flex columnGap="12px" key={`social-link-${name}`}>
          {items?.map((innerItem) => (
            <FooterLink
              category={name}
              data-testid={`footer-link-${innerItem.dataTestId}`}
              href={innerItem.path}
              icon={innerItem.Icon}
              iconSizes={innerItem.iconSizes}
              key={`footer-social-link-${innerItem.dataTestId}`}
              name={innerItem.name}
              target="_blank"
            />
          ))}
        </Flex>
      ))}
    </Box>
  );
}
