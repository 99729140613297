import { useMemo } from 'react';

import { INavigationItem } from '../types/navigation';

import { InstagramIcon, TwitterIcon, YoutubeIcon } from '../../Icons';
import { getFanaticsUrls, getMonolithUrls } from '../utils';
import { mapMarketplaceUrlMonolithToCollect } from '../../collect/mapping/marketplaceUrl';

export function useNavItems(
  fanaticsCollectBaseUrl = 'https://fanaticscollect.com',
  navItemsOverride?: INavigationItem[],
  isInCollectApp?: boolean
): INavigationItem[] {
  const navItems = useMemo(() => {
    if (navItemsOverride) {
      return navItemsOverride;
    }

    const monolithUrls = getMonolithUrls(fanaticsCollectBaseUrl);

    const items: INavigationItem[] = [
      {
        name: 'Auction',
        path: monolithUrls.weeklyAuctionUrl,
        dataTestId: 'weekly',
      },
      {
        name: 'Premier',
        path: monolithUrls.premierAuctionUrl,
        dataTestId: 'premier',
      },
      {
        name: 'Buy Now',
        path: monolithUrls.fixedPriceUrl,
        dataTestId: 'fixed-price',
      },
      {
        name: 'Sports Cards',
        path: `${monolithUrls.weeklyAuctionUrl}?categorygroup=sportscards`,
        dataTestId: 'sports-cards',
        items: [
          {
            name: 'Baseball',
            path: `${monolithUrls.weeklyAuctionUrl}?category=baseball`,
            dataTestId: 'baseball',
          },
          {
            name: 'Basketball',
            path: `${monolithUrls.weeklyAuctionUrl}?category=basketball`,
            dataTestId: 'basketball',
          },
          {
            name: 'Football',
            path: `${monolithUrls.weeklyAuctionUrl}?category=football`,
            dataTestId: 'football',
          },
          {
            name: 'Hockey',
            path: `${monolithUrls.weeklyAuctionUrl}?category=hockey`,
            dataTestId: 'hockey',
          },
          {
            name: 'Soccer',
            path: `${monolithUrls.weeklyAuctionUrl}?category=soccer`,
            dataTestId: 'soccer',
          },
          {
            name: 'Combat Sports',
            path: `${monolithUrls.weeklyAuctionUrl}?category=combat`,
            dataTestId: 'combat',
          },
          {
            name: 'Golf',
            path: `${monolithUrls.weeklyAuctionUrl}?category=golf`,
            dataTestId: 'golf',
          },
          {
            name: 'Racing',
            path: `${monolithUrls.weeklyAuctionUrl}?category=racing`,
            dataTestId: 'racing',
          },
          {
            name: 'Misc Sports',
            path: `${monolithUrls.weeklyAuctionUrl}?category=miscsports`,
            dataTestId: 'misc-sports',
          },
        ],
      },
      {
        name: 'Trading Cards',
        path: `${monolithUrls.weeklyAuctionUrl}?categorygroup=tcg`,
        dataTestId: 'trading-card-games',
        items: [
          {
            name: 'Pokémon',
            path: `${monolithUrls.weeklyAuctionUrl}?category=pokemonenglish-pokemonjapanese-pokemonotherlanguage`,
            dataTestId: 'pokemon',
          },
          {
            name: 'Yu-Gi-Oh!',
            path: `${monolithUrls.weeklyAuctionUrl}?category=yugioh`,
            dataTestId: 'yu-gi-oh',
          },
          {
            name: 'Magic The Gathering',
            path: `${monolithUrls.weeklyAuctionUrl}?category=mtg`,
            dataTestId: 'magic-the-gathering',
          },
          {
            name: 'Collectible Card Games',
            path: `${monolithUrls.weeklyAuctionUrl}?category=ccg`,
            dataTestId: 'collectible-card-games',
          },
        ],
      },
      {
        name: 'More',
        path: `${monolithUrls.weeklyAuctionUrl}?categorygroup=othercategories-videogames-comics`,
        dataTestId: 'others',
        items: [
          {
            name: 'Wax',
            path: `${monolithUrls.weeklyAuctionUrl}?category=wax`,
            dataTestId: 'wax',
          },
          {
            name: 'Comics',
            path: `${monolithUrls.weeklyAuctionUrl}?categorygroup=comics`,
            dataTestId: 'comics',
          },
          {
            name: 'Memorabilia',
            path: `${monolithUrls.weeklyAuctionUrl}?category=memorabilia`,
            dataTestId: 'memorabilia',
          },
          {
            name: 'Video Games',
            path: `${monolithUrls.weeklyAuctionUrl}?categorygroup=videogames`,
            dataTestId: 'video-games',
          },
          {
            name: 'Sold Items',
            path: monolithUrls.salesHistoryUrl,
            dataTestId: 'sales-history',
          },
        ],
      },
    ];

    if (isInCollectApp) {
      const mapRecursive = (item: INavigationItem) => {
        if (item.path) {
          item.path = mapMarketplaceUrlMonolithToCollect(item.path).toString();
        }

        item.items?.forEach((subItem) => {
          mapRecursive(subItem);
        });
      };

      items.forEach(mapRecursive);
    }

    return items;
  }, [navItemsOverride, fanaticsCollectBaseUrl, isInCollectApp]);

  return navItems;
}

export function getFooterSocialLinks(): INavigationItem[] {
  const { social } = getFanaticsUrls();
  const { instagramLink, youtubeLink, twitterLink } = social;

  return [
    {
      name: '',
      items: [
        {
          name: '',
          path: instagramLink,
          Icon: InstagramIcon,
          iconSizes: {
            width: '24px',
            height: '24px',
          },
          dataTestId: 'instagram-link',
        },
        {
          name: '',
          path: youtubeLink,
          Icon: YoutubeIcon,
          iconSizes: {
            width: '24px',
            height: '24px',
          },
          dataTestId: 'youtube-link',
        },
        {
          name: '',
          path: twitterLink,
          Icon: TwitterIcon,
          iconSizes: {
            width: '24px',
            height: '24px',
          },
          dataTestId: 'twitter-link',
        },
      ],
    },
  ];
}
