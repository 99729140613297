import { useQuery } from '@tanstack/react-query';

import { useApi } from '../../../ApiClient';
import { IPastSearchResponse } from '../types/pastSearch';

export const useGetPastSearches = (
  pastSearchBaseUrl: string,
  enabled?: boolean
) => {
  const apiClient = useApi();
  return useQuery({
    queryKey: ['getPastSearch'],
    queryFn: async () =>
      apiClient?.get<IPastSearchResponse>(`/saved-search/v1`, {
        baseURL: pastSearchBaseUrl,
      }),
    select: (data) => ({
      ...data,
      totalSearches:
        (data?.savedSearchList?.length ?? 0) +
        (data?.lastThreeList?.length ?? 0),
      allSearches: [
        ...(data?.lastThreeList ?? []),
        ...(data?.savedSearchList ?? []),
      ],
    }),
    enabled: enabled !== undefined ? enabled : true,
  });
};
