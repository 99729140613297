import React from 'react';

import { createIcon } from '@chakra-ui/react';

export const PenIcon = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3631 5.65156L15.843 4.17157C16.6241 3.39052 17.8904 3.39052 18.6715 4.17157L20.0857 5.58579C20.8667 6.36683 20.8667 7.63316 20.0857 8.41421L18.6057 9.8942M14.3631 5.65156L4.74742 15.2672C4.41535 15.5993 4.21072 16.0376 4.1694 16.5054L3.92731 19.2459C3.87254 19.8659 4.39141 20.3848 5.01143 20.33L7.75184 20.0879C8.21965 20.0466 8.658 19.8419 8.99007 19.5099L18.6057 9.8942M14.3631 5.65156L18.6057 9.8942"
        stroke="#737373"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  ),
});
