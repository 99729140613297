import { gql } from '../../../artifacts/gql';

export const getUserOffersStatus = gql(`
  query webUserOffersStatusCCQuery {
    collectCurrentUserV2 {
      tenantId
      hasNewOffersFromSellers 
      hasNewOffersFromBidders 
    }
  }
`);
