import React, { useCallback, useMemo } from 'react';

import { ModalContent, ModalHeader } from '../../../components';
import { AddPaymentMethod } from '../../../components/AddPaymentMethod';
import { useModalStore } from '../../../stores';
import { EModalContent } from '../../../types';
import { EPaymentType } from '../types';

export const AddCreditCard = () => {
  const modalStore = useModalStore();

  const paymentTypes = useMemo(() => [EPaymentType.CARD], []);

  const handleBack = () => {
    modalStore.pop();
  };

  const handleSuccess = useCallback(() => {
    modalStore.navigate({ name: EModalContent.MY_WALLET });
  }, [modalStore]);

  return (
    <ModalContent
      header={<ModalHeader onClick={handleBack}>Add Credit Card</ModalHeader>}
    >
      <AddPaymentMethod
        showSetDefault
        paymentTypes={paymentTypes}
        onSuccess={handleSuccess}
      />
    </ModalContent>
  );
};
