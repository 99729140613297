import React from 'react';

import { Flex, Icon, Link, Text } from '@chakra-ui/react';

import { AppstoreIcon, GooglePlayIcon } from '../../../Icons';
import { EColor } from '../../../Theme';
import { getFanaticsUrls } from '../../utils/fanaticsUrls';

export function FooterAppLinks() {
  const { app } = getFanaticsUrls();
  const { appStoreLink, googlePlayLink } = app;

  return (
    <Flex
      align={{ base: 'center', lg: 'start' }}
      flex={1}
      flexDirection={{ base: 'column', md: 'row', lg: 'column' }}
      gap={{ base: '8px', md: '16px' }}
      justify={{ base: 'initial', md: 'center', lg: 'initial' }}
      px="16px"
      py="32px"
    >
      <Text fontSize="18px" fontWeight="700" sx={{ textWrap: 'nowrap' }}>
        Get the App
      </Text>

      <Flex flexDirection={{ base: 'row', lg: 'column' }} gap="4px">
        <Link href={appStoreLink} target="_blank">
          <Icon
            as={AppstoreIcon}
            color={EColor.Neutral75}
            height="40px"
            width="120px"
          />
        </Link>

        <Link href={googlePlayLink} target="_blank">
          <Icon
            as={GooglePlayIcon}
            color={EColor.Neutral75}
            height="40px"
            width="120px"
          />
        </Link>
      </Flex>
    </Flex>
  );
}
