import { createContext } from 'react';

import { STRIPE_MARKETPLACE_PUBLISHABLE_KEY } from '../config/constants';

const MEMBERS_API_URL_PROD = 'https://members.api.fanaticscollect.com';
const FANATICS_MARKETPLACE_BASE_URL_PROD = 'https://fanaticscollect.com';
const CHECKOUT_API_URL_PROD = 'https://checkout.api.fanaticscollect.com';

export const ModalContext = createContext<{
  membersApiUrl: string;
  fanaticsCollectBaseUrl: string;
  checkoutApiUrl: string;
  stripeMarketplaceKey?: string;
  addPaymentMethodLoginFlow: boolean;
  manageAccountUrl: string | undefined;
}>({
  membersApiUrl: MEMBERS_API_URL_PROD,
  fanaticsCollectBaseUrl: FANATICS_MARKETPLACE_BASE_URL_PROD,
  checkoutApiUrl: CHECKOUT_API_URL_PROD,
  // Made this optional to allow for the stripe key to be fetched from the server but keeping the default value for the time being until every platform
  // makes the change.
  stripeMarketplaceKey: STRIPE_MARKETPLACE_PUBLISHABLE_KEY,
  addPaymentMethodLoginFlow: false,
  manageAccountUrl: undefined,
});

export const ModalContextProvider = ModalContext.Provider;
