import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  HStack,
  Text,
} from '@chakra-ui/react';

import { MultipleOffersModalBody } from './components/MultipleOffersModalBody';
import { SingleOfferModalBody } from './components/SingleOfferModalBody';
import { CollectOfferData } from '../../artifacts/gql/graphql';
import {
  getUserPendingOffers,
  markOfferNotificationAsViewedMutation,
} from '../gql';

type CurrentModalType =
  | 'seller-single'
  | 'seller-single-countered'
  | 'seller-multiple'
  | 'buyer-single'
  | 'buyer-multiple';

type NotificationType =
  | 'offers-for-seller'
  | 'counter-offers-for-seller'
  | 'counter-offers-for-buyer';

type CurrentModal = {
  type: CurrentModalType;
  notificationType: NotificationType;
  offers: CollectOfferData[];
};

type UserOfferDialogProps = {
  offersNotificationDialogEnabled: boolean;
};

export const UserOfferDialog = ({
  offersNotificationDialogEnabled,
}: UserOfferDialogProps) => {
  const { data: userPendingOffers } = useQuery(getUserPendingOffers, {
    fetchPolicy: 'network-only',
    skip: !offersNotificationDialogEnabled,
  });
  const [markOfferNotificationAsViewed] = useMutation(
    markOfferNotificationAsViewedMutation
  );

  const [currentModal, setCurrentModal] = useState<CurrentModal | null>(null);
  const [shownOffersNotifications, setShownOffersNotifications] = useState<
    Set<NotificationType>
  >(new Set());

  const pendingOffers =
    userPendingOffers?.collectCurrentUserV2?.collectPendingOffers;

  const getFilteredOffers = useCallback((offers: CollectOfferData[] = []) => {
    return offers?.filter((offer) => offer?.showNotification) || [];
  }, []);

  useEffect(() => {
    if (currentModal || !pendingOffers) return;

    const offersForSellerToShow = getFilteredOffers(
      pendingOffers?.offersForSeller as CollectOfferData[]
    );
    const counterOffersForSellerToShow = getFilteredOffers(
      pendingOffers?.counterOffersForSeller as CollectOfferData[]
    );
    const counterOffersForBuyerToShow = getFilteredOffers(
      pendingOffers?.counterOffersForBuyer as CollectOfferData[]
    );

    if (
      offersForSellerToShow.length &&
      !shownOffersNotifications.has('offers-for-seller')
    ) {
      setCurrentModal({
        type:
          offersForSellerToShow.length === 1
            ? 'seller-single'
            : 'seller-multiple',
        offers: offersForSellerToShow,
        notificationType: 'offers-for-seller',
      });
      return;
    }

    if (
      counterOffersForSellerToShow.length &&
      !shownOffersNotifications.has('counter-offers-for-seller')
    ) {
      setTimeout(
        () => {
          setCurrentModal({
            type:
              counterOffersForSellerToShow.length === 1
                ? 'seller-single-countered'
                : 'seller-multiple',
            offers: counterOffersForSellerToShow,
            notificationType: 'counter-offers-for-seller',
          });
        },
        shownOffersNotifications.size > 0 ? 1000 : 0
      );
      return;
    }

    if (
      counterOffersForBuyerToShow.length &&
      !shownOffersNotifications.has('counter-offers-for-buyer')
    ) {
      setTimeout(
        () => {
          setCurrentModal({
            type:
              counterOffersForBuyerToShow.length === 1
                ? 'buyer-single'
                : 'buyer-multiple',
            offers: counterOffersForBuyerToShow,
            notificationType: 'counter-offers-for-buyer',
          });
        },
        shownOffersNotifications.size > 0 ? 1000 : 0
      );
    }
  }, [
    pendingOffers,
    currentModal,
    shownOffersNotifications,
    getFilteredOffers,
  ]);

  const viewHandler = useCallback(
    async (needAwait: boolean, needCloseModal: boolean) => {
      if (!currentModal) return;

      const variables = {
        input: {
          offerIds: currentModal?.offers.map((offer) => offer.offerId),
        },
      };

      try {
        // eslint-disable-next-line react/prop-types
        if (needAwait) {
          await markOfferNotificationAsViewed({
            variables,
          });
        } else {
          markOfferNotificationAsViewed({
            variables,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        // eslint-disable-next-line react/prop-types
        if (needCloseModal) {
          setShownOffersNotifications((prev) => {
            const newSet = new Set(prev);
            newSet.add(currentModal.notificationType);
            return newSet;
          });
          setCurrentModal(null);
        }
      }
    },
    [currentModal, markOfferNotificationAsViewed]
  );

  const onCloseHandler = () => {
    viewHandler(false, true);
  };

  const dialogTitle = useMemo(() => {
    return currentModal?.type === 'buyer-multiple' ||
      currentModal?.type === 'seller-multiple'
      ? 'Offers pending'
      : 'Offer pending';
  }, [currentModal?.type]);

  const renderDialogContent = () => {
    switch (currentModal?.type) {
      case 'seller-single':
        return (
          <SingleOfferModalBody
            offer={currentModal.offers[0]}
            type="seller"
            viewHandler={viewHandler}
          />
        );
      case 'seller-multiple':
        return (
          <MultipleOffersModalBody type="seller" viewHandler={viewHandler} />
        );
      case 'buyer-multiple':
        return (
          <MultipleOffersModalBody type="buyer" viewHandler={viewHandler} />
        );
      case 'buyer-single':
        return (
          <SingleOfferModalBody
            offer={currentModal.offers[0]}
            type="buyer"
            viewHandler={viewHandler}
          />
        );
      case 'seller-single-countered':
        return (
          <SingleOfferModalBody
            offer={currentModal.offers[0]}
            type="seller-countered"
            viewHandler={viewHandler}
          />
        );
      default:
        return <p>Oops, something went wrong...</p>;
    }
  };

  const closeButtonClickHandler = useCallback(() => {
    setCurrentModal(null);
  }, [setCurrentModal]);

  if (!offersNotificationDialogEnabled) return null;

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={!!currentModal}
      motionPreset="slideInBottom"
      onClose={onCloseHandler}
    >
      <ModalOverlay />
      <ModalContent bg="white" borderRadius="0" color="black" maxW="416px">
        <ModalHeader padding="24px">
          <HStack justify="space-between">
            <Text fontSize="xl">{dialogTitle}</Text>
            <ModalCloseButton
              position="relative"
              right="unset"
              top="unset"
              onClick={closeButtonClickHandler}
            >
              <CloseIcon boxSize="12px" />
            </ModalCloseButton>
          </HStack>
        </ModalHeader>

        {renderDialogContent()}
      </ModalContent>
    </Modal>
  );
};
