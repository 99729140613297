import React, { FC } from 'react';

import {
  FieldValues,
  FormProvider,
  FormProviderProps,
  SubmitErrorHandler,
  SubmitHandler,
} from 'react-hook-form';

export interface IFormProps<TFieldValues extends FieldValues>
  extends FormProviderProps<TFieldValues> {
  onSubmit: SubmitHandler<TFieldValues>;
  onInvalid?: SubmitErrorHandler<TFieldValues>;
  formStyles?: React.CSSProperties;
  formProps?: React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >;
}

export function createForm<TFieldValues extends FieldValues>() {
  const Form: FC<IFormProps<TFieldValues>> = (props) => {
    const {
      children,
      onSubmit,
      onInvalid,
      formStyles,
      formProps,
      ...restProps
    } = props;

    return (
      <FormProvider<TFieldValues> {...restProps}>
        <form
          style={{ width: '100%', ...formStyles }}
          onSubmit={restProps.handleSubmit(onSubmit, onInvalid)}
          {...formProps}
        >
          {children}
        </form>
      </FormProvider>
    );
  };

  return Form;
}
