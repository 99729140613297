import React, { FC, ReactNode, useMemo } from 'react';

import { CollectContext, ICollectContext } from './context';

interface CollectProviderProps extends ICollectContext {
  children: ReactNode;
}

export const CollectProvider: FC<CollectProviderProps> = ({
  children,
  isInCollectApp,
  stickyTop,
  onSearch,
  onModalSearchOpenChange,
}) => {
  const context = useMemo(
    () =>
      ({
        isInCollectApp,
        stickyTop,
        onSearch,
        onModalSearchOpenChange,
      } satisfies ICollectContext),
    [isInCollectApp, stickyTop, onSearch, onModalSearchOpenChange]
  );

  return (
    <CollectContext.Provider value={context}>
      {children}
    </CollectContext.Provider>
  );
};
