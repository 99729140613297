import React, { useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { RemoveScroll } from 'react-remove-scroll';

import { useContextualDisclosure } from '../../hooks/useContextualDisclosure';
import { INavigationItem } from '../../types/navigation';
import { HeaderNavItem } from '../NavMenuItems/HeaderNavItem';

interface IMainNavRowProps {
  navItems?: INavigationItem[];
}

export function MainNavRow({ navItems = [] }: IMainNavRowProps) {
  const { isOpen, onOpen, onClose, anyOpen } = useContextualDisclosure();
  const [scrollDisabled, setScrollDisabled] = useState<boolean>(false);

  return (
    <RemoveScroll forwardProps enabled={scrollDisabled}>
      <Box width="100%">
        <Flex
          aria-label="main navigation"
          display={{ base: 'none', lg: 'flex' }}
          justify="space-between"
          margin="0"
          mx="0"
          p={{ base: '16px', lg: 'unset' }}
          role="navigation"
          width="100%"
          zIndex="100"
          onMouseEnter={() => setScrollDisabled(true)}
          onMouseLeave={() => setScrollDisabled(false)}
        >
          <Box
            alignItems={{ base: 'center', lg: 'center' }}
            display="flex"
            flexDirection={{ base: 'column', lg: 'row' }}
            flexGrow={1}
            gap="12px"
            id="header-menu"
            justifyContent="space-between"
            sx={{ textWrap: 'nowrap' }}
            width="100%"
          >
            {navItems.map((navItem, idx) => (
              <HeaderNavItem
                anyOpen={anyOpen}
                idx={idx}
                isOpen={isOpen(navItem?.name)}
                key={navItem?.name}
                navItem={navItem}
                onClose={() => onClose(navItem?.name)}
                onOpen={() => onOpen(navItem?.name)}
              />
            ))}
          </Box>
        </Flex>
      </Box>
    </RemoveScroll>
  );
}
