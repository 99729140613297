import React, { useContext } from 'react';

import { Fade, Flex } from '@chakra-ui/react';

import { AdminSection } from './AdminSection';
import { CartButton } from './CartButton';
import { HeaderActions } from './HeaderActions';
import { LogoSection } from './LogoSection';
import { MainNavRow } from './MainNavRow';
import { MobileMainNav } from './MobileMainNav';
import { IAuth } from '../../../Auth/types/auth';
import { useIsMobile } from '../../../hooks';
import { MenuIcon } from '../../../Icons';
import { SearchBar } from '../../../SearchBar';
import { EColor } from '../../../Theme';
import { NavigationContext } from '../../context/NavigationContext';
import { INavigationItem } from '../../types/navigation';
import { ProfileDrawer } from '../ProfileDrawer';

export interface ITopNavRowProps {
  auth?: IAuth;
  pastSearchBaseUrl: string;
  manageAccountUrl: string | undefined;
  handleToggle: () => void;
  navItems?: INavigationItem[];
  isInitializing: boolean;
  show: boolean;
  closeMenu: () => void;
  showCartButton?: boolean;
  enablePlaidPAApproval: boolean;
  enableOfferNotificationBadges?: boolean;
}

export function TopNavRow({
  auth,
  pastSearchBaseUrl,
  manageAccountUrl,
  handleToggle,
  navItems,
  isInitializing,
  show,
  closeMenu,
  showCartButton,
  enablePlaidPAApproval,
  enableOfferNotificationBadges,
}: ITopNavRowProps) {
  const { profile } = auth || {};
  const isMobile = useIsMobile('md');

  const { isAdmin } = useContext(NavigationContext);

  return (
    <Flex
      align="center"
      aria-label="portals"
      justify="space-between"
      padding={0}
      role="navigation"
      width="100%"
    >
      <Flex
        justifyContent="space-between"
        paddingX={{ base: '15px', md: '15px' }}
        paddingY={0}
        width="100%"
      >
        {/* Logo */}
        {isMobile && (
          <Flex alignItems="center">
            <>
              <MenuIcon
                _hover={{ transform: 'scale(1.2)' }}
                boxSize="24px"
                color={EColor.Black}
                cursor="pointer"
                mr="8px"
                transitionDuration="0.2s"
                onClick={handleToggle}
              />
              {isMobile ? <LogoSection /> : null}
            </>
          </Flex>
        )}

        {!isMobile ? <LogoSection flexBasis={0} flexGrow={1} /> : null}

        <Flex
          flex={{ base: 1, lg: 'auto' }}
          flexWrap="wrap"
          gap={{ lg: '8px' }}
          marginX="15px"
          maxWidth="754px"
          minWidth={isMobile ? '100px' : undefined}
        >
          {/* Search */}
          <Flex flex={1} width="100%">
            <SearchBar pastSearchBaseUrl={pastSearchBaseUrl} />
          </Flex>

          {/* Navigation */}
          <Flex
            align="center"
            as="nav"
            fontWeight={300}
            id="header"
            justify="center"
            width="100%"
            wrap="nowrap"
          >
            <MainNavRow navItems={navItems} />
          </Flex>
        </Flex>

        {isMobile && (
          <MobileMainNav
            closeMenu={closeMenu}
            navItems={navItems}
            show={show}
          />
        )}

        {/* Actions */}
        <Flex
          alignItems={{ base: 'center', lg: 'flex-start' }}
          as={Fade}
          flexBasis={{ base: 'auto', lg: 0 }}
          flexGrow={{ base: 'auto', lg: 1 }}
          in={!isInitializing}
          justifyContent="flex-end"
        >
          {isAdmin ? (
            <AdminSection />
          ) : (
            <HeaderActions auth={auth} isInitializing={isInitializing} />
          )}
          {profile ? (
            <ProfileDrawer
              enableOfferNotificationBadges={enableOfferNotificationBadges}
              enablePlaidPAApproval={enablePlaidPAApproval}
              manageAccountUrl={manageAccountUrl}
              profile={profile}
            />
          ) : null}
          {!!showCartButton && profile && (
            <CartButton count={profile.cartCount} />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
