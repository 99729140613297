import Cookies from 'js-cookie';

import {
  ACCESS_TOKEN_COOKIE,
  REFRESH_TOKEN_COOKIE,
  NONCE_COOKIE,
  FANATICS_TENANT_ID_COOKIE,
} from '../../Auth';

export const collectLogout = (baseUrl: string) => {
  const url = new URL(baseUrl);
  const cookieDomain = url.hostname.startsWith('www.')
    ? url.hostname.replace('www.', '.')
    : `.${url.hostname}`;

  const cookieOptions = {
    path: '/',
    domain: cookieDomain,
    secure: true,
    SameSite: 'None',
  };

  Cookies.remove(ACCESS_TOKEN_COOKIE, cookieOptions);
  Cookies.remove(REFRESH_TOKEN_COOKIE, cookieOptions);
  Cookies.remove(NONCE_COOKIE, cookieOptions);
  Cookies.remove(FANATICS_TENANT_ID_COOKIE, cookieOptions);

  window.location.href = '/';
};
