import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js/min';
import { z } from 'zod';

export const CollectCompleteProfileSchema = z
  .object({
    firstName: z.string().min(1, 'Required'),
    lastName: z.string().min(1, 'Required'),
    phoneNumber: z.string().trim().min(1, 'Phone number is required'),
    countryCode: z.string(),
    optInSMS: z.boolean().optional().default(false),
  })
  .refine(
    ({ phoneNumber, countryCode }) =>
      isValidPhoneNumber(phoneNumber, countryCode.toUpperCase() as CountryCode),
    {
      path: ['phoneNumber'],
      message: 'Phone number is invalid',
    }
  );

export type TCollectCompleteProfileFormData = z.infer<
  typeof CollectCompleteProfileSchema
>;
