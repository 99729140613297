import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { useMonolithUrls } from './useMonolithUrls';
import { CollectKycStatus } from '../../../artifacts/gql/graphql';
import { getCurrentUserV2 } from '../../gql';
import { EColor } from '../../Theme';

const getStatusConfig = (url: string, approved: boolean) =>
  approved
    ? {
        label: 'Approved',
        color: EColor.Lime,
        href: url,
      }
    : {
        label: 'Get Approved',
        color: EColor.Neutral5,
        href: url,
      };

export const usePlaidRegistrationStatus = (enabled: boolean) => {
  const urls = useMonolithUrls();
  const premierUrl = urls.premierAuctionUrl;

  const { data } = useQuery(getCurrentUserV2, { skip: !enabled });
  const { kycStatus, collectPremierAuctionApproved } =
    data?.collectCurrentUserV2 ?? {};

  return useMemo(
    () =>
      getStatusConfig(
        premierUrl,
        kycStatus === CollectKycStatus.Verified &&
          !!collectPremierAuctionApproved
      ),
    [collectPremierAuctionApproved, kycStatus, premierUrl]
  );
};
