import { OwnCookies } from './OwnCookies';

export const CREDS_KEY = 'pwcc-credentials';

export function getFanaticsCollectCredentials() {
  return new OwnCookies().get(CREDS_KEY);
}

export function setFanaticsCollectCredentials(value: string) {
  return new OwnCookies().set(CREDS_KEY, value, 0.04);
}

export function clearFanaticsCollectCredentials() {
  return new OwnCookies().set(CREDS_KEY, 'deleted', 0);
}
