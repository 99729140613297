import React, { MouseEvent } from 'react';

import { Link, Box, Fade, Stack, Flex } from '@chakra-ui/react';

import { HeaderNavLink } from './HeaderNavLink';
import { LiveSuperscript } from '../../../components';
import { EGTMEvents, useGTMDataLayer } from '../../../hooks';
import { EColor, ETextVariant } from '../../../Theme';
import { INavigationItem } from '../../types/navigation';

export interface IHeaderNavMenuItemProps {
  navItem?: INavigationItem;
  idx: number;
  isOpen: boolean;
  anyOpen: boolean;
  onOpen: (e: MouseEvent) => void;
  onClose: (e: MouseEvent) => void;
}

export function HeaderNavItem({
  navItem,
  idx,
  isOpen,
  anyOpen,
  onOpen,
  onClose,
}: IHeaderNavMenuItemProps) {
  const pushGTMData = useGTMDataLayer();

  const handleMouseOver = (e: MouseEvent) => {
    if (navItem?.items && !isOpen) {
      pushGTMData({
        event: EGTMEvents.NAVIGATION_HOVER,
        navigationBreakdown: navItem.name,
      });
    }
    onOpen(e);
  };

  const handleCategoryClick = () => {
    if (!navItem?.items) {
      pushGTMData({
        event: EGTMEvents.NAVIGATION_CLICK,
        navigationBreakdown: navItem?.name,
        clickValue: navItem?.name,
      });
    }
  };

  if (!navItem) {
    return null;
  }

  return (
    <Flex justify="space-between" position="relative" tabIndex={idx}>
      <Fade in>
        <Link
          _hover={{
            textDecoration: 'none',
          }}
          alignItems="center"
          aria-label={navItem.name}
          color={EColor.Black}
          data-testid={`header-nav-${navItem.dataTestId}`}
          display="flex"
          fontFamily="ABC Diatype Mono"
          fontSize="14px"
          fontWeight="700"
          href={navItem?.path}
          lineHeight="16px"
          paddingBottom="3px"
          paddingTop="5px"
          textTransform="uppercase"
          variant={ETextVariant.SmallMono}
          onClick={handleCategoryClick}
          onMouseLeave={onClose}
          onMouseOver={handleMouseOver}
        >
          {navItem.name}
          {navItem.superscript && (
            <LiveSuperscript
              lineHeight="14px"
              marginX="3px"
              position="relative"
              top="-10px"
              {...navItem.superscriptStyles}
            >
              {navItem.superscript}
            </LiveSuperscript>
          )}
        </Link>
      </Fade>
      <Box
        _focus={{ display: 'block' }}
        aria-label={`${navItem.name} dropdown`}
        bgColor={EColor.White}
        borderRadius="8px"
        boxShadow="0px -8px 36px 0px rgba(0,0,0,0.07)"
        data-testid={`header-nav-dropdown-${navItem.dataTestId}`}
        display={isOpen ? 'block' : 'none'}
        fontSize="16px"
        fontWeight="400"
        left={{ base: '-32px', xl: '-24px' }}
        marginX={0}
        paddingBottom="5px"
        paddingTop="10px"
        paddingX={0}
        position="absolute"
        top="100%"
        zIndex={1}
        onMouseLeave={onClose}
        onMouseOver={onOpen}
      >
        {navItem.multiColumn ? (
          <Stack
            direction="row"
            justify="center"
            paddingTop="4px"
            paddingX="10px"
          >
            {navItem.items?.map((item, itemIdx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={itemIdx}>
                <HeaderNavLink
                  color="brand.warning"
                  data-testid={`header-nav-child-${item.dataTestId}`}
                  dropdownPath={[navItem.name]}
                  fontSize="12px"
                  fontWeight={600}
                  item={item}
                  mr="60px"
                  textTransform="uppercase"
                />
                {item.Component && (
                  <Box paddingX="40px" paddingY="4px">
                    <item.Component />
                  </Box>
                )}
                {item.items?.map((innerItem, innerIdx) => (
                  <HeaderNavLink
                    data-testid={`header-nav-grandchild-${innerItem.dataTestId}`}
                    dropdownPath={[navItem.name, item.name]}
                    item={innerItem}
                    // eslint-disable-next-line react/no-array-index-key
                    key={innerIdx}
                    parent={navItem.name}
                  />
                ))}
              </Box>
            ))}
          </Stack>
        ) : (
          <Flex
            direction="column"
            display={navItem.items ? 'flex' : 'none'}
            justify="start"
            minW="215px"
            wrap="wrap"
          >
            {navItem.items?.map((innerItem, itemIdx) => (
              <HeaderNavLink
                data-testid={`header-nav-child-${innerItem.dataTestId}`}
                dropdownPath={[navItem.name]}
                item={innerItem}
                // eslint-disable-next-line react/no-array-index-key
                key={itemIdx}
                parent={navItem.name}
              />
            ))}
          </Flex>
        )}
      </Box>
    </Flex>
  );
}
