import React, { FC } from 'react';

import { IconProps, Icon } from '@chakra-ui/react';

import { EColor } from '../Theme';

export const CheckmarkIcon: FC<IconProps & { isChecked: boolean }> = ({
  isChecked,
  ...restProps
}) => (
  <Icon viewBox="0 0 18 18" {...restProps}>
    <svg
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9"
        cy="9"
        r="9"
        {...(isChecked
          ? { fill: EColor.Lime }
          : { stroke: '#F2F2F2', strokeWidth: '2' })}
      />
      {isChecked && (
        <path
          d="M5 9.29231L7.5985 12L13 6"
          stroke="#282828"
          strokeLinecap="round"
          strokeWidth="2"
        />
      )}
    </svg>
  </Icon>
);
