export const LOGIN_INTENDED_URL_KEY = 'login-intended-url';

export function setLocalStorage(key: string, value?: any) {
  return localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorage(key: string) {
  try {
    return JSON.parse(localStorage.getItem(key) ?? 'null');
  } catch (e) {
    return null;
  }
}

export const removeLocalStorageItem = (key: string) => {
  return localStorage.removeItem(key);
};

export function getIntendedUrl() {
  return getLocalStorage(LOGIN_INTENDED_URL_KEY) || '/';
}

export function setIntendedUrl(value: string) {
  return setLocalStorage(LOGIN_INTENDED_URL_KEY, value);
}
