import React, { FC } from 'react';

import { QueryClientProvider } from '@tanstack/react-query';

import { UpcomingEventsContent } from './UpcomingEventsContent';
import { queryClient } from '../../utilities';

export interface IUpcomingEventsProps {
  auctionsBaseUrl: string;
  baseUrl: string;
}

export const UpcomingEvents: FC<IUpcomingEventsProps> = ({
  auctionsBaseUrl,
  baseUrl,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <UpcomingEventsContent
        auctionsBaseUrl={auctionsBaseUrl}
        baseUrl={baseUrl}
      />
    </QueryClientProvider>
  );
};
