import React, { useContext, useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import Cookies from 'js-cookie';

import { NavRows } from './NavRows';
import { ACCESS_TOKEN_COOKIE, FANATICS_TENANT_ID_COOKIE } from '../../../Auth';
import { IAuth } from '../../../Auth/types/auth';
import { NonProdBanner } from '../../../NonProdBanner';
import { SiteWideMessage } from '../../../SiteWideMessage';
import { UserOfferDialog } from '../../../UserOfferDialog';
import { decodeJwt } from '../../../utilities/decodeJwt';
import { NavigationContext } from '../../context/NavigationContext';
import { INavigationItem } from '../../types/navigation';
import { constructManageAccountUrl } from '../../utils/fanaticsUrls';

export interface IHeaderProps {
  auth?: IAuth;
  navItems?: INavigationItem[];
  searchBaseUrl: string;
  pastSearchBaseUrl: string;
  fanIdBaseUrl: string;
  fanIdClientId: string;
  fanIdPreview?: string;
  showPencilBanner?: boolean;
  siteWideMessage?: string;
  showNonProdBanner?: boolean;
  isInitializing?: boolean;
  showCartButton?: boolean;
  enablePlaidPAApproval?: boolean;
  enableOfferNotificationBadges?: boolean;
  offersNotificationDialogEnabled?: boolean;
}

export function Header({
  auth,
  navItems,
  searchBaseUrl,
  pastSearchBaseUrl,
  fanIdBaseUrl,
  fanIdClientId,
  fanIdPreview,
  showPencilBanner = true,
  siteWideMessage,
  showNonProdBanner,
  isInitializing = false,
  showCartButton,
  enablePlaidPAApproval = false,
  enableOfferNotificationBadges,
  offersNotificationDialogEnabled = false,
}: IHeaderProps) {
  const { fanaticsCollectBaseUrl } = useContext(NavigationContext);

  useEffect(() => {
    if (!Cookies.get(FANATICS_TENANT_ID_COOKIE)) {
      try {
        const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE);

        if (accessToken) {
          // eslint-disable-next-line camelcase
          const { tenant_id, exp, aud } = decodeJwt<{
            tenant_id: string;
            exp: number | undefined;
            aud: string | undefined;
          }>(accessToken);

          Cookies.set(FANATICS_TENANT_ID_COOKIE, tenant_id, {
            expires: new Date((exp ?? Date.now()) * 1000),
            domain: aud,
          });
        }
      } catch (error) {
        console.log('Could not set tenant id cookie', error);
      }
    }
  }, []);

  return (
    <>
      <Box>
        {showNonProdBanner && <NonProdBanner searchBaseUrl={searchBaseUrl} />}

        {showPencilBanner && (
          <SiteWideMessage
            data-testid="nav-main-header-banner"
            messageOverride={siteWideMessage}
          />
        )}
      </Box>
      <NavRows
        auth={auth}
        data-testid="nav-main-header-options"
        enableOfferNotificationBadges={enableOfferNotificationBadges}
        enablePlaidPAApproval={enablePlaidPAApproval}
        isInitializing={isInitializing}
        manageAccountUrl={constructManageAccountUrl(
          fanaticsCollectBaseUrl ?? '',
          fanIdBaseUrl,
          fanIdClientId,
          fanIdPreview
        )}
        navItems={navItems}
        pastSearchBaseUrl={pastSearchBaseUrl}
        showCartButton={showCartButton}
      />

      <UserOfferDialog
        offersNotificationDialogEnabled={offersNotificationDialogEnabled}
      />
    </>
  );
}

export default Header;
