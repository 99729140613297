import { useCallback } from 'react';

import Cookies from 'js-cookie';

import { IGTMData } from './types';
import {
  ACCESS_TOKEN_COOKIE,
  FANATICS_TENANT_ID_COOKIE,
  IAuth,
  useAuth,
} from '../../Auth';
import { ENonProdEnvs } from '../../NonProdBanner/types';
import { EMarketplaceType, getMarketplaceType } from '../../utilities';
import { decodeJwt } from '../../utilities/decodeJwt';

export const isTestEnv = ({ hostname } = window.location) => {
  const array = hostname.split('.');
  return (
    array.slice(-1)[0] === 'localhost' ||
    (Object.values(ENonProdEnvs) as string[]).includes(
      array.slice(0, -2).slice(-1)[0]
    )
  );
};

const stripUndefined = (obj: Record<string, unknown>) =>
  Object.fromEntries(
    Object.entries(obj).filter(([_, val]) => val !== undefined)
  );

const getGA4MarketplaceType = (marketplace?: EMarketplaceType) => {
  switch (marketplace) {
    case EMarketplaceType.WEEKLY:
      return 'weekly';
    case EMarketplaceType.PREMIER:
      return 'extended';
    case EMarketplaceType.FIXED:
      return 'vault';
    default:
      return undefined;
  }
};

export const getTenantId = () => {
  try {
    const tenantId = Cookies.get(FANATICS_TENANT_ID_COOKIE);

    if (tenantId) {
      return tenantId;
    }

    const pwccAccessToken = Cookies.get(ACCESS_TOKEN_COOKIE);

    if (pwccAccessToken) {
      return decodeJwt<{ tenant_id: string }>(pwccAccessToken).tenant_id;
    }
  } catch {
    return undefined;
  }
};

export const getGTMData = (
  data: IGTMData,
  authData?: IAuth,
  marketplace?: EMarketplaceType
) => {
  const profile = authData?.profile;
  const { userId, vaultAccountId } = profile ?? {};
  const tenantId = getTenantId();

  const gtmData = stripUndefined({
    ...data,

    // GA4
    authenticationState: !!profile && !!tenantId,
    marketplace: getGA4MarketplaceType(marketplace),
    pwccAccountId: userId,
    vaultAccountId,

    // Segment
    Marketplace: marketplace,
    'User ID': tenantId,
  });

  return gtmData;
};

export const useGTMDataLayer = () => {
  const authData = useAuth();
  const marketplace = getMarketplaceType();

  return useCallback(
    (gtmData: IGTMData) => {
      const data = getGTMData(gtmData, authData, marketplace);

      window?.dataLayer?.push(data);

      if (isTestEnv()) {
        // eslint-disable-next-line no-console
        console.log('Common Components GTM data:', data);
      }
    },
    [authData, marketplace]
  );
};
