import React, { FC, ReactNode } from 'react';

import {
  ModalBody,
  ModalBodyProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
} from '@chakra-ui/react';

import { EColor } from '../../Theme';

interface IModalContentProps extends ModalBodyProps {
  header?: ReactNode;
  footer?: ReactNode;
  footerProps?: ModalFooterProps;
  children: ReactNode;
}

export const ModalContent: FC<IModalContentProps> = ({
  header,
  footer,
  footerProps,
  children,
  ...restProps
}) => {
  return (
    <>
      {header && (
        <ModalHeader
          alignItems="center"
          borderBottom={`1px solid ${EColor.Neutral10}`}
          display="flex"
          minHeight="70px"
          py="20px"
        >
          {header}
        </ModalHeader>
      )}
      <ModalBody p="24px" {...restProps}>
        {children}
      </ModalBody>
      {footer && (
        <ModalFooter
          borderTop={`1px solid ${EColor.Neutral10}`}
          flexGrow={0}
          px="24px"
          py="16px"
          {...footerProps}
        >
          {footer}
        </ModalFooter>
      )}
    </>
  );
};
