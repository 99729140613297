import { generateMonolithUrl } from './monolithUrls';

export const getFanaticsUrls = () => ({
  fanaticsLiveUrl: 'https://www.fanatics.live/home',
  fanaticsCollectUrl: 'https://fanaticscollect.com',
  social: {
    instagramLink: 'https://www.instagram.com/fanaticscollect',
    facebookLink: 'https://www.facebook.com/profile.php?id=61562488791753',
    youtubeLink: 'https://www.youtube.com/@FanaticsCollect',
    twitterLink: 'https://x.com/FanaticsCollect',
    liveLink: 'https://www.fanatics.live/home',
  },
  app: {
    appStoreLink: 'https://apps.apple.com/us/app/pwcc-marketplace/id1546059696',
    googlePlayLink:
      'https://play.google.com/store/apps/details?id=com.pwcc.mobile',
  },
  icons: {
    fanaticsCollectFooterLogoUrl:
      'https://s3-us-west-2.amazonaws.com/pwccauctions/website/logos/fanatics-rotating-logo.gif',
    fanaticsCollectLogoSmallUrl:
      'https://s3-us-west-2.amazonaws.com/pwccauctions/website/logos/fanatics-logo-small.png',
    fanaticsCollectTransparentLogoUrl:
      'https://s3-us-west-2.amazonaws.com/pwccauctions/website/logos/fanatics-rotating-transparent-logo.gif',
    fanaticsCollectLogoImage:
      'https://s3-us-west-2.amazonaws.com/pwccauctions/website/logos/fanatics-marketplace-logo-image.jpg',
  },
});

export const constructManageAccountUrl = (
  fanaticsCollectBaseUrl: string,
  fanIdBaseUrl: string,
  fanIdClientId: string,
  fanIdPreview: string | undefined
) => {
  try {
    const url = new URL(`${fanIdBaseUrl}/account`);

    url.searchParams.append('client_id', fanIdClientId);

    url.searchParams.append(
      'redirect_uri',
      generateMonolithUrl(fanaticsCollectBaseUrl, '/callback/fanid')
    );

    if (fanIdPreview) {
      url.searchParams.append('preview', fanIdPreview);
    }

    return url.toString();
  } catch (error) {
    console.log('Error constructing manage account url', error);
  }
};
