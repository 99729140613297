import { keyframes } from '@chakra-ui/react';
import { Keyframes } from '@emotion/react';

export const useBackgroundAnimation = (
  width?: number,
  height?: number
): Keyframes | undefined => {
  if (width && height) {
    const keyFrame: Record<string, { backgroundPosition: string }> = {};
    const totalSteps = 100;
    const radius = height < width ? height / 1.5 : width / 1.5;

    for (let i = 0; i <= totalSteps; i += 1) {
      const x = radius * Math.cos((2 * Math.PI * i) / totalSteps) - width * 0.5;
      const y =
        radius * Math.sin((2 * Math.PI * i) / totalSteps) - height * 0.5;
      keyFrame[`${i}%`] = {
        backgroundPosition: `${x}px ${y}px`,
      };
    }
    return keyframes(keyFrame);
  }
};
