import React, { forwardRef } from 'react';

import { BoxProps, Flex, Box } from '@chakra-ui/react';

import { Button } from '../../../Button';
import { CartIcon } from '../../../Icons/CartIcon';
import { useProfileStore } from '../../../ModalProvider';
import { useMonolithUrls } from '../../hooks/useMonolithUrls';

interface ICartButtonProps extends BoxProps {
  count?: number;
}

export const CartButton = forwardRef<HTMLDivElement, ICartButtonProps>(
  ({ count = 0, ...props }, ref) => {
    const { profile } = useProfileStore();
    const { cartUrl } = useMonolithUrls();

    return (
      <Box
        ref={ref}
        {...props}
        aria-controls="cart-navigation"
        cursor="pointer"
        data-testid="nav-cart-button"
        ml={{ base: '0.5rem', md: undefined }}
        mr="2px"
        position="relative"
      >
        <Flex alignItems="center">
          <Button
            backgroundColor="transparent"
            border="transparent"
            borderRadius="16px"
            borderWidth="1px"
            height="32px"
            href={cartUrl}
            minWidth="32px"
            padding="0"
            position="relative"
            width="32px"
          >
            <CartIcon height="24px" width="24px" />
            {count > 0 && (
              <Flex
                alignItems="center"
                backgroundColor="black"
                border="1px solid white"
                borderRadius="10px"
                color="white"
                fontSize="10px"
                fontWeight="bold"
                justifyContent="center"
                padding="3px 5px"
                position="absolute"
                right="-1px"
                top="-2px"
              >
                {count > 99 ? '99+' : count}
              </Flex>
            )}
          </Button>
        </Flex>
      </Box>
    );
  }
);
