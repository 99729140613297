import React from 'react';

import { EColor } from '../Theme';

export const ArrowPrevIcon = () => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 8 14"
    width="8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 1L1 7L7 13"
      stroke={EColor.Black}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);
