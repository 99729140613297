import React, { FC, useMemo } from 'react';

import { Button as ChakraButton, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError, isAxiosError as checkIsAxiosError } from 'axios';
import { useForm, useWatch } from 'react-hook-form';

import {
  DEFAULT_PIN_INFO_MESSAGE,
  INVALID_CODE_RESPONSE_MESSAGE,
  INVALID_PIN_ERROR_MESSAGE,
  TIMED_OUT_CODE_RESPONSE_MESSAGE,
  TIMED_OUT_PIN_ERROR_MESSAGE,
} from './constants';
import {
  CODE_LENGTH,
  TVerificationCodeData,
  VerificationCodeSchema,
} from './schema';
import { createFormElements } from '../../../Form';
import { EColor, ETextVariant } from '../../../Theme';
import { tracker } from '../../../utilities/tracker';
import { useValidateCode } from '../../api';
import { EVerificationStep } from '../../context';
import { useVerificationStepper } from '../../hooks.ts';
import { DEFAULT_ERROR_MESSAGE } from '../constants';
import { ContinueButton } from '../shared';
import { EVerificationStatus } from '../types';
import { maskEmail, maskPhone } from '../utils';
import { EVerificationMethod } from '../VerificationMethodStep/types';

interface IVerificationCodeStep {
  email: string | undefined;
  phone: string | undefined;
  activeMethod: EVerificationMethod | null;
  monolithBaseUrl: string;
}

const { Form, FormTextInput } = createFormElements<TVerificationCodeData>();

export const VerificationCodeStep: FC<IVerificationCodeStep> = ({
  email,
  phone,
  activeMethod,
  monolithBaseUrl,
}) => {
  const { setStep } = useVerificationStepper();

  const methods = useForm<TVerificationCodeData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: zodResolver(VerificationCodeSchema),
    defaultValues: {
      code: '',
    },
  });

  const { formState, setError, control, resetField } = methods;
  const { errors: formErrors } = formState;

  const code = useWatch({ name: 'code', control });

  const validateResponse = useValidateCode(monolithBaseUrl);

  const {
    mutateAsync: validateCode,
    isPending: validateCodeIsLoading,
    isSuccess: validateCodeIsSuccess,
  } = validateResponse;

  const isCodeTimedOut = useMemo(
    () => formErrors.code?.message === TIMED_OUT_PIN_ERROR_MESSAGE,
    [formErrors.code]
  );

  const validateCodeHandler = async (_data: TVerificationCodeData) => {
    try {
      const toValue =
        activeMethod === EVerificationMethod.EMAIL ? email : phone;

      if (toValue) {
        const args = {
          to: toValue,
          code: _data.code,
        };
        const data = await validateCode(args);

        if (data?.status === EVerificationStatus.DENIED) {
          setError('code', { message: DEFAULT_ERROR_MESSAGE });
          tracker.track('Verification Code Denied', {
            errorMessage: DEFAULT_ERROR_MESSAGE,
            validationStatus: data.status,
          });
        }

        if (data?.status === EVerificationStatus.APPROVED) {
          setStep(EVerificationStep.VERIFICATION_COMPLETE);
        }
      }
    } catch (err) {
      const isAxiosError = checkIsAxiosError(err);
      let errorMessage = DEFAULT_ERROR_MESSAGE;
      if (isAxiosError) {
        const validateError = err as AxiosError<{ message: string }>;
        const validateErrorMessage = validateError?.response?.data?.message;

        switch (validateErrorMessage) {
          case INVALID_CODE_RESPONSE_MESSAGE:
            errorMessage = INVALID_PIN_ERROR_MESSAGE;
            setError('code', { message: errorMessage });
            break;
          case TIMED_OUT_CODE_RESPONSE_MESSAGE:
            errorMessage = TIMED_OUT_PIN_ERROR_MESSAGE;
            setError('code', { message: errorMessage });
            break;
          default:
            errorMessage = DEFAULT_ERROR_MESSAGE;
            setError('code', { message: errorMessage });
        }
      } else {
        setError('code', { message: errorMessage });
      }

      tracker.track('Verification Code Validation Failed', {
        errorMessage,
        isAxiosError,
        method: activeMethod,
        ...(isAxiosError ? {} : { errorString: JSON.stringify(err) }),
      });
    }
  };

  const resendVerificationHandler = async () => {
    resetField('code');
    setStep(EVerificationStep.VERIFICATION_METHOD);
  };

  return (
    <Form {...methods} onSubmit={validateCodeHandler}>
      <Text as="h1" mb="24px" variant={ETextVariant.XL2} wordBreak="break-word">
        We&apos;ve sent a code to
        <br />
        {activeMethod === EVerificationMethod.PHONE
          ? phone && maskPhone(phone, true)
          : email && maskEmail(email)}
      </Text>
      <FormTextInput
        name="code"
        placeholder="Enter verification code"
        variant="brand"
      />
      <ContinueButton
        isDisabled={
          code.length < CODE_LENGTH ||
          validateCodeIsLoading ||
          isCodeTimedOut ||
          validateCodeIsLoading ||
          validateCodeIsSuccess ||
          !!formErrors.code
        }
        isLoading={validateCodeIsLoading}
        isSuccess={validateCodeIsSuccess}
        type="submit"
      />
      <Text variant={ETextVariant.Small}>
        {DEFAULT_PIN_INFO_MESSAGE}
        <ChakraButton
          color={EColor.Neutral55}
          fontSize="14px"
          fontWeight={400}
          lineHeight="16px"
          ml="8px"
          textDecoration="underline"
          textTransform={formErrors.code ? 'capitalize' : 'initial'}
          variant="link"
          onClick={resendVerificationHandler}
        >
          Resend
        </ChakraButton>
      </Text>
    </Form>
  );
};
