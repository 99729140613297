import React from 'react';

import {
  Box,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  VStack,
  Stack,
  Button,
} from '@chakra-ui/react';
import { Property } from 'csstype';

import { AccordionNavLink } from './AccordionNavLink';
import { LiveSuperscript } from '../../../components';
import { EGTMEvents, useGTMDataLayer } from '../../../hooks';
import { EColor } from '../../../Theme';
import { INavigationItem } from '../../types/navigation';

export interface IAccordionNavMenuItemProps {
  navItem: INavigationItem;
  underline?: boolean;
  align?: string;
  textTransform?: Property.TextTransform;
  fontSize?: string;
  showIcons?: boolean;
  border?: number | string;
  color?: string;
  isFooter?: boolean;
}

export function AccordionNavMenuItem({
  navItem,
  underline = true,
  align = 'left',
  textTransform = 'uppercase',
  fontSize = '32px',
  showIcons = true,
  color = EColor.BrandDark,
  isFooter = false,
  ...rest
}: IAccordionNavMenuItemProps) {
  const pushGTMData = useGTMDataLayer();

  const footerGTMPrefix = isFooter ? ['Footer'] : [];

  const handleAccordionButtonClick = (
    isExpanded: boolean,
    item: INavigationItem
  ) => {
    if (!isExpanded && item.items) {
      pushGTMData({
        event: EGTMEvents.NAVIGATION_HOVER,
        navigationBreakdown: [...footerGTMPrefix, navItem.name].join(' | '),
      });
    }
  };

  const handleNavLinkClick = () => {
    pushGTMData({
      event: EGTMEvents.NAVIGATION_CLICK,
      navigationBreakdown: [...footerGTMPrefix, navItem.name].join(' | '),
      clickValue: navItem.name,
    });
  };

  if (!navItem.items) {
    return (
      <Button
        _active={{
          background: 'transparent',
        }}
        _hover={{
          background: 'blackAlpha.50',
          color: 'inherit !important',
        }}
        as="a"
        background="transparent"
        borderBottomWidth="1px"
        borderColor={underline ? 'rgba(255, 255, 255, 0.5)' : 'transparent'}
        borderRadius="none"
        borderTop="none"
        borderWidth="none"
        color={color}
        data-testid={`accordion-button-${navItem.dataTestId}`}
        display="flex"
        height="100%"
        href={navItem.path}
        justifyContent="space-between"
        justifyItems="space-between"
        paddingX="0"
        paddingY="10px"
        textTransform="none"
        width="100%"
        onClick={handleNavLinkClick}
        {...rest}
      >
        {align === 'center' && <Box height="1px" width="28px" />}
        <Box
          fontSize={fontSize}
          fontWeight="700"
          justifyContent="center"
          letterSpacing="0.03"
          lineHeight="36px"
          minHeight="28px"
        >
          {navItem.name}
          {navItem.superscript && (
            <LiveSuperscript
              lineHeight="14px"
              marginX="3px"
              position="relative"
              top="-10px"
              {...navItem.superscriptStyles}
            >
              {navItem.superscript}
            </LiveSuperscript>
          )}
        </Box>
      </Button>
    );
  }
  return (
    <AccordionItem
      border="none"
      borderBottom={EColor.Neutral5}
      borderBottomWidth="2px"
      color={color}
      width="100%"
      {...rest}
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton
            _expanded={{ borderBottomWidth: '0px' }}
            borderBottomWidth="1px"
            borderColor={underline ? 'rgba(255, 255, 255, 0.5)' : 'transparent'}
            data-testid={`accordion-button-${navItem.dataTestId}`}
            justifyContent="space-between"
            paddingX="0"
            paddingY="10px"
            onClick={() => handleAccordionButtonClick(isExpanded, navItem)}
          >
            {align === 'center' && (
              <Box height="1px" width={navItem.superscript ? '56px' : '28px'} />
            )}
            <Box fontSize={fontSize} fontWeight="700">
              {navItem.name}
              {navItem.superscript && (
                <LiveSuperscript
                  lineHeight="14px"
                  marginX="3px"
                  position="relative"
                  top="-10px"
                  {...navItem.superscriptStyles}
                >
                  {navItem.superscript}
                </LiveSuperscript>
              )}
            </Box>
            <AccordionIcon boxSize="28px" />
          </AccordionButton>
          <AccordionPanel paddingTop={0} paddingX={0}>
            <VStack alignItems={align}>
              {navItem.multiColumn ? (
                <Box width="100%">
                  {navItem.items?.map((item, itemIdx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Stack key={itemIdx}>
                      <AccordionNavLink
                        align={align}
                        color="brand.warning"
                        data-testid={`accordion-link-${item.dataTestId}`}
                        fontWeight={700}
                        item={item}
                        mt="20px"
                        parents={[...footerGTMPrefix, navItem.name]}
                        showIcons={showIcons}
                        textTransform="uppercase"
                      />
                      {item.items?.map((innerItem, innerIdx) => (
                        <AccordionNavLink
                          align={align}
                          data-testid={`accordion-link-${innerItem.dataTestId}`}
                          item={innerItem}
                          // eslint-disable-next-line react/no-array-index-key
                          key={innerIdx}
                          parents={[
                            ...footerGTMPrefix,
                            navItem.name,
                            item.name,
                          ]}
                          showIcons={showIcons}
                        />
                      ))}
                    </Stack>
                  ))}
                </Box>
              ) : (
                navItem.items?.map((innerItem) => (
                  <AccordionNavLink
                    align={align}
                    data-testid={`accordion-link-${innerItem.dataTestId}`}
                    item={innerItem}
                    key={innerItem.name}
                    parents={[...footerGTMPrefix, navItem.name]}
                    showIcons={showIcons}
                  />
                ))
              )}
            </VStack>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
}
