import { EColor } from '../colors';
import type { ComponentSingleStyleConfig } from '@chakra-ui/react';

export const InputConfig: ComponentSingleStyleConfig = {
  baseStyle: {},
  variants: {
    outline: {
      field: {
        borderColor: EColor.BrandLightGray,
        borderWidth: '1px',
        borderRadius: '5px',
        color: EColor.BrandCorduroy,
        fontWeight: 700,
        _invalid: {
          borderColor: EColor.Transparent,
          boxShadow: `0 0 0 1px ${EColor.BrandDanger}`,
        },
        _placeholder: {
          fontWeight: 400,
        },
        _focus: {
          borderColor: EColor.BrandDark,
          boxShadow: 'none',
        },
      },
    },
    brand: {
      field: {
        height: '40px',
        backgroundColor: EColor.White,
        borderColor: EColor.Neutral25,
        borderWidth: '1px',
        borderRadius: '8px',
        color: EColor.Black,
        paddingX: '13px',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '26px',
        letterSpacing: '0.32px',
        _placeholder: {
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '26px',
          letterSpacing: '0.32px',
          color: EColor.Neutral35,
        },
        _focus: {
          borderColor: EColor.BrandCorduroy,
          boxShadow: 'none',
        },
        ':not([value=""]), :-webkit-autofill': {
          borderColor: EColor.BrandCorduroy,
        },
        _invalid: {
          borderColor: EColor.Cherry,
        },
        _autofill: {
          boxShadow: `0 0 0px 1000px ${EColor.White} inset`,
          transition: 'background-color 5000s ease-in-out 0s',
        },
        _disabled: {
          ':not([value=""]), :-webkit-autofill': {
            borderColor: EColor.BrandLightGray,
            opacity: 1,
          },
        },
      },
    },
  },
};
