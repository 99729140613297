import React, { FC, useEffect, useMemo } from 'react';

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  VStack,
  useUpdateEffect,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import { useForm, useWatch } from 'react-hook-form';

import { ShippingAddressSchema, TShippingAddressFormData } from './schema';
import { countries as _countries, states, useAuth } from '../../../../Auth';
import { createFormElements } from '../../../../Form';
import { EGTMEvents, useGTMDataLayer } from '../../../../hooks';
import { EButtonVariant, EColor } from '../../../../Theme';
import { ModalContent, ModalHeader } from '../../../components';
import { useModalStore } from '../../../stores/modal';
import { EModalContent } from '../../../types/modalContent';
import {
  ICreateDefaultAddressError,
  useCreateDefaultAddress,
} from '../api/create-default-address';

const countries = _countries.map((item) => ({
  label: item.label,
  value: item.id,
}));

const US_COUNTRY_ID = countries[0].value.toString();

const { Form, FormTextInput, FormSelect } =
  createFormElements<TShippingAddressFormData>();

export const ShippingAddressForm: FC = () => {
  const auth = useAuth();
  const userName = [auth?.profile?.firstName, auth?.profile?.lastName]
    .filter(Boolean)
    .join(' ');

  const pushGTMData = useGTMDataLayer();

  const modalStore = useModalStore();
  const { mutateAsync: createDefaultAddress } = useCreateDefaultAddress();

  const methods = useForm<TShippingAddressFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(ShippingAddressSchema),
    defaultValues: {
      name: userName,
    },
  });
  const { control, setValue, setError, formState } = methods;
  const { errors, isSubmitting } = formState;

  const handleBack = () => {
    modalStore.pop();
  };

  const values = useWatch({
    control,
  });
  const { name, street1, city, zip, state, countryId } = values;

  const isSubmitButtonDisabled = useMemo(
    () => !name || !street1 || !city || !zip || !countryId || !state,

    [city, countryId, name, state, street1, zip]
  );

  const handleSubmit = async (data: TShippingAddressFormData) => {
    try {
      await createDefaultAddress({
        ...data,
        countryId: Number(data.countryId),
      });

      modalStore.navigate({ name: EModalContent.SHIPPING_ADDRESS_ADDED });
    } catch (error) {
      if (
        isAxiosError<ICreateDefaultAddressError>(error) &&
        error.response?.status === 422
      ) {
        const { message, errors: createAddressErrors } =
          error.response?.data ?? {};

        if (createAddressErrors) {
          const keys = Object.keys(createAddressErrors) as Array<
            keyof typeof createAddressErrors
          >;

          keys.forEach((key) => {
            setError(key, {
              message: createAddressErrors[key]?.[0],
            });
          });

          pushGTMData({
            event: EGTMEvents.ADD_ADDRESS_ERROR,
          });

          return;
        }

        if (message) {
          setError('root', {
            message,
          });

          pushGTMData({
            event: EGTMEvents.ADD_ADDRESS_ERROR,
          });
        }
      } else {
        setError('root', {
          message:
            isAxiosError(error) && error.response?.status === 409
              ? 'Shipping address has already been added'
              : "Couldn't add shipping address",
        });
      }
    }
  };

  const handleEnter = (
    event: React.KeyboardEvent<HTMLInputElement> & React.BaseSyntheticEvent
  ) => {
    if (event.code === 'Enter') {
      const { form } = event.target;
      if (form) {
        const index = [...form].indexOf(event.target);
        form[index + 1].focus();
        event.preventDefault();
      }
    }
  };

  const handleInvalid = () => {
    pushGTMData({
      event: EGTMEvents.ADD_ADDRESS_ERROR,
    });
  };

  useUpdateEffect(() => {
    if (countryId !== US_COUNTRY_ID) {
      setValue('state', '');
    }
  }, [countryId, setValue]);

  useEffect(() => {
    pushGTMData({
      event: EGTMEvents.ADD_ADDRESS_VIEW,
    });
  }, [pushGTMData]);

  return (
    <ModalContent
      header={
        <ModalHeader className="shipping-address" onClick={handleBack}>
          Add Your Shipping Address
        </ModalHeader>
      }
    >
      <Form {...methods} onInvalid={handleInvalid} onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormTextInput
            isDisabled={!!userName.length}
            name="name"
            placeholder="Name"
            variant="brand"
            onKeyUp={handleEnter}
          />

          <FormSelect
            _placeholder={{
              color: EColor.Neutral25,
            }}
            name="countryId"
            options={countries}
            placeholder="Country"
            variant="brand"
          />

          <FormTextInput
            name="street1"
            placeholder="Address"
            variant="brand"
            onKeyUp={handleEnter}
          />

          <FormTextInput
            name="street2"
            placeholder="Apt or Suite # (Optional)"
            variant="brand"
            onKeyUp={handleEnter}
          />

          <FormTextInput
            name="city"
            placeholder="City"
            variant="brand"
            onKeyUp={handleEnter}
          />

          <Flex gap={3.5}>
            {countryId === US_COUNTRY_ID ? (
              <FormSelect
                name="state"
                options={states}
                placeholder="State"
                variant="brand"
              />
            ) : (
              <FormTextInput
                name="state"
                placeholder="State"
                variant="brand"
                onKeyUp={handleEnter}
              />
            )}

            <FormTextInput
              name="zip"
              placeholder="Zip"
              variant="brand"
              onKeyUp={handleEnter}
            />
          </Flex>

          <FormControl isInvalid={!!errors.root?.message}>
            <FormErrorMessage
              fontSize="14px"
              lineHeight="18px"
              textTransform="unset"
            >
              {errors.root?.message}
            </FormErrorMessage>
          </FormControl>

          <Button
            fontFamily="ABC Diatype Mono"
            fontSize="16px"
            fontWeight={700}
            height="54px"
            isDisabled={isSubmitButtonDisabled}
            isLoading={isSubmitting}
            lineHeight="16px"
            type="submit"
            variant={EButtonVariant.BRAND_PRIMARY}
            width="100%"
          >
            Confirm address
          </Button>
        </VStack>
      </Form>
    </ModalContent>
  );
};
