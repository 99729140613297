import React, { FC, useCallback, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  StripeError,
  StripePaymentElementChangeEvent,
} from '@stripe/stripe-js';
import { isAxiosError } from 'axios';
import { useForm } from 'react-hook-form';

import { useSetDefaultPaymentMethod } from './api';
import { StripeFallback } from './StripeFallback';
import { StripeSchema } from './stripeSchema';
import { createFormElements } from '../../../Form';
import { EGTMEvents, useGTMDataLayer } from '../../../hooks';
import { EButtonVariant, EColor, ETextVariant } from '../../../Theme';
import { usePaymentMethods } from '../../states/MyWallet/api';
import { useProfileStore } from '../../stores';

interface IStripeFormData {
  setDefault: boolean;
}

const { Form, FormCheckbox } = createFormElements<IStripeFormData>();

interface IStripeFormProps {
  onSuccess: () => void;
  onCancel?: () => void;
  showSetDefault: boolean;
  submitButtonText: string;
}

export const StripeForm: FC<IStripeFormProps> = ({
  onSuccess,
  onCancel,
  showSetDefault,
  submitButtonText,
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const { updateProfile, profile } = useProfileStore();
  const { data: paymentMethods } = usePaymentMethods();
  const { mutateAsync: setDefaultPaymentMethod } = useSetDefaultPaymentMethod();
  const pushGTMData = useGTMDataLayer();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isStripeFormLoading, setIsStripeFormLoading] = useState(true);

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(StripeSchema),
    defaultValues: { setDefault: showSetDefault },
  });

  const { formState, setError } = methods;
  const { errors, isSubmitting } = formState;

  const handleChange = useCallback((event: StripePaymentElementChangeEvent) => {
    if (event.complete) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, []);

  const handleSubmit = async ({ setDefault }: IStripeFormData) => {
    try {
      if (!stripe || !elements) {
        return;
      }

      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}${window.location.pathname}`,
        },
        redirect: 'if_required',
      });

      if (
        setupIntent?.payment_method &&
        (setDefault || paymentMethods?.data.length === 0)
      ) {
        const paymentMethodId =
          typeof setupIntent.payment_method === 'string'
            ? setupIntent.payment_method
            : setupIntent.payment_method.id;

        await setDefaultPaymentMethod(paymentMethodId);
      }

      if (error) {
        if (error.message) {
          setError('root', { message: error.message });
        } else {
          setError('root', { message: "Couldn't add payment method" });
        }
      }

      if (!error) {
        if (profile && !profile.hasPaymentMethods) {
          updateProfile({ hasPaymentMethods: true });
        }

        onSuccess();
      }
    } catch (error) {
      setError('root', {
        message:
          isAxiosError(error) && error.response?.status === 409
            ? 'Payment method has already been added'
            : "Couldn't add payment method",
      });
    }
  };

  const handleInvalid = () => {
    pushGTMData({
      event: EGTMEvents.ADD_PAYMENT_METHOD_ERROR,
    });
  };

  const handleReady = () => {
    setIsStripeFormLoading(false);
  };

  const handleLoadError = (event: {
    elementType: 'payment';
    error: StripeError;
  }) => {
    setIsStripeFormLoading(false);
    setError('root', { message: event.error.message });
  };

  return (
    <Box minHeight="300px">
      <Form {...methods} onInvalid={handleInvalid} onSubmit={handleSubmit}>
        {isStripeFormLoading && <StripeFallback />}
        <PaymentElement
          options={{
            defaultValues: {
              billingDetails: {
                name: `${profile?.firstName ? `${profile.firstName} ` : ''}${
                  profile?.lastName ?? ''
                }`,
                email: profile?.email ?? '',
                phone: profile?.phone ?? '',
              },
            },
            wallets: {
              applePay: 'never',
              googlePay: 'never',
            },
          }}
          onChange={handleChange}
          onLoadError={(e) => handleLoadError(e)}
          onReady={handleReady}
        />
        {showSetDefault && (
          <FormCheckbox mt="16px" name="setDefault">
            <Text
              color={EColor.BrandCorduroy}
              fontSize="14px"
              variant={ETextVariant.Paragraph6}
            >
              Save as Default
            </Text>
          </FormCheckbox>
        )}

        <FormControl isInvalid={!!errors.root?.message}>
          <FormErrorMessage
            fontSize="14px"
            lineHeight="18px"
            textTransform="unset"
          >
            {errors.root?.message}
          </FormErrorMessage>
        </FormControl>
        <Flex gap="16px">
          <Button
            isDisabled={isButtonDisabled}
            isLoading={isSubmitting}
            mt="20px"
            type="submit"
            variant={EButtonVariant.BRAND_PRIMARY}
            width="100%"
          >
            {submitButtonText || 'Next'}
          </Button>
          {onCancel && (
            <Button
              mt="20px"
              variant={EButtonVariant.White}
              width="100%"
              onClick={onCancel}
            >
              Cancel
            </Button>
          )}
        </Flex>
      </Form>
    </Box>
  );
};
