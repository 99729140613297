import axios, { AxiosRequestConfig } from 'axios';

import { IAuth, IProfile } from './types/auth';
import { clearFanaticsCollectCredentials } from './utils/cookies';
import { isClient } from './utils/env';
import { tracker } from '../utilities/tracker';

export const ACCESS_TOKEN_COOKIE = 'pwcc-access-token';
export const REFRESH_TOKEN_COOKIE = 'pwcc-refresh-token';
export const NONCE_COOKIE = 'pwcc-nonce';
export const FANATICS_TENANT_ID_COOKIE = 'fanatics-tenant-id';

const defaultProfile = {
  firstName: '',
  lastName: '',
  email: '',
  unpaidInvoiceCount: 0,
  vaultAccountId: '',
  vaultAddress: {
    cityStateZip: '',
    street: '',
  },
} as IProfile;

export class Auth implements IAuth {
  profile?: IProfile;

  authBaseUrl: string;

  mobileBaseUrl: string;

  authApiVersion: string;

  logoutFn?: (forceLogout: boolean) => void;

  constructor(
    authBaseUrl: string,
    mobileBaseUrl: string,
    authApiVersion = 'v2',
    logoutFn?: (forceLogout: boolean) => void
  ) {
    this.profile = undefined;
    this.authBaseUrl = authBaseUrl;
    this.mobileBaseUrl = mobileBaseUrl;
    this.authApiVersion = authApiVersion;
    this.logoutFn = logoutFn;
  }

  logout = async (forceLogout = false) => {
    if (!isClient()) return;

    if (this.logoutFn) {
      return this.logoutFn(forceLogout);
    }

    await axios.post(`${this.authBaseUrl}/security/api/v1/auth/logout`, null, {
      withCredentials: true,
    });
    clearFanaticsCollectCredentials();
    if (this.profile || forceLogout) {
      window.location.reload();
    }
  };

  async fetchProfile(apiClient?: any) {
    try {
      const config: AxiosRequestConfig = {
        baseURL: this.mobileBaseUrl,
        withCredentials: false,
      };

      const results = await Promise.allSettled([
        apiClient.get('/mobile/api/v1/user/profile', config),
        apiClient.get('/mobile/api/v1/invoices/pending/count', config),
      ]);
      const profile = 'value' in results[0] ? results[0].value : undefined;
      const unpaidInvoiceCount =
        'value' in results[1] ? results[1].value : undefined;

      if (profile) {
        profile.unpaidInvoiceCount = unpaidInvoiceCount?.count;
        profile.vaultAccountId = profile.vaultId;
      }

      return this.setProfile(profile);
    } catch (e) {
      const isAxiosError = axios.isAxiosError(e);
      tracker.track('Fetch Profile Failed', {
        error: isAxiosError ? e.message : JSON.stringify(e),
      });
      // eslint-disable-next-line no-console
      console.log('Could not fetch profile', e);
      return this.setProfile(defaultProfile);
    }
  }

  setProfile(profile: IProfile) {
    if (!profile && isClient()) {
      clearFanaticsCollectCredentials();
    }
    this.profile = profile;
    return profile;
  }

  isAuthenticated = () => !!this.profile;
}

export default Auth;
