import { isClient } from '../Auth/utils/env';

export enum EMarketplaceType {
  WEEKLY = 'Weekly Auction',
  PREMIER = 'Premier Auction',
  FIXED = 'Buy Now Marketplace',
}

export const getMarketplaceType = () => {
  if (!isClient()) {
    return undefined;
  }
  if (window.location.pathname.includes('/vault-marketplace')) {
    return EMarketplaceType.FIXED;
  }
  if (window.location.pathname.includes('/premier-auction')) {
    return EMarketplaceType.PREMIER;
  }
  if (
    window.location.pathname.includes('/weekly-auction') ||
    window.location.pathname.includes('/items')
  ) {
    return EMarketplaceType.WEEKLY;
  }
};
