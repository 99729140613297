import React, {
  ChangeEventHandler,
  CSSProperties,
  FC,
  forwardRef,
  useMemo,
  useRef,
  useState,
} from 'react';

import 'react-phone-number-input/style.css';

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
} from '@chakra-ui/react';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js/min';
import {
  FieldPathByValue,
  FieldValues,
  PathValue,
  useController,
  useFormContext,
} from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';

import { phoneInputStyles } from './phoneInputStyles';
import { EColor } from '../../Theme';
import { formatProps } from '../utils';

export interface IFormPhoneInputProps<TFieldValues extends FieldValues>
  extends InputProps {
  name: FieldPathByValue<TFieldValues, string | null>;
  countryCodeName?: FieldPathByValue<TFieldValues, string>;
  controlProps?: Omit<FormControlProps, 'isInvalid'>;
  inputStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
}

type CountryValue = {
  prev: CountryCode | null;
  curr: CountryCode | null;
};

const InnerInput: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { onChange, ...rest } = props;

    const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      if (e.target.value === '+') {
        setTimeout(() => {
          // react-phone-number-input can accidentally clear single entered '+' sign.
          // Here we restore it if such problem happens. that helps to avoid bad UX
          // when user has to type same character twice.
          if (e.target.value === '') {
            e.target.value = '+';
          }
        }, 0);
      }

      onChange?.(e);
    };

    return <Input {...rest} ref={ref} onChange={onInputChange} />;
  }
);

export function createFormFloatingPhoneInput<
  TFieldValues extends FieldValues
>() {
  type CountryCodeValue = PathValue<
    TFieldValues,
    FieldPathByValue<TFieldValues, string>
  >;

  const FormFloatingPhoneInput: FC<IFormPhoneInputProps<TFieldValues>> = (
    props
  ) => {
    const {
      name,
      countryCodeName,
      controlProps,
      inputStyle,
      isRequired,
      containerStyle,
      buttonStyle,
      variant,
      onFocus,
      onBlur,
      ...restProps
    } = props;
    const [isFocused, setIsFocused] = useState<boolean>();
    const { field, fieldState } = useController<TFieldValues, typeof name>({
      name,
    });

    const { setValue } = useFormContext<TFieldValues>();

    const defaultCountry = 'US';
    const countryRef = useRef<CountryValue>({ prev: null, curr: null });

    const {
      value,
      onChange: onFieldChange,
      onBlur: onFieldBlur,
      ...restField
    } = field;
    const { error } = fieldState;

    const isFloated = useMemo(
      () => isFocused || !!value || error,
      [error, isFocused, value]
    );

    const currentColor = useMemo(() => {
      const color =
        isFocused || !!value ? EColor.BrandCorduroy : EColor.BrandLightGray;

      return error ? EColor.Tomato : color;
    }, [error, isFocused, value]);

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);
      onFocus?.(e);
    };
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      onFieldBlur();
      onBlur?.(e);
    };
    const handleCountryChange = (newValue: CountryCode | undefined) => {
      if (countryCodeName) {
        setValue(countryCodeName, newValue as CountryCodeValue);
      }

      countryRef.current = {
        prev: countryRef.current.curr,
        curr: newValue ?? null,
      };
      setTimeout(() => {
        countryRef.current.prev = null;
      }, 10);
    };
    const handleChange = (newValue: string | undefined) => {
      let phoneNumber = newValue;

      if (countryRef.current.prev && countryRef.current.curr && value) {
        if (newValue) {
          const oldCountryCode = getCountryCallingCode(countryRef.current.prev);
          const newCountryCode = getCountryCallingCode(countryRef.current.curr);
          const oldLocalNumber = value[0] === '+' ? value.substring(1) : value;

          if (newValue === `+${newCountryCode}${oldLocalNumber}`) {
            phoneNumber = `+${newCountryCode}${oldLocalNumber.replace(
              oldCountryCode,
              ''
            )}`;
          }
        } else {
          const oldCountryCode = getCountryCallingCode(countryRef.current.prev);
          const newCountryCode = getCountryCallingCode(countryRef.current.curr);

          phoneNumber = `+${newCountryCode}${value.replace(
            `+${oldCountryCode}`,
            ''
          )}`;
        }
      }

      onFieldChange(phoneNumber);
    };

    formatProps(restProps, { isRequired });

    return (
      <FormControl
        _focus={{
          boxShadow: 'none !important',
        }}
        isInvalid={Boolean(error)}
        sx={phoneInputStyles}
        {...controlProps}
      >
        <PhoneInput
          addInternationalOption={false}
          countrySelectProps={{
            tabIndex: -1,
          }}
          defaultCountry={countryRef.current.curr ?? defaultCountry}
          focusInputOnCountrySelection={false}
          inputComponent={InnerInput}
          numberInputProps={{
            ...restProps,
            ...restField,
            placeholder: '',
            _focus: {
              boxShadow: 'none',
            },
            style: {
              borderColor: EColor.Neutral25,
              borderRadius: '8px',
              padding: '3px 0 0 70px',
              width: '100%',
              height: '40px',
              color: EColor.Black,
              fontSize: '16px',
              fontWeight: 400,
              fontFamily: 'ABC Diatype',
              lineHeight: '26px',
              ...inputStyle,
            },
          }}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          onCountryChange={handleCountryChange}
          onFocus={handleFocus}
        />
        <FormLabel
          backgroundColor={EColor.White}
          color={isFloated ? currentColor : EColor.Neutral35}
          left="72px"
          letterSpacing="0.32px"
          paddingX="4px"
          pointerEvents="none"
          position="absolute"
          px={1}
          top="10px"
          transform={
            isFloated
              ? 'scale(0.85) translateY(-24px) translateX(-68px)'
              : 'none'
          }
          transformOrigin="left top"
          zIndex={2}
        >
          {restProps.placeholder}
        </FormLabel>
        <FormErrorMessage variant="brand">{error?.message}</FormErrorMessage>
      </FormControl>
    );
  };

  return FormFloatingPhoneInput;
}
