import React from 'react';

import { Flex, Skeleton } from '@chakra-ui/react';

export const StripeFallback = () => {
  return (
    <Flex flexDir="column" gap="16px" py="24px">
      <Skeleton height="48px" width="100%" />
      <Flex gap="16px">
        <Skeleton height="48px" width="50%" />
        <Skeleton height="48px" width="50%" />
      </Flex>
      <Skeleton height="48px" width="100%" />
      <Skeleton height="48px" width="100%" />
    </Flex>
  );
};
