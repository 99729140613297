import { QueryClient } from '@tanstack/react-query';
import axios from 'axios';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (
          axios.isAxiosError(error) &&
          error.response?.status &&
          error.response.status >= 500
        ) {
          return false;
        }

        return failureCount < 1;
      },
      retryDelay: 1000,
    },
  },
});

export const defaultQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      retryOnMount: false,
    },
  },
});
