export const isLocal = () => {
  return ['localhost', 'local.dev'].some((item) =>
    window.location.hostname.includes(item)
  );
};

export const isSalesHistory = () => {
  return window.location.origin.includes('sales-history');
};

export const isCheckout = () => {
  return window.location.origin.includes('checkout');
};

export const isPremierAuction = () => {
  return window.location.pathname === '/premier-auction-application';
};

export const isMarketplace = () => {
  return ['/weekly-auction', '/premier-auction', '/vault-marketplace'].some(
    (item) => window.location.pathname.startsWith(item)
  );
};
