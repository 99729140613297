import React from 'react';

import {
  Button,
  Divider,
  Flex,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Text,
} from '@chakra-ui/react';

import { useGetAccountFunds } from './api/getAccountFunds';
import { usePaymentMethods } from './api/getPaymentMethods';
import { PaymentMethodsList } from './components';
import { EGTMEvents, useGTMDataLayer } from '../../../hooks';
import { EButtonVariant, EColor, ETextVariant } from '../../../Theme';
import { useModalStore } from '../../stores';
import { EModalContent } from '../../types';

export const MyWallet = () => {
  const modalStore = useModalStore();
  const pushGTMData = useGTMDataLayer();

  const { data: accountFundsData, isPending: accountFundsIsLoading } =
    useGetAccountFunds();
  const { usableAmount = 0 } = accountFundsData ?? {};

  const { data: paymentMethodsData, isPending: isPaymentMethodsLoading } =
    usePaymentMethods();

  const isMaxMethodsCount = (paymentMethodsData?.data.length ?? 0) >= 10;

  const handleAddPaymentClick = () => {
    pushGTMData({
      event: EGTMEvents.PROFILE_WALLET_ADD_PAYMENT_BUTTON_CLICKED,
    });
    modalStore.navigate({ name: EModalContent.CHOOSE_PAYMENT_METHOD });
  };

  return (
    <>
      <ModalHeader
        borderBottom={`1px solid ${EColor.Neutral10}`}
        display="flex"
        flexDir="column"
        justifyContent="center"
        minHeight="70px"
        pb="12px"
        pt={{ base: 0, md: '24px' }}
      >
        <Text
          color={EColor.Black}
          fontSize={{ base: '24px' }}
          variant={{ base: ETextVariant.XL, md: ETextVariant.LG }}
        >
          My Wallet
        </Text>
        <Divider
          borderBottomWidth="1px"
          borderColor={EColor.Neutral10}
          left="-24px"
          position="relative"
          pt="18px"
          width="calc(100% + 48px)"
        />
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb="-4px"
          pt="8px"
        >
          <Text color={EColor.Black} variant={ETextVariant.SmallBold}>
            Account Funds
          </Text>
          {accountFundsIsLoading ? (
            <Spinner size="sm" />
          ) : (
            <Text color={EColor.Black} variant={ETextVariant.SmallBold}>
              ${usableAmount ?? 0}
            </Text>
          )}
        </Flex>
      </ModalHeader>

      <ModalBody
        maxH={{ base: '100%', md: '287px' }}
        minHeight={{ base: '100%', md: '287px' }}
        overflowX="hidden"
        overflowY="auto"
        p="0 24px 24px"
        sx={{
          '&::-webkit-scrollbar': {
            width: '16px',
            borderRadius: '100px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: EColor.Neutral15,
            border: '6px solid rgba(0, 0, 0, 0)',
            borderRadius: '100px',
            backgroundClip: 'padding-box',
          },
        }}
      >
        <PaymentMethodsList />
      </ModalBody>

      <ModalFooter
        borderTop={`1px solid ${EColor.Neutral10}`}
        flexDir="column"
        px="24px"
        py="16px"
      >
        {isMaxMethodsCount && (
          <Text
            color={EColor.Black}
            fontSize="10px"
            letterSpacing="0.1px"
            pb="12px"
            variant={ETextVariant.Paragraph5}
          >
            You&apos;ve reached your maximum amount of payment methods. Please
            delete one to add another.
          </Text>
        )}

        <Button
          isDisabled={isPaymentMethodsLoading || isMaxMethodsCount}
          variant={EButtonVariant.BRAND_PRIMARY}
          w="100%"
          onClick={handleAddPaymentClick}
        >
          Add Payment Method
        </Button>
      </ModalFooter>
    </>
  );
};
