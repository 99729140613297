import cookie from 'cookie';
import { omitBy } from 'lodash-es';

interface ServerCookieSetFn {
  (
    name: string,
    value: string | null,
    options: {
      maxAge?: number;
      expires?: number | Date;
    }
  ): void;
}

function writeCookie(name: string, value: string, days: number) {
  let date;
  let expires;
  if (days) {
    date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  } else {
    expires = '';
  }
  document.cookie = `${name}=${value}${expires}; path=/`;
}

function deleteCookie(name: string) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
}

function readCookie(name: string) {
  let i;
  let c;
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (i = 0; i < ca.length; i += 1) {
    c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
  }
  return '';
}

const isClient = typeof window !== 'undefined';

export class OwnCookies {
  serverCookies?: Record<string, string>;

  serverCookiesSet?: ServerCookieSetFn;

  setServerCookies(
    cookies: Record<string, string>,
    setCookies: ServerCookieSetFn
  ) {
    this.serverCookies = cookies;
    this.serverCookiesSet = setCookies;
  }

  set(name: string, value: string, days = 200) {
    if (isClient) {
      return writeCookie(name, encodeURIComponent(value), days);
    }
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    if (this.serverCookiesSet) {
      this.serverCookiesSet(name, value, { expires: date });
    }
  }

  get(name: string) {
    if (isClient) {
      return readCookie(name);
    }

    return this.serverCookies && this.serverCookies[name];
  }

  delete(name: string) {
    if (isClient) {
      return deleteCookie(name);
    }

    return (
      this.serverCookiesSet && this.serverCookiesSet(name, null, { maxAge: 0 })
    );
  }
}

export function parseCookies(cookieString = '') {
  const cookiesObject = cookie.parse(cookieString);
  return omitBy(cookiesObject, (value) => value === 'deleted');
}

export function parseCookiesToString(cookieString = '') {
  const cookieObject = parseCookies(cookieString);
  return Object.entries(cookieObject).reduce(
    (result, [key, value]) => `${result}${key}=${value}; `,
    ''
  );
}
