import { CountryCode, parsePhoneNumber } from 'libphonenumber-js/min';

export const formatPhoneNumber = (phoneNumber: string, countryCode: string) => {
  try {
    const parsedPhoneNumber = parsePhoneNumber(
      phoneNumber,
      countryCode as CountryCode
    );

    if (['US', 'CA'].includes(countryCode.toUpperCase())) {
      const countryCallingCode = parsedPhoneNumber?.countryCallingCode;
      const nationalPhoneNumber = parsedPhoneNumber.formatNational();

      return `+${countryCallingCode} ${nationalPhoneNumber}`;
    }

    return parsedPhoneNumber.formatInternational();
  } catch {
    return phoneNumber;
  }
};
