import { useEffect } from 'react';

export const useBFCache = (onPageHide: () => void) => {
  const pageHideListener = (event: PageTransitionEvent) => {
    if (event.persisted) {
      onPageHide();
    }
  };

  useEffect(() => {
    window.addEventListener('pagehide', pageHideListener);
    return () => {
      window.removeEventListener('pagehide', pageHideListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
