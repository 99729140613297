import React, { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useDisclosure, Drawer, Divider } from '@chakra-ui/react';

import { ProfileButton } from './ProfileButton';
import { ProfileDrawerContents } from './ProfileDrawerContents';
import { IProfile } from '../../../Auth/types/auth';
import { getUserOffersStatus } from '../../../gql';
import { EGTMEvents, useGTMDataLayer } from '../../../hooks';
import { useProfileStore } from '../../../ModalProvider/stores/profile';
import { EColor } from '../../../Theme';

interface IProfileDrawerProps {
  profile: IProfile;
  showDivider?: boolean;
  manageAccountUrl: string | undefined;
  enablePlaidPAApproval: boolean;
  enableOfferNotificationBadges?: boolean;
}

export function ProfileDrawer({
  enablePlaidPAApproval,
  profile,
  showDivider = false,
  manageAccountUrl,
  enableOfferNotificationBadges,
}: IProfileDrawerProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updateProfile } = useProfileStore();
  const pushGTMData = useGTMDataLayer();

  const { data } = useQuery(getUserOffersStatus, {
    skip: !profile || !enableOfferNotificationBadges,
  });

  const hasNewOffersFromSellers =
    data?.collectCurrentUserV2?.hasNewOffersFromSellers ?? false;
  const hasNewOffersFromBidders =
    data?.collectCurrentUserV2?.hasNewOffersFromBidders ?? false;

  const btnRef = React.useRef(null);

  useEffect(() => {
    updateProfile(profile);
  }, [profile, updateProfile]);

  const handleProfileButtonClick = () => {
    pushGTMData({ event: EGTMEvents.PROFILE_BUTTON_CLICKED });
    onOpen();
  };

  return (
    <>
      {showDivider && (
        <Divider
          backgroundColor={EColor.BrandDark}
          color={EColor.White}
          height="30px"
          mx="14px"
          my="0"
          orientation="vertical"
        />
      )}
      <ProfileButton
        hasOffers={hasNewOffersFromSellers || hasNewOffersFromBidders}
        isInverted={isOpen}
        ref={btnRef}
        onClick={handleProfileButtonClick}
      />
      <Drawer
        arial-label="settings"
        finalFocusRef={btnRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ProfileDrawerContents
          enablePlaidPAApproval={enablePlaidPAApproval}
          isBuyerOffers={hasNewOffersFromSellers}
          isSellerOffers={hasNewOffersFromBidders}
          manageAccountUrl={manageAccountUrl}
          profile={profile}
          onClose={onClose}
        />
      </Drawer>
    </>
  );
}
