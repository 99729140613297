export enum EModalContent {
  COMPLETE_PROFILE = 'complete-profile',
  ADD_SHIPPING_ADDRESS = 'add-shipping-address',
  SHIPPING_ADDRESS_ADDED = 'shipping-address-added',
  ADD_PAYMENT_METHOD = 'add-payment-method',
  PAYMENT_METHOD_ADDED = 'payment-method-added',
  MY_WALLET = 'my-wallet',
  CHOOSE_PAYMENT_METHOD = 'choose-payment-method',
  ADD_CREDIT_CARD = 'add-credit-card',
  ADD_US_BANK_ACCOUNT = 'add-us-bank-account',
  DELETE_METHOD_CONFIRMATION = 'delete-method-confirmation',

  COLLECT_LOGIN = 'collect-login',
  COLLECT_COMPLETE_PROFILE = 'collect-complete-profile',
  COLLECT_VERIFY_PHONE_NUMBER = 'collect-verify-phone-number',
  COLLECT_ACCOUNT_CREATED = 'collect-account-created',
  COLLECT_VERIFY_EMAIL_ADDRESS = 'collect-verify-email-address',
}
