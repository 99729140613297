import React from 'react';

import { ModalProvider as CCModalProvider } from '@fanatics-live/common-components';
import useFeature from '~/hooks/useFeature';
import { getEnv } from '~/utils/env';
import { FeatureFlags } from '~/utils/featureFlags';

const { MARKETPLACE_PUBLISHABLE_KEY } = getEnv();

export const ModalProvider = (props) => {
  const { value: fetchPublishableKeyEnabled } = useFeature(
    FeatureFlags.WEB_ENABLE_FETCH_PUBLISHABLE_KEY
  );
  return (
    <CCModalProvider
      {...props}
      enableFetchPublishableKey={fetchPublishableKeyEnabled}
      stripeMarketplaceKey={
        !fetchPublishableKeyEnabled
          ? (MARKETPLACE_PUBLISHABLE_KEY ?? '')
          : undefined
      }
    />
  );
};
