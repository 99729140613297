import { gql } from '../../../artifacts/gql';

export const getCurrentUserV2 = gql(`
  query webCurrentUserV2CCQuery {
    collectCurrentUserV2 {
      tenantId
      email {
        address
        verified
      }
      firstName
      lastName
      phone {
        number
        status
      }
      collectPendingInvoicesCount
      collectPremierAuctionApproved
      kycStatus
    }
  }
`);
