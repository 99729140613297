import React, { useMemo } from 'react';

import { Box, HStack, Skeleton } from '@chakra-ui/react';

import { IAuth } from '../../../Auth/types/auth';
import { Button } from '../../../Button';
import { useIsMobile } from '../../../hooks';
import { LoginButton } from '../../../LoginButton';
import { EColor } from '../../../Theme';
import { useMonolithUrls } from '../../hooks/useMonolithUrls';
import { EURLSearchParams } from '../../types';

const MAX_INVOICES = 9;

export interface IHeaderActionsProps {
  auth?: IAuth;
  isInitializing: boolean;
}

export function HeaderActions({ auth, isInitializing }: IHeaderActionsProps) {
  const { profile } = auth || {};
  const { payInvoiceUrl, submissionUrl } = useMonolithUrls();
  const isMobile = useIsMobile();

  const unpaidInvoiceCount = useMemo(
    () =>
      (profile?.unpaidInvoiceCount ?? 0) > MAX_INVOICES
        ? `${MAX_INVOICES}+`
        : profile?.unpaidInvoiceCount,
    [profile?.unpaidInvoiceCount]
  );

  if (isInitializing) {
    return (
      <Skeleton
        borderRadius={50}
        endColor={EColor.BrandDark}
        height="30px"
        startColor="brand.main"
        width={{ base: undefined, md: '220px' }}
      />
    );
  }

  return (
    <HStack
      alignItems="flex-start"
      display={{ base: 'flex' }}
      justify="flex-end"
      padding="0px"
    >
      {profile ? (
        <>
          {!!unpaidInvoiceCount && !isMobile && (
            <Button
              borderRadius="100px"
              borderWidth="1px"
              data-testid="nav-pay-button"
              fontFamily="ABC Diatype Mono"
              fontSize="14px"
              fontWeight={700}
              height="32px"
              href={`${payInvoiceUrl}${
                unpaidInvoiceCount === 1
                  ? `?${EURLSearchParams.REDIRECT_TO_CHECKOUT}=1`
                  : ''
              }`}
              lineHeight="16px"
              paddingX="20px"
              paddingY="9px"
              textTransform="uppercase"
            >
              <span>Pay</span>
              <Box
                backgroundColor="red"
                borderRadius={50}
                color={EColor.White}
                fontSize="12px"
                fontWeight={700}
                paddingX={1.5}
                paddingY={0.4}
                position="absolute"
                right={-2}
                top={-2}
              >
                {unpaidInvoiceCount}
              </Box>
            </Button>
          )}
          {window.location.host.includes('admin')
            ? null
            : !isMobile && (
                <Button
                  borderColor={EColor.Black}
                  borderWidth="1px"
                  data-testid="nav-submit-button"
                  fontFamily="ABC Diatype Mono"
                  fontSize={{ base: '16px' }}
                  fontWeight="700"
                  height="32px"
                  href={submissionUrl}
                  marginX="2px"
                  paddingX="24px"
                  paddingY="9px"
                  textTransform="uppercase"
                  width="116px"
                >
                  Sell Now
                </Button>
              )}
        </>
      ) : (
        <LoginButton
          borderWidth="1px"
          fontFamily="ABC Diatype Mono"
          fontSize={{ base: '16px' }}
          fontWeight="700"
          height="40px"
          paddingX="24px"
          paddingY="12px"
          textTransform="uppercase"
          width="106px"
        />
      )}
    </HStack>
  );
}
