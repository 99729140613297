import React from 'react';

import { Global } from '@emotion/react';

export function Fonts() {
  return (
    <Global
      styles={`
      /** ABC Diatype Regular **/
      @font-face {
        font-display: fallback;
        font-family: ABC Diatype;
        font-weight: normal;
        font-style: normal;
        src: url("https://s3-us-west-2.amazonaws.com/pwccauctions/website/fonts/ABCDiatype-Regular.otf") format('opentype'),
             url("https://s3-us-west-2.amazonaws.com/pwccauctions/website/fonts/ABCDiatype-Regular.woff2") format('woff2'),
             url("https://s3-us-west-2.amazonaws.com/pwccauctions/website/fonts/ABCDiatype-Regular.woff") format('woff');
      }

      /** ABC Diatype Bold **/
      @font-face {
        font-display: fallback;
        font-family: ABC Diatype;
        font-weight: bold;
        font-style: normal;
        src: url("https://s3-us-west-2.amazonaws.com/pwccauctions/website/fonts/ABCDiatype-Bold.otf") format('opentype'),
             url("https://s3-us-west-2.amazonaws.com/pwccauctions/website/fonts/ABCDiatype-Bold.woff2") format('woff2'),
             url("https://s3-us-west-2.amazonaws.com/pwccauctions/website/fonts/ABCDiatype-Bold.woff") format('woff');
      }

      /** ABC Diatype Mono Bold **/
      @font-face {
        font-display: fallback;
        font-family: ABC Diatype Mono;
        font-weight: bold;
        font-style: normal;
        src: url("https://s3-us-west-2.amazonaws.com/pwccauctions/website/fonts/ABCDiatypeMono-Bold.otf") format('opentype'),
             url("https://s3-us-west-2.amazonaws.com/pwccauctions/website/fonts/ABCDiatypeMono-Bold.woff2") format('woff2'),
             url("https://s3-us-west-2.amazonaws.com/pwccauctions/website/fonts/ABCDiatypeMono-Bold.woff") format('woff');
      }
      `}
    />
  );
}

export default Fonts;
