import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from 'postcode-validator';
import { z } from 'zod';

import { countries } from '../../../../Auth';

export const ShippingAddressSchema = z
  .object({
    name: z.string().trim().min(1, 'Name is required'),
    street1: z.string().trim().min(1, 'Address is required'),
    street2: z.string().trim().optional(),
    city: z.string().trim().min(1, 'City is required'),
    state: z.string().trim().min(1, 'State is required'),
    countryId: z.string().min(1, 'Country is required'),
    zip: z.string().trim().min(1, 'Zip is required'),
  })
  .refine(
    ({ countryId, zip }) => {
      const countryCode = countries.find(
        (c) => c.id.toString() === countryId
      )?.value;

      return zip &&
        countryCode &&
        postcodeValidatorExistsForCountry(countryCode)
        ? postcodeValidator(zip, countryCode)
        : true;
    },
    {
      path: ['zip'],
      message: 'Zip is invalid',
    }
  );

export type TShippingAddressFormData = z.infer<typeof ShippingAddressSchema>;
