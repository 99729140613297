import { UseQueryResult } from '@tanstack/react-query';

export type TUseQuery<
  TData = unknown,
  TArgs extends { [key: string]: any } = {},
  TError = unknown
> = (args: TArgs) => UseQueryResult<TData, TError>;

export enum EAuctionStatus {
  NOT_STARTED = 'NOT_STARTED',
  LIVE = 'LIVE',
  ENDED = 'ENDED',
}

export interface IAuctionResponseBase {
  id: number;
  name: string;
  startTime: string;
  endTime: string;
  extendedEndTime: string;
  status: EAuctionStatus;
  auctionImageUrl: string;
  inExtendedBidding: boolean;
  _links: {
    items: {
      href: string;
    };
    self: {
      href: string;
    };
  };
}

export interface IGetAuctionsResponseBase<
  TField extends string,
  TAuction extends IAuctionResponseBase
> {
  _embedded: {
    [key in TField]: TAuction[];
  };
  _links: {
    first?: {
      href: string;
    };
    self: {
      href: string;
    };
    next?: {
      href: string;
    };
    last?: {
      href: string;
    };
  };
  page: {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
  };
}

export interface IAuctionBase {
  id: number;
  name: string;
  startTime: Date;
  endTime: Date;
  extendedEndTime: Date;
  status: EAuctionStatus;
  imageUrl: string;
  inExtendedBidding?: boolean;
}

export interface IAuctionParams {
  auctionStatus?: EAuctionStatus[];
  page?: number;
  size?: number;
}

export interface IAuctionsArgs {
  params: IAuctionParams;
  auctionsBaseUrl: string;
}
