import { create } from 'zustand';

import { IProfile } from '../../Auth';

interface IProfileStore {
  profile: IProfile | undefined;
  updateProfile: (profile: Partial<IProfile>) => void;
  profileCompleted: boolean;
  setProfileCompleted: (value: boolean) => void;
  addPaymentMethodLoginFlow: boolean;
  setAddPaymentMethodLoginFlow: (value: boolean) => void;
}

export const useProfileStore = create<IProfileStore>()((set) => ({
  profile: undefined,
  updateProfile: (value) =>
    set(({ profile }) => ({
      profile: { ...(profile ?? ({} as IProfile)), ...value },
    })),
  profileCompleted: false,
  setProfileCompleted: (value) =>
    set((profileStore) => ({ ...profileStore, profileCompleted: value })),
  addPaymentMethodLoginFlow: false,
  setAddPaymentMethodLoginFlow: (value) =>
    set((profileStore) => ({
      ...profileStore,
      addPaymentMethodLoginFlow: value,
    })),
}));

useProfileStore.subscribe((state, prevState) => {
  const { addPaymentMethodLoginFlow } = state;
  if (prevState.profile) {
    const isPhoneVerified = !!state.profile?.isPhoneVerified;
    const isEmailVerified = !!state.profile?.isEmailVerified;
    const hasAddress = !!state.profile?.hasAddress;
    const hasPaymentMethods = !!state.profile?.hasPaymentMethods;

    const isPhoneVerifiedChanged =
      isPhoneVerified !== prevState.profile.isPhoneVerified;
    const isEmailVerifiedChanged =
      isEmailVerified !== prevState.profile.isEmailVerified;
    const hasAddressChanged = hasAddress !== prevState.profile.hasAddress;
    const hasPaymentMethodsChanged =
      hasPaymentMethods !== prevState.profile.hasPaymentMethods;

    const signUpFlowCondition =
      isPhoneVerified &&
      isEmailVerified &&
      hasAddress &&
      (isPhoneVerifiedChanged || isEmailVerifiedChanged || hasAddressChanged);

    const addPaymentMethodCondition =
      isPhoneVerified &&
      isEmailVerified &&
      hasAddress &&
      hasPaymentMethods &&
      (isPhoneVerifiedChanged ||
        isEmailVerifiedChanged ||
        hasAddressChanged ||
        hasPaymentMethodsChanged);

    if (
      (addPaymentMethodLoginFlow && addPaymentMethodCondition) ||
      (!addPaymentMethodLoginFlow && signUpFlowCondition)
    ) {
      state.setProfileCompleted(true);
    }

    if (isPhoneVerified && isPhoneVerifiedChanged) {
      window.dispatchEvent(new CustomEvent('profile:phone-verified'));
    }

    if (isEmailVerified && isEmailVerifiedChanged) {
      window.dispatchEvent(new CustomEvent('profile:email-verified'));
    }

    if (hasAddress && hasAddressChanged) {
      window.dispatchEvent(new CustomEvent('profile:address-added'));
    }

    if (hasPaymentMethods && hasPaymentMethodsChanged) {
      window.dispatchEvent(new CustomEvent('profile:payment-method-added'));
    }
  }
});
