import { EColor } from '../colors';
import type { ComponentSingleStyleConfig } from '@chakra-ui/react';

export const SelectConfig: ComponentSingleStyleConfig = {
  baseStyle: {},
  variants: {
    outline: {
      field: {
        borderColor: EColor.BrandLightGray,
        borderWidth: '1px',
        borderRadius: '5px',
        color: EColor.BrandCorduroy,
        fontWeight: 700,
        _invalid: {
          borderColor: EColor.Transparent,
          boxShadow: `0 0 0 1px ${EColor.BrandDanger}`,
        },
        _placeholder: {
          fontWeight: 400,
        },
      },
    },
    brand: {
      field: {
        height: '48px',
        backgroundColor: EColor.White,
        borderColor: EColor.BrandLightGray,
        borderWidth: '1px',
        borderRadius: '5px',
        paddingX: '13px',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0.32px',
        _placeholder: {
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.32px',
        },
        _focus: {
          borderColor: EColor.BrandCorduroy,
          boxShadow: 'none',
        },
        _invalid: {
          borderColor: EColor.BrandDanger,
        },
        _autofill: {
          boxShadow: `0 0 0px 1000px ${EColor.White} inset`,
          transition: 'background-color 5000s ease-in-out 0s',
        },
      },
    },
  },
};
