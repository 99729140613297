import React, { FC } from 'react';

import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FieldPathByValue, FieldValues, useController } from 'react-hook-form';

export interface IFormCheckboxProps<TFieldValues extends FieldValues>
  extends CheckboxProps {
  name: FieldPathByValue<TFieldValues, boolean>;
}

export function createFormCheckbox<TFieldValues extends FieldValues>() {
  const FormCheckbox: FC<IFormCheckboxProps<TFieldValues>> = (props) => {
    const { name, children, ...restProps } = props;
    const { field, fieldState } = useController<TFieldValues, typeof name>({
      name,
    });

    const { value } = field;
    const { error } = fieldState;

    return (
      <FormControl isInvalid={Boolean(error)}>
        <Checkbox
          {...field}
          {...restProps}
          colorScheme="dark"
          isChecked={value}
          size="lg"
        >
          {children}
        </Checkbox>
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    );
  };

  return FormCheckbox;
}
