import React, { FC, ReactNode } from 'react';

import { Box, Button as ChakraButton } from '@chakra-ui/react';

interface ISliderButtonProps {
  isDisabled: boolean;
  handleClick: () => void;
  children: ReactNode;
}

export const SliderButton: FC<ISliderButtonProps> = ({
  isDisabled,
  handleClick,
  children,
}) => {
  return (
    <Box
      _hover={{
        opacity: isDisabled ? 1 : 0.8,
      }}
      borderRadius="10px"
    >
      <ChakraButton
        alignItems="center"
        backgroundColor="transparent"
        borderRadius="10px"
        borderWidth="0px"
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        disabled={isDisabled}
        display="flex"
        height="56px"
        justifyContent="center"
        opacity={isDisabled ? 0.5 : 1}
        variant="unstyled"
        width="56px"
        onClick={handleClick}
      >
        {children}
      </ChakraButton>
    </Box>
  );
};
