import { BoxProps, TextProps } from '@chakra-ui/react';
import { format, intervalToDuration, isAfter, parse } from 'date-fns';

import { isWeeklyAuction } from './helpers';
import { EColor } from '../../Theme';
import { EAuctionStatus } from '../types/auction';
import { TPremierAuction } from '../types/premierAuction';
import { TWeeklyAuction } from '../types/weeklyAuction';

export type TAuctionTopBadgeConfig = {
  label: string;
  labelTransform: TextProps['textTransform'];
  circleColor?: BoxProps['color'];
};

export type TAuctionBottomConfig = {
  startDate: string;
  endDate: string;
  countdown?: string;
  isExtended?: boolean;
  buttonText?: string;
};

export enum EBadgeLabels {
  PREVIEW = 'Preview',
  STARTS_IN = 'Starts in',
  LIVE = 'Live',
  IN_EXTENDED = 'In extending bidding',
  EXTENDED_IN = 'Extending bidding in',
  COMING_SOON = 'Coming soon',
  UP_NEXT = 'Up Next',
  UNKNOWN = 'unknown',
}

enum EAuctionButton {
  BID_NOW = 'Bid Now',
  PREVIEW = 'Preview',
}

export const getAuctionConfig = (
  auction: TWeeklyAuction | TPremierAuction
): {
  topBadgeConfig: TAuctionTopBadgeConfig;
  bottomConfig: TAuctionBottomConfig;
} => {
  const getDuration = (date: Date) => {
    const duration = intervalToDuration({
      start: new Date(),
      end: date,
    });
    const { months, days, hours, minutes } = duration;

    const monthsFormatted = months ? `${months}m` : '';
    const daysFormatted = days ? `${days}d` : '';
    const hoursFormatted = hours ? `${hours}h` : '';
    const minutesFormatted = days ? '' : `${minutes}m`;

    return [
      monthsFormatted,
      daysFormatted,
      hoursFormatted,
      minutesFormatted,
    ].join(' ');
  };

  const startDate = format(parse('', '', auction.startTime), 'MMM d');
  const endDate = format(auction.endTime, 'MMM d');

  if (isWeeklyAuction(auction) && auction.preview) {
    return {
      topBadgeConfig: {
        label: EBadgeLabels.UP_NEXT,
        labelTransform: 'uppercase',
        circleColor: EColor.Neutral25,
      },
      bottomConfig: {
        startDate,
        endDate,
        countdown: getDuration(auction.startTime),
        buttonText: EAuctionButton.PREVIEW,
      },
    };
  }

  switch (auction.status) {
    case EAuctionStatus.LIVE:
      return {
        topBadgeConfig: {
          label: EBadgeLabels.LIVE,
          labelTransform: 'uppercase',
          circleColor: EColor.FanaticsRed,
        },
        bottomConfig: {
          startDate,
          endDate,
          buttonText: EAuctionButton.BID_NOW,
          ...(() => {
            const isExtended = isAfter(new Date(), auction.endTime);

            const countdown = !isExtended
              ? getDuration(auction.endTime)
              : undefined;

            return {
              countdown,
              isExtended,
            };
          })(),
        },
      };
    case EAuctionStatus.NOT_STARTED:
      return {
        topBadgeConfig: {
          label: EBadgeLabels.UP_NEXT,
          labelTransform: 'uppercase',
          circleColor: EColor.Neutral25,
        },
        bottomConfig: {
          startDate,
          endDate,
          countdown: getDuration(auction.startTime),
        },
      };
    default:
      return {
        topBadgeConfig: {
          label: 'unknown',
          labelTransform: 'uppercase',
          circleColor: EColor.Neutral25,
        },
        bottomConfig: {
          startDate,
          endDate,
          countdown: getDuration(auction.startTime),
        },
      };
  }
};
