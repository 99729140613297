import React from 'react';

import { Link, Box, BoxProps, Icon, Flex } from '@chakra-ui/react';

import { LiveSuperscript } from '../../../components';
import { EGTMEvents, useGTMDataLayer } from '../../../hooks';
import { EColor, ETextVariant } from '../../../Theme';
import { INavigationItem } from '../../types/navigation';

export interface IHeaderNavLinkProps extends BoxProps {
  item: INavigationItem;
  parent?: String;
  dropdownPath?: string[];
}

export function HeaderNavLink({
  item,
  parent,
  color = EColor.BrandDark,
  dropdownPath = [],
  ...rest
}: IHeaderNavLinkProps) {
  const pushGTMData = useGTMDataLayer();

  const handleClick = () => {
    const dropdownList = [...dropdownPath, item.name].join(' | ');

    pushGTMData({
      event: EGTMEvents.NAVIGATION_CLICK,
      navigationBreakdown: dropdownList,
      clickValue: item.name,
    });
  };

  return (
    <Box
      _hover={{
        backgroundColor: EColor.Neutral5,
        color: EColor.Black,
        cursor: item.path ? 'pointer' : 'unset',
        textDecoration: 'none',
      }}
      alignItems="center"
      as={Link}
      backgroundColor="unset"
      color={color}
      data-parent={parent || ''}
      display="flex"
      href={item.path}
      justifyContent="space-between"
      key={item.name}
      marginX={0}
      maxWidth="215px"
      paddingX="16px"
      paddingY="5px"
      transitionDuration="0s"
      variant={ETextVariant.BaseParagraph}
      whiteSpace="nowrap"
      onClick={handleClick}
      {...rest}
    >
      <Flex align="center">
        {item.Icon ? (
          <Icon as={item.Icon} boxSize="24px" paddingRight="7px" />
        ) : null}
        {item.name}
      </Flex>
      {item.superscript && (
        <LiveSuperscript>{item.superscript}</LiveSuperscript>
      )}
    </Box>
  );
}
