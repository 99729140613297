import { useMutation } from '@tanstack/react-query';

import { useApi } from '../../../../../ApiClient';
import { useModalContext } from '../../../../hooks/useModalContext';

interface IVerifyPhoneArgs {
  code: string;
}

export interface IVerifyPhoneError {
  message?: string;
  errors?: {
    code?: string[];
  };
}

export const useVerifyPhone = () => {
  const apiClient = useApi();
  const { membersApiUrl } = useModalContext();

  return useMutation<void, unknown, IVerifyPhoneArgs>({
    mutationKey: ['collect-verify-phone'],
    mutationFn: async (args) => {
      await apiClient!.patch<void>(
        `${membersApiUrl}/members/v2/registration/verify/phone`,
        args
      );
    },
  });
};
