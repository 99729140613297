import React, { FC } from 'react';

import { Container, ContainerProps } from '@chakra-ui/react';

export interface IResponsiveContainerProps extends ContainerProps {}

export const ResponsiveContainer: FC<IResponsiveContainerProps> = (props) => {
  const { children, ...restProps } = props;

  return (
    <Container
      centerContent
      h="100%"
      maxW="100%"
      px={{ base: '8px', xl: '0px' }}
      w={{ base: '100%', xl: '1144px' }}
      {...restProps}
    >
      {children}
    </Container>
  );
};
