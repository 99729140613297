import { z } from 'zod';

const CODE_LENGTH = 6;

export const VerificationCodeSchema = z.object({
  code: z
    .string()
    .trim()
    .length(CODE_LENGTH, `Code should be ${CODE_LENGTH} digits long`),
});

export type TVerificationCodeData = z.infer<typeof VerificationCodeSchema>;
