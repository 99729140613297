import React, { FC } from 'react';

import { Flex, FlexProps } from '@chakra-ui/react';

import { Button } from '../Button';

export interface IFormControlsProps extends FlexProps {
  prevLabel?: string;
  nextLabel?: string;
  nextDisabled?: boolean;
  nextLoading?: boolean;
  onPrevClick?: () => void;
  onNextClick?: () => void;
}

export const FormControls: FC<IFormControlsProps> = (props) => {
  const {
    prevLabel = 'cancel',
    nextLabel = 'apply',
    nextDisabled,
    nextLoading,
    onPrevClick,
    onNextClick,
    ...restProps
  } = props;

  return (
    <Flex
      flex={1}
      flexDirection="row"
      flexWrap="wrap-reverse"
      justifyContent="center"
      px="15px"
      {...restProps}
    >
      <Button
        data-testid="cancel-form-button"
        m="8px"
        w={{ base: '100%', md: 'auto' }}
        onClick={onPrevClick}
      >
        {prevLabel}
      </Button>
      <Button
        inverted
        data-testid="confirm-form-button"
        isDisabled={nextDisabled || nextLoading}
        isLoading={nextLoading}
        m="8px"
        type="submit"
        w={{ base: '100%', md: 'auto' }}
        onClick={onNextClick}
      >
        {nextLabel}
      </Button>
    </Flex>
  );
};
