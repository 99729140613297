import { EColor } from '../colors';
import type { ComponentSingleStyleConfig } from '@chakra-ui/react';

export const DividerConfig: ComponentSingleStyleConfig = {
  baseStyle: {
    opacity: 1,
    borderColor: EColor.BrandLightGray,
  },
};

export const MonolithDividerConfig: ComponentSingleStyleConfig = {
  baseStyle: {
    opacity: 1,
    borderColor: EColor.BrandLightGray,
    margin: 0,
  },
};
