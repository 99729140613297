import React from 'react';

import { Box, Flex } from '@chakra-ui/react';

import { TopNavRow } from './TopNavRow';
import { IAuth } from '../../../Auth';
import { useCollectContext } from '../../../collect/context';
import { useBFCache } from '../../../hooks';
import { maxWidths } from '../../../utilities';
import { INavigationItem } from '../../types/navigation';

export interface INavRowsProps {
  auth?: IAuth;
  navItems?: INavigationItem[];
  pastSearchBaseUrl: string;
  manageAccountUrl: string | undefined;
  isInitializing: boolean;
  showCartButton?: boolean;
  enablePlaidPAApproval: boolean;
  enableOfferNotificationBadges?: boolean;
}

export function NavRows({
  auth,
  navItems,
  pastSearchBaseUrl,
  manageAccountUrl,
  isInitializing,
  showCartButton,
  enablePlaidPAApproval,
  enableOfferNotificationBadges,
}: INavRowsProps) {
  const [show, setShow] = React.useState(false);
  const { stickyTop } = useCollectContext();

  const closeMenu = () => setShow(false);
  const handleToggle = () => setShow(!show);

  useBFCache(closeMenu);

  return (
    <Flex
      alignItems="center"
      backdropFilter="saturate(1.8) blur(20px)"
      backgroundColor="rgba(255, 255, 255, 0.8)"
      boxShadow="0px 3px 9px rgba(0, 0, 0, 0.05)"
      flexDirection="column"
      justify="space-between"
      paddingBottom="8px"
      paddingTop="16px"
      position={{ base: undefined, lg: 'sticky' }}
      top={{ base: '0', lg: stickyTop ?? '0' }}
      width="100%"
      zIndex="1030"
    >
      <Box maxWidth={maxWidths} width="100%">
        <TopNavRow
          auth={auth}
          closeMenu={closeMenu}
          enableOfferNotificationBadges={enableOfferNotificationBadges}
          enablePlaidPAApproval={enablePlaidPAApproval}
          handleToggle={handleToggle}
          isInitializing={isInitializing}
          manageAccountUrl={manageAccountUrl}
          navItems={navItems}
          pastSearchBaseUrl={pastSearchBaseUrl}
          show={show}
          showCartButton={showCartButton}
        />
      </Box>
    </Flex>
  );
}
