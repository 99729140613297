import React, { FC, ReactNode } from 'react';

import { Box, Flex, HStack, Text } from '@chakra-ui/react';

import { CheckmarkIcon, PenIcon } from '../../../Icons';
import { EColor } from '../../../Theme';

interface IVerificationSectionProps {
  label: ReactNode;
  isVerified: boolean;
  onClick: () => void;
  lastItem?: boolean;
}

export const VerificationSection: FC<IVerificationSectionProps> = ({
  label,
  isVerified,
  onClick,
  lastItem,
}) => {
  const handleClick = () => {
    if (!isVerified) {
      onClick();
    }
  };

  return (
    <Flex
      align="center"
      cursor={!isVerified ? 'pointer' : 'inherit'}
      justify="space-between"
      paddingBottom={lastItem ? '' : '18px'}
      paddingTop="18px"
      w="100%"
      onClick={handleClick}
    >
      <HStack spacing={2}>
        <CheckmarkIcon h="18px" isChecked={isVerified} w="18px" />
        <Text
          color={EColor.Black}
          fontFamily="ABC Diatype Mono"
          fontSize="16px"
          fontWeight="700"
          lineHeight="16px"
          textTransform="uppercase"
        >
          {label}
        </Text>
      </HStack>

      <Box h="24px" w="24px">
        {!isVerified && <PenIcon h="100%" w="100%" />}
      </Box>
    </Flex>
  );
};
