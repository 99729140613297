import React, { FC, useEffect } from 'react';

import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  Text,
} from '@chakra-ui/react';

import { ProgressCircle } from './ProgressCircle';
import { EButtonVariant, EColor, ETextVariant } from '../../../Theme';
import { useBottomBarStore } from '../../stores/bottom-bar';
import { useModalStore } from '../../stores/modal';
import { EModalContent } from '../../types/modalContent';

export const BottomBar: FC = () => {
  const bottomBarStore = useBottomBarStore();
  const modalStore = useModalStore();

  const handleOpenCompleteProfile = () => {
    modalStore.navigate({ name: EModalContent.COMPLETE_PROFILE });
  };

  useEffect(() => {
    if (modalStore.stack.length && bottomBarStore.isOpen) {
      bottomBarStore.close();
    }
  }, [bottomBarStore, modalStore.stack]);

  return (
    <Popover
      closeOnBlur={false}
      isOpen={bottomBarStore.isOpen}
      onClose={bottomBarStore.close}
    >
      <PopoverContent
        border="none"
        borderRadius="none"
        bottom={{ base: 0, md: '40px' }}
        boxShadow="0px 0px 50px 0px rgba(40, 40, 40, 0.15) !important"
        left={{ base: 'calc(50% - 187px)', md: 'calc(50% - 205px)' }}
        maxW="375px"
        p={6}
        position="fixed"
        sx={{
          '@media screen and (max-width: 374px)': {
            left: 0,
          },
        }}
        variants={{
          enter: {
            transform: 'translateY(0px)',
          },
          exit: {
            transform: 'translateY(300px)',
          },
        }}
        width="100%"
      >
        <Flex align="start" flexDir="column" gap="16px">
          <Grid
            alignItems="center"
            gridTemplateAreas={{
              base: `'progress-circle title'
                      'subtitle subtitle'`,
              md: `'progress-circle title'
                   'progress-circle subtitle'`,
            }}
          >
            <GridItem gridArea="progress-circle" mr="9px">
              <ProgressCircle />
            </GridItem>

            <Box maxW={{ base: '210px', md: '100%' }}>
              <Text
                color={EColor.Black}
                lineHeight="16px"
                variant={ETextVariant.SmallBold}
              >
                Welcome to Fanatics Collect
              </Text>
              <Text
                color={EColor.Neutral65}
                gridArea="subtitle"
                marginTop="4px"
                variant={ETextVariant.XS}
              >
                Complete your profile to buy.
              </Text>
            </Box>
          </Grid>
          <Button
            fontFamily="ABC Diatype Mono"
            fontSize="16px"
            fontWeight={700}
            h="40px"
            lineHeight="16px"
            variant={EButtonVariant.BRAND_PRIMARY}
            w="100%"
            onClick={handleOpenCompleteProfile}
          >
            Add info
          </Button>
        </Flex>
        <PopoverCloseButton
          _hover={{
            backgroundColor: 'transparent',
            color: EColor.BrandDarkOpacity,
          }}
          backgroundColor="transparent"
          color={EColor.Black}
          height="54px"
          position="absolute"
          right="5px"
          size="md"
          top="5px"
          width="54px"
        />
      </PopoverContent>
    </Popover>
  );
};
