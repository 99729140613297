import { getMonolithUrls } from '../../utils';

export interface IFooterLink {
  name: string;
  path: string;
  dataTestId: string;
}

type IFooterLinks = Array<{
  name: string;
  items: IFooterLink[];
}>;

export function getFooterLinks(baseUrl: string) {
  const monolithUrls = getMonolithUrls(baseUrl);

  return [
    {
      name: 'Marketplace',
      items: [
        {
          name: 'Buy Now',
          path: monolithUrls.fixedPriceUrl,
          dataTestId: 'fixed-price',
        },
        {
          name: 'Auction',
          path: monolithUrls.weeklyAuctionUrl,
          dataTestId: 'weekly',
        },
        {
          name: 'Premier',
          path: monolithUrls.premierAuctionUrl,
          dataTestId: 'premier',
        },
      ],
    },
    {
      name: 'Services',
      items: [
        {
          name: 'How it works',
          path: monolithUrls.aboutTheMarketplaceUrl,
          dataTestId: 'how-it-works',
        },
        {
          name: 'Eye Appeal',
          path: monolithUrls.eyeAppealUrl,
          dataTestId: 'eye-appeal',
        },
        {
          name: 'Sold Items',
          path: monolithUrls.salesHistoryUrl,
          dataTestId: 'sales-history',
        },
        {
          name: 'Definitive Guides',
          path: monolithUrls.definitiveGuidesUrl,
          dataTestId: 'def-guides',
        },
      ],
    },
    {
      name: 'Support',
      items: [
        {
          name: 'Help Center',
          path: monolithUrls.helpCenterUrl,
          dataTestId: 'help-center',
        },
        {
          name: 'Newsroom',
          path: monolithUrls.newsroomUrl,
          dataTestId: 'newsroom',
        },
        {
          name: 'Dealer Program',
          path: monolithUrls.dealerProgram,
          dataTestId: 'dealer-program',
        },
        {
          name: 'Affiliates',
          path: monolithUrls.affiliates,
          dataTestId: 'affiliates',
        },
      ],
    },
  ] satisfies IFooterLinks;
}
