import React, { createContext, FC, ReactNode, useMemo, useState } from 'react';

export enum EVerificationStep {
  VERIFICATION_METHOD = 'verification method',
  VERIFICATION_CODE = 'verification code',
  VERIFICATION_COMPLETE = 'verification complete',
}

export interface IVerificationStepperContext {
  step: EVerificationStep;
  setStep: (step: EVerificationStep) => void;
}

export const VerificationStepperContext =
  createContext<IVerificationStepperContext>({} as IVerificationStepperContext);

interface IVerificationStepperProviderProps {
  children: ReactNode;
}

export const VerificationStepperProvider: FC<
  IVerificationStepperProviderProps
> = ({ children }) => {
  const [step, setStep] = useState<EVerificationStep>(
    EVerificationStep.VERIFICATION_METHOD
  );

  const value = useMemo<IVerificationStepperContext>(
    () => ({
      step,
      setStep,
    }),
    [step]
  );

  return (
    <VerificationStepperContext.Provider value={value}>
      {children}
    </VerificationStepperContext.Provider>
  );
};
