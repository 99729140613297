import { useMemo } from 'react';

import { useGetPremierAuctions } from '../api/getPremierAuctions';
import { useGetWeeklyAuctions } from '../api/getWeeklyAuctions';
import { IAuctionParams } from '../types/auction';

export const useGetAuctions = (
  params: IAuctionParams,
  auctionsBaseUrl: string
) => {
  const { data: weeklyAuctionsData } = useGetWeeklyAuctions({
    params,
    auctionsBaseUrl,
  });
  const { data: premierAuctionsData } = useGetPremierAuctions({
    params,
    auctionsBaseUrl,
  });

  const auctionsData = useMemo(() => {
    if (!weeklyAuctionsData && !premierAuctionsData) {
      return;
    }
    return [...(weeklyAuctionsData ?? []), ...(premierAuctionsData ?? [])]
      .sort((a, b) => a.endTime.valueOf() - b.endTime.valueOf())
      .slice(0, params.size);
  }, [weeklyAuctionsData, premierAuctionsData, params.size]);

  return {
    auctionsData,
  };
};
