import { ElementType, FC } from 'react';

import { BoxProps } from '@chakra-ui/react';

export enum EURLSearchParams {
  REDIRECT_TO_CHECKOUT = 'redirect_to_checkout',
}

export interface INavigationItem {
  name: string;
  dataTestId?: string;
  routeName?: string;
  hideInFooter?: boolean;
  hideInDesktopFooter?: boolean;
  superscript?: string;
  superscriptStyles?: BoxProps;
  path?: string;
  active?: boolean | string;
  callback?: string;
  externalUrl?: string;
  url?: string;
  items?: INavigationItem[];
  multiColumn?: boolean;
  Icon?: ElementType;
  Component?: FC<any>;
  hideInMobile?: boolean;
  iconSizes?: {
    width?: string;
    height?: string;
  };
}
