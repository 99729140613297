import React, { FC } from 'react';

import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';

import { EColor } from '../../../Theme';
import { useModalContext } from '../../hooks/useModalContext';
import { useProfileStore } from '../../stores/profile';

export const ProgressCircle: FC = () => {
  const { profile } = useProfileStore();
  const { addPaymentMethodLoginFlow } = useModalContext();

  const {
    isPhoneVerified = false,
    isEmailVerified = false,
    hasAddress = false,
    hasPaymentMethods = false,
  } = profile ?? {};

  const baseProgressArr = [isPhoneVerified, isEmailVerified, hasAddress];

  const items = addPaymentMethodLoginFlow
    ? [...baseProgressArr, hasPaymentMethods]
    : baseProgressArr;

  const totalCount = items.length;
  const completedCount = items.filter(Boolean).length;

  return (
    <CircularProgress
      color={EColor.Lime}
      size="40px"
      value={(completedCount / totalCount) * 100}
    >
      <CircularProgressLabel
        color={EColor.Black}
        fontFamily="ABC Diatype Mono"
        fontSize="12px"
        fontWeight="700"
        lineHeight="16px"
      >
        {`${completedCount}/${totalCount}`}
      </CircularProgressLabel>
    </CircularProgress>
  );
};
