import React, { useCallback, useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Flex, Spinner, Text, Button, Box } from '@chakra-ui/react';
import axios from 'axios';

import { getCurrentUserV2, resendWelcomeEmail } from '../../../../gql';
import { EGTMEvents, useGTMDataLayer } from '../../../../hooks';
import { CautionErrorIcon, CheckIcon } from '../../../../Icons';
import { EButtonVariant, EColor, ETextVariant } from '../../../../Theme';
import { tracker } from '../../../../utilities/tracker';
import { ModalContent, ModalHeader } from '../../../components';
import { useModalStore, useProfileStore } from '../../../stores';
import { EModalContent } from '../../../types';

export const CollectVerifyEmail = () => {
  const modalStore = useModalStore();
  const { updateProfile } = useProfileStore();
  const pushGTMData = useGTMDataLayer();
  const [resendError, setResendError] = useState<boolean>(false);
  const [isVerifiedClicked, setIsVerifiedClicked] = useState<boolean>(false);
  const [
    getCurrentUser,
    { data: currentUserV2Data, loading: currentUserV2IsLoading },
  ] = useLazyQuery(getCurrentUserV2, { fetchPolicy: 'cache-and-network' });
  const [
    resendEmail,
    { data: resendEmailData, loading: resendEmailIsLoading },
  ] = useMutation(resendWelcomeEmail);

  const isEmailVerified =
    currentUserV2Data?.collectCurrentUserV2?.email?.verified;

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  useEffect(() => {
    if (isEmailVerified) {
      updateProfile({ isEmailVerified: true });
      modalStore.navigate({ name: EModalContent.COMPLETE_PROFILE });
    }
  }, [isEmailVerified, modalStore, updateProfile]);

  const handleHaveVerified = useCallback(async () => {
    try {
      pushGTMData({
        event: EGTMEvents.I_HAVE_VERIFIED_PRESSED,
      });
      tracker.track('I Have Verified Tapped', {});

      setIsVerifiedClicked(false);
      await getCurrentUser();
    } catch (error) {
      const isAxiosError = axios.isAxiosError(error);

      tracker.track('I Have Verified Failed', {
        isAxiosError,
        errorString: JSON.stringify(
          isAxiosError ? error.response?.data : error
        ),
      });
    } finally {
      setIsVerifiedClicked(true);
    }
  }, [getCurrentUser, pushGTMData]);

  const handleResend = async () => {
    try {
      pushGTMData({
        event: EGTMEvents.RESEND_VERIFICATION_PRESSED,
      });
      tracker.track('Resend Email Tapped', {});

      setResendError(false);
      await resendEmail();
    } catch (error) {
      setResendError(true);

      const isAxiosError = axios.isAxiosError(error);

      tracker.track('Resend Email Failed', {
        isAxiosError,
        errorString: JSON.stringify(
          isAxiosError ? error.response?.data : error
        ),
      });
    }
  };

  useEffect(() => {
    pushGTMData({
      event: EGTMEvents.VERIFY_EMAIL_VIEW,
    });
  }, [pushGTMData]);

  return (
    <ModalContent
      header={
        <ModalHeader className="verify-email" onClick={modalStore.pop}>
          Verify Email Address
        </ModalHeader>
      }
    >
      <Box>
        {currentUserV2IsLoading && !currentUserV2Data ? (
          <Flex justifyContent="center" w="100%">
            <Spinner size="md" />
          </Flex>
        ) : !currentUserV2IsLoading && !currentUserV2Data ? (
          <>
            <Text pb="12px" variant={ETextVariant.BaseParagraph}>
              Something went wrong
            </Text>

            <Button
              disabled={currentUserV2IsLoading}
              isLoading={currentUserV2IsLoading}
              my={4}
              size="lg"
              variant={EButtonVariant.BRAND_PRIMARY}
              w="100%"
              onClick={() => getCurrentUser()}
            >
              Try Again
            </Button>
          </>
        ) : (
          <>
            {!isEmailVerified && (
              <Text
                color={EColor.Neutral65}
                lineHeight="24px"
                mb="12px"
                variant={ETextVariant.BaseParagraph}
              >
                To continue using Collect we need a verified email address. Tap
                the link in the email we sent to{' '}
                <Text
                  as="span"
                  color={EColor.Black}
                  fontWeight="700"
                  wordBreak="break-word"
                >
                  {currentUserV2Data?.collectCurrentUserV2?.email?.address}
                </Text>
              </Text>
            )}

            {currentUserV2Data && !isEmailVerified && isVerifiedClicked && (
              <Flex alignItems="center" gap="4px">
                <CautionErrorIcon
                  bottom="1px"
                  height="24px"
                  position="relative"
                  width="24px"
                />
                <Text
                  color={EColor.Corsa}
                  lineHeight="16px"
                  m={0}
                  variant={ETextVariant.BaseParagraph}
                >
                  Email is not verified.
                </Text>
              </Flex>
            )}

            <Button
              disabled={currentUserV2IsLoading}
              isLoading={currentUserV2IsLoading}
              my="12px"
              size="lg"
              variant={EButtonVariant.BRAND_PRIMARY}
              w="100%"
              onClick={handleHaveVerified}
            >
              I Have Verified
            </Button>

            <Flex gap="4px">
              <Text
                color={EColor.Neutral65}
                lineHeight="24px"
                variant={ETextVariant.BaseParagraph}
              >
                Did not receive your email?
              </Text>
              {resendEmailData?.collectResendWelcomeEmail?.successful ? (
                <Flex alignItems="center" gap="4px">
                  <Text lineHeight="24px" variant={ETextVariant.BaseBold}>
                    Sent
                  </Text>
                  <Box bottom="2px" position="relative">
                    <CheckIcon />
                  </Box>
                </Flex>
              ) : (
                <Button
                  color={EColor.Black}
                  fontSize="16px"
                  fontWeight={700}
                  isLoading={resendEmailIsLoading}
                  lineHeight="16px"
                  textDecoration="underline"
                  variant="link"
                  onClick={handleResend}
                >
                  Resend
                </Button>
              )}
            </Flex>

            {resendError && (
              <Text
                color={EColor.Corsa}
                lineHeight="16px"
                m={0}
                variant={ETextVariant.XS}
              >
                Something went wrong. Try again
              </Text>
            )}
          </>
        )}
      </Box>
    </ModalContent>
  );
};
