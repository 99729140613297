import React, { FC } from 'react';

import { Box, Center, Text } from '@chakra-ui/react';

import { useGetPastSearches } from './api';
import { PastSearchItem } from './PastSearchItem';
import { useAuth } from '../../Auth';
import { SearchIcon } from '../../Icons';
import { EColor } from '../../Theme';

const MAX_PAST_SEARCHES = 10;

interface IPastSearchesProps {
  pastSearchBaseUrl: string;
}

export const PastSearches: FC<IPastSearchesProps> = ({ pastSearchBaseUrl }) => {
  const auth = useAuth();
  const isLoggedIn = !!auth?.profile;
  const { data: pastSearches, isPending: pastSearchesIsPending } =
    useGetPastSearches(pastSearchBaseUrl, isLoggedIn);

  const renderEmptyResults = (description: string) => (
    <Center flex={1} flexDirection="column">
      <Center bg={EColor.Neutral2} borderRadius="32px" h="64px" w="64px">
        <SearchIcon h="32px" w="32px" />
      </Center>

      <Text color={EColor.Neutral90} fontSize="18px" fontWeight="700">
        No recent searches
      </Text>

      <Text
        color={EColor.Neutral80}
        fontSize="14px"
        fontWeight="500"
        textAlign="center"
      >
        {description}
      </Text>
    </Center>
  );

  if (!isLoggedIn) {
    return renderEmptyResults('You have to be logged in to save search terms');
  }

  if (pastSearchesIsPending) {
    return (
      <Center flex={1}>
        <Text color={EColor.Neutral80} fontSize="14px" fontWeight="500">
          Loading recent searches...
        </Text>
      </Center>
    );
  }

  if (!pastSearches?.allSearches.length) {
    return renderEmptyResults(
      'Your recent search terms will be saved here to be used again in the future'
    );
  }

  return (
    <Center flexDir="column" height="100%">
      <Box
        height="100%"
        marginTop={{ base: '28px', lg: '8px' }}
        maxW="100%"
        position="relative"
        width="100%"
      >
        <Box height="100%" padding={{ sm: 0, md: '0px 12px 0px 24px' }}>
          <Text
            as="div"
            backgroundColor={EColor.Neutral5}
            borderRadius="2px"
            color={EColor.BrandCorduroy}
            display="inline-block"
            fontSize="8px"
            fontWeight="700"
            letterSpacing="0.5px"
            marginBottom="8px"
            padding="2px 4px"
            width="fit-content"
          >
            RECENT SEARCHES
          </Text>

          {pastSearches.allSearches
            .slice(0, MAX_PAST_SEARCHES)
            .map(({ term, lastRun }, idx) => (
              <PastSearchItem
                isLast={idx === pastSearches.allSearches.length - 1}
                itemIndex={idx}
                key={lastRun}
                pastSearchBaseUrl={pastSearchBaseUrl}
                term={term}
              />
            ))}
        </Box>
      </Box>
    </Center>
  );
};
