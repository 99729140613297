import { useEffect } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import useFeature from '~/hooks/useFeature';
import { getEnv } from '~/utils/env';

const { DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN, BUILD_VERSION, ENV } =
  getEnv();

const SPLIT_TREATMENTS = ['Datadog_Browser_Logs_Session_Sample_Rate'];

function initialize(
  sessionSampleRate,
  rumSessionSampleRate,
  rumSessionReplaySampleRate
) {
  if (DATADOG_APPLICATION_ID && DATADOG_CLIENT_TOKEN) {
    datadogLogs.init({
      clientToken: DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'sales-history-ui',
      env: ENV,
      forwardConsoleLogs: 'all',
      forwardErrorsToLogs: true,
      sessionSampleRate,
    });

    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'sales-history-ui',
      env: ENV,
      version: BUILD_VERSION,
      sessionSampleRate: rumSessionSampleRate,
      sessionReplaySampleRate: rumSessionReplaySampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }
}

export const DatadogProvider = ({ children }) => {
  const {
    value: sessionSampleRate,
    isReady,
    error,
  } = useFeature(SPLIT_TREATMENTS[0]);

  useEffect(() => {
    if (isReady && !error) {
      initialize(Number(sessionSampleRate.treatment) || 0, 0, 0);
    }
  }, [isReady, sessionSampleRate, error]);

  return children;
};
