import React, { ElementType } from 'react';

import { Icon, Link, LinkProps } from '@chakra-ui/react';

import { EGTMEvents, useGTMDataLayer } from '../../../hooks';
import { EColor } from '../../../Theme';

export interface IFooterLinkProps extends LinkProps {
  name: string;
  to?: string;
  href?: string;
  category?: string;
  icon?: ElementType;
  iconSizes?: {
    width?: string;
    height?: string;
  };
  dense?: boolean;
}

export function FooterLink({
  name,
  to,
  category,
  icon,
  iconSizes,
  dense,
  ...rest
}: IFooterLinkProps) {
  const pushGTMData = useGTMDataLayer();

  const handleClick = () => {
    const categoryText = category ? [category] : [];
    const navBreakdownText = ['Footer', ...categoryText, name].join(' | ');
    pushGTMData({
      event: EGTMEvents.NAVIGATION_CLICK,
      navigationBreakdown: navBreakdownText,
      clickValue: name,
    });
  };
  return (
    <Link
      _hover={{
        color: EColor.BrandCorduroy,
        textDecoration: 'none',
      }}
      cursor="pointer"
      fontFamily="ABC Diatype Mono"
      fontSize={dense ? '10px' : '12px'}
      fontWeight="700"
      lineHeight={dense ? '10px' : '12px'}
      textDecoration="none"
      textTransform="uppercase"
      to={to}
      {...rest}
      onClick={handleClick}
    >
      {name}
      {icon && (
        <Icon
          as={icon}
          color={EColor.Neutral75}
          height={iconSizes?.height}
          width={iconSizes?.width}
        />
      )}
    </Link>
  );
}
