import React from 'react';

import { Button, IButtonProps } from '../Button';
import { useIsMobile } from '../hooks';
import { LogInIcon } from '../Icons';
import { useModalStore } from '../ModalProvider/stores/modal';
import { EModalContent } from '../ModalProvider/types/modalContent';
import { EColor } from '../Theme';

export interface ILoginButtonProps extends IButtonProps {}

export function LoginButton(props: ILoginButtonProps) {
  const modalStore = useModalStore();
  const isSmall = useIsMobile('sm');

  return isSmall ? (
    <LogInIcon
      boxSize="30px"
      color={EColor.Black}
      cursor="pointer"
      data-testid="nav-sign-in-button"
      role="button"
      onClick={() => {
        modalStore.navigate({
          name: EModalContent.COLLECT_LOGIN,
          redirectUrl: window.location.href,
        });
      }}
    />
  ) : (
    <Button
      data-testid="nav-sign-in-button"
      onClick={() => {
        modalStore.navigate({
          name: EModalContent.COLLECT_LOGIN,
          redirectUrl: window.location.href,
        });
      }}
      {...props}
    >
      Log In
    </Button>
  );
}
