import { getFanaticsUrls } from '../../Navigation/utils/fanaticsUrls';
import { IAuctionBase, IAuctionParams } from '../types/auction';
import {
  TGetPremierAuctionsResponse,
  TPremierAuctionsData,
} from '../types/premierAuction';
import {
  TGetWeeklyAuctionsResponse,
  TWeeklyAuction,
  TWeeklyAuctionsData,
} from '../types/weeklyAuction';

const { icons } = getFanaticsUrls();

export const createAuctionParams = (params: IAuctionParams): string => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (!value) {
      return acc;
    }
    if (Array.isArray(value)) {
      return value.reduce(
        (a, v) => `${a}${a.length ? '&' : '?'}${key}=${v}`,
        acc
      );
    }
    return `${acc}${acc.length ? '&' : '?'}${key}=${value}`;
  }, '');
};

export const premierAuctionsArgsAdapter = (params: IAuctionParams) => {
  const { auctionStatus, ...restParams } = params;

  return {
    premier_auction_status: auctionStatus,
    sort: ['end_datetime', 'asc'],
    ...restParams,
  };
};

const convertDate = (date: string): Date =>
  new Date(date.replace('T', ' ').replace(/-/g, '/'));

export const weeklyAuctionsArgsAdapter = (params: IAuctionParams) => ({
  sort: ['end_date', 'asc'],
  ...params,
});

export const weeklyAuctionsDataAdapter = (
  response: TGetWeeklyAuctionsResponse
): TWeeklyAuctionsData => {
  const { monthlyAuctions } = response._embedded;

  return monthlyAuctions.map((monthlyAuction) => {
    const {
      id,
      name,
      startTime,
      endTime,
      extendedEndTime,
      status,
      auctionImageUrl,
      preview,
    } = monthlyAuction;

    return {
      id,
      name,
      startTime: convertDate(startTime),
      endTime: convertDate(endTime),
      extendedEndTime: convertDate(extendedEndTime),
      status,
      imageUrl: auctionImageUrl || icons.fanaticsCollectLogoImage,
      preview,
    };
  });
};

export const premierAuctionsDataAdapter = (
  response: TGetPremierAuctionsResponse
): TPremierAuctionsData => {
  const { premierAuctions } = response._embedded;

  return premierAuctions.map((premierAuction) => {
    const {
      id,
      name,
      startTime,
      endTime,
      extendedEndTime,
      auctionImageUrl,
      status,
    } = premierAuction;

    return {
      id,
      name,
      startTime: convertDate(startTime),
      endTime: convertDate(endTime),
      extendedEndTime: convertDate(extendedEndTime),
      status,
      imageUrl: auctionImageUrl || icons.fanaticsCollectLogoImage,
    };
  });
};

export const isWeeklyAuction = (
  auction: IAuctionBase
): auction is TWeeklyAuction => {
  return 'preview' in auction;
};
