import React from 'react';

import {
  Box,
  Button,
  Link,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react';

import { EGTMEvents, useGTMDataLayer } from '../../../../hooks';
import { FanaticsCollectLoginLogo } from '../../../../Icons';
import { useMonolithUrls } from '../../../../Navigation/hooks/useMonolithUrls';
import { generateMonolithUrl } from '../../../../Navigation/utils';
import { EButtonVariant, EColor, ETextVariant } from '../../../../Theme';
import { tracker } from '../../../../utilities/tracker';
import { useModalContext } from '../../../hooks/useModalContext';
import { useModalStore } from '../../../stores';
import { EModalContent } from '../../../types';

export const CollectLogin = () => {
  const { fanaticsCollectBaseUrl } = useModalContext();
  const { fanIdSupportUrl, fanaticsUrl } = useMonolithUrls();
  const modalStore = useModalStore();
  const loginLookup = modalStore.lookup<EModalContent.COLLECT_LOGIN>();
  const redirectUrl = loginLookup?.redirectUrl
    ? encodeURIComponent(loginLookup?.redirectUrl)
    : undefined;
  const loginPath = `/auth/login${
    redirectUrl ? `?redirectUrl=${redirectUrl}` : ''
  }`;
  const registerPath = `/auth/register${
    redirectUrl ? `?redirectUrl=${redirectUrl}` : ''
  }`;

  const loginUrl = generateMonolithUrl(fanaticsCollectBaseUrl, loginPath);
  const registerUrl = generateMonolithUrl(fanaticsCollectBaseUrl, registerPath);

  const pushGTMData = useGTMDataLayer();

  return (
    <>
      <ModalHeader alignItems="center" display="flex" minHeight="70px" p="24px">
        <FanaticsCollectLoginLogo height="46px" width="126px" />
      </ModalHeader>
      <ModalBody px="24px" py="8px">
        <Text pb="8px" variant={ETextVariant.LG}>
          Log in to Fanatics Collect
        </Text>
        <Text color={EColor.Neutral75} variant={ETextVariant.Small}>
          {'PWCC users should log in using their PWCC email. Users with '}
          <Link
            color={EColor.Black}
            href={fanaticsUrl}
            target="_blank"
            textDecoration="underline"
          >
            Fanatics.com
          </Link>
          {' accounts can log in with their email and password.'}
        </Text>

        <Box mt="6px">
          <Link
            color={EColor.Black}
            fontSize="14px"
            fontWeight={700}
            href={fanIdSupportUrl}
            lineHeight="16px"
            textDecoration="underline"
          >
            Help with Fanatics ID
          </Link>
        </Box>
      </ModalBody>

      <ModalFooter display="flex" flexDir="column" gap="8px" p="24px">
        <Button
          _hover={{
            color: EColor.Black,
          }}
          as="a"
          fontFamily="ABC Diatype"
          fontSize="14px"
          textTransform="none"
          variant={EButtonVariant.BRAND_SECONDARY}
          width="100%"
          onClick={() => {
            pushGTMData({ event: EGTMEvents.LOGIN });
            window.location.href = loginUrl;
          }}
        >
          Log in to Fanatics Collect
        </Button>

        <Button
          _hover={{
            color: EColor.White,
          }}
          as="a"
          fontFamily="ABC Diatype"
          fontSize="14px"
          textTransform="none"
          variant={EButtonVariant.BRAND_PRIMARY}
          width="100%"
          onClick={() => {
            pushGTMData({ event: EGTMEvents.CREATE_ACCOUNT });
            tracker.track('Sign Up Create Account Button Tapped', {});

            window.location.href = registerUrl;
          }}
        >
          Create Fanatics ID
        </Button>
      </ModalFooter>
    </>
  );
};
