import { EColor } from './colors';
import type { SystemStyleObject } from '@chakra-ui/react';
import type { Dict } from '@chakra-ui/utils';

export const styles = {
  global: () => {
    const config: Dict<SystemStyleObject> = {
      'html, body': {
        backgroundColor: EColor.Neutral2,
        WebkitTapHighlightColor: 'transparent',
      },
    };

    return config;
  },
} as const;
