import React, { FC } from 'react';

import { QueryClientProvider } from '@tanstack/react-query';

import { Search } from './Search';
import { defaultQueryClient } from '../utilities';

export interface ISearchBarProps {
  pastSearchBaseUrl: string;
}

export const SearchBar: FC<ISearchBarProps> = ({ pastSearchBaseUrl }) => {
  return (
    <QueryClientProvider client={defaultQueryClient}>
      <Search pastSearchBaseUrl={pastSearchBaseUrl} />
    </QueryClientProvider>
  );
};
