import React from 'react';

import { createIcon } from '@chakra-ui/react';

export const CartIcon = createIcon({
  displayName: 'CartIcon',
  viewBox: '0 0 20 20',
  path: (
    <>
      <path
        d="M16.2507 18.3333C16.941 18.3333 17.5007 17.7737 17.5007 17.0833C17.5007 16.393 16.941 15.8333 16.2507 15.8333C15.5603 15.8333 15.0007 16.393 15.0007 17.0833C15.0007 17.7737 15.5603 18.3333 16.2507 18.3333Z"
        fill="black"
      />
      <path
        d="M7.91732 18.3333C8.60767 18.3333 9.16732 17.7737 9.16732 17.0833C9.16732 16.393 8.60767 15.8333 7.91732 15.8333C7.22696 15.8333 6.66732 16.393 6.66732 17.0833C6.66732 17.7737 7.22696 18.3333 7.91732 18.3333Z"
        fill="black"
      />
      <path
        clipRule="evenodd"
        d="M7.91732 16.4583C7.57214 16.4583 7.29232 16.7381 7.29232 17.0833C7.29232 17.4285 7.57214 17.7083 7.91732 17.7083C8.2625 17.7083 8.54232 17.4285 8.54232 17.0833C8.54232 16.7381 8.2625 16.4583 7.91732 16.4583ZM6.04232 17.0833C6.04232 16.0478 6.88178 15.2083 7.91732 15.2083C8.95285 15.2083 9.79232 16.0478 9.79232 17.0833C9.79232 18.1188 8.95285 18.9583 7.91732 18.9583C6.88178 18.9583 6.04232 18.1188 6.04232 17.0833ZM16.2507 16.4583C15.9055 16.4583 15.6257 16.7381 15.6257 17.0833C15.6257 17.4285 15.9055 17.7083 16.2507 17.7083C16.5958 17.7083 16.8757 17.4285 16.8757 17.0833C16.8757 16.7381 16.5958 16.4583 16.2507 16.4583ZM14.3757 17.0833C14.3757 16.0478 15.2151 15.2083 16.2507 15.2083C17.2862 15.2083 18.1257 16.0478 18.1257 17.0833C18.1257 18.1188 17.2862 18.9583 16.2507 18.9583C15.2151 18.9583 14.3757 18.1188 14.3757 17.0833Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M0.833984 1.66665C0.833984 1.20641 1.20708 0.833313 1.66732 0.833313C3.31859 0.833313 4.27495 1.70979 4.71805 2.49998H18.334C18.5811 2.49998 18.8154 2.60964 18.9738 2.79936C19.1321 2.98907 19.1981 3.23927 19.1539 3.48238L17.4872 12.6491C17.4152 13.0453 17.0701 13.3333 16.6673 13.3333H4.35963C3.72317 13.3333 3.35536 13.4746 3.1669 13.6171C3.00846 13.7369 2.91732 13.8987 2.91732 14.1666C2.91732 14.4345 3.00846 14.5964 3.1669 14.7162C3.35536 14.8587 3.72317 15 4.35963 15H16.2507C16.7109 15 17.084 15.3731 17.084 15.8333C17.084 16.2935 16.7109 16.6666 16.2507 16.6666H4.35963C3.50916 16.6666 2.73915 16.4824 2.16152 16.0455C1.55387 15.5859 1.25065 14.9144 1.25065 14.1666C1.25065 13.4189 1.55387 12.7474 2.16152 12.2878C2.73915 11.8509 3.50916 11.6666 4.35963 11.6666H4.83547L3.3534 3.51522C3.27302 3.23818 2.84962 2.49998 1.66732 2.49998C1.20708 2.49998 0.833984 2.12688 0.833984 1.66665ZM5.16583 4.16665L6.52946 11.6666H15.9718L17.3355 4.16665H5.16583Z"
        fill="black"
        fillRule="evenodd"
      />
    </>
  ),
});
