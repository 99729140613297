import { ModalContentProps, ModalProps } from '@chakra-ui/react';

import { useModalStore } from '../stores';
import { EModalContent } from '../types';

interface IModalOptions {
  size: string;
  showCloseButton: boolean;
  dismisable?: boolean;
  modalContentProps?: ModalContentProps;
  modalProps?: Partial<ModalProps>;
}

const typeMap: Record<EModalContent, IModalOptions> = {
  [EModalContent.COMPLETE_PROFILE]: { size: 'md', showCloseButton: true },
  [EModalContent.ADD_SHIPPING_ADDRESS]: { size: 'md', showCloseButton: true },
  [EModalContent.SHIPPING_ADDRESS_ADDED]: { size: 'md', showCloseButton: true },
  [EModalContent.ADD_PAYMENT_METHOD]: { size: 'md', showCloseButton: true },
  [EModalContent.PAYMENT_METHOD_ADDED]: { size: 'md', showCloseButton: true },
  [EModalContent.MY_WALLET]: { size: 'full', showCloseButton: true },
  [EModalContent.CHOOSE_PAYMENT_METHOD]: {
    size: 'full',
    showCloseButton: false,
  },
  [EModalContent.ADD_CREDIT_CARD]: {
    size: 'full',
    showCloseButton: false,
  },
  [EModalContent.ADD_US_BANK_ACCOUNT]: {
    size: 'full',
    showCloseButton: false,
  },
  [EModalContent.DELETE_METHOD_CONFIRMATION]: {
    size: 'full',
    showCloseButton: false,
  },

  [EModalContent.COLLECT_LOGIN]: {
    size: 'md',
    showCloseButton: true,
    modalContentProps: {
      maxWidth: '446px',
      borderRadius: '8px',
    },
    modalProps: {
      closeOnOverlayClick: true,
    },
  },
  [EModalContent.COLLECT_COMPLETE_PROFILE]: {
    size: 'md',
    showCloseButton: false,
    dismisable: false,
    modalContentProps: {
      maxWidth: '363px',
    },
  },
  [EModalContent.COLLECT_VERIFY_PHONE_NUMBER]: {
    size: 'md',
    showCloseButton: false,
    dismisable: false,
    modalContentProps: {
      maxWidth: '363px',
    },
  },
  [EModalContent.COLLECT_ACCOUNT_CREATED]: {
    size: 'md',
    showCloseButton: false,
    dismisable: false,
    modalContentProps: {
      maxWidth: '363px',
    },
  },
  [EModalContent.COLLECT_VERIFY_EMAIL_ADDRESS]: {
    size: 'md',
    showCloseButton: true,
  },
};

export const useModalOptions = () => {
  const modalStore = useModalStore();
  const currentScreen = modalStore.lookup();

  return currentScreen
    ? typeMap[currentScreen.name]
    : { size: 'md', showCloseButton: true };
};
