import React from 'react';

import { Button, Flex, Text } from '@chakra-ui/react';

import { EButtonVariant, EColor, ETextVariant } from '../../../../Theme';
import { ModalContent, ModalHeader } from '../../../components';
import { useModalStore } from '../../../stores';
import { EModalContent } from '../../../types';
import { useDeletePaymentMethod } from '../api/deletePaymentMethod';

export const DeleteMethodConfirmation = () => {
  const modalStore = useModalStore();
  const paymentMethodId =
    modalStore.lookup<EModalContent.DELETE_METHOD_CONFIRMATION>()
      ?.paymentMethodId ?? '';

  const { mutateAsync: deletePaymentMethod, isPending: isMethodDeleting } =
    useDeletePaymentMethod();

  const handleBackToMyWallet = () => {
    modalStore.pop();
  };

  const handleDelete = async () => {
    try {
      await deletePaymentMethod(paymentMethodId);
    } finally {
      modalStore.pop();
    }
  };

  return (
    <ModalContent
      footer={
        <Flex
          alignItems="center"
          flexDir="column"
          gap="20px"
          mb="11px"
          width="100%"
        >
          <Button
            isDisabled={isMethodDeleting}
            isLoading={isMethodDeleting}
            variant={EButtonVariant.BRAND_PRIMARY}
            width="100%"
            onClick={handleDelete}
          >
            Delete
          </Button>
          <Button
            color={EColor.Neutral55}
            fontSize="14px"
            fontWeight="400"
            lineHeight="16px"
            textDecoration="underline"
            variant="link"
            onClick={handleBackToMyWallet}
          >
            Cancel
          </Button>
        </Flex>
      }
      footerProps={{ borderTop: 'none' }}
      header={
        <ModalHeader onClick={handleBackToMyWallet}>Are You Sure?</ModalHeader>
      }
    >
      <Text
        color={EColor.Neutral55}
        fontSize="24px"
        fontWeight="700"
        letterSpacing="-0.72px"
        lineHeight="28px"
        mt="12px"
        variant={ETextVariant.Paragraph6}
      >
        You are about to{' '}
        <Text as="span" color={EColor.Black}>
          delete
        </Text>{' '}
        this payment method.
      </Text>
    </ModalContent>
  );
};
