export enum EPaymentType {
  ACCOUNT_FUNDS = 'account_funds',
  CARD = 'card',
  BANK_ACCOUNT = 'us_bank_account',
  APPLE_PAY = 'applePay',
  GOOGLE_PAY = 'googlePay',
  WIRE = 'wire',
}

export enum ECardBrand {
  AMEX = 'amex',
  DISCOVER = 'discover',
  MASTERCARD = 'mastercard',
  VISA = 'visa',
  UNKNOWN = 'unknown',
}

interface ICardPaymentMethod {
  brand: ECardBrand;
  exp_month: number;
  exp_year: number;
  last4: string;
}

interface IBankAccountPaymentMethod {
  bank_name: string | null;
  last4: string;
}

export interface IPaymentMethod {
  id: string;
  type: EPaymentType;
  default?: boolean;
  card?: ICardPaymentMethod;
  us_bank_account?: IBankAccountPaymentMethod;
}

export interface IPaymentMethodsList {
  data: IPaymentMethod[];
}
