import { loadStripe, Stripe, StripeElementsOptions } from '@stripe/stripe-js';

import { EColor } from '../../../Theme';
import { Nullable } from '../../../utilities/types';

export const stripeOptions: StripeElementsOptions = {
  locale: 'en',
  currency: 'usd',
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Montserrat&display=swap',
    },
  ],
  loader: 'never',
  appearance: {
    theme: 'stripe',
    variables: {
      borderRadius: '5px',
      colorText: EColor.BrandDark,
      fontFamily: 'Montserrat',
      colorDanger: EColor.BrandDangerLight2,
      fontSizeBase: '16px',
    },
    rules: {
      '.Tab': {
        borderColor: EColor.BrandCorduroy,
      },
      '.Input': {
        borderColor: EColor.BrandCorduroy,
      },
      '.Input:focus': {
        borderColor: EColor.BrandCorduroy,
        boxShadow: 'none',
      },
      '.Input--empty': {
        borderColor: EColor.BrandLightGray,
      },
      '.Input::placeholder': {
        color: EColor.BrandLightGray,
      },
      '.Label': {
        color: EColor.BrandCorduroy,
        fontSize: '14px',
      },
    },
  },
};

export const getStripePromise = (
  stripeMarketplaceKey: string
): Promise<Nullable<Stripe>> | null => {
  const stripeMarketplacePromise = loadStripe(stripeMarketplaceKey);

  return stripeMarketplacePromise;
};
