import { EMarketplaceType } from '../../utilities';

declare global {
  interface Window {
    dataLayer: any;
    analytics: any;
  }
}

export enum EGTMEvents {
  SEARCH_BAR_CLICK = 'searchBarClick',
  SITE_SEARCH = 'siteSearch',
  NAVIGATION_CLICK = 'navigationClick',
  NAVIGATION_HOVER = 'navigationHover',
  BANNER_CLICK = 'bannerClick',

  LOGIN = 'logIn',
  LOGIN_ERROR = 'logInError',
  CREATE_ACCOUNT = 'createAccount',
  SIGN_UP_VIEW = 'signUpView',
  SIGN_UP_ERROR = 'signUpError',
  VERIFY_PHONE_VIEW = 'verifyPhoneView',
  VERIFY_PHONE_WRONG_CODE = 'verifyPhoneWrongCodeMessage',
  ACCOUNT_CREATED_VIEW = 'accountCreatedView',
  COMPLETE_PROFILE_VIEW = 'completeProfileView',
  ADD_ADDRESS_VIEW = 'addAddressView',
  ADD_ADDRESS_ERROR = 'addAddressErrors',
  ADDRESS_ADDED_VIEW = 'addressSavedView',
  ADD_PAYMENT_METHOD_VIEW = 'addPaymentMethodView',
  ADD_PAYMENT_METHOD_ERROR = 'addPaymentMethodErrors',
  PAYMENT_METHOD_ADDED_VIEW = 'paymentMethodSavedView',
  VERIFY_EMAIL_VIEW = 'verifyEmailView',
  RESEND_VERIFICATION_PRESSED = 'Resend verification pressed',
  I_HAVE_VERIFIED_PRESSED = "I've verified pressed",
  EMAIL_VERIFIED_VIEW = 'emailVerifiedView',
  PROFILE_COMPLETE_VIEW = 'profileCompleteView',

  LOGIN_MODAL_VIEWED = 'Log In Page Viewed',
  MANAGE_ACCOUNT_BUTTON_CLICKED = 'Manage Account Button Clicked',
  PROFILE_WALLET_BUTTON_CLICKED = 'Profile Wallet Button Clicked',
  PROFILE_WALLET_ADD_PAYMENT_BUTTON_CLICKED = 'Profile Wallet Add Payment Button Clicked',
  PROFILE_MANAGE_ACCOUNT_BUTTON_CLICKED = 'Profile Manage Account Button Clicked',
  PROFILE_BUTTON_CLICKED = 'Profile Button Clicked',
}

export enum ESearchQueryType {
  ITEM_CLICK = 'itemClick',
  RECOMMENDED_SEARCH = 'recommendedSearch',
  USER_SEARCH = 'userSearch',
  SEE_ALL = 'seeAll',
}

export interface IGTMData {
  event: EGTMEvents;

  // GA4
  authenticationState?: boolean;
  clickDestination?: string;
  clickListTotal?: string;
  clickPosition?: string;
  clickValue?: string;
  customerEmail?: string;
  fanaticsCollectUserId?: number;
  itemName?: string;
  marketplace?: 'weekly' | 'extended' | 'vault';
  navigationBreakdown?: string;
  pwccAccountId?: number;
  searchBarClicks?: number;
  searchQuery?: string;
  searchQueryType?: ESearchQueryType;
  searchResultClicks?: number;
  vaultAccountId?: string;

  // Segment
  'User ID'?: number;
  Marketplace?: EMarketplaceType;
}
