const mapMarketplaceType = (pathname: string) => {
  const map: Record<string, string> = {
    '/weekly-auction': 'WEEKLY',
    '/premier-auction': 'PREMIER',
    '/vault-marketplace': 'FIXED',
  };

  return map[pathname];
};

const createMapParamFn = (map: Record<string, string>) => {
  return (valueStr: string) =>
    valueStr
      .split('-')
      .map((value) => map[value])
      .filter(Boolean)
      .join(',');
};

const mapCategoryGroupParam = createMapParamFn({
  sportscards: 'Sports Cards',
  tcg: 'Trading Card Games',
  comics: 'Comics',
  videogames: 'Video Games',
  othercategories: 'Other',
});

type SubCategoryOptions = Array<{
  collect: string;
  monolith: string;
}>;

const createSubMap = (category: string, options: SubCategoryOptions) => {
  return options.reduce((acc, { collect, monolith }) => {
    // `baseball` => `Sports Cards > Baseball`
    acc[monolith] = `${category} > ${collect}`;

    return acc;
  }, {} as Record<string, string>);
};

const mapSubCategoryParam = createMapParamFn({
  ...createSubMap('Sports Cards', [
    { collect: 'Baseball', monolith: 'baseball' },
    { collect: 'Basketball', monolith: 'basketball' },
    { collect: 'Football', monolith: 'football' },
    { collect: 'Hockey', monolith: 'hockey' },
    { collect: 'Soccer', monolith: 'soccer' },
    { collect: 'Racing', monolith: 'racing' },
    { collect: 'Combat Sports', monolith: 'combat' },
    { collect: 'Golf', monolith: 'golf' },
  ]),
  ...createSubMap('Trading Card Games', [
    { collect: 'Pokémon (English)', monolith: 'pokemonenglish' },
    { collect: 'Pokémon (Japanese)', monolith: 'pokemonjapanese' },
    { collect: 'Pokémon (Other Languages)', monolith: 'pokemonotherlanguage' },
    { collect: 'Yu-gi-oh!', monolith: 'yugioh' },
    { collect: 'Magic The Gathering', monolith: 'mtg' },
    { collect: 'Collectible Card Games', monolith: 'ccg' },
  ]),
  ...createSubMap('Comics', [
    { collect: 'Golden Age (1938-1955)', monolith: 'comicsgoldenage' },
    { collect: 'Silver Age (1956-1969)', monolith: 'comicssilverage' },
    { collect: 'Bronze Age (1970-1979)', monolith: 'comicsbronzeage' },
    { collect: 'Modern Age (1980-Present)', monolith: 'comicsmodernage' },
    { collect: 'Marvel', monolith: 'comicsmarvel' },
    { collect: 'DC', monolith: 'comicsdc' },
    { collect: 'Other', monolith: 'comicsotherpublishers' },
  ]),
  ...createSubMap('Video Games', [
    { collect: 'Atari', monolith: 'videogamesatari' },
    { collect: 'Xbox', monolith: 'videogamesxbox' },
    { collect: 'Nintendo', monolith: 'videogamesnintendo' },
    { collect: 'Sega', monolith: 'videogamessega' },
    { collect: 'Playstation', monolith: 'videogamesplaystation' },
    { collect: 'Game Magazines & Guides', monolith: 'videogamesmags' },
    { collect: 'Other', monolith: 'videogamesotherpublishers' },
  ]),
  ...createSubMap('Other', [
    { collect: 'Memorabilia', monolith: 'memorabilia' },
    { collect: 'Wax', monolith: 'wax' },
    { collect: 'Tickets', monolith: 'tickets' },
    { collect: 'Photos', monolith: 'photos' },
  ]),
});

const mapGradingServiceParam = createMapParamFn({
  psa: 'PSA',
  beckett: 'BGS',
  sgc: 'SGC',
  cgc: 'CGC',
  mba: 'MBA',
  csg: 'CSG',
  ngc: 'NGC',
  pmg: 'PMG Banknote',
  wata: 'WATA',
  vga: 'VGA',
  other: 'Other Approved',
});

const mapEyeAppealParam = createMapParamFn({
  a: 'Eye Appeal - A',
  e: 'Eye Appeal - E',
  s: 'Eye Appeal - S',
});

const mapGreatPriceParam = createMapParamFn({
  1: 'true',
});

const mapPurchaseOptionsParam = createMapParamFn({
  2: 'allowOffers',
});

const mapYearParam = (yearStr: string) => {
  const [min, max] = yearStr.split('_');

  if (min && max) {
    return [min, max].join(',');
  }
};

const mapPriceParam = (yearStr: string) => {
  const [minInCents, maxInCents] = yearStr.split('_');

  if (minInCents && maxInCents) {
    return [Number(minInCents) / 100, Number(maxInCents) / 100].join(',');
  }
};

interface MappersMap {
  [monolithKey: string]: {
    collectKey: string;
    map: (value: string) => string | undefined;
  };
}

const mappersMap: MappersMap = {
  categorygroup: {
    collectKey: 'categoryGroup',
    map: mapCategoryGroupParam,
  },
  category: {
    collectKey: 'category',
    map: mapSubCategoryParam,
  },
  authenticator: {
    collectKey: 'gradingService',
    map: mapGradingServiceParam,
  },
  eye_appeal: {
    collectKey: 'eyeAppealGrade',
    map: mapEyeAppealParam,
  },
  year_range: {
    collectKey: 'year',
    map: mapYearParam,
  },
  min_max: {
    collectKey: 'price',
    map: mapPriceParam,
  },
  great_price: {
    collectKey: 'greatPrice',
    map: mapGreatPriceParam,
  },
  purchase_options: {
    collectKey: 'purchaseOption',
    map: mapPurchaseOptionsParam,
  },
};

export const mapMarketplaceUrlMonolithToCollect = (path: string) => {
  const url = new URL(path);

  const searchParams = Array.from(url.searchParams).reduce(
    (acc, [key, value]) => {
      const mapper = mappersMap[key];

      if (mapper) {
        const mapped = mapper.map(value);

        if (mapped) {
          acc.set(mapper.collectKey, mapped);
        }
      }

      return acc;
    },
    new URLSearchParams()
  );

  if (searchParams.size !== 0)
    searchParams.set('type', mapMarketplaceType(url.pathname));
  // searchParams.set('status', 'Live');

  url.search = searchParams.toString();

  return url;
};
