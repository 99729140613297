import React, { useContext } from 'react';

import { Flex, Box, Center, Collapse } from '@chakra-ui/react';
import { css, Global } from '@emotion/react';

import { EGTMEvents, useGTMDataLayer } from '../../hooks';
import { FanaticsCollectCardLogoDark } from '../../Icons/FanaticsCollectLogo';
import { NavigationContext } from '../../Navigation/context/NavigationContext';
import { EColor } from '../../Theme';
import { useSiteWideMessage } from '../hooks/useSiteWideMessage';

const styles = css`
  .site-message a {
    color: #282828;
    border-bottom: none !important;
    font-weight: 600;
    text-decoration: none;
  }

  .site-message a:hover {
    color: #282828;
    text-decoration: underline;
  }
`;

export interface ISiteWideMessageProps {
  messageOverride?: string;
}

export function SiteWideMessage({ messageOverride }: ISiteWideMessageProps) {
  const { fanaticsCollectBaseUrl } = useContext(NavigationContext);
  const { message } = useSiteWideMessage(
    fanaticsCollectBaseUrl,
    messageOverride
  );
  const pushGTMData = useGTMDataLayer();

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const tagName = (e.target as HTMLElement).localName;

    if (tagName === 'a') {
      const { href } = e.target as HTMLLinkElement;
      const text = message?.replace(/<\/?a\b[^><]*>/gi, '');

      pushGTMData({
        event: EGTMEvents.BANNER_CLICK,
        clickDestination: href,
        clickValue: text,
      });
    }
  };

  return (
    <Collapse in={!!message || !!messageOverride}>
      <Center
        background={EColor.Neutral5}
        className="site-message"
        color={EColor.BrandCorduroy}
        fontSize={{ base: '10px', md: '12px' }}
        fontWeight={500}
        minHeight="30px"
        paddingX="24px"
        textAlign="center"
      >
        <Global styles={styles} />

        <Flex alignItems="center" justifyContent="center">
          <FanaticsCollectCardLogoDark
            height={{ base: '24px', md: '16px' }}
            marginRight="8px"
          />
          <Box
            dangerouslySetInnerHTML={{ __html: message ?? '' }}
            onClick={handleClick}
          />
        </Flex>
      </Center>
    </Collapse>
  );
}
