import React, { FC, useEffect, useMemo, useState } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';

import { useAddPaymentMethod } from './api/addPaymentMethod';
import { getStripePromise, stripeOptions } from './helpers';
import { StripeFallback } from './StripeFallback';
import { StripeForm } from './StripeForm';
import { CautionThinIcon } from '../../../Icons';
import { ETextVariant } from '../../../Theme';
import { useModalContext } from '../../hooks/useModalContext';
import { EPaymentType } from '../../states/MyWallet/types';

interface IAddPaymentMethodProps {
  paymentTypes: EPaymentType[];
  onSuccess: () => void;
  onCancel?: () => void;
  showSetDefault?: boolean;
  submitButtonText?: string;
}

export const AddPaymentMethod: FC<IAddPaymentMethodProps> = ({
  paymentTypes,
  onSuccess,
  onCancel,
  showSetDefault = false,
  submitButtonText = '',
}) => {
  const [error, setError] = useState<string>();
  const { stripeMarketplaceKey } = useModalContext();
  const {
    data: addPaymentData,
    mutateAsync: addPaymentMethod,
    isPending: isLoading,
  } = useAddPaymentMethod();

  const stripePromise = useMemo(
    () =>
      stripeMarketplaceKey ? getStripePromise(stripeMarketplaceKey) : null,
    [stripeMarketplaceKey]
  );

  useEffect(() => {
    const createSetupIntent = async () => {
      try {
        setError('');

        await addPaymentMethod({
          paymentTypes,
        });
      } catch {
        setError('Something went wrong');
      }
    };

    createSetupIntent();
  }, [addPaymentMethod, paymentTypes]);

  if (isLoading) return <StripeFallback />;

  return (
    <>
      {addPaymentData?.clientSecret && (
        <Elements
          options={{
            ...stripeOptions,
            // @ts-expect-error
            clientSecret: addPaymentData.clientSecret,
          }}
          stripe={stripePromise}
        >
          <StripeForm
            showSetDefault={showSetDefault}
            submitButtonText={submitButtonText}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </Elements>
      )}
      {error && (
        <Flex alignItems="center" gap="10px" pb="12px" pt="16px">
          <CautionThinIcon />
          <Box>
            <Text variant={ETextVariant.Paragraph5}>{error}</Text>
          </Box>
        </Flex>
      )}
    </>
  );
};
