import React, { FC, useMemo } from 'react';

import { Box, Text } from '@chakra-ui/react';
import { useSwipeable } from 'react-swipeable';

import { SliderButton } from './SliderButton';
import { useIsMobile } from '../../../hooks';
import { BackIcon } from '../../../Icons';
import { EColor, ETextVariant } from '../../../Theme';
import { useSlider } from '../../hooks/useSlider';
import { IAuctionBase } from '../../types/auction';
import { AuctionItem } from '../AuctionItem';

interface ISliderProps {
  data: IAuctionBase[];
  baseUrl: string;
}

const SWIPE_CONFIG = { delta: 20, swipeDuration: Infinity, trackMouse: true };

export const Slider: FC<ISliderProps> = ({ data, baseUrl }) => {
  const {
    position,
    swipePosition,
    isNextItem,
    isPrevItem,
    sliderRef,
    containerRef,
    handleNextItem,
    handlePrevItem,
    swipingHandler,
    swipeEndHandler,
  } = useSlider(data.length);
  const isMobile = useIsMobile();

  const handlers = useSwipeable({
    onSwiped: swipeEndHandler,
    onSwiping: swipingHandler,
    ...SWIPE_CONFIG,
  });

  const isSliderDisabled = useMemo(
    () => !isNextItem && !isPrevItem,
    [isNextItem, isPrevItem]
  );

  return (
    <Box overflow="hidden" width="100%">
      <Box backgroundColor={EColor.Black} height="16px" mb="16px" width="8px" />
      <Box
        alignItems="center"
        display="flex"
        gap="8px"
        justifyContent="space-between"
        marginBottom="27px"
        ref={containerRef}
      >
        <Text
          as="h2"
          letterSpacing={{ base: 0 }}
          variant={isMobile ? ETextVariant.XL3 : ETextVariant.XL4}
        >
          Upcoming Auctions
        </Text>
        <Box display="flex" gap="8px">
          <SliderButton handleClick={handlePrevItem} isDisabled={!isPrevItem}>
            <BackIcon height="56px" width="56px" />
          </SliderButton>
          <SliderButton handleClick={handleNextItem} isDisabled={!isNextItem}>
            <BackIcon height="56px" transform="rotate(180deg)" width="56px" />
          </SliderButton>
        </Box>
      </Box>
      <Box
        data-testid="auctions-slider"
        transform={`translate(${
          isSliderDisabled ? 0 : swipePosition || position
        }px)`}
        transitionDuration={swipePosition ? '0.05s' : '0.3s'}
        userSelect="none"
        width="fit-content"
        {...handlers}
      >
        <Box display="flex" gap="30px" ref={sliderRef}>
          {data.map((item) => (
            <AuctionItem baseUrl={baseUrl} data={item} key={item.id} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
