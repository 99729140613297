export enum EColor {
  // Main Colors
  White = '#FFFFFF',
  Black = '#000000',
  Tomato = '#FF5555',
  Cherry = '#FF3333',
  FanaticsRed = '#FF0000',
  Corsa = '#DE0000',
  Lime = '#96F6A2',
  Blue50 = '#1D71ED',
  Blue100 = '#0B3E89',

  // Neutral Colors
  Neutral0 = '#FFFFFF',
  Neutral2 = '#F9F9F9',
  Neutral5 = '#F2F2F2',
  Neutral10 = '#E5E5E5',
  Neutral15 = '#D4D4D4',
  Neutral25 = '#BFBFBF',
  Neutral35 = '#A2A2A2',
  Neutral45 = '#8C8C8C',
  Neutral55 = '#737373',
  Neutral65 = '#595959',
  Neutral75 = '#4A4C4C',
  Neutral80 = '#313131',
  Neutral85 = '#262626',
  Neutral90 = '#121212',
  Neutral95 = '#0D0D0D',
  Neutral100 = '#000000',

  // Current Colors
  BrandLightGray = '#CDCDCD',
  BrandDark = '#282828',
  BrandCorduroy = '#666767',
  BrandDarkAlabaster = '#E9E9E9',
  BrandWarning = '#9B9B9B',
  // BrandDark with opacity 0.3
  BrandDarkOpacity = '#2828284c',
  Transparent = '#00000000',
  Gray5 = '#E0E0E0',
  Light3 = '#F0F0F0',
  DarkGreen = '#218838',
  DarkBlue = '#0F63B8',
  Gray500 = '#718096',
  MediumGreen = '#1F7F34',
  LightGreen = '#8bc53f',
  LightGreen2 = '#E2FAE7',
  Caution = '#FB1919',
  BrandDanger = '#EF4923',
  BrandDangerLight = '#ef492380',
  BrandDangerLight2 = '#FC5454',
  Red = '#EB5757',
  LightRed = '#FDDCDC',
  MediumRed = '#B6381B',
  LightBlue = '#139AD6',
  MediumBlue = '#3E8DBB',
  Purple = '#5420ca',
}

export enum EGradient {
  /**
   * @deprecated
   */
  GrayWhiteLinear = 'linear(to-r, #F2F3F4, #FFFFFF)',
  ErrorPageGradient = 'radial-gradient(50% 50% at 50% 50%, rgba(255, 218, 0, 0.60) 0%, rgba(255, 218, 0, 0.00) 100%)',
  FanaticsErrorGradient = 'radial-gradient(50% 50% at 50% 50%, rgba(107, 162, 243, 0.60) 0%, rgba(255, 255, 255, 0) 100%)',
}
