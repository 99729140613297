import React, { useMemo } from 'react';

import { Flex, Link, useBreakpointValue } from '@chakra-ui/react';

import { EColor } from '../../../Theme';
import { useMonolithUrls } from '../../hooks/useMonolithUrls';

export function AdminSection() {
  const { adminUrl, homeUrl } = useMonolithUrls();
  const onAdminPage = useMemo(() => window.location.href.includes('admin'), []);
  const exitAdminLabel = useBreakpointValue(
    { base: 'Exit', lg: 'Exit Admin' },
    { ssr: false }
  );

  return (
    <Flex justify="end">
      <Link
        _hover={{ opacity: 0.7 }}
        color={EColor.Black}
        fontSize="12px"
        fontWeight={600}
        href={onAdminPage ? homeUrl : adminUrl}
        mt={{ base: '2px', lg: '6px' }}
        textDecoration="underline"
      >
        {onAdminPage ? exitAdminLabel : 'Admin'}
      </Link>
    </Flex>
  );
}
