/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation webMarkOfferNotificationAsViewedCCMutation($input: CollectMarkOfferNotificationAsViewedInput!) {\n    collectMarkOfferNotificationAsViewed(input: $input) {\n      successful\n      messages {\n        field\n        message\n        code\n      }\n    }\n  }\n": types.WebMarkOfferNotificationAsViewedCcMutationDocument,
    "\n  mutation webResendWelcomeEmailCCMutation {\n    collectResendWelcomeEmail {\n    successful\n    messages {\n      field\n      message\n      code\n    }\n    result\n  }\n  }\n": types.WebResendWelcomeEmailCcMutationDocument,
    "\n  query webCurrentUserV2CCQuery {\n    collectCurrentUserV2 {\n      tenantId\n      email {\n        address\n        verified\n      }\n      firstName\n      lastName\n      phone {\n        number\n        status\n      }\n      collectPendingInvoicesCount\n      collectPremierAuctionApproved\n      kycStatus\n    }\n  }\n": types.WebCurrentUserV2CcQueryDocument,
    "\n  query webStripeKeyCCQuery {\n    collectCurrentUserV2 {\n      stripePublishableKey\n      tenantId\n    }\n  }\n": types.WebStripeKeyCcQueryDocument,
    "\n  query webUserOffersStatusCCQuery {\n    collectCurrentUserV2 {\n      tenantId\n      hasNewOffersFromSellers \n      hasNewOffersFromBidders \n    }\n  }\n": types.WebUserOffersStatusCcQueryDocument,
    "\n  query webCurrentUserV2PendingOffersCCQuery {\n    collectCurrentUserV2 {\n      tenantId\n      collectPendingOffers {\n        offersForSeller {\n          ...OfferData\n        }\n        counterOffersForSeller {\n          ...OfferData\n        }\n        counterOffersForBuyer {\n          ...OfferData\n        }\n      }\n    }\n  }\n": types.WebCurrentUserV2PendingOffersCcQueryDocument,
    "\n  fragment OfferData on CollectOfferData {\n    offerId\n    listingId\n    listingTitle\n    showNotification\n  }\n": types.OfferDataFragmentDoc,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation webMarkOfferNotificationAsViewedCCMutation($input: CollectMarkOfferNotificationAsViewedInput!) {\n    collectMarkOfferNotificationAsViewed(input: $input) {\n      successful\n      messages {\n        field\n        message\n        code\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation webMarkOfferNotificationAsViewedCCMutation($input: CollectMarkOfferNotificationAsViewedInput!) {\n    collectMarkOfferNotificationAsViewed(input: $input) {\n      successful\n      messages {\n        field\n        message\n        code\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation webResendWelcomeEmailCCMutation {\n    collectResendWelcomeEmail {\n    successful\n    messages {\n      field\n      message\n      code\n    }\n    result\n  }\n  }\n"): (typeof documents)["\n  mutation webResendWelcomeEmailCCMutation {\n    collectResendWelcomeEmail {\n    successful\n    messages {\n      field\n      message\n      code\n    }\n    result\n  }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query webCurrentUserV2CCQuery {\n    collectCurrentUserV2 {\n      tenantId\n      email {\n        address\n        verified\n      }\n      firstName\n      lastName\n      phone {\n        number\n        status\n      }\n      collectPendingInvoicesCount\n      collectPremierAuctionApproved\n      kycStatus\n    }\n  }\n"): (typeof documents)["\n  query webCurrentUserV2CCQuery {\n    collectCurrentUserV2 {\n      tenantId\n      email {\n        address\n        verified\n      }\n      firstName\n      lastName\n      phone {\n        number\n        status\n      }\n      collectPendingInvoicesCount\n      collectPremierAuctionApproved\n      kycStatus\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query webStripeKeyCCQuery {\n    collectCurrentUserV2 {\n      stripePublishableKey\n      tenantId\n    }\n  }\n"): (typeof documents)["\n  query webStripeKeyCCQuery {\n    collectCurrentUserV2 {\n      stripePublishableKey\n      tenantId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query webUserOffersStatusCCQuery {\n    collectCurrentUserV2 {\n      tenantId\n      hasNewOffersFromSellers \n      hasNewOffersFromBidders \n    }\n  }\n"): (typeof documents)["\n  query webUserOffersStatusCCQuery {\n    collectCurrentUserV2 {\n      tenantId\n      hasNewOffersFromSellers \n      hasNewOffersFromBidders \n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query webCurrentUserV2PendingOffersCCQuery {\n    collectCurrentUserV2 {\n      tenantId\n      collectPendingOffers {\n        offersForSeller {\n          ...OfferData\n        }\n        counterOffersForSeller {\n          ...OfferData\n        }\n        counterOffersForBuyer {\n          ...OfferData\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query webCurrentUserV2PendingOffersCCQuery {\n    collectCurrentUserV2 {\n      tenantId\n      collectPendingOffers {\n        offersForSeller {\n          ...OfferData\n        }\n        counterOffersForSeller {\n          ...OfferData\n        }\n        counterOffersForBuyer {\n          ...OfferData\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment OfferData on CollectOfferData {\n    offerId\n    listingId\n    listingTitle\n    showNotification\n  }\n"): (typeof documents)["\n  fragment OfferData on CollectOfferData {\n    offerId\n    listingId\n    listingTitle\n    showNotification\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;