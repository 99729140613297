import React, { FC, useRef } from 'react';

import { Box, BoxProps, Text, Image, Button } from '@chakra-ui/react';

import { useBackgroundAnimation } from './hooks';
import { ResponsiveContainer } from '../../Container/ResponsiveContainer';
import { useIsMobile } from '../../hooks';
import { getFanaticsUrls } from '../../Navigation/utils/fanaticsUrls';
import { EButtonVariant, EGradient, ETextVariant } from '../../Theme';
import { UpcomingEvents } from '../../UpcomingEvents';
import { maxWidths } from '../../utilities';
import {
  DEFAULT_BUTTON_TEXT,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_ERROR_TITLE,
} from '../constants';

const { icons } = getFanaticsUrls();

export interface IErrorPageProps extends BoxProps {
  statusCode?: number;
  title?: string;
  message?: string;
  fanaticsCollectBaseUrl?: string;
  auctionsBaseUrl?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  showEvents?: boolean;
  showButton?: boolean;
}

export const ErrorPage: FC<IErrorPageProps> = (props) => {
  const {
    statusCode,
    title = DEFAULT_ERROR_TITLE,
    message = DEFAULT_ERROR_MESSAGE,
    fanaticsCollectBaseUrl,
    auctionsBaseUrl,
    buttonText,
    onButtonClick,
    showEvents = true,
    showButton = true,
    ...restProps
  } = props;
  const mainBoxRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();

  const animation = useBackgroundAnimation(
    mainBoxRef?.current?.offsetWidth,
    mainBoxRef?.current?.offsetHeight
  );

  const isEvents = showEvents && fanaticsCollectBaseUrl && auctionsBaseUrl;

  const handleButtonClick = () => {
    if (buttonText && onButtonClick) {
      onButtonClick();
    } else if (fanaticsCollectBaseUrl) {
      window.location.href = fanaticsCollectBaseUrl;
    }
  };

  return (
    <Box
      animation={animation ? `${animation} 15s linear infinite` : ''}
      background={EGradient.FanaticsErrorGradient}
      backgroundRepeat="no-repeat"
      backgroundSize="200% 200%"
      display="flex"
      height="100%"
      justifyContent="center"
      ref={mainBoxRef}
      {...restProps}
    >
      <ResponsiveContainer
        justifyContent="center"
        maxWidth={maxWidths}
        px={{ base: '15px', md: '30px' }}
        py={
          isEvents
            ? { base: '40px', md: '72px' }
            : { base: '128px', md: '140px' }
        }
        w="100%"
      >
        <Box
          alignItems={{ base: 'start', md: 'center' }}
          display="flex"
          flexDirection={{
            base: 'column-reverse',
            md: 'row',
          }}
          gap={{ base: '32px', md: '12px' }}
          justifyContent="center"
          width="100%"
        >
          <Box flex={{ md: 'auto', lg: 1.5 }} mb="40px" width="100%">
            <Box maxWidth={{ base: '100%', md: '500px' }}>
              {statusCode === 404 && (
                <Text mb="8px" variant={ETextVariant.BaseBold}>
                  {statusCode}
                </Text>
              )}
              <Text
                as="h1"
                letterSpacing={{ base: 0 }}
                mb="16px"
                textTransform="capitalize"
                variant={isMobile ? ETextVariant.XL4 : ETextVariant.XL6}
              >
                {title}
              </Text>
              <Text
                lineHeight="24px"
                mb={{ md: '24px' }}
                variant={ETextVariant.BaseParagraph}
              >
                {message}
              </Text>
              {showButton && (
                <Box
                  display="flex"
                  flexDirection="column"
                  marginTop={{ base: '48px', md: 0 }}
                  width="fit-content"
                >
                  <Button
                    variant={EButtonVariant.BRAND_SECONDARY}
                    onClick={handleButtonClick}
                  >
                    {buttonText || DEFAULT_BUTTON_TEXT}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            flex={{ md: 'auto', lg: 1 }}
            justifyContent={{ md: 'flex-end', lg: 'flex-start' }}
            width="100%"
          >
            <Image
              alt="Fanatics Collect Modal Logo"
              height={{ base: '54px', md: '219px' }}
              src={icons.fanaticsCollectLogoSmallUrl}
              width={{ base: '58px', md: '230px' }}
            />
          </Box>
        </Box>
        {isEvents && (
          <Box width="100%">
            <UpcomingEvents
              auctionsBaseUrl={auctionsBaseUrl}
              baseUrl={fanaticsCollectBaseUrl}
            />
          </Box>
        )}
      </ResponsiveContainer>
    </Box>
  );
};
