import React, { FC } from 'react';

import { Box, Text } from '@chakra-ui/react';

import { EColor, ETextVariant } from '../../Theme';
import { TAuctionTopBadgeConfig } from '../utils/getAuctionConfig';

interface IAuctionBadgeProps {
  config: TAuctionTopBadgeConfig;
}

export const AuctionBadge: FC<IAuctionBadgeProps> = ({ config }) => {
  const { label, labelTransform, circleColor } = config;

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      maxWidth="100%"
      rowGap="2px"
    >
      <Box
        alignItems="center"
        backgroundColor={EColor.Black}
        display="flex"
        flexDirection="row"
        maxWidth="100%"
        paddingX="8px"
        paddingY="4px"
        width="fit-content"
      >
        <Box
          backgroundColor={circleColor}
          borderRadius="50%"
          height="12px"
          marginRight="4px"
          width="12px"
        />

        <Text
          color={EColor.White}
          fontFamily="ABC Diatype Mono"
          fontWeight={700}
          overflow="hidden"
          textOverflow="ellipsis"
          textTransform={labelTransform}
          variant={ETextVariant.XS}
          whiteSpace="pre"
        >
          {label}
        </Text>
      </Box>
    </Box>
  );
};
