import { useEffect, useMemo, useState } from 'react';

import axios from 'axios';

import { getMonolithUrls } from '../../Navigation/utils/monolithUrls';

export function useSiteWideMessage(
  fanaticsCollectBaseUrl?: string,
  messageDataOverride?: string
): {
  message?: string;
  isLoading: boolean;
} {
  const [message, setMessageData] = useState<string | undefined>();
  const { siteWideMessageUrl } = useMemo(
    () => getMonolithUrls(fanaticsCollectBaseUrl),
    [fanaticsCollectBaseUrl]
  );
  useEffect(() => {
    (async () => {
      try {
        if (messageDataOverride) {
          setMessageData(messageDataOverride);
        } else {
          const response = await axios.get(siteWideMessageUrl);
          if (Array.isArray(response.data)) {
            setMessageData(response.data[0]);
          } else {
            setMessageData(response.data);
          }
        }
      } catch (error) {
        setMessageData(messageDataOverride ?? '');
        console.error('Error fetching site wide message', error);
      }
    })();
  }, [siteWideMessageUrl, messageDataOverride]);

  return { message, isLoading: !message };
}
