import React, { FC } from 'react';

import { CloseButtonProps, ModalCloseButton } from '@chakra-ui/react';

import { EColor } from '../../Theme';

interface FullScreenCloseButtonProps extends CloseButtonProps {}

export const FullScreenCloseButton: FC<FullScreenCloseButtonProps> = (
  props
) => {
  return (
    <ModalCloseButton
      _hover={{ opacity: 0.7 }}
      color={EColor.Black}
      mb="4px"
      ml="18px"
      mt="18px"
      position="static"
      size="md"
      {...props}
    />
  );
};
