import React, { FC, useEffect } from 'react';

import { Text, Button, Flex } from '@chakra-ui/react';

import { EGTMEvents, useGTMDataLayer } from '../../../../hooks';
import { FanaticsCollectModalLogo } from '../../../../Icons';
import { EButtonVariant, EColor, ETextVariant } from '../../../../Theme';
import { ModalContent } from '../../../components';
import { useModalStore } from '../../../stores';
import { EModalContent } from '../../../types';

export const PaymentMethodAdded: FC = () => {
  const modalStore = useModalStore();
  const pushGTMData = useGTMDataLayer();

  const handleNavigateCompleteProfile = () => {
    modalStore.navigate({ name: EModalContent.COMPLETE_PROFILE });
  };

  useEffect(() => {
    pushGTMData({
      event: EGTMEvents.PAYMENT_METHOD_ADDED_VIEW,
    });
  }, [pushGTMData]);

  return (
    <ModalContent>
      <Flex flexDir="column">
        <FanaticsCollectModalLogo
          height="50px"
          mb="30px"
          mt="24px"
          width="50px"
        />
        <Text
          color={EColor.Black}
          fontSize="24px"
          mb="32px"
          variant={ETextVariant.XL}
        >
          Your Payment
          <br />
          Method is Saved
        </Text>
        <Button
          height="54px"
          variant={EButtonVariant.BRAND_PRIMARY}
          width="100%"
          onClick={handleNavigateCompleteProfile}
        >
          Next
        </Button>
      </Flex>
    </ModalContent>
  );
};
