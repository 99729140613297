import React, { useMemo } from 'react';

import {
  Accordion,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

import { Button } from '../../../Button';
import { CloseIcon, FanaticsCollectModalLogo } from '../../../Icons';
import { EColor } from '../../../Theme';
import { useMonolithUrls } from '../../hooks/useMonolithUrls';
import { INavigationItem } from '../../types/navigation';
import { getFanaticsUrls } from '../../utils/fanaticsUrls';
import { AccordionNavMenuItem } from '../NavMenuItems/AccordionNavMenuItem';

interface IMobileMainNavProps {
  navItems?: INavigationItem[];
  show: boolean;
  closeMenu: () => void;
}

export function MobileMainNav({
  navItems = [],
  show,
  closeMenu,
}: IMobileMainNavProps) {
  const cleanedNavItems = useMemo(
    () =>
      navItems?.map((item) => ({
        ...item,
        items: item?.items?.filter((subItem) => !subItem.hideInMobile),
      })),
    [navItems]
  );

  const { submissionUrl } = useMonolithUrls();
  const { fanaticsCollectUrl } = getFanaticsUrls();

  return (
    <Modal isOpen={show} motionPreset="slideInBottom" onClose={closeMenu}>
      <ModalOverlay />
      <ModalContent
        alignItems="center"
        background={EColor.White}
        marginY="0"
        maxWidth="100vw"
        minHeight="100vh"
        paddingY="20px"
        width="100vw"
      >
        <ModalCloseButton
          color={EColor.BrandDark}
          left="15px"
          paddingX="15px"
          paddingY="20px"
          position="absolute"
          top="40px"
        >
          <CloseIcon
            _hover={{ transform: 'scale(1.2)' }}
            boxSize="24px"
            transition=".2s"
          />
        </ModalCloseButton>
        <Button
          borderColor={EColor.Black}
          borderWidth="1px"
          data-testid="nav-submit-button"
          fontFamily="ABC Diatype Mono"
          fontSize={{ base: '14px' }}
          fontWeight="700"
          height="32px"
          href={submissionUrl}
          lineHeight="15px"
          marginX="2px"
          marginY="5px"
          paddingX="24px"
          paddingY="9px"
          position="absolute"
          right="25px"
          size="md"
          textTransform="uppercase"
          top="37px"
          transition="0.2s"
          width="92px"
        >
          Sell Now
        </Button>
        <ModalBody marginX="40px" width="100%">
          <Link href={fanaticsCollectUrl}>
            <FanaticsCollectModalLogo
              height="56px"
              left="-13px"
              marginTop="80px"
              position="relative"
            />
          </Link>
          <Accordion allowToggle marginTop="20px">
            {cleanedNavItems?.map((navItem) => (
              <AccordionNavMenuItem key={navItem.name} navItem={navItem} />
            ))}
          </Accordion>
          {/* <MarketingSection marginTop="30px" /> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
