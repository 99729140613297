export enum EBusinessCategory {
  Marketplace = 'marketplace',
  Vault = 'vault',
}

export interface IStripeError {
  message?: string;
  errors?: {
    cardNumber?: string[];
    expiredDate?: string[];
    cvc?: string[];
    countryId?: string[];
    zip?: string[];
  };
}
