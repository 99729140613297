import { useMutation } from '@tanstack/react-query';

import { useApi } from '../../../../ApiClient';
import { useModalContext } from '../../../hooks/useModalContext';

interface ISetDefaultData {
  clientSecret: string;
}

export const useSetDefaultPaymentMethod = () => {
  const apiClient = useApi();

  const { checkoutApiUrl } = useModalContext();

  return useMutation<ISetDefaultData, unknown, string>({
    mutationKey: ['setDefaultPaymentMethod'],
    mutationFn: async (defaultPaymentMethod) => {
      return apiClient!.patch<ISetDefaultData>(
        `${checkoutApiUrl}/checkout/customer`,
        { defaultPaymentMethod }
      );
    },
  });
};
