import React, { FC } from 'react';

import { Box, Button, Text } from '@chakra-ui/react';

import { EGTMEvents, useGTMDataLayer } from '../../../../hooks';
import { getMonolithUrls } from '../../../../Navigation/utils';
import { EColor, ETextVariant } from '../../../../Theme';
import { collectLogout } from '../../../../utilities/logout';
import { useModalContext } from '../../../hooks/useModalContext';

export const AccountLinks: FC = () => {
  const { fanaticsCollectBaseUrl, manageAccountUrl } = useModalContext();
  const pushGTMData = useGTMDataLayer();
  const { phoneNumberHelpUrl } = getMonolithUrls(fanaticsCollectBaseUrl);

  return (
    <Box lineHeight="12px" pt="12px">
      <Text
        as="span"
        color={EColor.Neutral65}
        lineHeight="16px"
        mr="3px"
        variant={ETextVariant.XS2}
      >
        Having trouble?
      </Text>

      {!!manageAccountUrl && (
        <Button
          color={EColor.Black}
          fontSize="10px"
          fontWeight={700}
          lineHeight="16px"
          minW="auto"
          mr="3px"
          textDecoration="underline"
          variant="link"
          onClick={() => {
            pushGTMData({ event: EGTMEvents.MANAGE_ACCOUNT_BUTTON_CLICKED });
            window.location.href = manageAccountUrl;
          }}
        >
          Manage account,
        </Button>
      )}

      <Button
        color={EColor.Black}
        fontSize="10px"
        fontWeight={700}
        lineHeight="16px"
        minW="auto"
        mr="3px"
        textDecoration="underline"
        variant="link"
        onClick={() => collectLogout(fanaticsCollectBaseUrl)}
      >
        Logout,
      </Button>

      <Text
        as="span"
        color={EColor.Neutral65}
        lineHeight="16px"
        mr="3px"
        variant={ETextVariant.XS2}
      >
        or
      </Text>

      <Button
        as="a"
        color={EColor.Black}
        display="inline"
        fontSize="10px"
        fontWeight={700}
        href={phoneNumberHelpUrl}
        lineHeight="16px"
        minW="auto"
        mr="3px"
        textDecoration="underline"
        variant="link"
        whiteSpace="pre-wrap"
      >
        Get help with your phone number
      </Button>
    </Box>
  );
};
