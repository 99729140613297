import { ETextVariant } from './config';
import type { SystemStyleObject } from '@chakra-ui/react';

export const textStyles: Record<ETextVariant, SystemStyleObject> = {
  [ETextVariant.XL6]: {
    fontSize: '72px',
    fontWeight: 700,
    lineHeight: '72px',
    letterSpacing: '-0.03em',
  },
  [ETextVariant.XL5]: {
    fontSize: '64px',
    fontWeight: 700,
    lineHeight: '64px',
    letterSpacing: { base: '-0.03em' },
  },
  [ETextVariant.XL4]: {
    fontSize: '56px',
    fontWeight: 700,
    lineHeight: '56px',
    letterSpacing: '-0.03em',
  },
  [ETextVariant.XL3]: {
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.03em',
  },
  [ETextVariant.XL2]: {
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '-0.03em',
  },
  [ETextVariant.XL]: {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '-0.03em',
  },
  [ETextVariant.LG]: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  [ETextVariant.BaseParagraph]: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '28px',
  },
  [ETextVariant.BaseBold]: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  [ETextVariant.BaseMono]: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '16px',
  },
  [ETextVariant.Small]: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  [ETextVariant.SmallBold]: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16px',
  },
  [ETextVariant.SmallMono]: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16px',
  },
  [ETextVariant.XS]: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  [ETextVariant.XSBold]: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
  },
  [ETextVariant.XSMono]: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
  },
  [ETextVariant.XS2]: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '10px',
  },
  [ETextVariant.XS2Legal]: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '10px',
  },
  [ETextVariant.XS2Bold]: {
    fontSize: '10px',
    fontWeight: 700,
    lineHeight: '14px',
  },
  [ETextVariant.XS2Mono]: {
    fontSize: '10px',
    fontWeight: 700,
    lineHeight: '12px',
  },

  // Current Styles
  [ETextVariant.Heading1]: {
    fontSize: { base: '17px', md: '38px' },
    fontWeight: 900,
    lineHeight: { base: '20.72px', md: '45px' },
    letterSpacing: { base: '0.5px', md: '0.02em' },
  },
  [ETextVariant.Heading2]: {
    fontSize: { base: '13px', md: '18px' },
    fontWeight: { base: 700, md: 900 },
    lineHeight: { base: '20px', md: '30px' },
    letterSpacing: '0.02em',
  },
  [ETextVariant.Heading3]: {
    fontSize: { base: '13px', md: '16px' },
    fontWeight: 900,
    lineHeight: '30px',
    letterSpacing: '0.02em',
  },
  [ETextVariant.Heading4]: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.02em',
  },
  [ETextVariant.Heading5]: {
    fontSize: { base: '10px', md: '12px' },
    fontWeight: 500,
    lineHeight: { base: '13px', md: '20px' },
    letterSpacing: { base: '0.01em', md: '0.02em' },
  },
  [ETextVariant.Heading6]: {
    fontSize: { base: '23px', md: '48px' },
    fontWeight: 900,
    lineHeight: { base: '28px', md: '48px' },
    letterSpacing: { base: '0.005em', md: '0.02em' },
  },
  [ETextVariant.Heading7]: {
    fontSize: '12px',
    fontWeight: { base: 700, md: 900 },
    lineHeight: { base: '20px', md: '16px' },
    letterSpacing: '0.02em',
  },
  [ETextVariant.Heading8]: {
    fontSize: { base: '13px', md: '16px' },
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.02em',
  },
  [ETextVariant.Heading9]: {
    fontSize: { base: '17px', md: '18px' },
    fontWeight: 900,
    lineHeight: '30px',
    letterSpacing: '0.02em',
  },
  [ETextVariant.Heading10]: {
    fontSize: '10px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.02em',
  },
  [ETextVariant.BodyX]: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '30px',
    letterSpacing: '0.01em',
  },
  [ETextVariant.Paragraph2]: {
    fontSize: { base: '13px', md: '18px' },
    fontWeight: { base: 500, md: 400 },
    lineHeight: { base: '21px', md: '30px' },
    letterSpacing: { base: '0.01em', md: '0.02em' },
  },
  [ETextVariant.Paragraph3]: {
    fontSize: { base: '13px', md: '16px' },
    fontWeight: { base: 500, md: 400 },
    lineHeight: { base: '21px', md: '30px' },
    letterSpacing: { base: '0.01em', md: '0.02em' },
  },
  [ETextVariant.Paragraph4]: {
    fontSize: '12px',
    fontWeight: { base: 300, md: 500 },
    lineHeight: '18px',
    letterSpacing: '0.01em',
  },
  [ETextVariant.Paragraph5]: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '130%',
    letterSpacing: '0.12px',
  },
  [ETextVariant.Paragraph6]: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.32px',
  },
};
