import React from 'react';

import { createIcon } from '@chakra-ui/react';

import { EColor } from '../Theme';

export const CheckmarkRoundedIcon = createIcon({
  displayName: 'CheckmarkRounded',
  viewBox: '0 0 38 38',
  path: (
    <svg fill="none" height="38" width="38" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="19"
        cy="19"
        fillOpacity="0.1"
        r="18"
        stroke={EColor.White}
        strokeWidth="2"
      />
      <path
        d="M12 20.0359L16.5474 25L26 14"
        stroke={EColor.White}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  ),
});
