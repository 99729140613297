import { EColor } from '../colors';
import type { ComponentSingleStyleConfig } from '@chakra-ui/react';

export const FormErrorConfig: ComponentSingleStyleConfig = {
  baseStyle: {
    text: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.02em',
      color: EColor.Corsa,
      textTransform: 'uppercase',
    },
  },
  variants: {
    brand: {
      text: {
        lineHeight: '16px',
        marginTop: '4px',
        paddingLeft: '15px',
        textTransform: 'initial',
      },
    },
  },
};
