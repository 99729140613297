import { SystemStyleObject } from '@chakra-ui/react';

import { EColor } from '../../Theme';

export const phoneInputStyles: SystemStyleObject = {
  '.PhoneInput': {
    display: 'block',
  },

  '.PhoneInputInput:not([value=""]), .PhoneInputInput:focus,': {
    borderColor: `${EColor.BrandCorduroy} !important`,
  },

  '.PhoneInputCountry': {
    position: 'absolute',
    width: '60px',
    height: '40px',
    justifyContent: 'center',
    zIndex: 2,
  },

  '.PhoneInputCountry:after': {
    content: '""',
    background: EColor.BrandLightGray,
    position: 'absolute',
    bottom: '15%',
    left: '60px',
    height: '70%',
    width: '1px',
  },

  '.PhoneInput ::-webkit-scrollbar': {
    width: '16px',
    borderRadius: '100px',
  },

  '.PhoneInput ::-webkit-scrollbar-thumb': {
    backgroundColor: EColor.BrandLightGray,
    border: '6px solid rgba(0, 0, 0, 0)',
    borderRadius: '100px',
    backgroundClip: 'padding-box',
    height: '50px',
  },
};
