import React, { FC, useMemo, useState } from 'react';

import { Flex, Link, Spinner, Text } from '@chakra-ui/react';

import { useAddPastSearch, useDeletePastSearch } from './api';
import { useAuth } from '../../Auth';
import { EGTMEvents, ESearchQueryType, useGTMDataLayer } from '../../hooks';
import { CloseIcon } from '../../Icons';
import { useMonolithUrls } from '../../Navigation/hooks/useMonolithUrls';
import { EColor } from '../../Theme';
import { getMarketplaceType } from '../../utilities';
import {} from '../hooks';
import { getMarketplaceSearchURL } from '../utils/helpers';

interface IPastSearchItemProps {
  term: string;
  pastSearchBaseUrl: string;
  isLast?: boolean;
  itemIndex?: number;
}

export const PastSearchItem: FC<IPastSearchItemProps> = ({
  term,
  pastSearchBaseUrl,
  isLast,
  itemIndex,
}) => {
  // use 'react state' to load state due to different versions of 'react-query' in different apps
  const [isDeleteSearchLoading, setIsDeleteSearchLoading] =
    useState<boolean>(false);
  const isOnMarketplace = getMarketplaceType();
  const monolithUrls = useMonolithUrls();
  const auth = useAuth();
  const isLoggedIn = !!auth?.profile;
  const pushGTMData = useGTMDataLayer();

  const url = useMemo(
    () => getMarketplaceSearchURL(term, monolithUrls, isOnMarketplace),
    [isOnMarketplace, monolithUrls, term]
  );

  const { mutateAsync: deleteSearch } = useDeletePastSearch(pastSearchBaseUrl);

  const { mutateAsync: addPastSearch } = useAddPastSearch(pastSearchBaseUrl);

  const deletePastSearch = async () => {
    setIsDeleteSearchLoading(true);

    try {
      await deleteSearch(term);
    } finally {
      setIsDeleteSearchLoading(false);
    }
  };

  const addPastSearchHandler = () => {
    pushGTMData({
      event: EGTMEvents.SITE_SEARCH,
      authenticationState: isLoggedIn,
      clickValue: term,
      clickPosition: String(itemIndex),
      fanaticsCollectUserId: auth?.profile?.userId,
      vaultAccountId: auth?.profile?.vaultAccountId,
      searchResultClicks: 1,
      searchQueryType: ESearchQueryType.ITEM_CLICK,
    });

    addPastSearch(term);
  };

  return (
    <Flex
      alignItems="center"
      borderBottom={isLast ? 'none' : '1px'}
      borderBottomColor={EColor.Neutral5}
      width="100%"
    >
      <Link
        _hover={{
          color: EColor.BrandDark,
          textDecoration: 'underline',
        }}
        alignItems="center"
        color={EColor.BrandDark}
        display="flex"
        flex={1}
        fontSize="12px"
        fontWeight={500}
        href={url}
        letterSpacing="0.02em"
        lineHeight="18px"
        paddingX={{ base: 0, lg: '6px ' }}
        paddingY={{ base: '13px', lg: '8px' }}
        onClick={addPastSearchHandler}
      >
        <Text marginBottom={0}>{term}</Text>
      </Link>

      {isDeleteSearchLoading ? (
        <Spinner color={EColor.BrandDark} marginRight="2px" size="sm" />
      ) : (
        <Flex
          _hover={{
            backgroundColor: EColor.Neutral5,
          }}
          alignItems="center"
          as="button"
          borderRadius="50%"
          height="20px"
          justifyContent="center"
          width="20px"
        >
          <CloseIcon color={EColor.BrandDark} onClick={deletePastSearch} />
        </Flex>
      )}
    </Flex>
  );
};
