const localDomainPortMap: Record<string, number> = {
  checkout: 3000,
  'sales-history': 3001,
};

const getBaseUrlParts = (fanaticsCollectBaseUrl: string) => {
  const baseUrlParts = fanaticsCollectBaseUrl.split('://');

  return baseUrlParts.length !== 2
    ? { protocol: 'https', baseUrl: baseUrlParts[0]?.replace('www.', '') }
    : {
        protocol: baseUrlParts[0],
        baseUrl: baseUrlParts[1]?.replace('www.', ''),
      };
};

export const generateMonolithUrl = (
  fanaticsCollectBaseUrl: string,
  path?: string,
  domain = 'www'
) => {
  const { protocol, baseUrl } = getBaseUrlParts(fanaticsCollectBaseUrl);
  const subDomain =
    (baseUrl.includes('pwccmp') || baseUrl.includes('fanaticsmp')) &&
    domain === 'www'
      ? ''
      : `${domain}.`;

  // if developing locally
  if (
    fanaticsCollectBaseUrl.includes('.test') ||
    fanaticsCollectBaseUrl.includes('localhost')
  ) {
    if (domain in localDomainPortMap) {
      return `http://localhost:${localDomainPortMap[domain]}${path}`;
    }
  }

  return `${protocol}://${subDomain}${baseUrl}${path}`;
};

export const getMonolithUrls = (baseUrl = 'fanaticscollect.com') => ({
  fanaticsLiveUrl: 'https://www.fanatics.live/home',
  fanaticsCollectUrl: 'https://fanaticscollect.com',
  cartUrl: generateMonolithUrl(baseUrl, '/cart'),
  buyUrl: generateMonolithUrl(baseUrl, '/how-to-buy'),
  sellUrl: generateMonolithUrl(baseUrl, '/how-to-sell'),
  gradeUrl: generateMonolithUrl(baseUrl, '/how-to-grade'),
  homeUrl: generateMonolithUrl(baseUrl, '/'),
  adminUrl: generateMonolithUrl(baseUrl, '/', 'admin'),
  loginUrl: generateMonolithUrl(baseUrl, '/login'),
  signupUrl: generateMonolithUrl(baseUrl, '/join'),
  collectLoginUrl: generateMonolithUrl(baseUrl, '/auth/login'),
  collectRegisterUrl: generateMonolithUrl(baseUrl, '/auth/register'),
  forgotPasswordUrl: generateMonolithUrl(baseUrl, '/password/reset'),
  weeklyAuctionUrl: generateMonolithUrl(baseUrl, '/weekly-auction'),
  weeklyAuctionItemsUrl: generateMonolithUrl(baseUrl, '/items'),
  premierAuctionUrl: generateMonolithUrl(baseUrl, '/premier-auction'),
  fixedPriceUrl: generateMonolithUrl(baseUrl, '/vault-marketplace'),
  premierAuctionApplicationUrl: generateMonolithUrl(
    baseUrl,
    '/premier-auction-application'
  ),
  updatePasswordUrl: generateMonolithUrl(baseUrl, '/password/change'),
  updatePhonedUrl: generateMonolithUrl(baseUrl, '/phone', 'members'),
  marketplaceAccountUrl: generateMonolithUrl(
    baseUrl,
    '/preferences#marketplace-card',
    'members'
  ),
  vaultAccountUrl: generateMonolithUrl(
    baseUrl,
    '/preferences#vault-card',
    'members'
  ),
  capitalAccountUrl: generateMonolithUrl(
    baseUrl,
    '/preferences#capital-card',
    'members'
  ),
  identityUrl: generateMonolithUrl(
    baseUrl,
    '/preferences#identity-card',
    'members'
  ),
  signedDocumentsUrl: generateMonolithUrl(baseUrl, '/documents', 'members'),
  termsAndConditionsUrl: generateMonolithUrl(baseUrl, '/documents', 'members'),
  emailSubscriptionUrl: generateMonolithUrl(
    baseUrl,
    '/preferences#email-card',
    'members'
  ),
  privacyPolicyUrl:
    'https://help.fanaticscollect.com/hc/en-us/articles/18433288548509-Privacy',
  termsOfServiceUrl: generateMonolithUrl(baseUrl, '/tos'),
  termsOfUseUrl:
    'https://help.fanaticscollect.com/hc/en-us/articles/18433319094045-Terms-of-Use',
  helpCenterUrl: 'https://help.fanaticscollect.com/hc/en-us',
  fanaticsUrl: 'http://fanatics.com',
  fanIdSupportUrl:
    'https://help.fanaticscollect.com/hc/en-us/articles/19622634971549-FanID-Support',
  cookiePolicyUrl:
    'https://help.fanaticscollect.com/hc/en-us/articles/18433744789789-Cookie-Policy',
  returnPolicyUrl: generateMonolithUrl(baseUrl, '/returns'),
  employeePolicyUrl:
    'https://help.fanaticscollect.com/hc/en-us/articles/18447239356957-Employee-policy',
  payInvoiceUrl: generateMonolithUrl(baseUrl, '/invoices', 'checkout'),
  submissionUrl: generateMonolithUrl(baseUrl, '/submissions/create', 'members'),
  faqUrl:
    'https://help.fanaticscollect.com/hc/en-us/articles/18941423574685-Frequently-Asked-Questions',
  dealerProgram: 'https://info.fanaticscollect.com/dealer-program-application',
  affiliates: 'https://info.fanaticscollect.com/affiliate-referral-page-buyer',
  adminUsersUrl: generateMonolithUrl(baseUrl, '/users', 'admin'),
  aboutTheVaultUrl:
    'https://help.fanaticscollect.com/hc/en-us/articles/19008715653661-The-Vault',
  salesHistoryUrl: generateMonolithUrl(baseUrl, '', 'sales-history'),
  marketIndicesUrl: generateMonolithUrl(baseUrl, '/market-indices'),
  definitiveGuidesUrl: generateMonolithUrl(baseUrl, '/definitive-guides'),
  newsroomUrl: generateMonolithUrl(baseUrl, '/newsroom'),
  eyeAppealUrl: generateMonolithUrl(baseUrl, '/eye-appeal'),
  marketplaceTenetsUrl:
    'https://help.fanaticscollect.com/hc/en-us/articles/18866648239133-Marketplace-Tenets',
  careersUrl: 'https://www.fanaticsinc.com/careers',
  contactUsUrl: generateMonolithUrl(baseUrl, '/contact-us'),
  memberSummaryUrl: generateMonolithUrl(baseUrl, '/', 'members'),
  memberVaultUrl: generateMonolithUrl(baseUrl, '/vault', 'members'),
  memberMySellingUrl: generateMonolithUrl(
    baseUrl,
    '/marketplace/my-selling/auctions',
    'members'
  ),
  memberMyBuyingUrl: generateMonolithUrl(
    baseUrl,
    '/marketplace/my-buying/bid-history/weekly-sunday-auction',
    'members'
  ),
  memberMyCapitalUrl: generateMonolithUrl(
    baseUrl,
    '/capital/advances',
    'members'
  ),
  memberMySubmissionsUrl: generateMonolithUrl(
    baseUrl,
    '/submissions',
    'members'
  ),
  memberMyAccountingUrl: generateMonolithUrl(baseUrl, '/invoices', 'members'),
  memberMyPreferencesUrl: generateMonolithUrl(
    baseUrl,
    '/preferences',
    'members'
  ),
  auctionScheduleUrl: generateMonolithUrl(
    baseUrl,
    '/api/marketplaces/calendar'
  ),
  siteWideMessageUrl: generateMonolithUrl(
    baseUrl,
    '/api/nav/site-wide-message'
  ),
  aboutTheMarketplaceUrl:
    'https://help.fanaticscollect.com/hc/en-us/articles/18433146043933-How-It-Works',
  aboutAuthentication: generateMonolithUrl(baseUrl, '/authentication'),
  marketplaceTermsAndConditionsUrl: generateMonolithUrl(
    baseUrl,
    '/marketplace-tos'
  ),
  vaultServicesAgreementUrl: generateMonolithUrl(
    baseUrl,
    '/vault-services-agreement'
  ),
  searchesAppUrl: generateMonolithUrl(baseUrl, '/apps/searches'),
  phoneNumberHelpUrl:
    'https://help.fanaticscollect.com/hc/en-us/articles/20280541507869-Login-Issues-Q-A',
  myBuyingUrl: generateMonolithUrl(
    baseUrl,
    '/marketplace/my-buying/bid-history/fixed-price',
    'members'
  ),
  mySellingUrl: generateMonolithUrl(baseUrl, '/marketplace/offers', 'members'),
});

export interface IMonolithUrls extends ReturnType<typeof getMonolithUrls> {}
export type IMonolithUrlKeys = keyof IMonolithUrls;
