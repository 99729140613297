import React, { ReactNode } from 'react';

import { Box, Flex, FlexProps, Link } from '@chakra-ui/react';

import { EGTMEvents, useGTMDataLayer } from '../../../hooks';
import { EColor } from '../../../Theme';

export interface IProfileDrawerSectionProps extends FlexProps {
  url?: string;
  arrowAlignment?: AlignSetting;
  children: ReactNode;
  height?: string;
  text?: string;
  showBadge?: boolean;
}

export function ProfileDrawerSection({
  children,
  url,
  arrowAlignment,
  height,
  text,
  showBadge,
  ...rest
}: IProfileDrawerSectionProps) {
  const pushGTMData = useGTMDataLayer();

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    pushGTMData({
      event: EGTMEvents.NAVIGATION_CLICK,
      navigationBreakdown: `Profile | ${text}`,
      clickValue: text,
    });
    rest.onClick?.(e);
  };

  return (
    <Flex
      _hover={{ backgroundColor: EColor.Neutral5, textDecoration: 'none' }}
      align="center"
      as={url ? Link : Box}
      fontFamily="ABC Diatype Mono"
      fontSize="12px"
      fontWeight="700"
      height={height || '40px'}
      href={url}
      justify="space-between"
      lineHeight="16px"
      paddingX={4}
      textTransform="uppercase"
      {...rest}
      onClick={handleClick}
    >
      {showBadge ? (
        <Flex alignItems="center" gap="6px">
          {children}
          <Box
            backgroundColor={EColor.Tomato}
            borderRadius="50%"
            bottom="1px"
            height="8px"
            position="relative"
            width="8px"
          />
        </Flex>
      ) : (
        children
      )}
    </Flex>
  );
}
