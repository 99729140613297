import { extendTheme, ThemeOverride } from '@chakra-ui/react';

export const fanaticsCollectTheme: ThemeOverride = {
  breakpoints: {
    sm: '320px',
    md: '768px',
    lg: '992px',
    xl: '1230px',
    '2xl': '1440px',
  },
  radii: {
    4: '4px',
  },
  fonts: {
    body: 'ABC Diatype, Helvetica, Arial, sans-serif',
    heading: 'ABC Diatype, Helvetica, Arial, sans-serif',
  },
  shadows: {
    1: '0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)',
    2: '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)',
    3: '0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16)',
    4: '0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)',
    5: '0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16)',
    6: '0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24)',
  },
  colors: {
    brand: {
      corduroy: '#666767',
      dark: '#282828',
      lightGray: '#CDCDCD',
      background: '#F2F2F2',
      darkAlabaster: '#E9E9E9',
      alabaster: '#F2F2F2',
      main: '#FFDD00',
      silver: '#C4C4C4',
      link: '#0F63B8',
      warning: '#9B9B9B',
    },
    heading: '#282828',
    main: '#BE2076',
    mainLightest: '#F9DCEB',
    mainDark: '#920060',
    dark: {
      50: '#B7B7B7',
      100: '#9B9B9B',
      200: '#7E7E7E',
      300: '#616161',
      400: '#454545',
      500: '#282828',
      600: '#232323',
      700: '#1E1E1E',
      800: '#191919',
      900: '#141414',
    },
  },
};

export default extendTheme(fanaticsCollectTheme);
