import { create } from 'zustand';

import { TCollectCompleteProfileFormData } from '../states/Collect/CollectCompleteProfile/schema';
import { EModalContent } from '../types/modalContent';

export type IModalState =
  | {
      name: EModalContent.COMPLETE_PROFILE;
    }
  | {
      name: EModalContent.ADD_SHIPPING_ADDRESS;
    }
  | {
      name: EModalContent.ADD_SHIPPING_ADDRESS;
    }
  | {
      name: EModalContent.SHIPPING_ADDRESS_ADDED;
    }
  | {
      name: EModalContent.ADD_PAYMENT_METHOD;
    }
  | {
      name: EModalContent.PAYMENT_METHOD_ADDED;
    }
  | {
      name: EModalContent.MY_WALLET;
    }
  | {
      name: EModalContent.CHOOSE_PAYMENT_METHOD;
    }
  | {
      name: EModalContent.ADD_CREDIT_CARD;
    }
  | {
      name: EModalContent.ADD_US_BANK_ACCOUNT;
    }
  | {
      name: EModalContent.DELETE_METHOD_CONFIRMATION;
      paymentMethodId: string;
    }
  | {
      name: EModalContent.COLLECT_LOGIN;
      redirectUrl?: string;
    }
  | {
      name: EModalContent.COLLECT_COMPLETE_PROFILE;
    }
  | {
      name: EModalContent.COLLECT_VERIFY_PHONE_NUMBER;
      data: TCollectCompleteProfileFormData;
    }
  | {
      name: EModalContent.COLLECT_ACCOUNT_CREATED;
    }
  | {
      name: EModalContent.COLLECT_VERIFY_EMAIL_ADDRESS;
    };

interface IModalStore {
  stack: IModalState[];
  lookup: <TContent extends EModalContent>() =>
    | ({ name: TContent } & IModalState)
    | undefined;
  navigate: (state: IModalState) => void;
  push: (state: IModalState) => void;
  replace: (state: IModalState) => void;
  pop: () => void;
  clear: () => void;
}

export const useModalStore = create<IModalStore>((set, get) => ({
  stack: [],
  lookup: <TContent extends EModalContent>() =>
    get().stack[get().stack.length - 1] as
      | ({ name: TContent } & IModalState)
      | undefined,
  navigate: (state: IModalState) =>
    set(({ stack }) => {
      const stateIndex = stack.findIndex(({ name }) => name === state.name);
      const source = stateIndex > -1 ? stack.slice(0, stateIndex) : stack;

      return { stack: [...source, state] };
    }),
  push: (state: IModalState) =>
    set(({ stack }) => ({ stack: [...stack, state] })),
  replace: (state: IModalState) =>
    set(({ stack }) => ({ stack: [...stack.slice(0, -1), state] })),
  pop: () => set(({ stack }) => ({ stack: stack.slice(0, -1) })),
  clear: () => set(() => ({ stack: [] })),
}));
