import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';

import {
  Modal as ChakraModal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

import { useIsMobile } from '../hooks';
import { FullScreenCloseButton, ModalCloseButton } from './components';
import { useModalOptions } from './hooks/useModalOptions';
import { useUpdateInfo } from './hooks/useUpdateInfo';

export interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const Modal: FC<IModalProps> = ({ isOpen, onClose, children }) => {
  const [isKeyboardOpened, setIsKeyboardOpened] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const modalOptions = useModalOptions();
  const { size, showCloseButton, dismisable = true } = modalOptions;
  const modalSize = size === 'full' && isMobile ? 'full' : 'md';
  const isFullModalSize = modalSize === 'full';
  const visualViewportHeightRef = useRef(visualViewport?.height ?? 0);

  useUpdateInfo();

  useEffect(() => {
    if (isMobile) {
      const resizeHandler = () => {
        setIsKeyboardOpened(
          (visualViewport?.height ?? 0) < visualViewportHeightRef.current
        );
      };

      visualViewport?.addEventListener('resize', resizeHandler);

      return () => {
        visualViewport?.removeEventListener('resize', resizeHandler);
      };
    }
  }, [isMobile]);

  const handleClose = () => {
    if (dismisable) {
      onClose();
    }
  };

  return (
    <ChakraModal
      autoFocus={false}
      blockScrollOnMount={!isKeyboardOpened}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      size={modalSize}
      onClose={handleClose}
      {...modalOptions.modalProps}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius="none"
        marginTop={isFullModalSize ? 0 : { base: '64px', md: '90px' }}
        maxWidth={isFullModalSize ? '100%' : '343px'}
        {...modalOptions.modalContentProps}
      >
        {showCloseButton &&
          (isFullModalSize ? (
            <FullScreenCloseButton />
          ) : (
            <ModalCloseButton
              left={isFullModalSize ? 0 : { base: 0, md: 'auto' }}
              right={isFullModalSize ? 0 : { base: 'auto', md: '-48px' }}
              top={{ base: '-40px', md: 0 }}
            />
          ))}

        {children}
      </ModalContent>
    </ChakraModal>
  );
};
