import { isClient } from '../../Auth/utils/env';
import { IMonolithUrls } from '../../Navigation/utils/monolithUrls';
import { EMarketplaceType } from '../../utilities';

const getMonolithMarketplaceUrl = (
  marketplace: EMarketplaceType,
  monolithUrls: IMonolithUrls
): string => {
  const map = {
    [EMarketplaceType.FIXED]: monolithUrls.fixedPriceUrl,
    [EMarketplaceType.WEEKLY]: monolithUrls.weeklyAuctionUrl,
    [EMarketplaceType.PREMIER]: monolithUrls.premierAuctionUrl,
  };

  return map[marketplace];
};

export const getMarketplaceSearchURL = (
  text: string,
  monolithUrls: IMonolithUrls,
  isOnMarketplace: EMarketplaceType | undefined
) => {
  const marketplace = isOnMarketplace || EMarketplaceType.WEEKLY;
  const url = getMonolithMarketplaceUrl(marketplace, monolithUrls);

  if (isClient() && isOnMarketplace) {
    const params = new URLSearchParams(window.location.search);
    params.set('q', text);
    // Reset to page 1
    params.delete('page');
    return `${url}/?${params.toString()}`;
  }
  return `${url}/?q=${encodeURIComponent(text)}`;
};
