import React from 'react';

import { createIcon } from '@chakra-ui/react';

import { EColor } from '../Theme';

export const CautionThinIcon = createIcon({
  path: (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="white" height="24" width="24" />
      <circle
        cx="12"
        cy="12"
        fill={EColor.White}
        r="8"
        stroke={EColor.Corsa}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M12 13L12 8"
        stroke={EColor.Corsa}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <circle cx="12" cy="16" fill={EColor.Corsa} r="1" />
    </svg>
  ),
});
