import React, { ReactNode } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

import { EColor } from '../Theme';

export interface ILiveSuperscriptProps extends BoxProps {
  children: ReactNode;
}

export function LiveSuperscript({ children, ...rest }: ILiveSuperscriptProps) {
  const isClosed = children?.toString().toLowerCase() === 'closed';
  return (
    <Box
      as="span"
      background={isClosed ? EColor.Neutral5 : 'brand.main'}
      borderColor={isClosed ? EColor.BrandCorduroy : 'black'}
      borderRadius="4"
      borderWidth="1px"
      color={isClosed ? EColor.BrandCorduroy : 'black'}
      fontSize="9px"
      fontWeight="700"
      height="15px"
      marginLeft="20px"
      paddingX="3px"
      textTransform="uppercase"
      {...rest}
    >
      {children}
    </Box>
  );
}
