import { useMutation } from '@tanstack/react-query';

import { useApi } from '../../../../../ApiClient';
import { useModalContext } from '../../../../hooks/useModalContext';

interface IUpdateUserArgs {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  optInSMS: boolean;
}

export interface IUpdateUserError {
  message?: string;
  errors?: {
    first_name?: string[];
    last_name?: string[];
    phone?: string[];
    opt_in_sms?: string[];
  };
}

const updateUserAdapter = (data: IUpdateUserArgs) => ({
  first_name: data.firstName,
  last_name: data.lastName,
  phone: data.phoneNumber,
  opt_in_sms: data.optInSMS,
});

export const useUpdateUser = () => {
  const apiClient = useApi();
  const { membersApiUrl } = useModalContext();

  return useMutation<void, unknown, IUpdateUserArgs>({
    mutationKey: ['collect-update-user'],
    mutationFn: async (args) => {
      await apiClient!.patch<void>(
        `${membersApiUrl}/members/v2/registration/user`,
        updateUserAdapter(args)
      );
    },
  });
};
