import React, { FC, ReactNode } from 'react';

import { Flex, IconButton, Text } from '@chakra-ui/react';

import { ArrowPrevIcon, FanaticsCollectModalLogo } from '../../Icons';
import { EColor, ETextVariant } from '../../Theme';

interface IModalHeaderProps {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
}

export const ModalHeader: FC<IModalHeaderProps> = ({
  children,
  onClick,
  className,
}) => {
  return (
    <Flex alignItems="center" flexWrap="wrap">
      {onClick ? (
        <IconButton
          _hover={{ bg: 'transparent' }}
          aria-label="Back button"
          bg="transparent"
          border="none"
          icon={<ArrowPrevIcon />}
          left="-12px"
          position="relative"
          onClick={onClick}
        />
      ) : (
        <FanaticsCollectModalLogo
          alignSelf="start"
          display="flex"
          height="36px"
          mr="12px"
          width="36px"
        />
      )}

      <Text color={EColor.Black} fontSize="20px" variant={ETextVariant.LG}>
        {children}
      </Text>
    </Flex>
  );
};
