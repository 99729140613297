import React, { ReactNode, useMemo } from 'react';

import { ApolloProvider as ApolloProviderClient } from '@apollo/client';

import { makeApolloClient } from '../utils';

interface ApolloProviderMaybeProps {
  graphqlApiUrl: string;
  children: ReactNode;
  skipProvider?: boolean;
}

export const ApolloProviderMaybe = ({
  graphqlApiUrl,
  children,
  skipProvider,
}: ApolloProviderMaybeProps) => {
  const client = useMemo(
    () => (!skipProvider ? makeApolloClient(graphqlApiUrl) : null),
    [skipProvider, graphqlApiUrl]
  );

  if (client) {
    return (
      <ApolloProviderClient client={client}>{children}</ApolloProviderClient>
    );
  }

  return <>{children}</>;
};
