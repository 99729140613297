import React, { FC } from 'react';

import { Flex, Text } from '@chakra-ui/react';

import { FooterLink } from './FooterLink';
import { FooterSocialLinks } from './FooterSocialLinks';
import { EColor, ETextVariant } from '../../../Theme';
import { maxWidths } from '../../../utilities';
import { useMonolithUrls } from '../../hooks/useMonolithUrls';
import { getFooterSocialLinks } from '../../hooks/useNavItems';

export const FooterCopyright: FC = () => {
  const { privacyPolicyUrl, termsOfUseUrl } = useMonolithUrls();
  const footerSocialLinks = getFooterSocialLinks();

  return (
    <Flex bg={EColor.Neutral5} justify="center" w="100%">
      <Flex
        align="center"
        flexDirection={{ base: 'column-reverse', md: 'row' }}
        justify="space-between"
        maxW={maxWidths}
        p="28px"
        rowGap="16px"
        w="100%"
      >
        <Text
          fontFamily="ABC Diatype Mono"
          textTransform="uppercase"
          variant={ETextVariant.XSMono}
          whiteSpace="nowrap"
        >
          © Fanatics Collect {new Date().getFullYear()}
        </Text>

        <FooterSocialLinks links={footerSocialLinks} />

        <Flex columnGap="16px">
          <FooterLink
            dense
            data-testid="footer-link-privacy-policy"
            href={privacyPolicyUrl}
            name="Privacy Policy"
            whiteSpace="nowrap"
          />

          <FooterLink
            dense
            data-testid="footer-link-terms-of-use"
            href={termsOfUseUrl}
            name="Terms of Use"
            whiteSpace="nowrap"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
