import React, { FC } from 'react';

import { Slider } from './Slider';
import { useGetAuctions } from '../hooks/useGetAuctions';
import { EAuctionStatus } from '../types/auction';

interface IUpcomingEventsContentProps {
  auctionsBaseUrl: string;
  baseUrl: string;
}

export const UpcomingEventsContent: FC<IUpcomingEventsContentProps> = ({
  auctionsBaseUrl,
  baseUrl,
}) => {
  const { auctionsData } = useGetAuctions(
    {
      auctionStatus: [EAuctionStatus.LIVE, EAuctionStatus.NOT_STARTED],
      size: 10,
    },
    auctionsBaseUrl
  );

  return auctionsData ? <Slider baseUrl={baseUrl} data={auctionsData} /> : null;
};
