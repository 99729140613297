import { useMutation } from '@tanstack/react-query';

import { useApi } from '../../../../ApiClient';
import { useModalContext } from '../../../hooks/useModalContext';

interface IVerifyPhoneResponseData {
  to: string;
  sid: string;
  channel: string;
  status: string;
  dateCreated: string;
  dateUpdated: string;
}

export const useResendCode = () => {
  const apiClient = useApi();
  const { membersApiUrl } = useModalContext();

  return useMutation<IVerifyPhoneResponseData | undefined, unknown, string>({
    mutationKey: ['collect-resend-code'],
    mutationFn: async (phoneNumber) => {
      const body = { to: phoneNumber, channel: 'sms' };

      return apiClient!.post<IVerifyPhoneResponseData>(
        `${membersApiUrl}/members/v2/registration/verify/phone`,
        body
      );
    },
  });
};
