import { createContext, useContext } from 'react';

export interface ICollectContext {
  isInCollectApp?: boolean;
  stickyTop?: string;
  onSearch?: (searchText: string) => void;
  onModalSearchOpenChange?: (isOpen: boolean) => void;
}

export const CollectContext = createContext<ICollectContext>({});

export const useCollectContext = () => useContext(CollectContext);
