import React, { FC, useEffect } from 'react';

import { Text, Button, Flex } from '@chakra-ui/react';

import { EGTMEvents, useGTMDataLayer } from '../../../../hooks';
import { FanaticsCollectModalLogo } from '../../../../Icons';
import { EButtonVariant, EColor, ETextVariant } from '../../../../Theme';
import { ModalContent } from '../../../components';
import { useModalStore } from '../../../stores/modal';
import { EModalContent } from '../../../types/modalContent';

export const ShippingAddressAdded: FC = () => {
  const modalStore = useModalStore();
  const pushGTMData = useGTMDataLayer();

  const handleNavigateCompleteProfile = () => {
    modalStore.navigate({ name: EModalContent.COMPLETE_PROFILE });
  };

  useEffect(() => {
    pushGTMData({
      event: EGTMEvents.ADDRESS_ADDED_VIEW,
    });
  }, [pushGTMData]);

  return (
    <ModalContent>
      <Flex align="center" flexDirection="column" gap="56px" mt="26px">
        <Flex align="center" flexDirection="row" gap="12px">
          <FanaticsCollectModalLogo
            height="45px"
            position="relative"
            width="45px"
          />
          <Text
            color={EColor.Black}
            fontSize="23px"
            textTransform="capitalize"
            variant={ETextVariant.XL}
          >
            Your Address is Saved
          </Text>
        </Flex>

        <Button
          fontFamily="ABC Diatype Mono"
          fontSize="16px"
          fontWeight={700}
          height="54px"
          lineHeight="16px"
          variant={EButtonVariant.BRAND_PRIMARY}
          w="100%"
          onClick={handleNavigateCompleteProfile}
        >
          Next
        </Button>
      </Flex>
    </ModalContent>
  );
};
