import React, { FC, useEffect, useRef, useState } from 'react';

import {
  Box,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  useOutsideClick,
} from '@chakra-ui/react';
import { RemoveScroll } from 'react-remove-scroll';

import { IPastSearches, PastSearches } from './PastSearches';
import { SearchInput } from './SearchInput';
import { EColor } from '../Theme';

interface IPopoverSearchProps {
  searchText: string;
  setSearchText: (value: string) => void;
  pastSearchBaseUrl: string;
  pastSearchesData: IPastSearches | undefined;
}

export const PopoverSearch: FC<IPopoverSearchProps> = ({
  searchText,
  setSearchText,
  pastSearchBaseUrl,
  pastSearchesData,
}) => {
  const [scrollDisabled, setScrollDisabled] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isPopoverOpened, setIsPopoverOpened] = useState<boolean>(false);
  const popoverRef = useRef(null);

  useEffect(() => {
    if (
      !!pastSearchesData?.allSearches.length &&
      isFocused &&
      searchText.length === 0
    ) {
      setIsPopoverOpened(true);
    }

    if (!pastSearchesData?.allSearches.length || searchText.length > 0) {
      setIsPopoverOpened(false);
    }
  }, [isFocused, pastSearchesData?.allSearches.length, searchText.length]);

  useOutsideClick({
    ref: popoverRef,
    handler: () => {
      setIsFocused(false);
      setIsPopoverOpened(false);
    },
  });

  return (
    <Popover
      matchWidth
      autoFocus={false}
      isOpen={isPopoverOpened}
      placement="bottom"
    >
      <Box ref={popoverRef} width="100%">
        <PopoverAnchor>
          <SearchInput
            pastSearchBaseUrl={pastSearchBaseUrl}
            searchText={searchText}
            setIsFocused={setIsFocused}
            setIsOpened={setIsPopoverOpened}
            setSearchText={setSearchText}
          />
        </PopoverAnchor>
        <RemoveScroll enabled={scrollDisabled} removeScrollBar={false}>
          <PopoverContent
            borderRadius="10px"
            boxShadow="0px 0px 50px 0px rgba(40, 40, 40, 0.20) !important" // important for override js-focus-visible global styles
            height="321px"
            minWidth="-webkit-fill-available"
            overflow="auto"
            sx={{
              '&::-webkit-scrollbar': {
                width: '16px',
                borderRadius: '100px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: EColor.BrandLightGray,
                border: '6px solid rgba(0, 0, 0, 0)',
                borderRadius: '100px',
                backgroundClip: 'padding-box',
              },
            }}
            onMouseEnter={() => setScrollDisabled(true)}
            onMouseLeave={() => setScrollDisabled(false)}
          >
            <PopoverBody height="100%" marginBottom="12px" padding="12px 0">
              <PastSearches pastSearchBaseUrl={pastSearchBaseUrl} />
            </PopoverBody>
          </PopoverContent>
        </RemoveScroll>
      </Box>
    </Popover>
  );
};
