import { useQuery } from '@tanstack/react-query';

import { useApi } from '../../ApiClient';
import { IAuctionsArgs, TUseQuery } from '../types/auction';
import {
  TGetPremierAuctionsResponse,
  TPremierAuctionsData,
} from '../types/premierAuction';
import {
  createAuctionParams,
  premierAuctionsArgsAdapter,
  premierAuctionsDataAdapter,
} from '../utils/helpers';
import { premierAuctionsKey } from '../utils/queryKeys';

export const useGetPremierAuctions: TUseQuery<
  TPremierAuctionsData,
  IAuctionsArgs
> = (args) => {
  const { params, auctionsBaseUrl, ...restArgs } = args;
  const apiClient = useApi();

  return useQuery({
    queryKey: premierAuctionsKey,
    queryFn: async (): Promise<TPremierAuctionsData | undefined> => {
      const convertedParams = premierAuctionsArgsAdapter(params);
      const urlParams = createAuctionParams(convertedParams);
      const data = await apiClient?.get<TGetPremierAuctionsResponse>(
        `${auctionsBaseUrl}/api/v1/premier-auctions${urlParams}`
      );

      return data ? premierAuctionsDataAdapter(data) : undefined;
    },
    enabled: !!apiClient,
    ...restArgs,
  });
};
