import { FieldValues } from 'react-hook-form';

import { createForm } from './Form';
import { createFormCheckbox } from './FormCheckbox';
import { FormControls } from './FormControls';
import { createFormDatePickerInput } from './FormDatePickerInput';
import { createFormFloatingPhoneInput } from './FormFloatingPhoneInput';
import { createFormNumberInput } from './FormNumberInput';
import { createFormRadioGroup } from './FormRadioGroup';
import { FormSection } from './FormSection';
import { createFormSelect } from './FormSelect';
import { createFormTextInput } from './FormTextInput';

export type { IFormTextInputProps } from './FormTextInput';
export type { IOption } from './FormRadioGroup';
export * from './FormSection';
export * from './FormControls';
export * from './Select';

export function createFormElements<TFieldValues extends FieldValues>() {
  return {
    Form: createForm<TFieldValues>(),
    FormCheckbox: createFormCheckbox<TFieldValues>(),
    FormTextInput: createFormTextInput<TFieldValues>(),
    FormSelect: createFormSelect<TFieldValues>(),
    FormNumberInput: createFormNumberInput<TFieldValues>(),
    FormRadioGroup: createFormRadioGroup<TFieldValues>(),
    FormDatePickerInput: createFormDatePickerInput<TFieldValues>(),
    FormSection,
    FormControls,
    FormFloatingPhoneInput: createFormFloatingPhoneInput<TFieldValues>(),
  };
}
