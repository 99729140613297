import { gql } from '../../../artifacts/gql';

export const getUserPendingOffers = gql(`
  query webCurrentUserV2PendingOffersCCQuery {
    collectCurrentUserV2 {
      tenantId
      collectPendingOffers {
        offersForSeller {
          ...OfferData
        }
        counterOffersForSeller {
          ...OfferData
        }
        counterOffersForBuyer {
          ...OfferData
        }
      }
    }
  }
`);

export const OfferData = gql(`
  fragment OfferData on CollectOfferData {
    offerId
    listingId
    listingTitle
    showNotification
  }
`);
