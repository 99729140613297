const booleanParser = (treatmentWithConfig) => {
  if (treatmentWithConfig.treatment === 'control') return false;
  if (
    treatmentWithConfig.treatment !== 'on' &&
    treatmentWithConfig.treatment !== 'off'
  ) {
    throw new Error('Treatment must be "on" or "off"');
  }
  return treatmentWithConfig.treatment === 'on';
};

const numberParser = (treatmentWithConfig, defaultValue) => {
  if (treatmentWithConfig.treatment === 'control') return defaultValue;
  return Number(treatmentWithConfig.treatment);
};

export const FeatureFlags = {
  WEB_ENABLE_PLAID_PA_APPROVAL: 'Web_Enable_Plaid_PA_Approval',
  WEB_ENABLE_SH_LINK_TO_CW_PDP: 'Web_Enable_SH_Link_To_CW_PDP',
  DATADOG_BROWSER_LOGS_SESSION_SAMPLE_RATE:
    'Datadog_Browser_Logs_Session_Sample_Rate',
  WEB_ENABLE_FETCH_PUBLISHABLE_KEY: 'Web_Enable_Fetch_Publishable_key',
};

export const TreatmentParsersMap = {
  [FeatureFlags.WEB_ENABLE_SH_LINK_TO_CW_PDP]: booleanParser,
  [FeatureFlags.WEB_ENABLE_PLAID_PA_APPROVAL]: booleanParser,
  [FeatureFlags.DATADOG_BROWSER_LOGS_SESSION_SAMPLE_RATE]: (treatment) =>
    numberParser(treatment, 50),
  [FeatureFlags.WEB_ENABLE_FETCH_PUBLISHABLE_KEY]: booleanParser,
};
