import React, { FC, useEffect } from 'react';

import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react';

import { PastSearches } from './PastSearches';
import { SearchInput } from './SearchInput';
import { EColor } from '../Theme';
import { useCollectContext } from '../collect';

interface IModalSearchProps {
  searchText: string;
  setSearchText: (value: string) => void;
  pastSearchBaseUrl: string;
}

export const ModalSearch: FC<IModalSearchProps> = ({
  searchText,
  setSearchText,
  pastSearchBaseUrl,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onModalSearchOpenChange } = useCollectContext();

  const handleModalSearchOpenChange = (open: boolean) => {
    if (onModalSearchOpenChange) {
      onModalSearchOpenChange(open);
    } else {
      // hide the Zendesk chat widget when the search modal is open
      const windowRef = window as any;

      if (windowRef.zE !== undefined) {
        try {
          if (open) {
            windowRef.zE('messenger', 'hide');
          } else {
            windowRef.zE('messenger', 'show');
          }
        } catch {
          // ignore
        }
      }
    }
  };

  const handleOpen = () => {
    onOpen();
    handleModalSearchOpenChange(true);
  };

  const handleClose = () => {
    onClose();
    handleModalSearchOpenChange(false);
  };

  return (
    <>
      <SearchInput
        isOpenModal={isOpen}
        openModal={handleOpen}
        pastSearchBaseUrl={pastSearchBaseUrl}
        searchText={searchText}
        setSearchText={setSearchText}
      />

      <Modal
        isOpen={isOpen}
        motionPreset="scale"
        size="full"
        onClose={handleClose}
      >
        <ModalContent>
          <ModalBody display="flex" flexDirection="column" marginTop="12px">
            <Flex alignItems="center" gap={{ base: '0px', md: '12px' }}>
              <Box
                _hover={{ backgroundColor: EColor.Neutral5 }}
                alignItems="center"
                as="button"
                borderRadius="10px"
                display="flex"
                height={{ base: '34px', md: '36px' }}
                justifyContent="center"
                left={{ base: '-12px', md: 0 }}
                position="relative"
                width={{ base: '34px', md: '36px' }}
                onClick={handleClose}
              >
                <ArrowBackIcon height="26px" width="26px" />
              </Box>

              <SearchInput
                closeModal={handleClose}
                isOpenModal={isOpen}
                pastSearchBaseUrl={pastSearchBaseUrl}
                searchText={searchText}
                setSearchText={setSearchText}
              />
            </Flex>

            {searchText.length === 0 && (
              <PastSearches pastSearchBaseUrl={pastSearchBaseUrl} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
