enum ECodeErrorsResponse {
  INVALID_CODE_RESPONSE_MESSAGE = 'Invalid code.',
  TIMED_OUT_CODE_RESPONSE_MESSAGE = 'Code has expired.',
}

export enum ECodeType {
  SMS = 'SMS',
  EMAIL = 'Email',
}

export const getCodeError = (type: ECodeType, text?: string) => {
  if (!text) return;

  switch (text) {
    case ECodeErrorsResponse.INVALID_CODE_RESPONSE_MESSAGE:
      return `Wrong ${type} code. Please try again.`;
    case ECodeErrorsResponse.TIMED_OUT_CODE_RESPONSE_MESSAGE:
      return `This ${type} code is expired.`;
    default:
      return text;
  }
};
