import React, { useState } from 'react';

import { ModalBody, VStack, Button, Text } from '@chakra-ui/react';

import { useMonolithUrls } from '../../Navigation/hooks/useMonolithUrls';

type MultipleOffersModalBodyProps = {
  type: 'seller' | 'buyer';
  viewHandler: (needAwait: boolean, needCloseModal: boolean) => Promise<void>;
};

export const MultipleOffersModalBody = ({
  type,
  viewHandler,
}: MultipleOffersModalBodyProps) => {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { myBuyingUrl, mySellingUrl } = useMonolithUrls();

  const viewOffersClickHandler = async () => {
    setIsRedirecting(true);
    await viewHandler(true, false);

    if (type === 'seller') {
      window.location.assign(mySellingUrl);
    } else {
      window.location.assign(myBuyingUrl);
    }
  };

  return (
    <ModalBody padding="0px 24px 24px 24px">
      <VStack spacing={6}>
        <Text>
          You have multiple offers pending. Review and respond before they
          expire.
        </Text>
        <Button
          colorScheme="dark"
          isLoading={isRedirecting}
          size="md"
          textTransform="uppercase"
          variant="solid"
          width="100%"
          onClick={viewOffersClickHandler}
        >
          VIEW MY {type === 'seller' ? 'SELLING' : 'BUYING'}
        </Button>
      </VStack>
    </ModalBody>
  );
};
