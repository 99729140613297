import { useState } from 'react';

import { isClient } from '../../Auth/utils/env';

export function useSearchQuery(): [string, (searchText: string) => void] {
  const params = isClient()
    ? new URLSearchParams(window.location.search)
    : undefined;
  const query = params?.get('q') ?? '';
  return useState<string>(query);
}
