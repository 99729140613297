import React, { useEffect } from 'react';

import { Button, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { useForm, useWatch } from 'react-hook-form';

import { useVerifyPhone, IVerifyPhoneError } from './api/verify-phone';
import { formatPhoneNumber } from './utils';
import { createFormElements } from '../../../../Form';
import { EGTMEvents, useGTMDataLayer } from '../../../../hooks';
import { EButtonVariant, EColor, ETextVariant } from '../../../../Theme';
import { tracker } from '../../../../utilities/tracker';
import { ModalContent, ModalHeader } from '../../../components';
import {
  TVerificationCodeData,
  VerificationCodeSchema,
} from '../../../lib/schema';
import { useModalStore } from '../../../stores/modal';
import { EModalContent } from '../../../types/modalContent';
import { ECodeType, getCodeError } from '../../../utils';
import { useResendCode } from '../api/resend-code';
import { AccountLinks } from '../shared/AccountLinks';

const { Form, FormTextInput } = createFormElements<TVerificationCodeData>();

export const CollectVerifyPhone = () => {
  const modalStore = useModalStore();
  const { phoneNumber, countryCode } =
    modalStore.lookup<EModalContent.COLLECT_VERIFY_PHONE_NUMBER>()?.data ?? {};

  const pushGTMData = useGTMDataLayer();

  const methods = useForm<TVerificationCodeData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(VerificationCodeSchema),
    defaultValues: { code: undefined },
  });

  const { setError, control } = methods;

  const code = useWatch({ name: 'code', control });

  const { mutateAsync: verifyPhone, isPending: isVerifyPhoneLoading } =
    useVerifyPhone();
  const { mutateAsync: resendCode, isPending: isResendCodeLoading } =
    useResendCode();

  const handleBackToRegistration = () => {
    modalStore.pop();
  };

  const handleResend = async () => {
    if (phoneNumber) {
      try {
        await resendCode(phoneNumber);
      } catch (error) {
        const isAxiosError = axios.isAxiosError(error);

        tracker.track('Resend Code Failed', {
          isAxiosError,
          errorString: JSON.stringify(
            isAxiosError ? error.response?.data : error
          ),
        });
      }
    }
  };

  const handleSubmit = async (data: TVerificationCodeData) => {
    try {
      await verifyPhone(data);

      pushGTMData({
        event: EGTMEvents.ACCOUNT_CREATED_VIEW,
      });
      tracker.track('Phone Number Verified', {
        success: true,
      });
      tracker.track('Registration Completed', {});

      modalStore.navigate({ name: EModalContent.COLLECT_ACCOUNT_CREATED });
    } catch (error) {
      const isAxiosError = axios.isAxiosError<IVerifyPhoneError>(error);

      if (isAxiosError) {
        const { message, errors } = error.response?.data ?? {};

        const errorMessage =
          getCodeError(ECodeType.SMS, errors?.code?.[0]) ??
          getCodeError(ECodeType.SMS, message) ??
          'Something went wrong';

        setError('code', {
          message: errorMessage,
        });
      }

      pushGTMData({
        event: EGTMEvents.VERIFY_PHONE_WRONG_CODE,
      });
      tracker.track('Phone Number Verified', {
        success: false,
      });
      tracker.track('Verify Phone Failed', {
        isAxiosError,
        errorString: JSON.stringify(
          isAxiosError ? error.response?.data : error
        ),
      });
    }
  };

  useEffect(() => {
    pushGTMData({
      event: EGTMEvents.VERIFY_PHONE_VIEW,
    });
    tracker.track('Phone Verification Screen Viewed', {});
  }, [pushGTMData]);

  return (
    <ModalContent
      header={
        <ModalHeader onClick={handleBackToRegistration}>
          Verify Phone Number
        </ModalHeader>
      }
    >
      <Text
        color={EColor.Black}
        lineHeight="26px"
        variant={ETextVariant.BaseParagraph}
      >
        We’ve sent a code to
      </Text>
      <Text
        color={EColor.Black}
        lineHeight="26px"
        mb="24px"
        variant={ETextVariant.BaseBold}
      >
        {formatPhoneNumber(phoneNumber ?? '', countryCode ?? '')}
      </Text>
      <Form
        {...methods}
        formStyles={{ lineHeight: '24px' }}
        onSubmit={handleSubmit}
      >
        <FormTextInput
          color={EColor.Black}
          controlProps={{ mb: '20px' }}
          inputMode="numeric"
          name="code"
          placeholder="Enter the verification code"
          variant="brand"
        />

        <Button
          fontFamily="ABC Diatype Mono"
          fontSize="16px"
          fontWeight={700}
          height="54px"
          isDisabled={!code}
          isLoading={isVerifyPhoneLoading || isResendCodeLoading}
          lineHeight="16px"
          mb="26px"
          mt="2px"
          type="submit"
          variant={EButtonVariant.BRAND_PRIMARY}
          width="100%"
        >
          Verify
        </Button>

        <Text mb="4px" variant={ETextVariant.Small}>
          Didn&apos;t receive your code?
          <Button
            color={EColor.Neutral55}
            fontSize="14px"
            fontWeight={400}
            lineHeight="16px"
            ml="8px"
            textDecoration="underline"
            variant="link"
            onClick={handleResend}
          >
            Resend
          </Button>
        </Text>

        <AccountLinks />
      </Form>
    </ModalContent>
  );
};
