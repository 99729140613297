import { createContext } from 'react';

import { INavigationItem } from '../types/navigation';

export interface INavigationContextProps {
  fanaticsCollectBaseUrl?: string;
  navItems?: INavigationItem[];
  isAdmin?: boolean;
  isSSR?: boolean;
}

export const NavigationContext = createContext<INavigationContextProps>({});

export const NavigationProvider = NavigationContext.Provider;
