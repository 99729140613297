import React, { FC, useEffect } from 'react';

import { useSearchQuery } from './hooks';
import { ModalSearch } from './ModalSearch';
import { useGetPastSearches } from './PastSearches';
import { PopoverSearch } from './PopoverSearch';
import { useAuth } from '../Auth';
import { useIsMobile } from '../hooks';

interface ISearchProps {
  pastSearchBaseUrl: string;
}

export const Search: FC<ISearchProps> = ({ pastSearchBaseUrl }) => {
  const [searchText, setSearchText] = useSearchQuery();
  const isMobile = useIsMobile('md');

  const auth = useAuth();
  const isLoggedIn = !!auth?.profile;

  const pathname =
    typeof window !== 'undefined' ? window.location.pathname : null;

  useEffect(() => {
    if (typeof window === 'undefined' || pathname === null) return;
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const query = params?.get('q') ?? '';
    // When changing the page without a search query, clear the search text
    if (!query) {
      setSearchText('');
    }
  }, [pathname, setSearchText]);

  const { data: pastSearchesData } = useGetPastSearches(
    pastSearchBaseUrl,
    isLoggedIn
  );
  return isMobile ? (
    <ModalSearch
      pastSearchBaseUrl={pastSearchBaseUrl}
      searchText={searchText}
      setSearchText={setSearchText}
    />
  ) : (
    <PopoverSearch
      pastSearchBaseUrl={pastSearchBaseUrl}
      pastSearchesData={pastSearchesData}
      searchText={searchText}
      setSearchText={setSearchText}
    />
  );
};
