import React, { useEffect, useMemo, useState } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import { PaymentMethod } from './PaymentMethod';
import { CautionThinIcon } from '../../../../Icons';
import { Loader } from '../../../../Loader';
import { EColor, ETextVariant } from '../../../../Theme';
import { useSetDefaultPaymentMethod } from '../../../components/AddPaymentMethod/api';
import { usePaymentMethods } from '../api';
// import { ECardBrand, EPaymentType } from '../types';
import { IPaymentMethod } from '../types';
import { getIsExpiredCard } from '../utils';

// const mockPaymenMethods = [
//   {
//     id: '0',
//     type: EPaymentType.CARD,
//     default: true,
//     card: {
//       brand: ECardBrand.VISA,
//       exp_month: 6,
//       exp_year: 2026,
//       last4: '4242',
//     },
//   },
//   {
//     id: '1',
//     type: EPaymentType.CARD,
//     default: false,
//     card: {
//       brand: ECardBrand.AMEX,
//       exp_month: 6,
//       exp_year: 2026,
//       last4: '5675',
//     },
//   },
//   {
//     id: '2',
//     type: EPaymentType.BANK_ACCOUNT,
//     default: true,
//     us_bank_account: {
//       bank_name: 'Chase Bank',
//       last4: '9999',
//     },
//   },
//   {
//     id: '3',
//     type: EPaymentType.CARD,
//     default: false,
//     card: {
//       brand: ECardBrand.AMEX,
//       exp_month: 6,
//       exp_year: 2023,
//       last4: '4392',
//     },
//   },
//   {
//     id: '4',
//     type: EPaymentType.CARD,
//     default: false,
//     card: {
//       brand: ECardBrand.AMEX,
//       exp_month: 6,
//       exp_year: 2026,
//       last4: '4392',
//     },
//   },
// ] satisfies IPaymentMethodInDto['data'];

export const PaymentMethodsList = () => {
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState<
    string | undefined
  >();
  const queryClient = useQueryClient();
  const { data: paymentMethodsData, isPending: isPaymentMethodsLoading } =
    usePaymentMethods();

  const {
    mutateAsync: setDefaultPaymentMethod,
    isPending: setDefaultPaymentMethodIsPending,
  } = useSetDefaultPaymentMethod();

  const handleSetDefault = async (paymentMethod: IPaymentMethod) => {
    try {
      await setDefaultPaymentMethod(paymentMethod.id);
      setDefaultPaymentMethodId(paymentMethod.id);
      queryClient.invalidateQueries({ queryKey: ['get-payment-methods'] });
    } catch {
      throw new Error("Couldn't set default payment method");
    }
  };

  const isValidPaymentExist = useMemo(() => {
    return paymentMethodsData?.data.some((paymentMethod) => {
      if (paymentMethod.card) {
        const { exp_month: expMonth, exp_year: expYear } = paymentMethod.card;
        return !getIsExpiredCard(expMonth, expYear);
      }
      return true;
    });
  }, [paymentMethodsData?.data]);

  const isDefaultPaymentMethodExpired = useMemo(() => {
    const defaultPaymentMethod = paymentMethodsData?.data.find(
      (item) => item.default
    );
    if (defaultPaymentMethod && defaultPaymentMethod.card) {
      return getIsExpiredCard(
        defaultPaymentMethod.card.exp_month,
        defaultPaymentMethod.card.exp_year
      );
    }
  }, [paymentMethodsData?.data]);

  useEffect(() => {
    const defaultMethod = paymentMethodsData?.data.find((item) => item.default);
    setDefaultPaymentMethodId(defaultMethod?.id);
  }, [paymentMethodsData?.data]);

  if (isPaymentMethodsLoading) {
    return <Loader />;
  }

  return (
    <Box>
      {!paymentMethodsData?.data.length && (
        <Flex alignItems="center" gap="10px" py="12px">
          <Text
            color={EColor.Neutral55}
            fontSize="24px"
            fontWeight="700"
            letterSpacing="-0.72px"
            lineHeight="28px"
            mt="24px"
            variant={ETextVariant.Paragraph6}
          >
            You need to{' '}
            <Text as="span" color={EColor.Black}>
              add a payment method
            </Text>{' '}
            before you can bid.
          </Text>
        </Flex>
      )}

      {!isValidPaymentExist &&
        paymentMethodsData &&
        paymentMethodsData.data.length > 0 && (
          <Flex alignItems="center" gap="10px" pb="12px" pt="12px">
            <CautionThinIcon height="24px" width="24px" />
            <Box>
              <Text variant={ETextVariant.Small}>
                You don’t have a valid payment method.
              </Text>
              <Text variant={ETextVariant.SmallBold}>
                Please add one to be able to bid.
              </Text>
            </Box>
          </Flex>
        )}

      {((!defaultPaymentMethodId && isValidPaymentExist) ||
        isDefaultPaymentMethodExpired) && (
        <Flex alignItems="center" gap="10px" pb="12px" pt="12px">
          <CautionThinIcon height="24px" width="24px" />
          <Text variant={ETextVariant.Small}>
            Please select a default payment method in order to bid.
          </Text>
        </Flex>
      )}

      {isValidPaymentExist &&
        defaultPaymentMethodId &&
        !isDefaultPaymentMethodExpired && (
          <Text color={EColor.Neutral55} my="18px" variant={ETextVariant.Small}>
            Select your default payment method.
          </Text>
        )}

      <Flex
        flexDir="column"
        gap="8px"
        opacity={setDefaultPaymentMethodIsPending ? 0.4 : 1}
      >
        {paymentMethodsData?.data.map((item) => (
          <PaymentMethod
            isDefault={defaultPaymentMethodId === item.id}
            item={item}
            key={item.id}
            setDefaultPaymentMethod={handleSetDefault}
          />
        ))}
      </Flex>
    </Box>
  );
};
