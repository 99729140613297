import React, { FC, useEffect, useMemo } from 'react';

import { Button, Flex, Link, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { useForm, useWatch } from 'react-hook-form';

import { IUpdateUserError, useUpdateUser } from './api/update-user';
import {
  CollectCompleteProfileSchema,
  TCollectCompleteProfileFormData,
} from './schema';
import { createFormElements } from '../../../../Form';
import { EGTMEvents, useGTMDataLayer } from '../../../../hooks';
import { useMonolithUrls } from '../../../../Navigation/hooks/useMonolithUrls';
import { EButtonVariant, EColor, ETextVariant } from '../../../../Theme';
import { tracker } from '../../../../utilities/tracker';
import { ModalContent, ModalHeader } from '../../../components';
import { parsePhone } from '../../../lib/parsers';
import { useModalStore, useProfileStore } from '../../../stores';
import { EModalContent } from '../../../types';
import { useResendCode } from '../api/resend-code';
import { AccountLinks } from '../shared/AccountLinks';

const { Form, FormTextInput, FormFloatingPhoneInput, FormCheckbox } =
  createFormElements<TCollectCompleteProfileFormData>();

export const CollectCompleteProfile: FC = () => {
  const modalStore = useModalStore();
  const { profile } = useProfileStore();
  const pushGTMData = useGTMDataLayer();
  const { privacyPolicyUrl, termsOfUseUrl } = useMonolithUrls();
  const { mutateAsync: updateUser, isPending: isUpdateUserLoading } =
    useUpdateUser();
  const { mutateAsync: resendCode, isPending: isResendCodeLoading } =
    useResendCode();

  const defaultValues = useMemo(() => {
    const { phoneNumber = '', countryCode = 'US' } =
      parsePhone(profile?.phone ?? '') ?? {};

    return {
      firstName: profile?.firstName ?? '',
      lastName: profile?.lastName ?? '',
      phoneNumber,
      countryCode,
    };
  }, [profile?.firstName, profile?.lastName, profile?.phone]);

  const methods = useForm<TCollectCompleteProfileFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(CollectCompleteProfileSchema),
    defaultValues,
  });
  const { control, setError } = methods;

  const values = useWatch({
    control,
  });

  const handleSubmit = async (data: TCollectCompleteProfileFormData) => {
    try {
      tracker.track('Complete Profile Continue Tapped', {});

      await updateUser(data);
      await resendCode(data.phoneNumber);

      tracker.track('Navigate to Collect Verify Phone Number', {
        firstName: data.firstName,
        lastName: data.lastName,
        hasPhone: !!data.phoneNumber,
        countryCode: data.countryCode,
        optInSMS: data.optInSMS,
      });
      modalStore.navigate({
        name: EModalContent.COLLECT_VERIFY_PHONE_NUMBER,
        data,
      });
    } catch (error) {
      const isAxiosError = axios.isAxiosError<IUpdateUserError>(error);

      if (isAxiosError) {
        const { errors, message } = error.response?.data ?? {};

        if (errors?.first_name) {
          setError('firstName', {
            message: errors.first_name[0],
          });
        }

        if (errors?.last_name) {
          setError('lastName', {
            message: errors.last_name[0],
          });
        }

        if (errors?.phone) {
          setError('phoneNumber', {
            message: errors.phone[0],
          });
        }

        if (!errors?.first_name && !errors?.last_name && !errors?.phone) {
          setError('phoneNumber', {
            message: message ?? 'Cannot verify phone number',
          });
        }
      }

      pushGTMData({
        event: EGTMEvents.SIGN_UP_ERROR,
      });

      tracker.track('Collect Complete Profile Failed', {
        isAxiosError,
        errorString: JSON.stringify(
          isAxiosError ? error.response?.data : error
        ),
      });
    }
  };

  const handleEnter = (
    event: React.KeyboardEvent<HTMLInputElement> & React.BaseSyntheticEvent
  ) => {
    if (event.code === 'Enter') {
      const { form } = event.target;

      if (form) {
        const index = [...form].indexOf(event.target);
        form[index + 1].focus();
        event.preventDefault();
      }
    }
  };

  const handleInvalid = () => {
    pushGTMData({
      event: EGTMEvents.SIGN_UP_ERROR,
    });
  };

  useEffect(() => {
    pushGTMData({
      event: EGTMEvents.SIGN_UP_VIEW,
    });
    tracker.track('Complete Profile Screen Viewed', {});
  }, [pushGTMData]);

  const isSubmitButtonDisabled = useMemo(
    () => !values.firstName || !values.lastName || !values.phoneNumber,
    [values]
  );

  return (
    <ModalContent header={<ModalHeader>Complete Your Profile</ModalHeader>}>
      <Form
        {...methods}
        formStyles={{ lineHeight: '24px' }}
        onInvalid={handleInvalid}
        onSubmit={handleSubmit}
      >
        <Flex flexDir="column" gap="16px">
          <Flex gap="15px">
            <FormTextInput
              autoComplete="given-name"
              name="firstName"
              placeholder="First"
              variant="brand"
              onKeyUp={handleEnter}
            />
            <FormTextInput
              autoComplete="family-name"
              name="lastName"
              placeholder="Last"
              variant="brand"
              onKeyUp={handleEnter}
            />
          </Flex>

          <FormFloatingPhoneInput
            countryCodeName="countryCode"
            name="phoneNumber"
            placeholder="Phone Number"
          />
        </Flex>

        <FormCheckbox mt="16px" name="optInSMS">
          <Text color={EColor.Neutral55} variant={ETextVariant.XS}>
            {
              'By checking this box, you agree to receive up to 6 promotional texts per month from Fanatics Collect. Message & data rates may apply. Reply STOP to opt-out. See our '
            }
            <Link
              color={EColor.Black}
              href={privacyPolicyUrl}
              target="_blank"
              textDecoration="underline"
            >
              Privacy Policy
            </Link>
            {' and '}
            <Link
              color={EColor.Black}
              href={termsOfUseUrl}
              target="_blank"
              textDecoration="underline"
            >
              Terms of Use
            </Link>
            {' for details.'}
          </Text>
        </FormCheckbox>

        <Button
          fontFamily="ABC Diatype Mono"
          fontSize="16px"
          fontWeight="700"
          height="54px"
          isDisabled={isSubmitButtonDisabled}
          isLoading={isUpdateUserLoading || isResendCodeLoading}
          lineHeight="16px"
          type="submit"
          variant={EButtonVariant.BRAND_PRIMARY}
          width="100%"
        >
          Continue
        </Button>

        <AccountLinks />
      </Form>
    </ModalContent>
  );
};
