import { EColor } from '../colors';
import type { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const FormConfig: ComponentMultiStyleConfig = {
  parts: ['container'],
  variants: {
    floating: {
      container: {
        _focusWithin: {
          label: {
            transform: 'scale(0.85) translateY(-25px)',
          },
        },
        'input:not([value=""]) + label, input:-webkit-autofill + label, textarea:not(:placeholder-shown) ~ label, .chakra-select__wrapper + label':
          {
            transform: 'scale(0.85) translateY(-25px)',
            color: EColor.BrandCorduroy,
          },
        'input:focus + label': {
          color: EColor.BrandCorduroy,
        },
        'input[aria-invalid=true]:focus + label, input[aria-invalid=true]:not([value=""]) + label, input[aria-invalid=true]:-webkit-autofill + label':
          {
            color: EColor.BrandDanger,
          },
        '.chakra-select__wrapper + label[data-invalid]': {
          color: EColor.BrandDanger,
        },
        label: {
          color: EColor.Neutral35,
          top: '2px',
          left: 0,
          zIndex: 2,
          position: 'absolute',
          backgroundColor: EColor.White,
          pointerEvents: 'none',
          mx: 3,
          px: 1,
          my: 2,
          transformOrigin: 'left top',
          letterSpacing: '0.32px',
        },
      },
    },
  },
  baseStyle: {
    container: {
      p: '1px',
    },
  },
};
