import { cssVar, type ComponentSingleStyleConfig } from '@chakra-ui/react';

import { EColor } from '../colors';

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

export const SkeletonConfig: ComponentSingleStyleConfig = {
  baseStyle: {
    [$startColor.variable]: EColor.BrandDarkAlabaster,
    [$endColor.variable]: EColor.Neutral2,
    borderRadius: '4px',
  },
};
