import React, { FC } from 'react';

import { Center, CenterProps, Spinner } from '@chakra-ui/react';

interface ILoaderProps extends CenterProps {}

export const Loader: FC<ILoaderProps> = (props) => {
  return (
    <Center pt="8px" {...props}>
      <Spinner size="lg" />
    </Center>
  );
};
