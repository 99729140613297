import { useContext, useMemo } from 'react';

import { NavigationContext } from '../context/NavigationContext';
import { getMonolithUrls } from '../utils/monolithUrls';

export const useMonolithUrls = () => {
  const { fanaticsCollectBaseUrl } = useContext(NavigationContext);
  return useMemo(
    () => getMonolithUrls(fanaticsCollectBaseUrl),
    [fanaticsCollectBaseUrl]
  );
};
