import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { SwipeEventData } from 'react-swipeable';

import { useIsMobile } from '../../hooks';
import {
  SLIDER_STEP_WIDTH,
  SLIDER_MOBILE_STEP_WIDTH,
} from '../utils/constants';

const GAP = 15;

export const useSlider = (length: number) => {
  const [position, setPosition] = useState<number>(0);
  const [swipePosition, setSwipePosition] = useState<number>(0);
  const [isNextItem, setIsNextItem] = useState<boolean>(true);
  const [isPrevItem, setIsPrevItem] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const sliderStepWidth = useMemo(
    () => (isMobile ? SLIDER_MOBILE_STEP_WIDTH : SLIDER_STEP_WIDTH),
    [isMobile]
  );

  const sliderRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const maxWidth = useMemo(
    () => length * sliderStepWidth,
    [length, sliderStepWidth]
  );

  useEffect(() => {
    const containerWidth = containerRef.current?.offsetWidth || 0;
    const sliderWidth = sliderRef.current?.offsetWidth || 0;
    setIsNextItem(containerWidth < sliderWidth + position);
    setIsPrevItem(position < 0);
  }, [position, length]);

  const updateDimensions = useCallback(() => {
    const containerWidth = containerRef.current?.offsetWidth || 0;
    const sliderWidth = sliderRef.current?.offsetWidth || 0;
    setIsNextItem(containerWidth < sliderWidth + position);
  }, [position]);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [updateDimensions]);

  const handleNextItem = useCallback(() => {
    const maxPosition =
      -(maxWidth - (containerRef.current?.offsetWidth ?? 0)) + GAP;

    if (position < maxWidth && isNextItem) {
      const newPosition = position - sliderStepWidth;

      if (newPosition < maxPosition) {
        setPosition(maxPosition);
      } else {
        setPosition(newPosition);
      }
    }
  }, [isNextItem, maxWidth, position, sliderStepWidth]);

  const handlePrevItem = useCallback(() => {
    if (position < 0 && isPrevItem) {
      const newPosition =
        Math.round((position + sliderStepWidth) / sliderStepWidth) *
        sliderStepWidth;
      if (newPosition < 0) {
        setPosition(newPosition);
      } else {
        setPosition(0);
      }
    }
  }, [isPrevItem, position, sliderStepWidth]);

  const swipingHandler = useCallback(
    (eventData: SwipeEventData) => {
      const { deltaX } = eventData;
      const newPosition = position + deltaX;
      const maxPosition =
        -(maxWidth - (containerRef.current?.offsetWidth ?? 0)) + GAP;

      if (newPosition > 0) {
        setSwipePosition(newPosition / 3);
      } else if (newPosition < maxPosition) {
        setSwipePosition(maxPosition + (newPosition - maxPosition) / 3);
      } else {
        setSwipePosition(newPosition);
      }
    },
    [maxWidth, position]
  );

  const swipeEndHandler = useCallback(() => {
    const maxPosition =
      -(maxWidth - (containerRef.current?.offsetWidth ?? 0)) + GAP;
    if (swipePosition > 0) {
      setPosition(0);
    } else if (swipePosition < maxPosition) {
      setPosition(maxPosition);
    } else {
      const newPosition =
        Math.round(swipePosition / sliderStepWidth) * sliderStepWidth;
      setPosition(newPosition > 0 ? 0 : newPosition);
    }

    setSwipePosition(0);
  }, [maxWidth, sliderStepWidth, swipePosition]);

  return {
    position,
    swipePosition,
    isNextItem,
    isPrevItem,
    sliderRef,
    containerRef,
    handleNextItem,
    handlePrevItem,
    swipingHandler,
    swipeEndHandler,
  };
};
