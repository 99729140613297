import React, { FC, ReactNode } from 'react';

import { Flex, FlexProps } from '@chakra-ui/react';

import { ArrowNextIcon } from '../../../../Icons';
import { EColor } from '../../../../Theme';

interface IPaymentMethodBoxProps extends FlexProps {
  children: ReactNode;
}

export const PaymentMethodBox: FC<IPaymentMethodBoxProps> = ({
  children,
  ...restProps
}) => {
  return (
    <Flex
      alignItems="center"
      as="button"
      borderBottom={`1px solid ${EColor.Neutral10}`}
      height="64px"
      justifyContent="space-between"
      px="24px"
      width="100%"
      {...restProps}
    >
      <Flex alignItems="center" gap="8px">
        {children}
      </Flex>
      <ArrowNextIcon />
    </Flex>
  );
};
