import { extendTheme, ThemeOverride } from '@chakra-ui/react';

import { breakpoints } from './breakpoints';
import {
  ButtonConfig,
  CheckboxConfig,
  DividerConfig,
  FormConfig,
  FormErrorConfig,
  InputConfig,
  RadioConfig,
  TextConfig,
  SelectConfig,
  MonolithTextConfig,
  MonolithLinkConfig,
  MonolithDividerConfig,
  SkeletonConfig,
} from './config';
import { styles } from './styles';
import { textStyles } from './textStyles';
import { fanaticsCollectTheme } from '../theme';

const appTheme: ThemeOverride = {
  breakpoints,
  styles,
  textStyles,
  components: {
    Button: ButtonConfig,
    Text: TextConfig,
    Input: InputConfig,
    Select: SelectConfig,
    Radio: RadioConfig,
    Checkbox: CheckboxConfig,
    Divider: DividerConfig,
    Form: FormConfig,
    FormError: FormErrorConfig,
    Skeleton: SkeletonConfig,
  },
};

export const monolithAppTheme: ThemeOverride = {
  components: {
    Text: MonolithTextConfig,
    Link: MonolithLinkConfig,
    Divider: MonolithDividerConfig,
  },
};

export const fanaticsCollectAppTheme = extendTheme(
  fanaticsCollectTheme,
  appTheme
);
export const fanaticsCollectMonolithTheme = extendTheme(
  fanaticsCollectAppTheme,
  monolithAppTheme
);
export * from './config';
export * from './colors';
export * from './textStyles';
export * from './breakpoints';
