import { useMutation } from '@tanstack/react-query';

import { useApi } from '../../../../ApiClient';
import { useModalContext } from '../../../hooks/useModalContext';

export const useDeletePaymentMethod = () => {
  const apiClient = useApi();
  const { checkoutApiUrl } = useModalContext();

  return useMutation<null, unknown, string>({
    mutationKey: ['delete-payment-method'],
    mutationFn: async (paymentMethodId: string) =>
      apiClient!.delete(
        `${checkoutApiUrl}/checkout/payment-methods/${paymentMethodId}`
      ),
  });
};
