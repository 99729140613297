import { useMutation } from '@tanstack/react-query';

import { useApi } from '../../../../ApiClient';
import { useModalContext } from '../../../hooks/useModalContext';
import { useProfileStore } from '../../../stores/profile';

interface ICreateDefaultAddressArgs {
  name: string;
  street1: string;
  street2?: string;
  city: string;
  state: string;
  zip: string;
  countryId: number;
}

export interface ICreateDefaultAddressError {
  message?: string;
  errors?: {
    name?: string[];
    street1?: string[];
    street2?: string[];
    city?: string[];
    state?: string[];
    zip?: string[];
    countryId?: string[];
  };
}

export const useCreateDefaultAddress = () => {
  const apiClient = useApi();
  const { membersApiUrl } = useModalContext();
  const { updateProfile } = useProfileStore();

  return useMutation<
    unknown,
    ICreateDefaultAddressError,
    ICreateDefaultAddressArgs
  >({
    mutationKey: ['createDefaultAddress'],
    mutationFn: async (args) => {
      return apiClient!.post(
        `${membersApiUrl}/members/v1/registration/address`,
        args
      );
    },
    onSuccess: () => {
      updateProfile({ hasAddress: true });
    },
  });
};
