import { useMemo } from 'react';

import { useMonolithUrls } from './useMonolithUrls';
import { ExtendedRegistrationStatus } from '../../Auth/types/auth';
import { EColor } from '../../Theme';

const getStatusConfig = (urls: { [key: string]: string }) => ({
  PENDING: {
    label: 'Pending',
    color: EColor.Neutral5,
    href: urls.premierAuctionUrl,
  },
  APPROVED: {
    label: 'Approved',
    color: EColor.Lime,
    href: urls.premierAuctionUrl,
  },
  DENIED: {
    label: 'Denied',
    color: EColor.Corsa,
    href: urls.premierAuctionUrl,
  },
  default: {
    label: 'Apply',
    color: undefined,
    href: urls.premierAuctionApplicationUrl,
  },
});

export function useRegistrationStatus(
  extendedRegistrationStatus?: ExtendedRegistrationStatus
) {
  const urls = useMonolithUrls();

  return useMemo(
    () => getStatusConfig(urls)[extendedRegistrationStatus ?? 'default'],
    [extendedRegistrationStatus, urls]
  );
}
