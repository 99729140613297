import React, { FC } from 'react';

import {
  ModalCloseButton as ChakraModalCloseButton,
  CloseButtonProps,
} from '@chakra-ui/react';

import { EColor } from '../../Theme';

interface ModalCloseButtonProps extends CloseButtonProps {}

export const ModalCloseButton: FC<ModalCloseButtonProps> = (props) => {
  return (
    <ChakraModalCloseButton
      _hover={{
        color: EColor.Neutral55,
        transition: 'background-color 0.3s ease-in-out',
      }}
      backgroundColor="transparent"
      color={EColor.White}
      height="40px"
      position="absolute"
      size="md"
      transition="background-color 0.3s ease-in-out"
      width="40px"
      {...props}
    />
  );
};
