import React, { ReactNode } from 'react';

import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react';
import { HTMLMotionProps } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';

import { EColor } from '../Theme';

type Props = ButtonProps & HTMLMotionProps<'button'>;
export interface IButtonProps extends Props {
  children?: ReactNode;
  onClick?: () => void;
  color?: string;
  bg?: string;
  borderColor?: string;
  inverted?: boolean;
  label?: string;
  // Anchor link
  href?: string;
  // React Router link
  to?: string;
  isMain?: boolean;
  target?: string;
  transition?: string | any;
}

export function Button({
  color = EColor.Black,
  bg = EColor.White,
  borderColor,
  inverted,
  to,
  href,
  target,
  children,
  isMain,
  ...rest
}: IButtonProps) {
  let toUseColor = (inverted && bg) || color;
  let toUseBg = (inverted && color) || bg;
  if (isMain) {
    toUseColor = EColor.BrandDark;
    toUseBg = 'brand.main';
  }

  let as: React.ElementType = 'button';
  if (href) {
    as = 'a';
  } else if (to) {
    as = RouterLink;
  }

  return (
    <ChakraButton
      _active={{
        bg: isMain ? toUseBg : toUseColor,
        color: isMain ? toUseColor : toUseBg,
        filter: 'brightness(60%)',
      }}
      _disabled={{
        bg: EColor.Neutral15,
        borderColor: EColor.Neutral15,
        color: EColor.Neutral35,
        _hover: {
          bg: EColor.Neutral15,
          borderColor: EColor.Neutral15,
          color: EColor.Neutral35,
          cursor: 'not-allowed',
          transform: 'none',
        },
      }}
      _hover={{
        bg: EColor.White,
        color: EColor.Black,
      }}
      as={as}
      bg={toUseBg}
      borderColor={isMain || inverted ? borderColor || toUseBg : toUseColor}
      borderRadius="40px"
      borderWidth="3px"
      color={toUseColor}
      fontWeight="700"
      href={href}
      lineHeight="1"
      target={target}
      textTransform="uppercase"
      to={to}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
}
