/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Currency: { input: any; output: any; }
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string, without a time component.
   */
  Date: { input: any; output: any; }
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: { input: any; output: any; }
  UUID: { input: any; output: any; }
  /** Represents an uploaded file. */
  Upload: { input: any; output: any; }
};

export type CollectAccountFunds = {
  __typename?: 'CollectAccountFunds';
  availableForPayout?: Maybe<Money>;
  pendingUnderwriting?: Maybe<Money>;
  total?: Maybe<Money>;
  usableFunds?: Maybe<Money>;
};

export type CollectActivityForgetRecentViewPayload = {
  __typename?: 'CollectActivityForgetRecentViewPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectActivityForgetRecentViewResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectActivityForgetRecentViewResponse = {
  __typename?: 'CollectActivityForgetRecentViewResponse';
  record: CollectRecentlyViewedItem;
};

export type CollectActivityTrackViewInput = {
  itemId: Scalars['String']['input'];
  itemType: CollectRecentlyViewedItemType;
};

export type CollectActivityTrackViewPayload = {
  __typename?: 'CollectActivityTrackViewPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectActivityTrackViewResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectActivityTrackViewResponse = {
  __typename?: 'CollectActivityTrackViewResponse';
  record: CollectRecentlyViewedItem;
};

export type CollectAddFixedPriceToSetlistPayload = {
  __typename?: 'CollectAddFixedPriceToSetlistPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectAddFixedPriceToSetlistResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectAddFixedPriceToSetlistResponse = {
  __typename?: 'CollectAddFixedPriceToSetlistResponse';
  fixedPrice: CollectListing;
};

export type CollectAddItemToSetlistPayload = {
  __typename?: 'CollectAddItemToSetlistPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectAddItemToSetlistResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectAddItemToSetlistResponse = {
  __typename?: 'CollectAddItemToSetlistResponse';
  item: CollectItem;
};

export type CollectAddPremierToSetlistPayload = {
  __typename?: 'CollectAddPremierToSetlistPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectAddPremierToSetlistResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectAddPremierToSetlistResponse = {
  __typename?: 'CollectAddPremierToSetlistResponse';
  premier: CollectListing;
};

export type CollectAddProductToSetlistPayload = {
  __typename?: 'CollectAddProductToSetlistPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectAddProductToSetlistResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectAddProductToSetlistResponse = {
  __typename?: 'CollectAddProductToSetlistResponse';
  product: CollectProduct;
};

export type CollectAddToCollectionPayload = {
  __typename?: 'CollectAddToCollectionPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectAddToCollectionResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectAddToCollectionResponse = {
  __typename?: 'CollectAddToCollectionResponse';
  item: CollectItem;
};

export type CollectAddVariantToSetlistPayload = {
  __typename?: 'CollectAddVariantToSetlistPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectAddVariantToSetlistResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectAddVariantToSetlistResponse = {
  __typename?: 'CollectAddVariantToSetlistResponse';
  variant: CollectVariant;
};

export type CollectAddVaultItemsToGroupPayload = {
  __typename?: 'CollectAddVaultItemsToGroupPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectVaultItemGroup>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectAddWeeklyToSetlistPayload = {
  __typename?: 'CollectAddWeeklyToSetlistPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectAddWeeklyToSetlistResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectAddWeeklyToSetlistResponse = {
  __typename?: 'CollectAddWeeklyToSetlistResponse';
  weekly: CollectListing;
};

/** Address representation for Collect application */
export type CollectAddress = {
  __typename?: 'CollectAddress';
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  countryName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

export type CollectAddressCreateInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type CollectAddressCreatePayload = {
  __typename?: 'CollectAddressCreatePayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectAddressCreateResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectAddressCreateResponse = {
  __typename?: 'CollectAddressCreateResponse';
  id: Scalars['UUID']['output'];
};

export type CollectAddressValidateInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type CollectAddressValidatePayload = {
  __typename?: 'CollectAddressValidatePayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectAddressValidateResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectAddressValidateResponse = {
  __typename?: 'CollectAddressValidateResponse';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  street1: Scalars['String']['output'];
  street2?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

export enum CollectAlgoliaSearchIndexName {
  Listing = 'LISTING',
  ListingBestDeals = 'LISTING_BEST_DEALS',
  ListingBestValueDesc = 'LISTING_BEST_VALUE_DESC',
  ListingConfidenceValueGuidePrice = 'LISTING_CONFIDENCE_VALUE_GUIDE_PRICE',
  ListingFeatured = 'LISTING_FEATURED',
  ListingHighestPrice = 'LISTING_HIGHEST_PRICE',
  ListingLowestPrice = 'LISTING_LOWEST_PRICE',
  ListingPendingOffersDesc = 'LISTING_PENDING_OFFERS_DESC',
  ListingRecentlyAdded = 'LISTING_RECENTLY_ADDED',
  ListingSoldAsc = 'LISTING_SOLD_ASC',
  ListingSoldDesc = 'LISTING_SOLD_DESC',
  ListingYearAsc = 'LISTING_YEAR_ASC',
  ListingYearDesc = 'LISTING_YEAR_DESC'
}

export type CollectAlgoliaSearchRequest = {
  indexName: CollectAlgoliaSearchIndexName;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type CollectAlgoliaSearchResponse = {
  __typename?: 'CollectAlgoliaSearchResponse';
  hits: Array<CollectAlgoliaSearchResponseHit>;
};

export type CollectAlgoliaSearchResponseHit = {
  __typename?: 'CollectAlgoliaSearchResponseHit';
  listingUuid: Scalars['UUID']['output'];
};

export enum CollectAlgoliaSearchStrategy {
  None = 'NONE',
  StopIfEnoughMatches = 'STOP_IF_ENOUGH_MATCHES'
}

export type CollectAlternatingShippingAddress = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type CollectAuction = {
  /** The integer ID of the auction. this only applies to weekly auction */
  auctionIntegerId?: Maybe<Scalars['Int']['output']>;
  collectListingTimer: CollectListingTimer;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  /** The integer ID of the auction schedule */
  integerId?: Maybe<Scalars['Int']['output']>;
  isCustom?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  payoutDate?: Maybe<Scalars['Date']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  status: CollectAuctionStatus;
  subtitle?: Maybe<Scalars['String']['output']>;
};

export type CollectAuctionAndListingsDelta = {
  __typename?: 'CollectAuctionAndListingsDelta';
  auction: CollectAuction;
  listingsDelta?: Maybe<Array<Maybe<CollectListingDelta>>>;
};

export type CollectAuctionConnection = {
  __typename?: 'CollectAuctionConnection';
  edges?: Maybe<Array<Maybe<CollectAuctionEdge>>>;
  pageInfo: PageInfo;
};

export type CollectAuctionEdge = {
  __typename?: 'CollectAuctionEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectAuction>;
};

export type CollectAuctionIdInput = {
  /** Auction ID */
  id: Scalars['UUID']['input'];
  /** Type of the auction (weekly or premier) */
  type: CollectAuctionType;
};

export type CollectAuctionIdListInput = {
  /** Auction ID */
  auctionIds: Array<InputMaybe<Scalars['UUID']['input']>>;
  /** Type of the auction (weekly or premier) */
  type: CollectAuctionType;
};

export enum CollectAuctionStatus {
  Closed = 'CLOSED',
  ExtendedBidding = 'EXTENDED_BIDDING',
  Live = 'LIVE',
  Preview = 'PREVIEW'
}

export enum CollectAuctionType {
  Premier = 'PREMIER',
  Weekly = 'WEEKLY'
}

export type CollectBatchCancelListingsPayload = {
  __typename?: 'CollectBatchCancelListingsPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectBatchCancelListingsResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

/**
 * Result of a batch cancel is a list of payloads for each listing cancel.
 * Each entry in the payloads list is a Payload object.
 */
export type CollectBatchCancelListingsResponse = {
  __typename?: 'CollectBatchCancelListingsResponse';
  payloads: Array<CollectCancelListingPayload>;
};

export type CollectBatchCreateListingInput = {
  requests?: InputMaybe<Array<InputMaybe<CollectCreateListingInput>>>;
};

export type CollectBatchCreateListingPayload = {
  __typename?: 'CollectBatchCreateListingPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectBatchCreateListingResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectBatchCreateListingResponse = {
  __typename?: 'CollectBatchCreateListingResponse';
  failedVaultItems?: Maybe<Array<Maybe<CollectVaultItem>>>;
  listings?: Maybe<Array<Maybe<CollectListing>>>;
};

export type CollectBatchCreateListingsV2Payload = {
  __typename?: 'CollectBatchCreateListingsV2Payload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectBatchCreateListingsV2Response>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectBatchCreateListingsV2Response = {
  __typename?: 'CollectBatchCreateListingsV2Response';
  payloads: Array<CollectBatchCreateListingsV2ResponsePayload>;
};

export type CollectBatchCreateListingsV2ResponsePayload = {
  __typename?: 'CollectBatchCreateListingsV2ResponsePayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectBatchCreateListingsV2ResponseResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

/** When listing was created, it's listing. When it was not created, it's vault_item */
export type CollectBatchCreateListingsV2ResponseResponse = {
  __typename?: 'CollectBatchCreateListingsV2ResponseResponse';
  listing?: Maybe<CollectListing>;
  vaultItem?: Maybe<CollectVaultItem>;
};

export type CollectBatchMakeOffersPayload = {
  __typename?: 'CollectBatchMakeOffersPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectBatchMakeOffersResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectBatchMakeOffersResponse = {
  __typename?: 'CollectBatchMakeOffersResponse';
  payloads: Array<CollectBatchMakeOffersResponsePayload>;
};

export type CollectBatchMakeOffersResponsePayload = {
  __typename?: 'CollectBatchMakeOffersResponsePayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectBatchMakeOffersResponseResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectBatchMakeOffersResponseResponse = {
  __typename?: 'CollectBatchMakeOffersResponseResponse';
  listing?: Maybe<CollectListing>;
  offer?: Maybe<CollectFixedPriceListingOfferWithHistory>;
};

/**
 * An update object for a specific listing which is used in batching update.
 * For weekly and premier listings updates all arguments other than `type`,
 * `id` and `marketplace_event_id` are ignored. Fixed price works with all args
 */
export type CollectBatchUpdateListingsInput = {
  allowOffers?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
  listingPriceInCents?: InputMaybe<Scalars['Int']['input']>;
  marketplaceEventId?: InputMaybe<Scalars['UUID']['input']>;
  marketplaceEventType?: InputMaybe<CollectMarketplaceEventType>;
  rejectPriceThresholdInCents?: InputMaybe<Scalars['Int']['input']>;
  type: CollectListingType;
};

export type CollectBatchUpdateListingsPayload = {
  __typename?: 'CollectBatchUpdateListingsPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectBatchUpdateListingsResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

/**
 * Result of a batch update is a list of payloads for each listing update.
 * Each entry in the payloads list is listing-type-specific Payload object.
 */
export type CollectBatchUpdateListingsResponse = {
  __typename?: 'CollectBatchUpdateListingsResponse';
  payloads: Array<CollectBatchUpdateListingsResponseUpdatePayload>;
};

export type CollectBatchUpdateListingsResponseUpdatePayload = CollectUpdateFixedPriceListingPayload | CollectUpdatePremierListingPayload | CollectUpdateWeeklyListingPayload;

/** The bid increment config for valid bids */
export type CollectBidIncrementConfig = {
  __typename?: 'CollectBidIncrementConfig';
  boundary?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type CollectBidPayload = {
  __typename?: 'CollectBidPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectBidResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

/** The response of a multi bid. */
export type CollectBidResponse = {
  __typename?: 'CollectBidResponse';
  bidResults?: Maybe<Array<Maybe<CollectBidResult>>>;
  isFirstBid?: Maybe<Scalars['Boolean']['output']>;
};

/** The result of a bid */
export type CollectBidResult = {
  __typename?: 'CollectBidResult';
  bidId?: Maybe<Scalars['UUID']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  isFirstBid: Scalars['Boolean']['output'];
  listing: CollectListing;
};

export enum CollectBidType {
  Outbid = 'OUTBID',
  Winning = 'WINNING'
}

/** It contains the information needed to place a bid on one listing */
export type CollectBigSingle = {
  listingId: Scalars['UUID']['input'];
  maxBid?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectBoCheckoutListingConfirmIntentPayload = {
  __typename?: 'CollectBoCheckoutListingConfirmIntentPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectBoCheckoutListingConfirmIntentResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectBoCheckoutListingConfirmIntentResponse = {
  __typename?: 'CollectBoCheckoutListingConfirmIntentResponse';
  order?: Maybe<CollectBoOrder>;
};

export type CollectBoCheckoutListingCreateDraftOrderResponse = {
  __typename?: 'CollectBoCheckoutListingCreateDraftOrderResponse';
  order?: Maybe<CollectBoOrder>;
};

export type CollectBoCheckoutListingCreateIntentPayload = {
  __typename?: 'CollectBoCheckoutListingCreateIntentPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectBoCheckoutListingCreateIntentResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectBoCheckoutListingCreateIntentResponse = {
  __typename?: 'CollectBoCheckoutListingCreateIntentResponse';
  order?: Maybe<CollectBoOrder>;
};

export type CollectBoOrder = {
  __typename?: 'CollectBoOrder';
  buyerFanId?: Maybe<Scalars['String']['output']>;
  chargeRemainingInCents?: Maybe<Scalars['Int']['output']>;
  chargeTotal?: Maybe<Scalars['Int']['output']>;
  clientPlatform?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  feePlatformTakeInCents?: Maybe<Scalars['Int']['output']>;
  feeTotalCreditCardProcessingInCents?: Maybe<Scalars['Int']['output']>;
  feeTotalProductSalesTaxInCents?: Maybe<Scalars['Int']['output']>;
  feeTotalShippingInCents?: Maybe<Scalars['Int']['output']>;
  feeTotalTaxInCents?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  orderItems?: Maybe<Array<Maybe<CollectBoOrderItem>>>;
  orderPayments?: Maybe<Array<Maybe<CollectBoOrderPayment>>>;
  orderType?: Maybe<Scalars['String']['output']>;
  paymentStatus?: Maybe<Scalars['String']['output']>;
  referenceCode?: Maybe<Scalars['String']['output']>;
  subTotalProductInCents?: Maybe<Scalars['Int']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type CollectBoOrderItem = {
  __typename?: 'CollectBoOrderItem';
  createdAt?: Maybe<Scalars['String']['output']>;
  feeShippingInCents?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  listingId?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  productValueInCents?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  referenceCode?: Maybe<Scalars['String']['output']>;
  shipToAddress?: Maybe<CollectShippingAddress>;
  taxCode?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type CollectBoOrderPayment = {
  __typename?: 'CollectBoOrderPayment';
  clientPlatform?: Maybe<Scalars['String']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  idempotencyKey?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  paymentAmountInCents?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stripePaymentIntentId?: Maybe<Scalars['String']['output']>;
  stripePaymentMethodId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type CollectBoShippingAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type CollectCalendarEvent = {
  __typename?: 'CollectCalendarEvent';
  endsAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  marketplace: Scalars['String']['output'];
  name: Scalars['String']['output'];
  startsAt: Scalars['DateTime']['output'];
  status: CollectCalendarEventStatus;
};

export enum CollectCalendarEventStatus {
  Closed = 'CLOSED',
  ComingSoon = 'COMING_SOON',
  Extended = 'EXTENDED',
  Open = 'OPEN',
  Preview = 'PREVIEW'
}

export type CollectCancelListingPayload = {
  __typename?: 'CollectCancelListingPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCancelListingResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCancelListingResponse = {
  __typename?: 'CollectCancelListingResponse';
  listing?: Maybe<CollectListing>;
};

export type CollectCancelVaultItemRequestPayload = {
  __typename?: 'CollectCancelVaultItemRequestPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCancelVaultItemRequestResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCancelVaultItemRequestResponse = {
  __typename?: 'CollectCancelVaultItemRequestResponse';
  vaultItemRequest?: Maybe<CollectVaultItemRequest>;
};

export type CollectCancelVaultRetrievalPayload = {
  __typename?: 'CollectCancelVaultRetrievalPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCancelVaultRetrievalResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCancelVaultRetrievalResponse = {
  __typename?: 'CollectCancelVaultRetrievalResponse';
  vaultItemRequest?: Maybe<CollectVaultItemRequest>;
};

export type CollectCancelVaultSubmissionPayload = {
  __typename?: 'CollectCancelVaultSubmissionPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCancelVaultSubmissionResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCancelVaultSubmissionResponse = {
  __typename?: 'CollectCancelVaultSubmissionResponse';
  submission?: Maybe<CollectVaultSubmission>;
};

export type CollectCardGrade = {
  __typename?: 'CollectCardGrade';
  grade?: Maybe<Scalars['Float']['output']>;
  population?: Maybe<Scalars['Int']['output']>;
};

/** The users cart */
export type CollectCart = {
  __typename?: 'CollectCart';
  /** The list of available for purchase listings */
  availableListings?: Maybe<CollectCartListingConnection>;
  id: Scalars['UUID']['output'];
  status: CollectCartStatus;
  /** The list of unavailable for purchase listings */
  unavailableListings?: Maybe<CollectCartListingConnection>;
};


/** The users cart */
export type CollectCartAvailableListingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** The users cart */
export type CollectCartUnavailableListingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectCartListingConnection = {
  __typename?: 'CollectCartListingConnection';
  edges?: Maybe<Array<Maybe<CollectCartListingEdge>>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
  totalAmount: Money;
};

export type CollectCartListingEdge = {
  __typename?: 'CollectCartListingEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectListing>;
};

export type CollectCartPayload = {
  __typename?: 'CollectCartPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCartResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCartResponse = {
  __typename?: 'CollectCartResponse';
  /** The Cart object */
  cart?: Maybe<CollectCart>;
};

export enum CollectCartStatus {
  Abandoned = 'ABANDONED',
  Active = 'ACTIVE',
  Complete = 'COMPLETE',
  Locked = 'LOCKED'
}

export type CollectCatalogSearchResult = {
  __typename?: 'CollectCatalogSearchResult';
  result?: Maybe<CollectItemOrProductOrListingOrVariantConnection>;
};


export type CollectCatalogSearchResultResultArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectCategory = {
  __typename?: 'CollectCategory';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CollectCategoryHierarchy = {
  __typename?: 'CollectCategoryHierarchy';
  categories?: Maybe<Array<Maybe<CollectCategory>>>;
  categoryType: Scalars['String']['output'];
};

export enum CollectCategoryType {
  CoinsCurrency = 'COINS_CURRENCY',
  Comics = 'COMICS',
  Other = 'OTHER',
  SportCards = 'SPORT_CARDS',
  TradingCardGames = 'TRADING_CARD_GAMES',
  VideoGames = 'VIDEO_GAMES',
  Wax = 'WAX'
}

/**
 * Result type containing the ChatGPT-recognized card details.
 * Represents structured data extracted from card images using AI analysis.
 * Each field corresponds to specific attributes of the recognized card.
 */
export type CollectChatgptMatchResult = {
  __typename?: 'CollectChatgptMatchResult';
  brand?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  issue?: Maybe<Scalars['String']['output']>;
  parallel?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

/**
 * Result type containing the combined results from ChatGPT operations.
 * This object encapsulates the outcomes of the card analysis, including match details, descriptions, and chat responses.
 */
export type CollectChatgptOperationsResult = {
  __typename?: 'CollectChatgptOperationsResult';
  /** Chat-related response based on user prompt. */
  chat?: Maybe<Scalars['String']['output']>;
  /** Description generated from card analysis. */
  describe?: Maybe<Scalars['String']['output']>;
  /** Match details from card recognition. */
  match?: Maybe<Array<Maybe<CollectChatgptMatchResult>>>;
};

export type CollectCheckoutCharges = {
  __typename?: 'CollectCheckoutCharges';
  /** Deadline for returning items back to vault */
  deferredFulfillmentDeadline: Scalars['DateTime']['output'];
  /** Potential fee if deadline is missed */
  deferredFulfillmentFee: Money;
  fulfillmentFee: Money;
  insuredValue: Money;
  needsUnderwriting: Scalars['Boolean']['output'];
  shippingFee?: Maybe<Money>;
};

export type CollectCheckoutDetails = {
  __typename?: 'CollectCheckoutDetails';
  accountFundsToUse: Money;
  allowedPaymentMethods?: Maybe<Array<Maybe<CollectValidPaymentMethod>>>;
  amountPaid?: Maybe<Money>;
  amountPaidOutstanding?: Maybe<Money>;
  availableShippingMethods: Array<CollectShippingMethod>;
  availableShippingTypes?: Maybe<Array<Maybe<CollectShippingType>>>;
  balanceDue: Money;
  businessCategory?: Maybe<CollectInvoiceBusinessCategory>;
  creditCardFee: Money;
  creditCardFeePct: Scalars['Float']['output'];
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  errors?: Maybe<Array<Maybe<CollectCheckoutDetailsError>>>;
  invoiceCategory?: Maybe<CollectInvoiceCategory>;
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  lineItems?: Maybe<Array<Maybe<CollectLineItem>>>;
  /** The list of CheckoutDetails Payment Attempts. */
  paymentAttempts?: Maybe<Array<Maybe<CollectPaymentAttempt>>>;
  payments?: Maybe<Array<Maybe<CollectPayment>>>;
  pendingPayments?: Maybe<Array<Maybe<CollectPayment>>>;
  potentialBalanceDueWithCreditCardFee: Money;
  potentialCreditCardFee: Money;
  potentialTotalWithCreditCardFee: Money;
  shipping: Money;
  shippingAddress?: Maybe<CollectShippingAddress>;
  shippingMethod?: Maybe<CollectShippingMethod>;
  shippingType: CollectShippingType;
  status: CollectInvoiceStatus;
  subTotal: Money;
  tax: Money;
  total: Money;
  type: CollectInvoiceType;
};

export type CollectCheckoutDetailsError = {
  __typename?: 'CollectCheckoutDetailsError';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CollectCheckoutDetailsInput = {
  invoice?: InputMaybe<CollectCheckoutDetailsInvoiceInput>;
  listing?: InputMaybe<CollectCheckoutDetailsListingInput>;
};

export type CollectCheckoutDetailsInvoiceInput = {
  alternateShippingAddress?: InputMaybe<CollectAlternatingShippingAddress>;
  invoiceId: Scalars['UUID']['input'];
  /** ShippingMethod UUID, used for external sellers, e.g. Burbank. If missing, the cheapest will be used */
  shippingMethodId?: InputMaybe<Scalars['UUID']['input']>;
  shippingType?: InputMaybe<CollectShippingType>;
};

export type CollectCheckoutDetailsListingInput = {
  alternateShippingAddress?: InputMaybe<CollectAlternatingShippingAddress>;
  listingId: Scalars['UUID']['input'];
  listingType: CollectListingType;
  /** ShippingMethod UUID, used for external sellers, e.g. Burbank. If missing, the cheapest will be used */
  shippingMethodId?: InputMaybe<Scalars['UUID']['input']>;
  shippingType?: InputMaybe<CollectShippingType>;
};

export type CollectCheckoutInvoiceConfirmInput = {
  alternateShippingAddress?: InputMaybe<CollectAlternatingShippingAddress>;
  fundsAmount?: InputMaybe<Scalars['Int']['input']>;
  invoiceId: Scalars['UUID']['input'];
  paymentIntentId?: InputMaybe<Scalars['String']['input']>;
  paymentMethod: Array<CollectValidPaymentMethod>;
  /** ShippingMethod UUID, required for external sellers, e.g. Burbank */
  shippingMethodId?: InputMaybe<Scalars['UUID']['input']>;
  shippingType: CollectShippingType;
};

export type CollectCheckoutInvoiceConfirmPayload = {
  __typename?: 'CollectCheckoutInvoiceConfirmPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCheckoutInvoiceConfirmResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCheckoutInvoiceConfirmResponse = {
  __typename?: 'CollectCheckoutInvoiceConfirmResponse';
  invoice: CollectInvoice;
  isFirstPurchase: Scalars['Boolean']['output'];
  results?: Maybe<Array<Maybe<CollectChekcoutInvoiceConfirmSinglePayload>>>;
};

export type CollectCheckoutInvoicePaymentIntentInput = {
  accountFundsAppliedInCents?: InputMaybe<Scalars['Int']['input']>;
  alternateShippingAddress?: InputMaybe<CollectAlternatingShippingAddress>;
  invoiceId: Scalars['UUID']['input'];
  paymentMethod: Array<CollectValidPaymentMethod>;
  /** ShippingMethod UUID, required for external sellers, e.g. Burbank */
  shippingMethodId?: InputMaybe<Scalars['UUID']['input']>;
  shippingType: CollectShippingType;
};

export type CollectCheckoutInvoicePaymentIntentPayload = {
  __typename?: 'CollectCheckoutInvoicePaymentIntentPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCheckoutInvoicePaymentIntentResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCheckoutInvoicePaymentIntentResponse = {
  __typename?: 'CollectCheckoutInvoicePaymentIntentResponse';
  clientSecret?: Maybe<Scalars['String']['output']>;
  ephemeralKey?: Maybe<Scalars['String']['output']>;
  paymentIntentId?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
};

export type CollectCheckoutPaymentLockInput = {
  invoice?: InputMaybe<CollectCheckoutPaymentLockInvoiceInput>;
  listing?: InputMaybe<CollectCheckoutPaymentLockListingInput>;
};

export type CollectCheckoutPaymentLockInvoiceInput = {
  alternateShippingAddress?: InputMaybe<CollectAlternatingShippingAddress>;
  invoiceId: Scalars['UUID']['input'];
  paymentMethod: Array<CollectValidPaymentMethod>;
  /** ShippingMethod UUID, used for external sellers, e.g. Burbank. If missing, the cheapest will be used */
  shippingMethodId?: InputMaybe<Scalars['UUID']['input']>;
  shippingType: CollectShippingType;
};

export type CollectCheckoutPaymentLockListingInput = {
  alternateShippingAddress?: InputMaybe<CollectAlternatingShippingAddress>;
  listingId: Scalars['UUID']['input'];
  listingType: CollectListingType;
  paymentMethod: Array<CollectValidPaymentMethod>;
  /** ShippingMethod UUID, used for external sellers, e.g. Burbank. If missing, the cheapest will be used */
  shippingMethodId?: InputMaybe<Scalars['UUID']['input']>;
  shippingType: CollectShippingType;
};

export type CollectCheckoutPaymentLockPayload = {
  __typename?: 'CollectCheckoutPaymentLockPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCheckoutPaymentLockResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCheckoutPaymentLockResponse = {
  __typename?: 'CollectCheckoutPaymentLockResponse';
  clientSecret?: Maybe<Scalars['String']['output']>;
  invoiceId: Scalars['UUID']['output'];
  invoiceIntegerId: Scalars['Int']['output'];
  paymentIntentId?: Maybe<Scalars['String']['output']>;
};

export type CollectCheckoutSetupIntentInput = {
  paymentMethodTypes: Array<CollectInvoicePaymentTypes>;
};

export type CollectCheckoutSetupIntentPayload = {
  __typename?: 'CollectCheckoutSetupIntentPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCheckoutSetupIntentResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCheckoutSetupIntentResponse = {
  __typename?: 'CollectCheckoutSetupIntentResponse';
  ephemeralKey?: Maybe<Scalars['String']['output']>;
  setupIntentClientSecret?: Maybe<Scalars['String']['output']>;
  setupIntentId?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
};

export type CollectCheckoutSummaryConstraintData = {
  __typename?: 'CollectCheckoutSummaryConstraintData';
  /** Potential fraction of the portfolio if request if fulfilled */
  afterCheckoutPct: Scalars['Float']['output'];
  /** Already checked out portfolio share value */
  alreadyCheckedOutValue: Money;
  /** Already checked out portfolio share in fraction */
  beforeCheckoutPct: Scalars['Float']['output'];
  /** Is it allowed to checkout the requested items (portfolio share) */
  isInvalid: Scalars['Boolean']['output'];
  /** Total value of the portfolio */
  totalValue: Money;
};

export type CollectCheckoutV2Address = {
  __typename?: 'CollectCheckoutV2Address';
  city: Scalars['String']['output'];
  country: CollectCheckoutV2Country;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1: Scalars['String']['output'];
  street2?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

export type CollectCheckoutV2AddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type CollectCheckoutV2BurbankPaymentSummaryShipping = {
  __typename?: 'CollectCheckoutV2BurbankPaymentSummaryShipping';
  address?: Maybe<CollectCheckoutV2Address>;
  method: CollectCheckoutV2ShippingMethod;
  type: CollectCheckoutV2BurbankShippingType;
};

export type CollectCheckoutV2BurbankShipping = {
  __typename?: 'CollectCheckoutV2BurbankShipping';
  address?: Maybe<CollectCheckoutV2Address>;
  /** Available shipping methods */
  availableMethods?: Maybe<Array<Maybe<CollectCheckoutV2ShippingMethod>>>;
  availableTypes: Array<CollectCheckoutV2BurbankShippingType>;
  method: CollectCheckoutV2ShippingMethod;
  type: CollectCheckoutV2BurbankShippingType;
};

/** Collect Shipping Types available for Burbank Sportscards */
export enum CollectCheckoutV2BurbankShippingType {
  /** The default account address */
  Default = 'DEFAULT',
  /** Requires shipping address */
  New = 'NEW'
}

export type CollectCheckoutV2CompletePaymentCartInput = {
  id: Scalars['UUID']['input'];
  /** Stripe Payment Intent ID. Empty for non-Stripe payments */
  paymentIntentId?: InputMaybe<Scalars['String']['input']>;
  /** Selected payment methods. Can be FUNDS, REGULAR_PM, or FUNDS+REGULAR_PM */
  paymentMethods: Array<CollectCheckoutV2PaymentMethod>;
};

export type CollectCheckoutV2CompletePaymentInvoiceInput = {
  id: Scalars['UUID']['input'];
  /** Stripe Payment Intent ID. Empty for non-Stripe payments */
  paymentIntentId?: InputMaybe<Scalars['String']['input']>;
  /** Selected payment methods. Can be FUNDS, REGULAR_PM, or FUNDS+REGULAR_PM */
  paymentMethod: Array<CollectCheckoutV2PaymentMethod>;
};

export type CollectCheckoutV2CompletePaymentPayload = {
  __typename?: 'CollectCheckoutV2CompletePaymentPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCheckoutV2CompletePaymentResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCheckoutV2CompletePaymentResponse = {
  __typename?: 'CollectCheckoutV2CompletePaymentResponse';
  invoiceUuids: Array<Scalars['UUID']['output']>;
  isFirstPurchase: Scalars['Boolean']['output'];
  results?: Maybe<Array<Maybe<CollectCheckoutV2CompleteSinglePaymentResult>>>;
};

export type CollectCheckoutV2CompleteSinglePaymentResult = {
  __typename?: 'CollectCheckoutV2CompleteSinglePaymentResult';
  amount?: Maybe<Money>;
  /** ID of the payment transaction */
  paymentTransactionId?: Maybe<Scalars['String']['output']>;
  /** Payment status */
  status?: Maybe<CollectCompletePaymentResultStatus>;
};

export type CollectCheckoutV2Country = {
  __typename?: 'CollectCheckoutV2Country';
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isEu: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  shippingPriority: Scalars['Int']['output'];
};

export type CollectCheckoutV2Details = {
  __typename?: 'CollectCheckoutV2Details';
  availablePaymentMethods: Array<CollectCheckoutV2PaymentMethod>;
  burbankSportscardsSection?: Maybe<CollectCheckoutV2DetailsBurbankSportscardsSection>;
  errors?: Maybe<Array<Maybe<CollectCheckoutV2DetailsError>>>;
  fanaticsAuthenticSection?: Maybe<CollectCheckoutV2DetailsFanaticsAuthenticSection>;
  fanaticsCollectSection?: Maybe<CollectCheckoutV2DetailsFanaticsCollectSection>;
  /** Successful payments */
  payments?: Maybe<Array<Maybe<CollectCheckoutV2Payment>>>;
  /** Payments that are not completed yet */
  pendingPayments?: Maybe<Array<Maybe<CollectCheckoutV2Payment>>>;
  totals: CollectCheckoutV2DetailsTotals;
};

export type CollectCheckoutV2DetailsBurbankSportscardsSection = {
  __typename?: 'CollectCheckoutV2DetailsBurbankSportscardsSection';
  errors?: Maybe<Array<Maybe<CollectCheckoutV2DetailsError>>>;
  /** Type of a potential or existing invoice */
  invoiceType?: Maybe<CollectInvoiceType>;
  /** Line items in the cart, unavailable items will have error code and error message */
  lineItems?: Maybe<Array<Maybe<CollectCheckoutV2LineItem>>>;
  seller: CollectSeller;
  /** Selected shipping */
  shipping?: Maybe<CollectCheckoutV2BurbankShipping>;
  totals?: Maybe<CollectCheckoutV2DetailsSectionTotals>;
};

export type CollectCheckoutV2DetailsBurbankSportscardsSectionInput = {
  shipping?: InputMaybe<CollectCheckoutV2DetailsBurbankSportscardsShippingInput>;
};

export type CollectCheckoutV2DetailsBurbankSportscardsShippingInput = {
  /** Custom address. Required for all shipping_methods except DEFAULT */
  address?: InputMaybe<CollectCheckoutV2AddressInput>;
  /** If not specified, the cheapest available will be used with saved account address */
  methodId?: InputMaybe<Scalars['UUID']['input']>;
  /** If custom address not specified, DEFAULT will be used */
  type?: InputMaybe<CollectCheckoutV2BurbankShippingType>;
};

export type CollectCheckoutV2DetailsCartInput = {
  burbankSportscardsSection?: InputMaybe<CollectCheckoutV2DetailsBurbankSportscardsSectionInput>;
  fanaticsAuthenticSection?: InputMaybe<CollectCheckoutV2DetailsFanaticsAuthenticSectionInput>;
  fanaticsCollectSection?: InputMaybe<CollectCheckoutV2DetailsFanaticsCollectSectionInput>;
  id: Scalars['UUID']['input'];
};

export type CollectCheckoutV2DetailsError = {
  __typename?: 'CollectCheckoutV2DetailsError';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CollectCheckoutV2DetailsFanaticsAuthenticSection = {
  __typename?: 'CollectCheckoutV2DetailsFanaticsAuthenticSection';
  errors?: Maybe<Array<Maybe<CollectCheckoutV2DetailsError>>>;
  /** Type of a potential or existing invoice */
  invoiceType?: Maybe<CollectInvoiceType>;
  /** Line items in the cart, unavailable items will have error code and error message */
  lineItems?: Maybe<Array<Maybe<CollectCheckoutV2LineItem>>>;
  seller: CollectSeller;
  /** Selected shipping */
  shipping?: Maybe<CollectCheckoutV2FanaticsShipping>;
  totals?: Maybe<CollectCheckoutV2DetailsSectionTotals>;
};

export type CollectCheckoutV2DetailsFanaticsAuthenticSectionInput = {
  shipping?: InputMaybe<CollectCheckoutV2DetailsFanaticsAuthenticShippingInput>;
};

export type CollectCheckoutV2DetailsFanaticsAuthenticShippingInput = {
  /** Custom address. Required for NEW shipping_method */
  address?: InputMaybe<CollectCheckoutV2AddressInput>;
  /** If not specified, VAULT will be used */
  type?: InputMaybe<CollectCheckoutV2FanaticsShippingType>;
};

export type CollectCheckoutV2DetailsFanaticsCollectSection = {
  __typename?: 'CollectCheckoutV2DetailsFanaticsCollectSection';
  errors?: Maybe<Array<Maybe<CollectCheckoutV2DetailsError>>>;
  /** Type of a potential or existing invoice */
  invoiceType?: Maybe<CollectInvoiceType>;
  /** Line items in the cart, unavailable items will have error code and error message */
  lineItems?: Maybe<Array<Maybe<CollectCheckoutV2LineItem>>>;
  seller: CollectSeller;
  /** Selected shipping */
  shipping?: Maybe<CollectCheckoutV2FanaticsShipping>;
  totals?: Maybe<CollectCheckoutV2DetailsSectionTotals>;
};

export type CollectCheckoutV2DetailsFanaticsCollectSectionInput = {
  shipping?: InputMaybe<CollectCheckoutV2DetailsFanaticsCollectShippingInput>;
};

export type CollectCheckoutV2DetailsFanaticsCollectShippingInput = {
  /** Custom address. Required for NEW shipping_method */
  address?: InputMaybe<CollectCheckoutV2AddressInput>;
  /** If not specified, VAULT will be used */
  type?: InputMaybe<CollectCheckoutV2FanaticsShippingType>;
};

export type CollectCheckoutV2DetailsInvoiceInput = {
  fanaticsAuthenticSection?: InputMaybe<CollectCheckoutV2DetailsFanaticsAuthenticSectionInput>;
  fanaticsCollectSection?: InputMaybe<CollectCheckoutV2DetailsFanaticsCollectSectionInput>;
  id: Scalars['UUID']['input'];
};

export type CollectCheckoutV2DetailsSectionTotals = {
  __typename?: 'CollectCheckoutV2DetailsSectionTotals';
  shipping: Money;
  subTotal: Money;
  tax: Money;
  total: Money;
};

/** Overall checkout amounts */
export type CollectCheckoutV2DetailsTotals = {
  __typename?: 'CollectCheckoutV2DetailsTotals';
  /** Total amount of users' account funds */
  accountFunds: Money;
  /** Amount of funds that will remain after the current payment */
  accountFundsRemaining: Money;
  /** The amount of account funds that will be used during the current checkout */
  accountFundsToUse: Money;
  /** Amount that already been paid */
  amountPaid?: Maybe<Money>;
  /** Amount that is left to be paid */
  amountPaidOutstanding?: Maybe<Money>;
  /** The amount to be paid during current checkout */
  balanceDue: Money;
  /** Amount that goes to Seller. Already included in subtotal */
  buyersPremium: Money;
  /** Fraction of a payment amount that potentially be added on top of total if paid by Credit Card, Apple Pay or Google Pay */
  creditCardFeeRate: Scalars['Float']['output'];
  potentialBalanceDueWithCreditCardFee: Money;
  /** Amount that potentially be added on top of total if paid by Credit Card, Apple Pay or Google Pay */
  potentialCreditCardFee: Money;
  potentialTotalWithCreditCardFee: Money;
  /** Sub Total, main payment amount */
  pureSubTotal: Money;
  shipping: Money;
  /** Sub Total, main payment amount. Buyers premium included */
  subTotal: Money;
  tax: Money;
  total: Money;
};

export type CollectCheckoutV2DetailsVaultListingInput = {
  burbankSportscardsSection?: InputMaybe<CollectCheckoutV2DetailsBurbankSportscardsSectionInput>;
  fanaticsAuthenticSection?: InputMaybe<CollectCheckoutV2DetailsFanaticsAuthenticSectionInput>;
  fanaticsCollectSection?: InputMaybe<CollectCheckoutV2DetailsFanaticsCollectSectionInput>;
  id: Scalars['UUID']['input'];
};

export type CollectCheckoutV2FanaticsPaymentSummaryShipping = {
  __typename?: 'CollectCheckoutV2FanaticsPaymentSummaryShipping';
  address?: Maybe<CollectCheckoutV2Address>;
  type: CollectCheckoutV2FanaticsShippingType;
};

export type CollectCheckoutV2FanaticsShipping = {
  __typename?: 'CollectCheckoutV2FanaticsShipping';
  address?: Maybe<CollectCheckoutV2Address>;
  availableTypes: Array<CollectCheckoutV2FanaticsShippingType>;
  type: CollectCheckoutV2FanaticsShippingType;
};

/** Collect Shipping Type available for Fanatic Collect and Fanatics Authentic */
export enum CollectCheckoutV2FanaticsShippingType {
  /** The default account address */
  Default = 'DEFAULT',
  /**
   * User will to Vault themselves and pick up items in person.
   * Available only when paying for a Pick Up In Person VaultItemRequest Invoice.
   */
  InPerson = 'IN_PERSON',
  /** Requires shipping address */
  New = 'NEW',
  /**
   * To do a checkout, items will be shipped to a Trade Show.
   * Available only when paying for a Trade Show Checkout VaultItemRequest Invoice.
   */
  TradeShow = 'TRADE_SHOW',
  /** Vault address */
  Vault = 'VAULT'
}

export type CollectCheckoutV2LineItem = {
  __typename?: 'CollectCheckoutV2LineItem';
  amount: Money;
  /** Can the Item be purchased */
  available: Scalars['Boolean']['output'];
  date?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** Has reason why the item is not available */
  error?: Maybe<CollectCheckoutV2LineItemError>;
  id: Scalars['Int']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  title?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['UUID']['output'];
};

export type CollectCheckoutV2LineItemError = {
  __typename?: 'CollectCheckoutV2LineItemError';
  /** Code that explains why the item is not available */
  code: Scalars['String']['output'];
  /** Message that explains why the item is not available */
  message: Scalars['String']['output'];
};

export type CollectCheckoutV2LockForPaymentBurbankSportscardsSectionInput = {
  shipping?: InputMaybe<CollectCheckoutV2LockForPaymentBurbankSportscardsShippingInput>;
};

export type CollectCheckoutV2LockForPaymentBurbankSportscardsShippingInput = {
  /** Custom address. Required for NEW shipping_method */
  address?: InputMaybe<CollectCheckoutV2AddressInput>;
  methodId?: InputMaybe<Scalars['UUID']['input']>;
  type: CollectCheckoutV2BurbankShippingType;
};

export type CollectCheckoutV2LockForPaymentCartInput = {
  burbankSportscardsSection?: InputMaybe<CollectCheckoutV2LockForPaymentBurbankSportscardsSectionInput>;
  fanaticsAuthenticSection?: InputMaybe<CollectCheckoutV2LockForPaymentFanaticsAuthenticSectionInput>;
  fanaticsCollectSection?: InputMaybe<CollectCheckoutV2LockForPaymentFanaticsCollectSectionInput>;
  id: Scalars['UUID']['input'];
  /** Selected payment methods. Can be FUNDS, REGULAR_PM, or FUNDS+REGULAR_PM */
  paymentMethods: Array<CollectCheckoutV2PaymentMethod>;
};

export type CollectCheckoutV2LockForPaymentFanaticsAuthenticSectionInput = {
  shipping?: InputMaybe<CollectCheckoutV2LockForPaymentFanaticsAuthenticShippingInput>;
};

export type CollectCheckoutV2LockForPaymentFanaticsAuthenticShippingInput = {
  /** Custom address. Required for NEW shipping_method */
  address?: InputMaybe<CollectCheckoutV2AddressInput>;
  type: CollectCheckoutV2FanaticsShippingType;
};

export type CollectCheckoutV2LockForPaymentFanaticsCollectSectionInput = {
  shipping?: InputMaybe<CollectCheckoutV2LockForPaymentFanaticsCollectShippingInput>;
};

export type CollectCheckoutV2LockForPaymentFanaticsCollectShippingInput = {
  /** Custom address. Required for NEW shipping_method */
  address?: InputMaybe<CollectCheckoutV2AddressInput>;
  type: CollectCheckoutV2FanaticsShippingType;
};

export type CollectCheckoutV2LockForPaymentInvoiceInput = {
  fanaticsAuthenticSection?: InputMaybe<CollectCheckoutV2LockForPaymentFanaticsAuthenticSectionInput>;
  fanaticsCollectSection?: InputMaybe<CollectCheckoutV2LockForPaymentFanaticsCollectSectionInput>;
  id: Scalars['UUID']['input'];
  /** Selected payment methods. Can be FUNDS, REGULAR_PM, or FUNDS+REGULAR_PM */
  paymentMethods: Array<CollectCheckoutV2PaymentMethod>;
};

export type CollectCheckoutV2LockForPaymentPayload = {
  __typename?: 'CollectCheckoutV2LockForPaymentPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCheckoutV2LockForPaymentResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCheckoutV2LockForPaymentResponse = {
  __typename?: 'CollectCheckoutV2LockForPaymentResponse';
  /** Cart UUID to pay for */
  cartUuid?: Maybe<Scalars['UUID']['output']>;
  /** Stripe Client Secret. Empty for non-Stripe payments. */
  clientSecret?: Maybe<Scalars['String']['output']>;
  /** Invoice IDs to pay */
  invoiceIntegerIds: Array<Scalars['Int']['output']>;
  /** Invoice UUIDs to pay */
  invoiceUuids: Array<Scalars['UUID']['output']>;
  /** Stripe Payment Intent ID. Empty for non-Stripe payments. */
  paymentIntentId?: Maybe<Scalars['String']['output']>;
};

export type CollectCheckoutV2LockForPaymentVaultListingInput = {
  burbankSportscardsSection?: InputMaybe<CollectCheckoutV2LockForPaymentBurbankSportscardsSectionInput>;
  fanaticsAuthenticSection?: InputMaybe<CollectCheckoutV2LockForPaymentFanaticsAuthenticSectionInput>;
  fanaticsCollectSection?: InputMaybe<CollectCheckoutV2LockForPaymentFanaticsCollectSectionInput>;
  id: Scalars['UUID']['input'];
  /** Selected payment methods. Can be FUNDS, REGULAR_PM, or FUNDS+REGULAR_PM */
  paymentMethods: Array<CollectCheckoutV2PaymentMethod>;
};

export type CollectCheckoutV2Order = {
  __typename?: 'CollectCheckoutV2Order';
  /** The date when the invoices were placed (if multiple - picked the earliest) */
  date?: Maybe<Scalars['Date']['output']>;
  /** Invoice integer ID or concatenated by dash IDs if multiple */
  id: Scalars['String']['output'];
  /** The lowest status of all invoices related to this payment */
  status: CollectInvoiceStatus;
};

/** The payment for invoice. */
export type CollectCheckoutV2Payment = {
  __typename?: 'CollectCheckoutV2Payment';
  amount: Money;
  date?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  metadata?: Maybe<CollectInvoicePaymentAttemptData>;
  paymentMethod?: Maybe<CollectCheckoutV2PaymentMethod>;
};

export enum CollectCheckoutV2PaymentMethod {
  Ach = 'ACH',
  ApplePay = 'APPLE_PAY',
  Card = 'CARD',
  Crypto = 'CRYPTO',
  Funds = 'FUNDS',
  GooglePay = 'GOOGLE_PAY',
  Wire = 'WIRE'
}

export type CollectCheckoutV2PaymentSummary = {
  __typename?: 'CollectCheckoutV2PaymentSummary';
  burbankSportscardsSection?: Maybe<CollectCheckoutV2PaymentSummaryBurbankSportscardsSection>;
  fanaticsAuthenticSection?: Maybe<CollectCheckoutV2PaymentSummaryFanaticsAuthenticSection>;
  fanaticsCollectSection?: Maybe<CollectCheckoutV2PaymentSummaryFanaticsCollectSection>;
  /** Virtual Order property, generated from all related invoices */
  order?: Maybe<CollectCheckoutV2Order>;
  /** Successful payments */
  payments?: Maybe<Array<Maybe<CollectCheckoutV2Payment>>>;
  /** Payments that are not completed yet */
  pendingPayments?: Maybe<Array<Maybe<CollectCheckoutV2Payment>>>;
  totals: CollectCheckoutV2PaymentSummaryTotals;
  wireInfo: CollectInvoiceWireInfo;
};

export type CollectCheckoutV2PaymentSummaryBurbankSportscardsSection = {
  __typename?: 'CollectCheckoutV2PaymentSummaryBurbankSportscardsSection';
  /** Type of an invoice */
  invoiceType: CollectInvoiceType;
  /** Line items in the cart. For sales, listing is attached */
  lineItems?: Maybe<Array<Maybe<CollectLineItem>>>;
  seller: CollectSeller;
  /** Selected shipping */
  shipping: CollectCheckoutV2BurbankPaymentSummaryShipping;
  totals: CollectCheckoutV2PaymentSummarySectionTotals;
};

export type CollectCheckoutV2PaymentSummaryCartInput = {
  id: Scalars['UUID']['input'];
};

export type CollectCheckoutV2PaymentSummaryFanaticsAuthenticSection = {
  __typename?: 'CollectCheckoutV2PaymentSummaryFanaticsAuthenticSection';
  /** Type of an invoice */
  invoiceType: CollectInvoiceType;
  /** Line items in the cart. For sales, listing is attached */
  lineItems?: Maybe<Array<Maybe<CollectLineItem>>>;
  seller: CollectSeller;
  /** Selected shipping */
  shipping: CollectCheckoutV2FanaticsPaymentSummaryShipping;
  totals: CollectCheckoutV2PaymentSummarySectionTotals;
};

export type CollectCheckoutV2PaymentSummaryFanaticsCollectSection = {
  __typename?: 'CollectCheckoutV2PaymentSummaryFanaticsCollectSection';
  /** Type of an invoice */
  invoiceType: CollectInvoiceType;
  /** Line items in the cart. For sales, listing is attached */
  lineItems?: Maybe<Array<Maybe<CollectLineItem>>>;
  seller: CollectSeller;
  /** Selected shipping */
  shipping: CollectCheckoutV2FanaticsPaymentSummaryShipping;
  totals: CollectCheckoutV2PaymentSummarySectionTotals;
};

export type CollectCheckoutV2PaymentSummaryInvoiceInput = {
  id: Scalars['UUID']['input'];
};

export type CollectCheckoutV2PaymentSummarySectionTotals = {
  __typename?: 'CollectCheckoutV2PaymentSummarySectionTotals';
  shipping: Money;
  subTotal: Money;
  tax: Money;
  total: Money;
};

/** Overall payment amounts */
export type CollectCheckoutV2PaymentSummaryTotals = {
  __typename?: 'CollectCheckoutV2PaymentSummaryTotals';
  /** Amount that already been paid */
  amountPaid?: Maybe<Money>;
  /** Amount that is left to be paid */
  amountPaidOutstanding?: Maybe<Money>;
  /** Amount that goes to Seller. Already included in subtotal */
  buyersPremium: Money;
  /** Amount was added on top of total if paid by Credit Card, Apple Pay or Google Pay */
  creditCardFee: Money;
  /** Fraction of a payment amount that potentially be added on top of total if paid by Credit Card, Apple Pay or Google Pay */
  creditCardFeeRate: Scalars['Float']['output'];
  /** Sub Total, main payment amount */
  pureSubTotal: Money;
  shipping: Money;
  /** Sub Total, main payment amount. Buyers premium included */
  subTotal: Money;
  tax: Money;
  total: Money;
};

/** All supported Sellers */
export enum CollectCheckoutV2Seller {
  BurbankSportscards = 'BURBANK_SPORTSCARDS',
  FanaticsAuthentic = 'FANATICS_AUTHENTIC',
  FanaticsCollect = 'FANATICS_COLLECT'
}

/** Shipping Method, all require shipping address */
export type CollectCheckoutV2ShippingMethod = {
  __typename?: 'CollectCheckoutV2ShippingMethod';
  cost?: Maybe<Money>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  usDomestic: Scalars['Boolean']['output'];
};

export type CollectChekcoutInvoiceConfirmSinglePayload = {
  __typename?: 'CollectChekcoutInvoiceConfirmSinglePayload';
  amount: Money;
  /** The date the payment was made, in PT timezone. */
  paymentDate?: Maybe<Scalars['DateTime']['output']>;
  paymentTransactionId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export enum CollectCompletePaymentResultStatus {
  Fail = 'FAIL',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type CollectCounterOfferInput = {
  listingId: Scalars['UUID']['input'];
  offerId: Scalars['UUID']['input'];
  offerInCents: Scalars['Int']['input'];
  sendChatMessage?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CollectCountriesV2Payload = {
  __typename?: 'CollectCountriesV2Payload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCountriesV2Response>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCountriesV2Response = {
  __typename?: 'CollectCountriesV2Response';
  /** The top countries user can select shipping address from */
  collectAddressTopCountries?: Maybe<Array<Maybe<CollectCountry>>>;
  countries: Array<Maybe<CollectCountry>>;
};

export type CollectCountry = {
  __typename?: 'CollectCountry';
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CollectCreateInvoiceInput = {
  offerInCents: Scalars['Int']['input'];
  vaultListingId: Scalars['UUID']['input'];
};

export type CollectCreateInvoicePayload = {
  __typename?: 'CollectCreateInvoicePayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCreateInvoiceResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCreateInvoiceResponse = {
  __typename?: 'CollectCreateInvoiceResponse';
  invoice?: Maybe<CollectInvoice>;
};

export type CollectCreateItemEditRequestPayload = {
  __typename?: 'CollectCreateItemEditRequestPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCreateItemEditRequestResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

/** The response of Item Edit Request creation */
export type CollectCreateItemEditRequestResponse = {
  __typename?: 'CollectCreateItemEditRequestResponse';
  itemEditRequest?: Maybe<CollectItemEditRequest>;
};

export type CollectCreateListingInput = {
  allowOffers?: InputMaybe<Scalars['Boolean']['input']>;
  listingPriceInCents?: InputMaybe<Scalars['Int']['input']>;
  /** aka auction ID (if any) */
  marketplaceEventId?: InputMaybe<Scalars['String']['input']>;
  rejectPriceThresholdInCents?: InputMaybe<Scalars['Int']['input']>;
  type: CollectListingType;
  vaultItemId: Scalars['UUID']['input'];
};

export type CollectCreateOwnershipTransferInput = {
  items: Array<InputMaybe<Scalars['UUID']['input']>>;
  transferRecipientIdentity: Scalars['String']['input'];
};

export type CollectCreatePayoutRequestsInput = {
  requestedAmount: Scalars['Int']['input'];
};

export type CollectCreatePayoutRequestsPayload = {
  __typename?: 'CollectCreatePayoutRequestsPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCreatePayoutRequestsResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCreatePayoutRequestsResponse = {
  __typename?: 'CollectCreatePayoutRequestsResponse';
  accountFunds?: Maybe<CollectAccountFunds>;
  payoutRequestGroup?: Maybe<CollectPayoutRequestGroup>;
};

export type CollectCreatePickUpInPersonInput = {
  items: Array<Scalars['UUID']['input']>;
};

export type CollectCreatePickUpInPersonPayload = {
  __typename?: 'CollectCreatePickUpInPersonPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCreatePickUpInPersonResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCreatePickUpInPersonResponse = {
  __typename?: 'CollectCreatePickUpInPersonResponse';
  vaultItemRequest?: Maybe<CollectVaultItemRequest>;
};

export type CollectCreatePlaidLinkTokenPayload = {
  __typename?: 'CollectCreatePlaidLinkTokenPayload';
  expiration?: Maybe<Scalars['String']['output']>;
  linkToken?: Maybe<Scalars['String']['output']>;
  requestId?: Maybe<Scalars['String']['output']>;
};

export type CollectCreateReportPayload = {
  __typename?: 'CollectCreateReportPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCreateReportResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCreateReportResponse = {
  __typename?: 'CollectCreateReportResponse';
  /** Report ID */
  id: Scalars['UUID']['output'];
};

export type CollectCreateSavedSearchPayload = {
  __typename?: 'CollectCreateSavedSearchPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCreateSavedSearchResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCreateSavedSearchResponse = {
  __typename?: 'CollectCreateSavedSearchResponse';
  /** The Saved Search entry */
  savedSearch?: Maybe<CollectSavedSearch>;
};

export type CollectCreateTradeShowCheckoutInput = {
  items: Array<Scalars['UUID']['input']>;
  tradeShowId: Scalars['Int']['input'];
};

export type CollectCreateTradeShowCheckoutPayload = {
  __typename?: 'CollectCreateTradeShowCheckoutPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCreateTradeShowCheckoutResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCreateTradeShowCheckoutResponse = {
  __typename?: 'CollectCreateTradeShowCheckoutResponse';
  vaultItemRequest?: Maybe<CollectVaultItemRequest>;
};

export type CollectCreateVaultItemGroupPayload = {
  __typename?: 'CollectCreateVaultItemGroupPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectVaultItemGroup>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCreateVaultRetrievalInput = {
  alternateAddress?: InputMaybe<CollectVaultRetrievalAlternateAddress>;
  items: Array<InputMaybe<Scalars['UUID']['input']>>;
};

export type CollectCreateVaultRetrievalPayload = {
  __typename?: 'CollectCreateVaultRetrievalPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCreateVaultRetrievalResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCreateVaultRetrievalResponse = {
  __typename?: 'CollectCreateVaultRetrievalResponse';
  vaultItemRequest?: Maybe<CollectVaultItemRequest>;
};

export type CollectCreateVaultSubmissionInput = {
  estimatedValue: Scalars['Int']['input'];
  grader?: InputMaybe<CollectVaultSubmissionGrader>;
  intent: CollectVaultSubmissionIntent;
  quantity: Scalars['Int']['input'];
};

export type CollectCreateVaultSubmissionPayload = {
  __typename?: 'CollectCreateVaultSubmissionPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCreateVaultSubmissionResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCreateVaultSubmissionResponse = {
  __typename?: 'CollectCreateVaultSubmissionResponse';
  submission?: Maybe<CollectVaultSubmission>;
};

export type CollectCreateVaultTransferInput = {
  items: Array<InputMaybe<Scalars['UUID']['input']>>;
  recipientIdentity: Scalars['String']['input'];
};

export type CollectCreateVaultTransferPayload = {
  __typename?: 'CollectCreateVaultTransferPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectCreateVaultTransferResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectCreateVaultTransferResponse = {
  __typename?: 'CollectCreateVaultTransferResponse';
  vaultItemRequest?: Maybe<CollectVaultItemRequest>;
};

export type CollectCurrentUser = {
  __typename?: 'CollectCurrentUser';
  accountFunds?: Maybe<CollectAccountFunds>;
  /** The limit of bid user can make (depends on user trust level) */
  bidLimit?: Maybe<Scalars['Int']['output']>;
  /** Business name */
  businessName?: Maybe<Scalars['String']['output']>;
  canSellOnCustomAuction?: Maybe<Scalars['Boolean']['output']>;
  chatToken?: Maybe<Scalars['String']['output']>;
  collectBidIncrementConfig?: Maybe<Array<Maybe<CollectBidIncrementConfig>>>;
  collectCollection: CollectSetlist;
  collectDefaultAddress?: Maybe<CollectAddress>;
  collectHasVaultItemOverAppraisedValue?: Maybe<Scalars['Boolean']['output']>;
  collectInvoices?: Maybe<CollectInvoiceConnection>;
  collectListings?: Maybe<CollectListingsConnection>;
  collectListingsWithOffers?: Maybe<CollectListingConnection>;
  collectOffers?: Maybe<CollectFixedPriceListingOfferWithHistoryConnection>;
  collectOutbidBidsCount?: Maybe<Scalars['Int']['output']>;
  collectOverdueInvoicesCount?: Maybe<Scalars['Int']['output']>;
  collectPaymentMethods?: Maybe<Array<Maybe<CollectPaymentMethod>>>;
  collectPendingInvoicesCount?: Maybe<Scalars['Int']['output']>;
  collectPendingOffers?: Maybe<CollectPendingOffer>;
  collectPremierAuctionApproved?: Maybe<Scalars['Boolean']['output']>;
  collectPremierAuctionRegisteration?: Maybe<CollectRegistration>;
  collectRecentlyViewed?: Maybe<Array<Maybe<CollectRecentlyViewedItem>>>;
  collectSetlist: CollectSetlist;
  collectSetlists?: Maybe<CollectSetlistConnection>;
  collectTotalVaultItemsCount?: Maybe<Scalars['Int']['output']>;
  collectUnreadOffersCount?: Maybe<Scalars['Int']['output']>;
  collectVaultItemGroups?: Maybe<CollectVaultItemGroupsConnection>;
  /** Mobile app only supports type_member_transfer and type_fulfill. Use collect_vault_item_requests_v2 to avoid this limitation */
  collectVaultItemRequests?: Maybe<CollectVaultItemRequestsConnection>;
  collectVaultItemRequestsV2?: Maybe<CollectVaultItemRequestsConnection>;
  collectVaultItems?: Maybe<CollectVaultItemsConnection>;
  /** Get aggregated market value for a list of vault items */
  collectVaultItemsMarketValueStatistics?: Maybe<CollectVaultItemsMarketValueStatistics>;
  /** User specific Ship To address in vault submissions */
  collectVaultSubmissionAddress: CollectAddress;
  collectVaultSubmissions?: Maybe<CollectVaultSubmissionConnection>;
  collectVaultValue?: Maybe<Money>;
  collectWaitingOffersCount?: Maybe<Scalars['Int']['output']>;
  collectWatchlist?: Maybe<CollectWatchlistConnection>;
  collectWinningBidsCount?: Maybe<Scalars['Int']['output']>;
  /** The user's email address */
  email?: Maybe<CollectEmail>;
  /** The user's first name. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Indicates if user is frozen or not */
  frozen: Scalars['Boolean']['output'];
  hasNewOffersFromBidders?: Maybe<Scalars['Boolean']['output']>;
  hasNewOffersFromSellers?: Maybe<Scalars['Boolean']['output']>;
  kycStatus?: Maybe<CollectKycStatus>;
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The user's phone number. */
  phone?: Maybe<CollectPhone>;
  premierAuctionBids?: Maybe<CollectPremierAuctionBidConnection>;
  stripeAccountOnboarded: Scalars['Boolean']['output'];
  stripeLoginUrl?: Maybe<Scalars['String']['output']>;
  stripeOnboardingUrl?: Maybe<Scalars['String']['output']>;
  stripePublishableKey: Scalars['String']['output'];
  stripePublishableKey2: Scalars['String']['output'];
  /** The user's FanID tenant ID. */
  tenantId: Scalars['UUID']['output'];
  weeklyAuctionBids?: Maybe<CollectWeeklyAuctionBidConnection>;
};


export type CollectCurrentUserCollectHasVaultItemOverAppraisedValueArgs = {
  valueInCents: Scalars['Int']['input'];
};


export type CollectCurrentUserCollectInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  invoicePaymentStatus?: InputMaybe<CollectInvoicePaymentStatuses>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CollectCurrentUserCollectListingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bidType?: InputMaybe<CollectBidType>;
  categoryId?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eyeAppealGrade?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeBids?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  listingAuctionId?: InputMaybe<Scalars['UUID']['input']>;
  listingCategories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  listingCertifiedSellers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  listingEyeAppealGrades?: InputMaybe<Array<InputMaybe<CollectEyeAppealGrade>>>;
  listingGradingServices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  listingPricesRange?: InputMaybe<CollectListingPricesRangeFilter>;
  listingSorting?: InputMaybe<CollectListingSorting>;
  listingStatuses?: InputMaybe<Array<InputMaybe<CollectListingStatusFilter>>>;
  listingTitleTerm?: InputMaybe<Scalars['String']['input']>;
  listingTypes?: InputMaybe<Array<InputMaybe<CollectListingType>>>;
  listingYearsRange?: InputMaybe<CollectListingYearsRangeFilter>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CollectCurrentUserCollectListingsWithOffersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offerKind: CollectOfferKind;
  offerStatus?: InputMaybe<Array<InputMaybe<CollectOfferStatusFilter>>>;
};


export type CollectCurrentUserCollectOffersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offerKind: CollectOfferKind;
  offerStatus?: InputMaybe<Array<InputMaybe<CollectOfferStatusFilter>>>;
};


export type CollectCurrentUserCollectRecentlyViewedArgs = {
  itemTypes?: InputMaybe<Array<InputMaybe<CollectRecentlyViewedItemType>>>;
};


export type CollectCurrentUserCollectSetlistArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  kind?: InputMaybe<CollectSetlistKind>;
};


export type CollectCurrentUserCollectSetlistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CollectCurrentUserCollectVaultItemGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterStatus?: InputMaybe<CollectVaultItemGroupStatus>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<CollectVaultItemGroupSortBy>;
  type: CollectVaultItemGroupType;
  vaultItemGroupId?: InputMaybe<Scalars['ID']['input']>;
};


export type CollectCurrentUserCollectVaultItemRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<CollectVaultItemRequestStatus>;
  type?: InputMaybe<CollectVaultItemRequestType>;
};


export type CollectCurrentUserCollectVaultItemRequestsV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<CollectVaultItemRequestSortBy>;
  status?: InputMaybe<CollectVaultItemRequestStatus>;
  type?: InputMaybe<CollectVaultItemRequestType>;
};


export type CollectCurrentUserCollectVaultItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  filterListable?: InputMaybe<Scalars['Boolean']['input']>;
  filterListed?: InputMaybe<Scalars['Boolean']['input']>;
  filterProtoItemUuid?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  filterStatus?: InputMaybe<CollectVaultStatus>;
  filterVaultItemGroupId?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  grade?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  gradingServiceIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  origins?: InputMaybe<Array<InputMaybe<CollectVaultItemOrigin>>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<CollectVaultSortBy>;
  state?: InputMaybe<CollectVaultItemState>;
  vaultSubmission?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};


export type CollectCurrentUserCollectVaultItemsMarketValueStatisticsArgs = {
  vaultItemIds: Array<Scalars['ID']['input']>;
};


export type CollectCurrentUserCollectVaultSubmissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<CollectVaultSubmissionStatus>;
  withCuratedItems?: InputMaybe<Scalars['Boolean']['input']>;
  withInternal?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CollectCurrentUserCollectWatchlistArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  listingAllowsOffers?: InputMaybe<Scalars['Boolean']['input']>;
  listingAuctionId?: InputMaybe<Scalars['UUID']['input']>;
  listingCategories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  listingCertifiedSellers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  listingEyeAppealGrades?: InputMaybe<Array<InputMaybe<CollectEyeAppealGrade>>>;
  listingGradingServices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  listingIsGreatPrice?: InputMaybe<Scalars['Boolean']['input']>;
  listingPricesRange?: InputMaybe<CollectListingPricesRangeFilter>;
  listingSorting?: InputMaybe<CollectListingSorting>;
  listingStatuses?: InputMaybe<Array<InputMaybe<CollectListingStatusFilter>>>;
  listingTitleTerm?: InputMaybe<Scalars['String']['input']>;
  listingTypes?: InputMaybe<Array<InputMaybe<CollectListingType>>>;
  listingYearsRange?: InputMaybe<CollectListingYearsRangeFilter>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CollectCurrentUserPremierAuctionBidsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CollectCurrentUserStripeOnboardingUrlArgs = {
  returnUrl: Scalars['String']['input'];
};


export type CollectCurrentUserWeeklyAuctionBidsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectDeleteSavedSearchPayload = {
  __typename?: 'CollectDeleteSavedSearchPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['String']['output']>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectDeleteVaultItemGroupPayload = {
  __typename?: 'CollectDeleteVaultItemGroupPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectDetailed = {
  debugMetadata?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type CollectDetailedActivity = {
  __typename?: 'CollectDetailedActivity';
  stub?: Maybe<Scalars['String']['output']>;
};

export type CollectDetailedSales = {
  __typename?: 'CollectDetailedSales';
  stub?: Maybe<Scalars['String']['output']>;
};

export type CollectDetailedStats = {
  __typename?: 'CollectDetailedStats';
  stub?: Maybe<Scalars['String']['output']>;
};

/** An email address for a user in marketplace. */
export type CollectEmail = {
  __typename?: 'CollectEmail';
  /** The email address. */
  address?: Maybe<Scalars['String']['output']>;
  /** Whether the email address is verified. */
  verified?: Maybe<Scalars['Boolean']['output']>;
};

/** Object which represents videos on external resources, like Vimeo */
export type CollectExternalVideo = {
  __typename?: 'CollectExternalVideo';
  /** Full link to the video */
  link: Scalars['String']['output'];
  /**
   * Links to the video thumbnails.
   * If no sizes argument given, will contain a link to the 640x360 image.
   * Otherwise, returns a list of link in order of the sizes given
   */
  thumbnails: Array<Scalars['String']['output']>;
};


/** Object which represents videos on external resources, like Vimeo */
export type CollectExternalVideoThumbnailsArgs = {
  sizes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum CollectEyeAppealGrade {
  /** Eye Appeal - A */
  EyeAppealA = 'EYE_APPEAL_A',
  /** Eye Appeal - E */
  EyeAppealE = 'EYE_APPEAL_E',
  /** Eye Appeal - S */
  EyeAppealS = 'EYE_APPEAL_S',
  /** PWCC-A */
  PwccA = 'PWCC_A',
  /** PWCC-E */
  PwccE = 'PWCC_E',
  /** PWCC-S */
  PwccS = 'PWCC_S'
}

/** Parameters for initiating authentication with FanID */
export type CollectFanIdAuthParams = {
  __typename?: 'CollectFanIdAuthParams';
  applicationClientId: Scalars['String']['output'];
  nonce: Scalars['String']['output'];
  prompt: Scalars['String']['output'];
  redirectUri: Scalars['String']['output'];
  responseType: Scalars['String']['output'];
  scope: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type CollectFilterOption = {
  __typename?: 'CollectFilterOption';
  displayName: Scalars['String']['output'];
  optionKey: Scalars['String']['output'];
  optionValues?: Maybe<Array<Maybe<CollectOptionValue>>>;
};

export type CollectFixedPriceAcceptOfferPayload = {
  __typename?: 'CollectFixedPriceAcceptOfferPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectFixedPriceAcceptOfferResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectFixedPriceAcceptOfferResponse = {
  __typename?: 'CollectFixedPriceAcceptOfferResponse';
  invoice?: Maybe<CollectInvoice>;
  offer?: Maybe<CollectFixedPriceListingOfferWithHistory>;
};

export type CollectFixedPriceListingOffer = {
  __typename?: 'CollectFixedPriceListingOffer';
  amount: Money;
  bidderViewedAt?: Maybe<Scalars['DateTime']['output']>;
  bids?: Maybe<Array<Maybe<CollectFixedPriceListingOfferBid>>>;
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  insertedAt: Scalars['DateTime']['output'];
  integerId: Scalars['Int']['output'];
  listing?: Maybe<CollectListing>;
  listingId: Scalars['UUID']['output'];
  paymentDetails?: Maybe<CollectFixedPriceOfferPaymentDetails>;
  sellerViewedAt?: Maybe<Scalars['DateTime']['output']>;
  status: CollectFixedPriceOfferStatus;
};

export type CollectFixedPriceListingOfferBid = {
  __typename?: 'CollectFixedPriceListingOfferBid';
  amount: Money;
  expiresAt: Scalars['DateTime']['output'];
  insertedAt: Scalars['DateTime']['output'];
  integerId: Scalars['Int']['output'];
  status: CollectFixedPriceListingOfferBidStatus;
};

export enum CollectFixedPriceListingOfferBidStatus {
  StatusBidderAccepted = 'STATUS_BIDDER_ACCEPTED',
  StatusBidderDeclined = 'STATUS_BIDDER_DECLINED',
  StatusBidderRemoved = 'STATUS_BIDDER_REMOVED',
  StatusCounteredByBidder = 'STATUS_COUNTERED_BY_BIDDER',
  StatusCounteredBySeller = 'STATUS_COUNTERED_BY_SELLER',
  StatusSellerAccepted = 'STATUS_SELLER_ACCEPTED',
  StatusSellerDeclined = 'STATUS_SELLER_DECLINED',
  StatusSellerRemoved = 'STATUS_SELLER_REMOVED',
  StatusWaitingForBidder = 'STATUS_WAITING_FOR_BIDDER',
  StatusWaitingForSeller = 'STATUS_WAITING_FOR_SELLER'
}

export type CollectFixedPriceListingOfferWithHistory = {
  __typename?: 'CollectFixedPriceListingOfferWithHistory';
  amount: Money;
  bidderViewedAt?: Maybe<Scalars['DateTime']['output']>;
  bids?: Maybe<Array<Maybe<CollectFixedPriceListingOfferBid>>>;
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  insertedAt: Scalars['DateTime']['output'];
  integerId: Scalars['Int']['output'];
  listing?: Maybe<CollectListing>;
  listingId: Scalars['UUID']['output'];
  paymentDetails?: Maybe<CollectFixedPriceOfferPaymentDetails>;
  previousOffers?: Maybe<Array<Maybe<CollectFixedPriceListingOffer>>>;
  sellerViewedAt?: Maybe<Scalars['DateTime']['output']>;
  status: CollectFixedPriceOfferStatus;
};

export type CollectFixedPriceListingOfferWithHistoryConnection = {
  __typename?: 'CollectFixedPriceListingOfferWithHistoryConnection';
  edges?: Maybe<Array<Maybe<CollectFixedPriceListingOfferWithHistoryEdge>>>;
  pageInfo: PageInfo;
};

export type CollectFixedPriceListingOfferWithHistoryEdge = {
  __typename?: 'CollectFixedPriceListingOfferWithHistoryEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectFixedPriceListingOfferWithHistory>;
};

export type CollectFixedPriceOfferPayload = {
  __typename?: 'CollectFixedPriceOfferPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectFixedPriceOfferResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectFixedPriceOfferPaymentDetails = {
  __typename?: 'CollectFixedPriceOfferPaymentDetails';
  invoiceId?: Maybe<Scalars['UUID']['output']>;
  paymentDueDate?: Maybe<Scalars['DateTime']['output']>;
};

export type CollectFixedPriceOfferResponse = {
  __typename?: 'CollectFixedPriceOfferResponse';
  offer?: Maybe<CollectFixedPriceListingOfferWithHistory>;
};

export enum CollectFixedPriceOfferStatus {
  StatusBidderAccepted = 'STATUS_BIDDER_ACCEPTED',
  StatusBidderDeclined = 'STATUS_BIDDER_DECLINED',
  StatusBidderRemoved = 'STATUS_BIDDER_REMOVED',
  StatusSellerAccepted = 'STATUS_SELLER_ACCEPTED',
  StatusSellerDeclined = 'STATUS_SELLER_DECLINED',
  StatusSellerRemoved = 'STATUS_SELLER_REMOVED',
  StatusWaitingForBidder = 'STATUS_WAITING_FOR_BIDDER',
  StatusWaitingForSeller = 'STATUS_WAITING_FOR_SELLER'
}

export type CollectFixedPriceSale = CollectMarketplaceSale & {
  __typename?: 'CollectFixedPriceSale';
  buyersPremium?: Maybe<Money>;
  id: Scalars['UUID']['output'];
  integerId: Scalars['Int']['output'];
  /** Not affected by faster listing query optimization */
  listing?: Maybe<CollectListing>;
  purchasePrice?: Maybe<Money>;
  shipping?: Maybe<Money>;
  tax?: Maybe<Money>;
  total?: Maybe<Money>;
};

export type CollectFulfillmentChargesInput = {
  alternateShippingAddress?: InputMaybe<CollectAlternatingShippingAddress>;
  invoiceId: Scalars['UUID']['input'];
  shippingType: CollectShippingType;
};

export type CollectFulfillmentChargesPayload = {
  __typename?: 'CollectFulfillmentChargesPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectFulfillmentChargesResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

/** The response of a fulfillment charges calculation. */
export type CollectFulfillmentChargesResponse = {
  __typename?: 'CollectFulfillmentChargesResponse';
  /** The amount of funds that can be used to pay an invoice. */
  accountFundsToUse: Money;
  /** The amount that left to pay. Takes pending payments into account. */
  balanceDue: Money;
  /** The amount that left to pay with potential credit card fee. Takes pending payments into account. */
  potentialBalanceDueWithCreditCardFee: Money;
  /** Potential fee that will be applied if the user pays with a credit card, Apple Pay or Google Pay. */
  potentialCreditCardFee: Money;
  /** Total amount of the invoice with a potential Credit Card, Apple Pay or Google Pay fee. */
  potentialTotalWithCreditCardFee: Money;
  /** Shipping cost. */
  shipping: Money;
  /** Tax amount. */
  tax: Money;
  taxExempt: Scalars['Boolean']['output'];
  /** Total amount of the invoice without a potential Credit Card, Apple Pay or Google Pay fee. */
  total: Money;
};

export type CollectGoogleResult = {
  __typename?: 'CollectGoogleResult';
  link?: Maybe<Scalars['String']['output']>;
  snippet?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Grader of a card */
export type CollectGrader = {
  __typename?: 'CollectGrader';
  /** Ordered list of possible grades */
  grades: Array<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  /** SVG logo */
  logo: Scalars['String']['output'];
  /** Name of the grader. Example: PSA */
  name: Scalars['String']['output'];
};

export type CollectGradingService = {
  __typename?: 'CollectGradingService';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CollectGradingServices = {
  __typename?: 'CollectGradingServices';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** The allowed file types for image uploads */
export enum CollectImageFileType {
  Jpeg = 'JPEG',
  Jpg = 'JPG',
  Png = 'PNG'
}

export type CollectImageMetadata = {
  __typename?: 'CollectImageMetadata';
  height?: Maybe<Scalars['Int']['output']>;
  orientation?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type CollectImageSet = {
  __typename?: 'CollectImageSet';
  large?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  small?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type CollectInvoice = {
  __typename?: 'CollectInvoice';
  allowedPaymentMethods?: Maybe<Array<Maybe<CollectValidPaymentMethod>>>;
  /** The paid invoice amount. */
  amountPaid?: Maybe<Money>;
  /** The oustanding invoice amount. */
  amountPaidOutstanding?: Maybe<Money>;
  /** The business category for an Invoice (marketplace | vault | capital). */
  businessCategory?: Maybe<CollectInvoiceBusinessCategory>;
  /** The Buyers Premium field (purchase price + 20%). */
  buyersPremium?: Maybe<Money>;
  cart?: Maybe<CollectInvoiceCart>;
  createdBy?: Maybe<CollectInvoiceCreatedByEntity>;
  /** The date by which an invoice should be paid. */
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  integerId: Scalars['Int']['output'];
  /**
   * The invoice category for an Invoice
   *       (marketplace | vault | capital | weekly | premier | fixed_price | manual).
   */
  invoiceCategory?: Maybe<CollectInvoiceCategory>;
  /** The date an invoice was created. */
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  /** The list of items invoice includes. */
  lineItems?: Maybe<Array<Maybe<CollectLineItem>>>;
  /** The date an invoice was paid. */
  paidDate?: Maybe<Scalars['DateTime']['output']>;
  /** The list of Invoice Payment Attempts. */
  paymentAttempts?: Maybe<Array<Maybe<CollectPaymentAttemptData>>>;
  /** The payment fees amount applied to payment, such as Credit Card Fee. */
  paymentFees: Money;
  /** The number of days to pay an invoice. */
  paymentTermDays: Scalars['Int']['output'];
  /** The list of invoice payments. */
  payments?: Maybe<Array<Maybe<CollectPayment>>>;
  /** The list of invoice pending payments. */
  pendingPayments?: Maybe<Array<Maybe<CollectPayment>>>;
  sales?: Maybe<Array<Maybe<CollectMarketplaceSale>>>;
  /** The cost of shipping of purchased items. */
  shipping: Money;
  /** The Invoice Shipping Address. */
  shippingAddress?: Maybe<CollectShippingAddress>;
  /** The Invoice Shipping Type. */
  shippingType?: Maybe<CollectShippingType>;
  status: CollectInvoiceStatus;
  /** The sum of items price, shipping, tax and fees. */
  subTotal: Money;
  /** The total amount of tax applied to an invoice. */
  tax: Money;
  /** The total amount of an invoice. */
  total: Money;
  type: CollectInvoiceType;
  wireInfo: CollectInvoiceWireInfo;
};

export enum CollectInvoiceBusinessCategory {
  Capital = 'capital',
  Marketplace = 'marketplace',
  Vault = 'vault'
}

export type CollectInvoiceCart = {
  __typename?: 'CollectInvoiceCart';
  id: Scalars['UUID']['output'];
  integerId: Scalars['Int']['output'];
  status?: Maybe<CollectCartStatus>;
};

export enum CollectInvoiceCategory {
  Capital = 'capital',
  FixedPrice = 'fixed_price',
  Manual = 'manual',
  Marketplace = 'marketplace',
  Premier = 'premier',
  Vault = 'vault',
  Weekly = 'weekly'
}

export type CollectInvoiceConnection = {
  __typename?: 'CollectInvoiceConnection';
  edges?: Maybe<Array<Maybe<CollectInvoiceEdge>>>;
  pageInfo: PageInfo;
};

export type CollectInvoiceCreatedByEntity = CollectFixedPriceSale | CollectPremierSale | CollectVaultItemRequest | CollectVaultSubmission | CollectWeeklySale;

export type CollectInvoiceEdge = {
  __typename?: 'CollectInvoiceEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectInvoice>;
};

export type CollectInvoiceInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  integerId?: InputMaybe<Scalars['Int']['input']>;
};

/** Invoice Payment Attempt data object. */
export type CollectInvoicePaymentAttemptData = {
  __typename?: 'CollectInvoicePaymentAttemptData';
  authorizationId?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<Scalars['String']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  payerEmail?: Maybe<Scalars['String']['output']>;
  payerFirstName?: Maybe<Scalars['String']['output']>;
  payerLastName?: Maybe<Scalars['String']['output']>;
  processorResponseCode?: Maybe<Scalars['String']['output']>;
  processorResponseText?: Maybe<Scalars['String']['output']>;
  processorResponseType?: Maybe<Scalars['String']['output']>;
  transactionFeeAmount?: Maybe<Scalars['String']['output']>;
};

export enum CollectInvoicePaymentStatuses {
  StatusCurrent = 'STATUS_CURRENT',
  StatusPaid = 'STATUS_PAID',
  StatusUnpaid = 'STATUS_UNPAID'
}

export enum CollectInvoicePaymentTypes {
  PaymentTypeCard = 'PAYMENT_TYPE_CARD',
  PaymentTypeUsBankAccount = 'PAYMENT_TYPE_US_BANK_ACCOUNT'
}

export enum CollectInvoiceStatus {
  StatusBuyerDefaulted = 'STATUS_BUYER_DEFAULTED',
  StatusDraft = 'STATUS_DRAFT',
  StatusPaid = 'STATUS_PAID',
  StatusPartiallyPaid = 'STATUS_PARTIALLY_PAID',
  StatusPaymentPending = 'STATUS_PAYMENT_PENDING',
  StatusSent = 'STATUS_SENT',
  StatusTimedOut = 'STATUS_TIMED_OUT',
  StatusViewed = 'STATUS_VIEWED',
  StatusVoided = 'STATUS_VOIDED'
}

export enum CollectInvoiceType {
  TypeAuctionSale = 'TYPE_AUCTION_SALE',
  TypeCapitalNote = 'TYPE_CAPITAL_NOTE',
  TypeCashAdvance = 'TYPE_CASH_ADVANCE',
  TypeDeferredCheckoutFees = 'TYPE_DEFERRED_CHECKOUT_FEES',
  TypeDeferredVaultFees = 'TYPE_DEFERRED_VAULT_FEES',
  TypeExtendedSale = 'TYPE_EXTENDED_SALE',
  TypeGradingFees = 'TYPE_GRADING_FEES',
  TypeInboundShippingLabel = 'TYPE_INBOUND_SHIPPING_LABEL',
  TypeManual = 'TYPE_MANUAL',
  TypeVaultFees = 'TYPE_VAULT_FEES',
  TypeVaultItemRequest = 'TYPE_VAULT_ITEM_REQUEST',
  TypeVaultPortfolioLoan = 'TYPE_VAULT_PORTFOLIO_LOAN',
  TypeVaultPortfolioLoanInterest = 'TYPE_VAULT_PORTFOLIO_LOAN_INTEREST',
  TypeVaultSale = 'TYPE_VAULT_SALE'
}

export type CollectInvoiceWireInfo = {
  __typename?: 'CollectInvoiceWireInfo';
  accountNumber: Scalars['String']['output'];
  bankAddressBusinessName: Scalars['String']['output'];
  bankAddressCity: Scalars['String']['output'];
  bankAddressName: Scalars['String']['output'];
  bankAddressState: Scalars['String']['output'];
  bankAddressStreet1: Scalars['String']['output'];
  bankAddressZipcode: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  routing: Scalars['String']['output'];
  swiftCode: Scalars['String']['output'];
};

/** Card or any other item which is owned by some user */
export type CollectItem = CollectDetailed & {
  __typename?: 'CollectItem';
  debugMetadata?: Maybe<Scalars['String']['output']>;
  grade?: Maybe<Scalars['String']['output']>;
  grader?: Maybe<CollectGrader>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isCandidate: Scalars['Boolean']['output'];
  marketValue?: Maybe<Money>;
  metadata?: Maybe<CollectProductMetadata>;
  myPrice: Money;
  notes?: Maybe<Scalars['String']['output']>;
  owner: CollectPublicUser;
  purchasePrice?: Maybe<Money>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  userPrice?: Maybe<Money>;
  variant?: Maybe<CollectVariant>;
};

/** A list of fields which can be updated in an item */
export type CollectItemChanges = {
  grade?: InputMaybe<Scalars['String']['input']>;
  graderId?: InputMaybe<Scalars['UUID']['input']>;
  marketValueInCents?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  purchasePriceInCents?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectItemConnection = {
  __typename?: 'CollectItemConnection';
  edges?: Maybe<Array<Maybe<CollectItemEdge>>>;
  pageInfo: PageInfo;
};

export type CollectItemEdge = {
  __typename?: 'CollectItemEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectItem>;
};

/** Item Edit Request fields */
export type CollectItemEditRequest = {
  __typename?: 'CollectItemEditRequest';
  /** The Item Edit Request entity id */
  entityId: Scalars['Int']['output'];
  /** The Item Edit Request entity type */
  entityType: CollectItemEditRequestEntityTypes;
  /** The Item Edit Request integer id */
  id: Scalars['Int']['output'];
  /** Inserted timestamp */
  insertedAt: Scalars['DateTime']['output'];
  /** The Item Edit Request priority id (low, standard, high) */
  itemEditRequestPriorityId: CollectItemEditRequestPriority;
  /** The attendant notes */
  notes?: Maybe<Scalars['String']['output']>;
  /** The new requested item title */
  propertyEdit: Scalars['String']['output'];
  /** The original title */
  propertyOriginal: Scalars['String']['output'];
  /** The Reply for the Request, initially is nil */
  reply?: Maybe<Scalars['String']['output']>;
  /** The Item Edit Request status */
  status: CollectItemEditRequestStatus;
  /** The Item Edit Request type */
  type: CollectItemEditRequestTypes;
  /** Updated timestamp */
  updatedAt: Scalars['DateTime']['output'];
  /** The item owner user id */
  userId: Scalars['Int']['output'];
};

export type CollectItemEditRequestConnection = {
  __typename?: 'CollectItemEditRequestConnection';
  edges?: Maybe<Array<Maybe<CollectItemEditRequestEdge>>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type CollectItemEditRequestEdge = {
  __typename?: 'CollectItemEditRequestEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectItemEditRequest>;
};

/** Entity types of Items Edit Requests */
export enum CollectItemEditRequestEntityTypes {
  EntityTypeItem = 'ENTITY_TYPE_ITEM',
  EntityTypeVaultItem = 'ENTITY_TYPE_VAULT_ITEM'
}

/** Priorities of Items Edit Requests */
export enum CollectItemEditRequestPriority {
  PriorityHigh = 'PRIORITY_HIGH',
  PriorityLow = 'PRIORITY_LOW',
  PriorityStandard = 'PRIORITY_STANDARD'
}

/** Statuses of Items Edit Requests */
export enum CollectItemEditRequestStatus {
  StatusAccepted = 'STATUS_ACCEPTED',
  StatusPending = 'STATUS_PENDING',
  StatusRejected = 'STATUS_REJECTED'
}

/** Types of Items Edit Requests */
export enum CollectItemEditRequestTypes {
  TypeAuctionTitle = 'TYPE_AUCTION_TITLE',
  TypeVaultTitle = 'TYPE_VAULT_TITLE'
}

export type CollectItemInput = {
  candidateImages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  candidateTitle: Scalars['String']['input'];
  grade?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  isCandidate?: InputMaybe<Scalars['Boolean']['input']>;
  marketValueInCents?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<CollectItemMetadataInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  purchasePriceInCents?: InputMaybe<Scalars['Int']['input']>;
  userPriceInCents?: InputMaybe<Scalars['Int']['input']>;
  variantId?: InputMaybe<Scalars['UUID']['input']>;
};

export type CollectItemMetadataInput = {
  brand?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parallel?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Scalars['String']['input']>;
  signatures?: InputMaybe<Scalars['String']['input']>;
  sport?: InputMaybe<Scalars['String']['input']>;
  subcategory?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  subset?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type CollectItemOrProductOrListingOrVariant = CollectItem | CollectListing | CollectProduct | CollectVariant;

export type CollectItemOrProductOrListingOrVariantConnection = {
  __typename?: 'CollectItemOrProductOrListingOrVariantConnection';
  availableFacets?: Maybe<Array<Maybe<CollectFilterOption>>>;
  edges?: Maybe<Array<Maybe<CollectItemOrProductOrListingOrVariantEdge>>>;
  pageInfo: PageInfo;
  totalHits?: Maybe<Scalars['Int']['output']>;
};

export type CollectItemOrProductOrListingOrVariantEdge = {
  __typename?: 'CollectItemOrProductOrListingOrVariantEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectItemOrProductOrListingOrVariant>;
};

export enum CollectKycStatus {
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED'
}

/** The item an invoice includes. */
export type CollectLineItem = {
  __typename?: 'CollectLineItem';
  amount: Money;
  date?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Not affected by faster listing query optimization */
  listing?: Maybe<CollectListing>;
  title?: Maybe<Scalars['String']['output']>;
};

/**
 * Marketplace auction listing.
 * It resolved by two different queries depending on the data you query.
 * To use the fast query, select only fields which are marked as `fast`.
 *
 * Relation fields (like variant or auction) are considered `fast`, but
 * they're slowing down the query, so please make sure that your queries
 * are granular.
 */
export type CollectListing = CollectDetailed & {
  __typename?: 'CollectListing';
  /** Identifies whether a vault listing is added to a cart */
  addedToCart?: Maybe<Scalars['Boolean']['output']>;
  /** Whether offers are allowed for specified listing. Fast field */
  allowOffers: Scalars['Boolean']['output'];
  /** Asking price of the fixed price listing. Fast field */
  askingPrice?: Maybe<Money>;
  auction?: Maybe<CollectAuction>;
  /** Amount of bids made on this listing. Fast field */
  bidCount?: Maybe<Scalars['Int']['output']>;
  /** Buy now price. Fast field */
  buyNowPrice?: Maybe<Money>;
  cardBrand?: Maybe<Scalars['String']['output']>;
  cardLadderInfo?: Maybe<CollectListingCardLadderInfo>;
  certifiedSeller?: Maybe<Scalars['String']['output']>;
  collectSales?: Maybe<Array<Maybe<CollectSale>>>;
  /** Current bid of the weekly/premier listing. Fast field */
  currentBid?: Maybe<Money>;
  debugMetadata?: Maybe<Scalars['String']['output']>;
  /** Decline price for fixed price listings. Fast field */
  declinePrice?: Maybe<Money>;
  /** Default item description in current weekly auction. Always NULL for premier and fixed_price */
  defaultItemDescription?: Maybe<Scalars['String']['output']>;
  /** Description of the listing. Fast field. */
  description?: Maybe<Scalars['String']['output']>;
  /** Count of users who have this item in their favorites. Fast field */
  favoritedCount?: Maybe<Scalars['Int']['output']>;
  highestBidder: Scalars['Boolean']['output'];
  /** UUID of the listing. Fast field */
  id: Scalars['UUID']['output'];
  imageSets: Array<Maybe<CollectImageSet>>;
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  incomingOffers?: Maybe<Array<Maybe<CollectFixedPriceListingOfferWithHistory>>>;
  /** Timestamp of insertion of the listing. Fast field */
  insertedAt?: Maybe<Scalars['DateTime']['output']>;
  integerId?: Maybe<Scalars['Int']['output']>;
  isFlash: Scalars['Boolean']['output'];
  isOwner: Scalars['Boolean']['output'];
  /** Type of the listing. Fast field */
  listingType: CollectListingType;
  /** Lot number of the listing. Fast field. */
  lotNo?: Maybe<Scalars['Int']['output']>;
  lotString?: Maybe<Scalars['String']['output']>;
  /** Lot subnumber of the listing. Fast field. */
  lotSubNo?: Maybe<Scalars['Int']['output']>;
  marketplaceEyeAppeal?: Maybe<Scalars['String']['output']>;
  moreFromTheBrandListings?: Maybe<Array<Maybe<CollectListing>>>;
  moreFromTheSellerListings?: Maybe<Array<Maybe<CollectListing>>>;
  myOffer?: Maybe<CollectFixedPriceListingOfferWithHistory>;
  offerLimits?: Maybe<CollectOfferLimits>;
  offerRecommendedPrice?: Maybe<Money>;
  ownerId: Scalars['UUID']['output'];
  paymentDetails?: Maybe<CollectListingPaymentDetails>;
  pendingOffer?: Maybe<CollectListingPendingOffer>;
  /** The previous asking price of the fixed price listing. Fast field */
  previousAskingPrice?: Maybe<Money>;
  /** AI-generated product detail page for the listing. Fast field. */
  productDetail?: Maybe<Scalars['String']['output']>;
  quantityAvailable?: Maybe<Scalars['Int']['output']>;
  quickOffers?: Maybe<Array<Maybe<CollectQuickOffer>>>;
  relatedListings?: Maybe<Array<Maybe<CollectListing>>>;
  /** Unique slug of the listing. Fast field */
  slug?: Maybe<Scalars['String']['output']>;
  /**
   * The date a listing was sold.
   * @deprecated Use `collectSales.soldDate` instead
   */
  soldDate?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use `collectSales.soldFor` instead */
  soldFor?: Maybe<Money>;
  /** Starting price of the listing. Fast field */
  startingPrice?: Maybe<Money>;
  /** Listing states based on user context. Fast field */
  states?: Maybe<CollectListingStates>;
  /** Listing status. Fast field. */
  status?: Maybe<CollectListingStatus>;
  subtitle?: Maybe<Scalars['String']['output']>;
  suggestedMinimumOfferValue?: Maybe<Money>;
  title: Scalars['String']['output'];
  /** Timestamp of last update of the listing. Fast field */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Variant this listing is representing. */
  variant?: Maybe<CollectVariant>;
  /** Vault item this listing is representing. */
  vaultItem: CollectVaultItem;
};


/**
 * Marketplace auction listing.
 * It resolved by two different queries depending on the data you query.
 * To use the fast query, select only fields which are marked as `fast`.
 *
 * Relation fields (like variant or auction) are considered `fast`, but
 * they're slowing down the query, so please make sure that your queries
 * are granular.
 */
export type CollectListingIncomingOffersArgs = {
  offerStatus?: InputMaybe<Array<InputMaybe<CollectOfferStatusFilter>>>;
};


/**
 * Marketplace auction listing.
 * It resolved by two different queries depending on the data you query.
 * To use the fast query, select only fields which are marked as `fast`.
 *
 * Relation fields (like variant or auction) are considered `fast`, but
 * they're slowing down the query, so please make sure that your queries
 * are granular.
 */
export type CollectListingMoreFromTheBrandListingsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Marketplace auction listing.
 * It resolved by two different queries depending on the data you query.
 * To use the fast query, select only fields which are marked as `fast`.
 *
 * Relation fields (like variant or auction) are considered `fast`, but
 * they're slowing down the query, so please make sure that your queries
 * are granular.
 */
export type CollectListingMoreFromTheSellerListingsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Marketplace auction listing.
 * It resolved by two different queries depending on the data you query.
 * To use the fast query, select only fields which are marked as `fast`.
 *
 * Relation fields (like variant or auction) are considered `fast`, but
 * they're slowing down the query, so please make sure that your queries
 * are granular.
 */
export type CollectListingRelatedListingsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectListingCardLadderInfo = {
  __typename?: 'CollectListingCardLadderInfo';
  confidenceValue?: Maybe<Scalars['Int']['output']>;
  estimatedRange: CollectListingCardLadderRange;
  estimatedValue: Money;
  searchUrl?: Maybe<Scalars['String']['output']>;
};

export type CollectListingCardLadderRange = {
  __typename?: 'CollectListingCardLadderRange';
  max: Money;
  min: Money;
};

export type CollectListingConnection = {
  __typename?: 'CollectListingConnection';
  edges?: Maybe<Array<Maybe<CollectListingEdge>>>;
  pageInfo: PageInfo;
};

/** Smaller version of collectListing object. Please prefer refetching the listing if you want all fields */
export type CollectListingDelta = {
  __typename?: 'CollectListingDelta';
  allowBidding?: Maybe<Scalars['Boolean']['output']>;
  askingPrice?: Maybe<Money>;
  bidCount?: Maybe<Scalars['Int']['output']>;
  currentBid?: Maybe<Money>;
  favoritedCount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  isGreatPrice?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CollectListingEdge = {
  __typename?: 'CollectListingEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectListing>;
};

export type CollectListingFilterOpts = {
  facetFilters?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  similarQuery?: InputMaybe<Scalars['String']['input']>;
  userToken?: InputMaybe<Scalars['String']['input']>;
};

export type CollectListingFilters = {
  auctionScheduleIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  listingTypes?: InputMaybe<Array<InputMaybe<CollectListingType>>>;
};

export type CollectListingIdInput = {
  /** Listing ID */
  id: Scalars['UUID']['input'];
  /** Type of the listing */
  type: CollectListingType;
};

export type CollectListingIntegerIdInput = {
  id: Scalars['Int']['input'];
  type: CollectListingType;
};

export type CollectListingPaymentDetails = {
  __typename?: 'CollectListingPaymentDetails';
  invoiceId?: Maybe<Scalars['UUID']['output']>;
  paymentDueDate?: Maybe<Scalars['DateTime']['output']>;
};

export type CollectListingPendingOffer = {
  __typename?: 'CollectListingPendingOffer';
  hasPending?: Maybe<Scalars['Boolean']['output']>;
  maxAmountInCents?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CollectListingPricesRangeFilter = {
  maxPriceInCents?: InputMaybe<Scalars['Int']['input']>;
  minPriceInCents?: InputMaybe<Scalars['Int']['input']>;
};

export enum CollectListingSorting {
  BestValue = 'BEST_VALUE',
  ConfidenceValueGuidePrice = 'CONFIDENCE_VALUE_GUIDE_PRICE',
  EarliestBid = 'EARLIEST_BID',
  EarliestSaleDate = 'EARLIEST_SALE_DATE',
  EarliestYear = 'EARLIEST_YEAR',
  Favorites = 'FAVORITES',
  Featured = 'FEATURED',
  HighestPrice = 'HIGHEST_PRICE',
  LatestBid = 'LATEST_BID',
  LatestSaleDate = 'LATEST_SALE_DATE',
  LatestYear = 'LATEST_YEAR',
  LowestPrice = 'LOWEST_PRICE',
  OffersPending = 'OFFERS_PENDING',
  RecentlyAdded = 'RECENTLY_ADDED',
  SoonestAuctionLatestBid = 'SOONEST_AUCTION_LATEST_BID'
}

/**
 * The state is checked against the user identity and the listing state.
 *   For unauthed request, it will return the original listing state.
 */
export type CollectListingStates = {
  __typename?: 'CollectListingStates';
  isClosed?: Maybe<Scalars['Boolean']['output']>;
  isFanaticsAuthentic?: Maybe<Scalars['Boolean']['output']>;
  isGreatPrice?: Maybe<Scalars['Boolean']['output']>;
  isWatching?: Maybe<Scalars['Boolean']['output']>;
  lastWindowWhenBidWasReceived?: Maybe<Scalars['Int']['output']>;
  userBidStatus?: Maybe<CollectUserBidState>;
  userCanBuy?: Maybe<Scalars['Boolean']['output']>;
  userMaxBid?: Maybe<Money>;
};

export enum CollectListingStatus {
  BoStatusCancelled = 'BO_STATUS_CANCELLED',
  BoStatusListed = 'BO_STATUS_LISTED',
  BoStatusSold = 'BO_STATUS_SOLD',
  FixedPriceStatusBuyerDefaulted = 'FIXED_PRICE_STATUS_BUYER_DEFAULTED',
  FixedPriceStatusListed = 'FIXED_PRICE_STATUS_LISTED',
  FixedPriceStatusPendingSale = 'FIXED_PRICE_STATUS_PENDING_SALE',
  FixedPriceStatusTimedOut = 'FIXED_PRICE_STATUS_TIMED_OUT',
  PremierStatusAwaitingApproval = 'PREMIER_STATUS_AWAITING_APPROVAL',
  PremierStatusAwaitingCuration = 'PREMIER_STATUS_AWAITING_CURATION',
  PremierStatusBuyerDefaulted = 'PREMIER_STATUS_BUYER_DEFAULTED',
  PremierStatusCurated = 'PREMIER_STATUS_CURATED',
  PremierStatusDenied = 'PREMIER_STATUS_DENIED',
  PremierStatusEndedWoSale = 'PREMIER_STATUS_ENDED_WO_SALE',
  PremierStatusLive = 'PREMIER_STATUS_LIVE',
  StatusCancelled = 'STATUS_CANCELLED',
  StatusSold = 'STATUS_SOLD',
  StatusUnknown = 'STATUS_UNKNOWN',
  WeeklyStatusAdminEnded = 'WEEKLY_STATUS_ADMIN_ENDED',
  WeeklyStatusClosed = 'WEEKLY_STATUS_CLOSED',
  WeeklyStatusCurating = 'WEEKLY_STATUS_CURATING',
  WeeklyStatusEndedWoSale = 'WEEKLY_STATUS_ENDED_WO_SALE',
  WeeklyStatusFinalReview = 'WEEKLY_STATUS_FINAL_REVIEW',
  WeeklyStatusListed = 'WEEKLY_STATUS_LISTED',
  WeeklyStatusPendingVaultFulfillment = 'WEEKLY_STATUS_PENDING_VAULT_FULFILLMENT',
  WeeklyStatusReviewed = 'WEEKLY_STATUS_REVIEWED',
  WeeklyStatusScheduling = 'WEEKLY_STATUS_SCHEDULING'
}

export enum CollectListingStatusFilter {
  Closed = 'CLOSED',
  Open = 'OPEN',
  Preview = 'PREVIEW',
  Sold = 'SOLD'
}

export type CollectListingTimer = {
  __typename?: 'CollectListingTimer';
  asOf?: Maybe<Scalars['DateTime']['output']>;
  auctionEndsAt?: Maybe<Scalars['DateTime']['output']>;
  auctionStartsAt?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use timestamps please */
  secondsUntilAuctionEnds?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use timestamps please */
  secondsUntilAuctionStart?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use timestamps please */
  secondsUntilExtednedBiddingEnds?: Maybe<Scalars['Int']['output']>;
  windowDurationSeconds?: Maybe<Scalars['Int']['output']>;
  windowEndsAt?: Maybe<Scalars['DateTime']['output']>;
  windowOrdinal?: Maybe<Scalars['Int']['output']>;
};

export enum CollectListingType {
  Bo = 'BO',
  FixedPrice = 'FIXED_PRICE',
  Premier = 'PREMIER',
  Weekly = 'WEEKLY'
}

export type CollectListingYearsRangeFilter = {
  maxYear?: InputMaybe<Scalars['Int']['input']>;
  minYear?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectListingsConnection = {
  __typename?: 'CollectListingsConnection';
  edges?: Maybe<Array<Maybe<CollectListingsEdge>>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type CollectListingsEdge = {
  __typename?: 'CollectListingsEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectListing>;
};

export type CollectMakeOfferInput = {
  listingId: Scalars['UUID']['input'];
  offerInCents: Scalars['Int']['input'];
  sendChatMessage?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CollectMarkOfferAsViewedInput = {
  offerId: Scalars['UUID']['input'];
};

export type CollectMarkOfferAsViewedPayload = {
  __typename?: 'CollectMarkOfferAsViewedPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['String']['output']>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectMarkOfferNotificationAsViewedInput = {
  offerIds: Array<Scalars['UUID']['input']>;
};

export type CollectMarkOfferNotificationAsViewedPayload = {
  __typename?: 'CollectMarkOfferNotificationAsViewedPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['String']['output']>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectMarketplaceAuthResult = {
  __typename?: 'CollectMarketplaceAuthResult';
  accessExpirationSecs: Scalars['Int']['output'];
  accessToken: Scalars['String']['output'];
  nonce: Scalars['String']['output'];
  refreshExpirationSecs: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
};

export enum CollectMarketplaceEventType {
  Premier = 'PREMIER',
  Weekly = 'WEEKLY'
}

export type CollectMarketplaceSale = {
  buyersPremium?: Maybe<Money>;
  id: Scalars['UUID']['output'];
  integerId: Scalars['Int']['output'];
  listing?: Maybe<CollectListing>;
  purchasePrice?: Maybe<Money>;
  shipping?: Maybe<Money>;
  tax?: Maybe<Money>;
  total?: Maybe<Money>;
};

/** Match level for search results */
export enum CollectMatchLevel {
  Match = 'MATCH',
  Possible = 'POSSIBLE',
  Prediction = 'PREDICTION',
  Similar = 'SIMILAR'
}

export enum CollectMemberCheckoutAddressType {
  Alternate = 'ALTERNATE',
  Default = 'DEFAULT'
}

export type CollectMonolithicSearchAuctionOption = {
  __typename?: 'CollectMonolithicSearchAuctionOption';
  auctionId: Scalars['UUID']['output'];
  auctionStatus: CollectAuctionStatus;
  displayName: Scalars['String']['output'];
};

export type CollectMonolithicSearchCategoryFilter = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  categoryType?: InputMaybe<CollectCategoryType>;
  tagName?: InputMaybe<Scalars['String']['input']>;
  type: CollectMonolithicSearchCategoryType;
};

export type CollectMonolithicSearchCategoryGroup = {
  __typename?: 'CollectMonolithicSearchCategoryGroup';
  displayName: Scalars['String']['output'];
  value: CollectCategoryType;
};

export type CollectMonolithicSearchCategoryOption = {
  __typename?: 'CollectMonolithicSearchCategoryOption';
  displayName: Scalars['String']['output'];
  group: CollectCategoryType;
  id: CollectMonolithicSearchCategoryOptionId;
  value: Array<CollectMonolithicSearchCategoryOptionValue>;
};

export enum CollectMonolithicSearchCategoryOptionId {
  Baseball = 'BASEBALL',
  Basketball = 'BASKETBALL',
  BoxingMma = 'BOXING_MMA',
  CollectibleCardGames = 'COLLECTIBLE_CARD_GAMES',
  ComicsBronzeAge = 'COMICS_BRONZE_AGE',
  ComicsGoldenAge = 'COMICS_GOLDEN_AGE',
  ComicsModernAge = 'COMICS_MODERN_AGE',
  ComicsPublisherDc = 'COMICS_PUBLISHER_DC',
  ComicsPublisherMarvel = 'COMICS_PUBLISHER_MARVEL',
  ComicsPublisherOther = 'COMICS_PUBLISHER_OTHER',
  ComicsSilverAge = 'COMICS_SILVER_AGE',
  Football = 'FOOTBALL',
  Golf = 'GOLF',
  Hockey = 'HOCKEY',
  MagicTheGathering = 'MAGIC_THE_GATHERING',
  Memorabilia = 'MEMORABILIA',
  MiscSports = 'MISC_SPORTS',
  NonSport = 'NON_SPORT',
  Photos = 'PHOTOS',
  PokemonEnglish = 'POKEMON_ENGLISH',
  PokemonJapanese = 'POKEMON_JAPANESE',
  PokemonOther = 'POKEMON_OTHER',
  Racing = 'RACING',
  Soccer = 'SOCCER',
  Tickets = 'TICKETS',
  VideoGamesAtari = 'VIDEO_GAMES_ATARI',
  VideoGamesMags = 'VIDEO_GAMES_MAGS',
  VideoGamesNintendo = 'VIDEO_GAMES_NINTENDO',
  VideoGamesOther = 'VIDEO_GAMES_OTHER',
  VideoGamesPlaystation = 'VIDEO_GAMES_PLAYSTATION',
  VideoGamesSega = 'VIDEO_GAMES_SEGA',
  VideoGamesXbox = 'VIDEO_GAMES_XBOX',
  Wax = 'WAX',
  YuGiOh = 'YU_GI_OH'
}

export type CollectMonolithicSearchCategoryOptionValue = {
  __typename?: 'CollectMonolithicSearchCategoryOptionValue';
  categoryId?: Maybe<Scalars['Int']['output']>;
  categoryType?: Maybe<CollectCategoryType>;
  tagName?: Maybe<Scalars['String']['output']>;
  type: CollectMonolithicSearchCategoryType;
};

export enum CollectMonolithicSearchCategoryType {
  CategoryId = 'CATEGORY_ID',
  CategoryType = 'CATEGORY_TYPE',
  TagName = 'TAG_NAME'
}

export type CollectMonolithicSearchOptionAuthenticator = {
  __typename?: 'CollectMonolithicSearchOptionAuthenticator';
  displayName: Scalars['String']['output'];
  value: CollectMonolithicSearchSettingAuthenticatorValue;
};

export type CollectMonolithicSearchOptionCollection = {
  __typename?: 'CollectMonolithicSearchOptionCollection';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CollectMonolithicSearchOptions = {
  __typename?: 'CollectMonolithicSearchOptions';
  auctions?: Maybe<Array<CollectMonolithicSearchAuctionOption>>;
  authenticators: Array<CollectMonolithicSearchOptionAuthenticator>;
  categories: Array<CollectMonolithicSearchCategoryOption>;
  categoryGroups: Array<CollectMonolithicSearchCategoryGroup>;
  eyeAppealGrades: Array<CollectMonolithicSearchSettingEyeAppealGrade>;
  featuredCollections: Array<CollectMonolithicSearchOptionCollection>;
};

export type CollectMonolithicSearchPriceRange = {
  max?: InputMaybe<Scalars['Int']['input']>;
  min?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectMonolithicSearchResponse = {
  __typename?: 'CollectMonolithicSearchResponse';
  entries: Array<CollectMonolithicSearchResponseEntry>;
  totalPages: Scalars['Int']['output'];
};

export type CollectMonolithicSearchResponseEntry = {
  __typename?: 'CollectMonolithicSearchResponseEntry';
  listingType: CollectListingType;
  listingUuid: Scalars['UUID']['output'];
};

export enum CollectMonolithicSearchSettingAuthenticatorValue {
  Beckett = 'BECKETT',
  Cgc = 'CGC',
  Csg = 'CSG',
  Mba = 'MBA',
  Ngc = 'NGC',
  Other = 'OTHER',
  Psa = 'PSA',
  Sgc = 'SGC',
  Vga = 'VGA',
  Wata = 'WATA'
}

export enum CollectMonolithicSearchSettingEyeAppealGrade {
  AboveAverage = 'ABOVE_AVERAGE',
  Exceptional = 'EXCEPTIONAL',
  None = 'NONE',
  Superior = 'SUPERIOR'
}

export type CollectMonolithicSearchYearRange = {
  max?: InputMaybe<Scalars['Int']['input']>;
  min?: InputMaybe<Scalars['Int']['input']>;
};

/** Multi bid request */
export type CollectMultiBidRequest = {
  auctionType: CollectAuctionType;
  bidRequests?: InputMaybe<Array<InputMaybe<CollectBigSingle>>>;
};

/** The allowed capture types for multiscan v2 */
export enum CollectMultiscanCaptureType {
  Auto = 'AUTO',
  Manual = 'MANUAL'
}

/** Response of multi scan request */
export type CollectMultiscanResponse = {
  __typename?: 'CollectMultiscanResponse';
  predictions?: Maybe<Array<Maybe<CollectProduct>>>;
  results?: Maybe<Array<Maybe<CollectProduct>>>;
  variantPredictions?: Maybe<Array<Maybe<CollectVariant>>>;
  variantResults?: Maybe<Array<Maybe<CollectVariant>>>;
};

export type CollectOfferData = {
  __typename?: 'CollectOfferData';
  listingId?: Maybe<Scalars['UUID']['output']>;
  listingTitle?: Maybe<Scalars['String']['output']>;
  offerId?: Maybe<Scalars['UUID']['output']>;
  showNotification?: Maybe<Scalars['Boolean']['output']>;
};

export enum CollectOfferKind {
  Buying = 'BUYING',
  Selling = 'SELLING'
}

export type CollectOfferLimits = {
  __typename?: 'CollectOfferLimits';
  max: Money;
  min: Money;
};

export type CollectOfferRoom = {
  __typename?: 'CollectOfferRoom';
  room?: Maybe<Scalars['String']['output']>;
};

export enum CollectOfferStatusFilter {
  Accepted = 'ACCEPTED',
  Active = 'ACTIVE',
  Declined = 'DECLINED',
  Expired = 'EXPIRED'
}

export type CollectOptionValue = {
  __typename?: 'CollectOptionValue';
  count?: Maybe<Scalars['Int']['output']>;
  displayName: Scalars['String']['output'];
};

export type CollectOwnershipTransferSummary = {
  __typename?: 'CollectOwnershipTransferSummary';
  needsUnderwriting: Scalars['Boolean']['output'];
};

/** The payment for invoice. */
export type CollectPayment = {
  __typename?: 'CollectPayment';
  amount: Money;
  date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  paymentMethod?: Maybe<CollectPaymentType>;
};

/** The payment attempt data for invoice. */
export type CollectPaymentAttempt = {
  __typename?: 'CollectPaymentAttempt';
  data?: Maybe<CollectInvoicePaymentAttemptData>;
  externalKey?: Maybe<Scalars['String']['output']>;
  fundsDisbursed: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  invoiceId: Scalars['Int']['output'];
  merchantAccountKey?: Maybe<Scalars['String']['output']>;
  paymentProcessorId: Scalars['Int']['output'];
  paymentTypeId: Scalars['Int']['output'];
  status?: Maybe<Scalars['String']['output']>;
};

/** Payment Attempt data object. */
export type CollectPaymentAttemptData = {
  __typename?: 'CollectPaymentAttemptData';
  bankName?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<Scalars['String']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type CollectPaymentMethod = {
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
};

export type CollectPaymentMethodBank = CollectPaymentMethod & {
  __typename?: 'CollectPaymentMethodBank';
  bank?: Maybe<CollectPaymentMethodBankDetails>;
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
};

export type CollectPaymentMethodBankDetails = {
  __typename?: 'CollectPaymentMethodBankDetails';
  lastFour: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CollectPaymentMethodCard = CollectPaymentMethod & {
  __typename?: 'CollectPaymentMethodCard';
  card?: Maybe<CollectPaymentMethodCardDetails>;
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
};

export type CollectPaymentMethodCardDetails = {
  __typename?: 'CollectPaymentMethodCardDetails';
  brand: Scalars['String']['output'];
  expirationMonth: Scalars['Int']['output'];
  expirationYear: Scalars['Int']['output'];
  lastFour: Scalars['String']['output'];
};

export type CollectPaymentMethodOther = CollectPaymentMethod & {
  __typename?: 'CollectPaymentMethodOther';
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
};

export enum CollectPaymentType {
  Ach = 'ACH',
  ApplePay = 'APPLE_PAY',
  Card = 'CARD',
  Check = 'CHECK',
  Crypto = 'CRYPTO',
  Funds = 'FUNDS',
  GooglePay = 'GOOGLE_PAY',
  Other = 'OTHER',
  Wire = 'WIRE'
}

export enum CollectPayoutMethod {
  Ach = 'ACH',
  Check = 'CHECK',
  StripeConnectedAccount = 'STRIPE_CONNECTED_ACCOUNT',
  WireTransferInternational = 'WIRE_TRANSFER_INTERNATIONAL',
  WireTransferUsDomestic = 'WIRE_TRANSFER_US_DOMESTIC'
}

export type CollectPayoutRequest = {
  __typename?: 'CollectPayoutRequest';
  fees: Money;
  id: Scalars['Int']['output'];
  insertedAt: Scalars['DateTime']['output'];
  netPayout: Money;
  payableTo: Scalars['String']['output'];
  payoutMethod: CollectPayoutMethod;
  payoutNote?: Maybe<Scalars['String']['output']>;
  requestedAmount: Money;
  status: CollectPayoutRequestStatus;
};

export type CollectPayoutRequestGroup = {
  __typename?: 'CollectPayoutRequestGroup';
  id: Scalars['Int']['output'];
  payoutRequests?: Maybe<Array<Maybe<CollectPayoutRequest>>>;
};

export enum CollectPayoutRequestStatus {
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  PaidOut = 'PAID_OUT',
  Pending = 'PENDING',
  Underwriting = 'UNDERWRITING'
}

export type CollectPendingOffer = {
  __typename?: 'CollectPendingOffer';
  counterOffersForBuyer?: Maybe<Array<Maybe<CollectOfferData>>>;
  counterOffersForSeller?: Maybe<Array<Maybe<CollectOfferData>>>;
  offersForSeller?: Maybe<Array<Maybe<CollectOfferData>>>;
};

/** A phone number for a user. */
export type CollectPhone = {
  __typename?: 'CollectPhone';
  /** The phone number. */
  number?: Maybe<Scalars['String']['output']>;
  /** The verification status. */
  status?: Maybe<CollectPhoneStatus>;
};

/** The verification status of a phone number. */
export enum CollectPhoneStatus {
  PhoneStatusBypassed = 'PHONE_STATUS_BYPASSED',
  PhoneStatusCallRequested = 'PHONE_STATUS_CALL_REQUESTED',
  PhoneStatusCodeSent = 'PHONE_STATUS_CODE_SENT',
  PhoneStatusManuallyVerified = 'PHONE_STATUS_MANUALLY_VERIFIED',
  PhoneStatusUnverifiable = 'PHONE_STATUS_UNVERIFIABLE',
  PhoneStatusUnverified = 'PHONE_STATUS_UNVERIFIED',
  PhoneStatusVerified = 'PHONE_STATUS_VERIFIED'
}

export type CollectPickUpInPersonCharges = {
  __typename?: 'CollectPickUpInPersonCharges';
  /** Grading and Archival fees */
  additionalFee: Money;
  fulfillmentFee: Money;
  handlingFee?: Maybe<Money>;
  needsUnderwriting: Scalars['Boolean']['output'];
  salesTax: Money;
};

export type CollectPickUpInPersonSummary = {
  __typename?: 'CollectPickUpInPersonSummary';
  /** Grading and Archival fees */
  additionalFee: Money;
  fulfillmentFee: Money;
  handlingFee?: Maybe<Money>;
  needsUnderwriting: Scalars['Boolean']['output'];
  salesTax: Money;
};

export type CollectPingPayload = {
  __typename?: 'CollectPingPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectPingResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectPingResponse = {
  __typename?: 'CollectPingResponse';
  id: Scalars['UUID']['output'];
  payload: Scalars['String']['output'];
};

export enum CollectPlaidLinkTokenProfile {
  Kyc = 'KYC',
  PremierAuctionApproval = 'PREMIER_AUCTION_APPROVAL'
}

/** prediction sources for search results */
export enum CollectPredictionSource {
  Chatgpt = 'CHATGPT',
  Ximilar = 'XIMILAR'
}

export type CollectPremierAuction = CollectAuction & {
  __typename?: 'CollectPremierAuction';
  /** Does this auction allow flash submissions at the moment */
  allowsFlashSubmissions?: Maybe<Scalars['Boolean']['output']>;
  auctionIntegerId?: Maybe<Scalars['Int']['output']>;
  collectListingTimer: CollectListingTimer;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  extendedEndsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  integerId?: Maybe<Scalars['Int']['output']>;
  /** Identifies whether an auction is custom */
  isCustom?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  payoutDate?: Maybe<Scalars['Date']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  status: CollectAuctionStatus;
  /** Applicable only for custom auctions */
  subtitle?: Maybe<Scalars['String']['output']>;
};

export type CollectPremierAuctionBid = {
  __typename?: 'CollectPremierAuctionBid';
  status: CollectPremierAuctionBidStatus;
  uuid: Scalars['UUID']['output'];
};

export type CollectPremierAuctionBidConnection = {
  __typename?: 'CollectPremierAuctionBidConnection';
  edges?: Maybe<Array<Maybe<CollectPremierAuctionBidEdge>>>;
  pageInfo: PageInfo;
};

export type CollectPremierAuctionBidEdge = {
  __typename?: 'CollectPremierAuctionBidEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectPremierAuctionBid>;
};

export enum CollectPremierAuctionBidStatus {
  StatusFirst = 'STATUS_FIRST',
  StatusNewHighest = 'STATUS_NEW_HIGHEST',
  StatusNewSecondHighest = 'STATUS_NEW_SECOND_HIGHEST',
  StatusNotProcessed = 'STATUS_NOT_PROCESSED',
  StatusRejected = 'STATUS_REJECTED',
  StatusStale = 'STATUS_STALE',
  StatusUpdatedHighest = 'STATUS_UPDATED_HIGHEST'
}

export type CollectPremierSale = CollectMarketplaceSale & {
  __typename?: 'CollectPremierSale';
  buyersPremium?: Maybe<Money>;
  id: Scalars['UUID']['output'];
  integerId: Scalars['Int']['output'];
  /** Not affected by faster listing query optimization */
  listing?: Maybe<CollectListing>;
  purchasePrice?: Maybe<Money>;
  shipping?: Maybe<Money>;
  tax?: Maybe<Money>;
  total?: Maybe<Money>;
};

export type CollectPresignedUrlPayload = {
  __typename?: 'CollectPresignedUrlPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectPresignedUrlPayloadType>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectPresignedUrlPayloadType = {
  __typename?: 'CollectPresignedUrlPayloadType';
  key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Object which represents price range */
export type CollectPriceRange = {
  __typename?: 'CollectPriceRange';
  high?: Maybe<Money>;
  low?: Maybe<Money>;
};

/** Card or any other product which can be found in catalog */
export type CollectProduct = CollectDetailed & {
  __typename?: 'CollectProduct';
  activity?: Maybe<CollectDetailedActivity>;
  cardNumber?: Maybe<Scalars['String']['output']>;
  debugMetadata?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  matchLevel?: Maybe<CollectMatchLevel>;
  metadata?: Maybe<CollectProductMetadata>;
  ownItCount: Scalars['Int']['output'];
  predictionSource?: Maybe<CollectProductPredictionSource>;
  sales?: Maybe<CollectDetailedSales>;
  stats?: Maybe<CollectDetailedStats>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  variants?: Maybe<CollectVariantConnection>;
  wantItCount: Scalars['Int']['output'];
};


/** Card or any other product which can be found in catalog */
export type CollectProductVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectProductDetailFeedback = {
  __typename?: 'CollectProductDetailFeedback';
  feedbackValue: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  insertedAt: Scalars['DateTime']['output'];
  originalValue?: Maybe<Scalars['String']['output']>;
  productDetailAttribute: Scalars['String']['output'];
  typeId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CollectProductDetailFeedbackInput = {
  feedbackValue: Scalars['String']['input'];
  id: Scalars['String']['input'];
  listingType: Scalars['String']['input'];
  originalValue?: InputMaybe<Scalars['String']['input']>;
  productDetailAttribute: Scalars['String']['input'];
};

export type CollectProductDetailFeedbackPayload = {
  __typename?: 'CollectProductDetailFeedbackPayload';
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  result?: Maybe<CollectProductDetailFeedback>;
  successful: Scalars['Boolean']['output'];
};

export type CollectProductMetadata = {
  __typename?: 'CollectProductMetadata';
  brand?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parallel?: Maybe<Scalars['String']['output']>;
  patch?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  serial?: Maybe<Scalars['String']['output']>;
  set?: Maybe<Scalars['String']['output']>;
  signatures?: Maybe<Scalars['String']['output']>;
  sport?: Maybe<Scalars['String']['output']>;
  subcategory?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  subset?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

/** product prediction sources for search results */
export enum CollectProductPredictionSource {
  Chatgpt = 'CHATGPT',
  Ximilar = 'XIMILAR'
}

export type CollectPublicUser = {
  __typename?: 'CollectPublicUser';
  collectCollection: CollectSetlist;
  collectSetlist?: Maybe<CollectSetlist>;
  collectSetlists?: Maybe<CollectSetlistConnection>;
  tenantId: Scalars['UUID']['output'];
};


export type CollectPublicUserCollectSetlistArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  kind?: InputMaybe<CollectSetlistKind>;
};


export type CollectPublicUserCollectSetlistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectQuickOffer = {
  __typename?: 'CollectQuickOffer';
  amount: Money;
  label: Scalars['String']['output'];
  pricePercentage: Scalars['Int']['output'];
};

export type CollectRecentlyViewedItem = {
  __typename?: 'CollectRecentlyViewedItem';
  item: CollectItemOrProductOrListingOrVariant;
  itemId: Scalars['String']['output'];
  itemType: CollectRecentlyViewedItemType;
  lastViewedAt: Scalars['DateTime']['output'];
};

export enum CollectRecentlyViewedItemType {
  FixedPrice = 'FIXED_PRICE',
  Item = 'ITEM',
  Premier = 'PREMIER',
  Product = 'PRODUCT',
  Weekly = 'WEEKLY'
}

export type CollectRegistration = {
  __typename?: 'CollectRegistration';
  status: CollectRegistrationStatus;
};

export enum CollectRegistrationStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Pending = 'PENDING'
}

export type CollectRemoveFixedPriceFromSetlistPayload = {
  __typename?: 'CollectRemoveFixedPriceFromSetlistPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectRemoveFixedPriceFromSetlistResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectRemoveFixedPriceFromSetlistResponse = {
  __typename?: 'CollectRemoveFixedPriceFromSetlistResponse';
  fixedPrice: CollectListing;
};

export type CollectRemoveFromCollectionPayload = {
  __typename?: 'CollectRemoveFromCollectionPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectRemoveFromCollectionResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectRemoveFromCollectionResponse = {
  __typename?: 'CollectRemoveFromCollectionResponse';
  product: CollectProduct;
};

export type CollectRemoveItemFromSetlistPayload = {
  __typename?: 'CollectRemoveItemFromSetlistPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectRemoveItemFromSetlistResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectRemoveItemFromSetlistResponse = {
  __typename?: 'CollectRemoveItemFromSetlistResponse';
  item: CollectItem;
};

export type CollectRemovePremierFromSetlistPayload = {
  __typename?: 'CollectRemovePremierFromSetlistPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectRemovePremierFromSetlistResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectRemovePremierFromSetlistResponse = {
  __typename?: 'CollectRemovePremierFromSetlistResponse';
  premier: CollectListing;
};

export type CollectRemoveProductFromSetlistPayload = {
  __typename?: 'CollectRemoveProductFromSetlistPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectRemoveProductFromSetlistResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectRemoveProductFromSetlistResponse = {
  __typename?: 'CollectRemoveProductFromSetlistResponse';
  product: CollectProduct;
};

export type CollectRemoveVariantFromSetlistPayload = {
  __typename?: 'CollectRemoveVariantFromSetlistPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectRemoveVariantFromSetlistResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectRemoveVariantFromSetlistResponse = {
  __typename?: 'CollectRemoveVariantFromSetlistResponse';
  variant: CollectVariant;
};

export type CollectRemoveVaultItemFromVaultItemGroupPayload = {
  __typename?: 'CollectRemoveVaultItemFromVaultItemGroupPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectVaultItemGroup>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectRemoveWeeklyFromSetlistPayload = {
  __typename?: 'CollectRemoveWeeklyFromSetlistPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectRemoveWeeklyFromSetlistResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectRemoveWeeklyFromSetlistResponse = {
  __typename?: 'CollectRemoveWeeklyFromSetlistResponse';
  weekly: CollectListing;
};

export enum CollectReportEntityType {
  FixedPrice = 'FIXED_PRICE',
  Item = 'ITEM',
  Premier = 'PREMIER',
  Product = 'PRODUCT',
  Setlist = 'SETLIST',
  Variant = 'VARIANT',
  Weekly = 'WEEKLY'
}

export type CollectReportReason = {
  __typename?: 'CollectReportReason';
  /** ID of the report reason */
  id: Scalars['UUID']['output'];
  /** Parent ID of the report reason if present */
  parentId?: Maybe<Scalars['UUID']['output']>;
  /** Description of the report reason */
  title: Scalars['String']['output'];
};

export type CollectRequestSessionEmailOtpPayload = {
  __typename?: 'CollectRequestSessionEmailOtpPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectResendPhoneVerificationCodePayload = {
  __typename?: 'CollectResendPhoneVerificationCodePayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectResendWelcomeEmailPayload = {
  __typename?: 'CollectResendWelcomeEmailPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectSale = {
  __typename?: 'CollectSale';
  soldDate?: Maybe<Scalars['DateTime']['output']>;
  soldFor?: Maybe<Money>;
};

export type CollectSaleCharges = {
  __typename?: 'CollectSaleCharges';
  shippingFee?: Maybe<Money>;
};

/** The data to return for Saved Search */
export type CollectSavedSearch = {
  __typename?: 'CollectSavedSearch';
  id: Scalars['UUID']['output'];
  lastUsedAt: Scalars['DateTime']['output'];
  searchData?: Maybe<CollectSearchData>;
  usagesCount: Scalars['Int']['output'];
};

export type CollectSavedSearchConnection = {
  __typename?: 'CollectSavedSearchConnection';
  edges?: Maybe<Array<Maybe<CollectSavedSearchEdge>>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type CollectSavedSearchEdge = {
  __typename?: 'CollectSavedSearchEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectSavedSearch>;
};

/** The allowed capture types for scan */
export enum CollectScanCaptureType {
  Auto = 'AUTO',
  Manual = 'MANUAL'
}

/**
 * Input type for scan feedback results.
 * This type encapsulates the details of a scanned item, including its metadata,
 * images, and match level. It is used to provide feedback on the results of a scan operation.
 */
export type CollectScanFeedbackResultInput = {
  id: Scalars['UUID']['input'];
  images?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  matchLevel?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<CollectScanFeedbackResultMetadataInput>;
};

export type CollectScanFeedbackResultMetadataInput = {
  brand?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parallel?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Scalars['String']['input']>;
  signatures?: InputMaybe<Scalars['String']['input']>;
  sport?: InputMaybe<Scalars['String']['input']>;
  subcategory?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  subset?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type CollectScanRequest = {
  /** The base64 encoded string of the back of the image, in the format of jpg */
  contentBack?: InputMaybe<Scalars['String']['input']>;
  /** The base64 encoded string of the front of the image, in the format of jpg */
  contentFront?: InputMaybe<Scalars['String']['input']>;
  contentUrl?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the object we want to update */
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type CollectScanResultFeedbackPayload = {
  __typename?: 'CollectScanResultFeedbackPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectScanResultFeedbackPayloadType>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectScanResultFeedbackPayloadType = {
  __typename?: 'CollectScanResultFeedbackPayloadType';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** The data to return for Saved Search */
export type CollectSearchData = {
  __typename?: 'CollectSearchData';
  /** The Search filters */
  filters?: Maybe<Scalars['String']['output']>;
  /** The Search query itself */
  query?: Maybe<Scalars['String']['output']>;
};

/** Data that should be input to create the Saved Search */
export type CollectSearchDataInput = {
  /** The Search filters */
  filters?: InputMaybe<Scalars['String']['input']>;
  /** The Search query itself */
  query?: InputMaybe<Scalars['String']['input']>;
};

export type CollectSearchDefaultFilter = {
  __typename?: 'CollectSearchDefaultFilter';
  indexName: CollectSearchIndexName;
  params: CollectSearchParams;
  prebuiltFilters?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CollectSearchFacetAuction = {
  __typename?: 'CollectSearchFacetAuction';
  attributeName: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  values?: Maybe<Array<Maybe<CollectSearchFacetValueAuction>>>;
};

export type CollectSearchFacetSimple = {
  __typename?: 'CollectSearchFacetSimple';
  attributeName: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  values?: Maybe<Array<Maybe<CollectSearchFacetValueSimple>>>;
};

export type CollectSearchFacetTypes = CollectSearchFacetAuction | CollectSearchFacetSimple;

export type CollectSearchFacetValueAuction = {
  __typename?: 'CollectSearchFacetValueAuction';
  auction: CollectAuction;
  count?: Maybe<Scalars['Int']['output']>;
  value: Scalars['String']['output'];
};

export type CollectSearchFacetValueSimple = {
  __typename?: 'CollectSearchFacetValueSimple';
  count?: Maybe<Scalars['Int']['output']>;
  value: Scalars['String']['output'];
};

export type CollectSearchIndex = {
  __typename?: 'CollectSearchIndex';
  displayName: Scalars['String']['output'];
  indexName: CollectSearchIndexName;
  indexValue: Scalars['String']['output'];
  type: CollectSearchType;
};

export enum CollectSearchIndexName {
  /** Algolia Primary Index. Sometimes referred as 'For You' index */
  Listing = 'LISTING',
  ListingBestDeals = 'LISTING_BEST_DEALS',
  ListingBestValueDesc = 'LISTING_BEST_VALUE_DESC',
  ListingConfidenceValueGuidePrice = 'LISTING_CONFIDENCE_VALUE_GUIDE_PRICE',
  ListingFeatured = 'LISTING_FEATURED',
  ListingHighestPrice = 'LISTING_HIGHEST_PRICE',
  ListingLowestPrice = 'LISTING_LOWEST_PRICE',
  ListingPendingOffersDesc = 'LISTING_PENDING_OFFERS_DESC',
  ListingRecentlyAdded = 'LISTING_RECENTLY_ADDED',
  ListingSoldAsc = 'LISTING_SOLD_ASC',
  ListingSoldDesc = 'LISTING_SOLD_DESC',
  ListingYearAsc = 'LISTING_YEAR_ASC',
  ListingYearDesc = 'LISTING_YEAR_DESC',
  Product = 'PRODUCT'
}

/**
 * Input type for ChatGPT card scanning operations.
 * Contains URLs for card images to be analyzed.
 * Each field represents a URL pointing to the respective card image.
 */
export type CollectSearchItemsInput = {
  backImageUrl?: InputMaybe<Scalars['String']['input']>;
  imageUrl: Scalars['String']['input'];
};

export type CollectSearchListing = {
  __typename?: 'CollectSearchListing';
  result?: Maybe<CollectSearchListingResultConnection>;
};


export type CollectSearchListingResultArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export enum CollectSearchListingIndexName {
  Listing = 'LISTING',
  ListingBestDeals = 'LISTING_BEST_DEALS',
  ListingBestValueDesc = 'LISTING_BEST_VALUE_DESC',
  ListingHighestPrice = 'LISTING_HIGHEST_PRICE',
  ListingLowestPrice = 'LISTING_LOWEST_PRICE',
  ListingRecentlyAdded = 'LISTING_RECENTLY_ADDED'
}

export type CollectSearchListingResultConnection = {
  __typename?: 'CollectSearchListingResultConnection';
  edges?: Maybe<Array<Maybe<CollectSearchListingResultEdge>>>;
  facets?: Maybe<Array<Maybe<CollectSearchFacetTypes>>>;
  pageInfo: PageInfo;
  totalHits?: Maybe<Scalars['Int']['output']>;
};

export type CollectSearchListingResultEdge = {
  __typename?: 'CollectSearchListingResultEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectListing>;
};

export type CollectSearchParams = {
  __typename?: 'CollectSearchParams';
  filters?: Maybe<Scalars['String']['output']>;
};

export type CollectSearchParamsInput = {
  filters?: InputMaybe<Scalars['String']['input']>;
  search: Scalars['String']['input'];
  similarQuery?: InputMaybe<Scalars['String']['input']>;
  userToken?: InputMaybe<Scalars['String']['input']>;
};

export type CollectSearchPrebuiltFilter = {
  __typename?: 'CollectSearchPrebuiltFilter';
  displayName: Scalars['String']['output'];
  facets: Array<Maybe<CollectSearchPrebuiltFilterFacet>>;
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  indexName?: Maybe<CollectSearchIndexName>;
};

export type CollectSearchPrebuiltFilterFacet = {
  __typename?: 'CollectSearchPrebuiltFilterFacet';
  attributeName: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CollectSearchProduct = {
  __typename?: 'CollectSearchProduct';
  result?: Maybe<CollectSearchProductResultConnection>;
};


export type CollectSearchProductResultArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectSearchProductResultConnection = {
  __typename?: 'CollectSearchProductResultConnection';
  edges?: Maybe<Array<Maybe<CollectSearchProductResultEdge>>>;
  facets?: Maybe<Array<Maybe<CollectSearchFacetTypes>>>;
  pageInfo: PageInfo;
  totalHits?: Maybe<Scalars['Int']['output']>;
};

export type CollectSearchProductResultEdge = {
  __typename?: 'CollectSearchProductResultEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectProduct>;
};

export type CollectSearchSettings = {
  __typename?: 'CollectSearchSettings';
  defaultFilter: CollectSearchDefaultFilter;
  indexes?: Maybe<Array<Maybe<CollectSearchIndex>>>;
  prebuiltFilters?: Maybe<Array<Maybe<CollectSearchPrebuiltFilter>>>;
};

export enum CollectSearchSortBy {
  /** Sort by best deals first */
  BestDeals = 'BEST_DEALS',
  /** Sort by best value first */
  BestValueDesc = 'BEST_VALUE_DESC',
  /** Sort by highest price first */
  HighestPrice = 'HIGHEST_PRICE',
  /** Sort by lowest price first */
  LowestPrice = 'LOWEST_PRICE',
  /** Sort by recently added items */
  RecentlyAdded = 'RECENTLY_ADDED'
}

export enum CollectSearchType {
  Listing = 'LISTING',
  Product = 'PRODUCT'
}

export type CollectSeller = {
  __typename?: 'CollectSeller';
  /** Collect seller name */
  name: Scalars['String']['output'];
  /**
   * This ENUM is important, because FANATICS_COLLECT is unified seller that may contain items from different locations.
   * For external locations there can be only one location per seller.
   */
  type: CollectCheckoutV2Seller;
  /** External Location ID. NULL for FANATICS_COLLECT because it's a unified seller */
  vaultItemLocationId?: Maybe<Scalars['Int']['output']>;
};

/** Seller Fee rate example */
export type CollectSellerFeeRate = {
  __typename?: 'CollectSellerFeeRate';
  sellerFee?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** Simplified Seller Fee rates example */
export type CollectSellerFeeRateV2 = {
  __typename?: 'CollectSellerFeeRateV2';
  baseRatio?: Maybe<Scalars['Float']['output']>;
  greatPriceRatio?: Maybe<Scalars['Float']['output']>;
};

export type CollectSession = {
  __typename?: 'CollectSession';
  message?: Maybe<Scalars['String']['output']>;
  valid?: Maybe<Scalars['Boolean']['output']>;
};

export type CollectSetlist = {
  __typename?: 'CollectSetlist';
  hidden: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['UUID']['output']>;
  items?: Maybe<CollectItemOrProductOrListingOrVariantConnection>;
  kind: CollectSetlistKind;
  title?: Maybe<Scalars['String']['output']>;
  totalNumberOfItems: Scalars['Int']['output'];
  totalWorth?: Maybe<Money>;
};


export type CollectSetlistItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectSetlistConnection = {
  __typename?: 'CollectSetlistConnection';
  edges?: Maybe<Array<Maybe<CollectSetlistEdge>>>;
  pageInfo: PageInfo;
};

export type CollectSetlistEdge = {
  __typename?: 'CollectSetlistEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectSetlist>;
};

export enum CollectSetlistKind {
  /** Indicates setlist which must be referenced by ID */
  Regular = 'REGULAR',
  /** Special setlist which can be referenced by kind */
  Wantlist = 'WANTLIST'
}

/** The Shipping Address for an Invoice. */
export type CollectShippingAddress = {
  __typename?: 'CollectShippingAddress';
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
  vaultAccountId?: Maybe<Scalars['Int']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type CollectShippingMethod = {
  __typename?: 'CollectShippingMethod';
  cost?: Maybe<Money>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  usDomestic: Scalars['Boolean']['output'];
};

export type CollectShippingService = {
  __typename?: 'CollectShippingService';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum CollectShippingType {
  Custom = 'CUSTOM',
  Default = 'DEFAULT',
  InPerson = 'IN_PERSON',
  New = 'NEW',
  TradeShow = 'TRADE_SHOW',
  Unknown = 'UNKNOWN',
  Vault = 'VAULT'
}

export type CollectShortLink = {
  __typename?: 'CollectShortLink';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  type: CollectShortLinkType;
  url: Scalars['String']['output'];
};

/** Represents the 2 types of short links */
export enum CollectShortLinkType {
  /** General Shortlink */
  General = 'GENERAL',
  /** Shorlink for vault shares */
  VaultShares = 'VAULT_SHARES'
}

/** Token pair obtained after authenticating a user. */
export type CollectTokenPair = {
  __typename?: 'CollectTokenPair';
  /** Access token to be used for authenticated requests. */
  accessToken: Scalars['String']['output'];
  /** Refresh token to be used to obtain a new token pair. */
  refreshToken: Scalars['String']['output'];
};

/** A pair of tokens obtained after authenticating a user. */
export type CollectTokenSet = {
  __typename?: 'CollectTokenSet';
  /** Access token to be used for authenticated requests. */
  accessToken: Scalars['String']['output'];
  /** The expiry timestamp returned by the FanID authentication flow */
  expiresAt: Scalars['Int']['output'];
  /** Refresh token to be used to obtain a new token pair. */
  refreshToken: Scalars['String']['output'];
  /** The user's tenant ID. */
  tenantId: Scalars['UUID']['output'];
};

/** Trade Shows can be used to Checkout Vault Items */
export type CollectTradeShow = {
  __typename?: 'CollectTradeShow';
  address: CollectAddress;
  allowsCheckout: Scalars['Boolean']['output'];
  checkoutDeadline?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endsAt: Scalars['Date']['output'];
  integerId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  startsAt: Scalars['Date']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type CollectTradeShowCheckoutCharges = {
  __typename?: 'CollectTradeShowCheckoutCharges';
  /** Deadline for returning items back to vault */
  deferredFulfillmentDeadline: Scalars['DateTime']['output'];
  /** Potential fee if deadline is missed */
  deferredFulfillmentFee: Money;
  /** Discount applied to a shipping fee */
  discountedShippingFee?: Maybe<Money>;
  fulfillmentFee: Money;
  insuredValue: Money;
  needsUnderwriting: Scalars['Boolean']['output'];
  shippingFee?: Maybe<Money>;
  /** Discount applied to a shipping fee in case of a trade show checkout type */
  tradeShowShippingDiscount: Scalars['Float']['output'];
};

export type CollectTradeShowCheckoutSummary = {
  __typename?: 'CollectTradeShowCheckoutSummary';
  constraintData?: Maybe<CollectCheckoutSummaryConstraintData>;
  /** Deadline for returning items back to vault */
  deferredFulfillmentDeadline: Scalars['DateTime']['output'];
  /** Potential fee if deadline is missed */
  deferredFulfillmentFee: Money;
  /** Discount applied to a shipping fee */
  discountedShippingFee?: Maybe<Money>;
  fulfillmentFee: Money;
  insuredValue: Money;
  needsUnderwriting: Scalars['Boolean']['output'];
  shippingFee?: Maybe<Money>;
  /** Discount applied to a shipping fee in case of a trade show checkout type */
  tradeShowShippingDiscount: Scalars['Float']['output'];
};

export type CollectUnableToListError = {
  __typename?: 'CollectUnableToListError';
  code: CollectUnableToListErrorCode;
  userMessage: Scalars['String']['output'];
};

export enum CollectUnableToListErrorCode {
  Flagged = 'FLAGGED',
  HasActiveFulfillmentOrTransferRequest = 'HAS_ACTIVE_FULFILLMENT_OR_TRANSFER_REQUEST',
  HasOffers = 'HAS_OFFERS',
  IneligibleCategory = 'INELIGIBLE_CATEGORY',
  IneligibleTitle = 'INELIGIBLE_TITLE',
  ListedInPremierAuction = 'LISTED_IN_PREMIER_AUCTION',
  ListedInWeeklyAuction = 'LISTED_IN_WEEKLY_AUCTION',
  NotArchived = 'NOT_ARCHIVED',
  NotInCollect = 'NOT_IN_COLLECT',
  NotOwned = 'NOT_OWNED',
  NotSellable = 'NOT_SELLABLE',
  TemporaryBlocked = 'TEMPORARY_BLOCKED'
}

export type CollectUpdateFixedPriceListingPayload = {
  __typename?: 'CollectUpdateFixedPriceListingPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectUpdateFixedPriceListingResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectUpdateFixedPriceListingResponse = {
  __typename?: 'CollectUpdateFixedPriceListingResponse';
  id: Scalars['UUID']['output'];
  listing?: Maybe<CollectListing>;
  type: CollectListingType;
};

export type CollectUpdateItemPayload = {
  __typename?: 'CollectUpdateItemPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectUpdateItemResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectUpdateItemResponse = {
  __typename?: 'CollectUpdateItemResponse';
  item: CollectItem;
};

export type CollectUpdateMemberCheckoutInput = {
  addressType: CollectMemberCheckoutAddressType;
  alternateAddress?: InputMaybe<CollectVaultItemRequestAlternateAddress>;
};

export type CollectUpdateMemberCheckoutPayload = {
  __typename?: 'CollectUpdateMemberCheckoutPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectUpdateMemberCheckoutResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectUpdateMemberCheckoutResponse = {
  __typename?: 'CollectUpdateMemberCheckoutResponse';
  vaultItemRequest?: Maybe<CollectVaultItemRequest>;
};

export type CollectUpdateOfferInput = {
  listingId: Scalars['UUID']['input'];
  offerId: Scalars['UUID']['input'];
  sendChatMessage?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields for updating the vault item's owner value */
export type CollectUpdateOwnerValueData = {
  id: Scalars['UUID']['input'];
  ownerValueInCents: Scalars['Int']['input'];
};

export type CollectUpdateOwnerValuesPayload = {
  __typename?: 'CollectUpdateOwnerValuesPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectUpdateOwnerValuesResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectUpdateOwnerValuesResponse = {
  __typename?: 'CollectUpdateOwnerValuesResponse';
  vaultItems: Array<Maybe<CollectVaultItemOwnerValue>>;
};

export type CollectUpdatePremierListingPayload = {
  __typename?: 'CollectUpdatePremierListingPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectUpdatePremierListingResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectUpdatePremierListingResponse = {
  __typename?: 'CollectUpdatePremierListingResponse';
  id: Scalars['UUID']['output'];
  listing?: Maybe<CollectListing>;
  type: CollectListingType;
};

export type CollectUpdateProfile = {
  __typename?: 'CollectUpdateProfile';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectUpdateProfileName = {
  __typename?: 'CollectUpdateProfileName';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectUpdateTradeShowCheckoutInput = {
  tradeShowId: Scalars['Int']['input'];
};

export type CollectUpdateTradeShowCheckoutPayload = {
  __typename?: 'CollectUpdateTradeShowCheckoutPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectUpdateTradeShowCheckoutResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectUpdateTradeShowCheckoutResponse = {
  __typename?: 'CollectUpdateTradeShowCheckoutResponse';
  vaultItemRequest?: Maybe<CollectVaultItemRequest>;
};

export type CollectUpdateUserPhone = {
  __typename?: 'CollectUpdateUserPhone';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectUpdateVaultItemGroupPayload = {
  __typename?: 'CollectUpdateVaultItemGroupPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectVaultItemGroup>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectUpdateVaultRetrievalInput = {
  addressType: CollectVaultRetrievalAddressType;
  alternateAddress?: InputMaybe<CollectVaultRetrievalAlternateAddress>;
};

export type CollectUpdateVaultRetrievalPayload = {
  __typename?: 'CollectUpdateVaultRetrievalPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectUpdateVaultRetrievalResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectUpdateVaultRetrievalResponse = {
  __typename?: 'CollectUpdateVaultRetrievalResponse';
  vaultItemRequest?: Maybe<CollectVaultItemRequest>;
};

export type CollectUpdateVaultSubmissionInput = {
  trackingNumber: Scalars['String']['input'];
};

export type CollectUpdateVaultSubmissionPayload = {
  __typename?: 'CollectUpdateVaultSubmissionPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectUpdateVaultSubmissionResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectUpdateVaultSubmissionResponse = {
  __typename?: 'CollectUpdateVaultSubmissionResponse';
  submission?: Maybe<CollectVaultSubmission>;
};

export type CollectUpdateWeeklyListingPayload = {
  __typename?: 'CollectUpdateWeeklyListingPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectUpdateWeeklyListingResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectUpdateWeeklyListingResponse = {
  __typename?: 'CollectUpdateWeeklyListingResponse';
  id: Scalars['UUID']['output'];
  listing?: Maybe<CollectListing>;
  type: CollectListingType;
};

export type CollectUseSavedSearchPayload = {
  __typename?: 'CollectUseSavedSearchPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['String']['output']>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

/** Represents any user in the system */
export type CollectUser = {
  __typename?: 'CollectUser';
  email?: Maybe<CollectEmail>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  integerId: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['UUID']['output'];
};

export enum CollectUserBidState {
  HighBid = 'HIGH_BID',
  NoBids = 'NO_BIDS',
  NoStatus = 'NO_STATUS',
  Outbid = 'OUTBID'
}

export type CollectUserReport = {
  /** ID of the reported entity */
  entityId: Scalars['UUID']['input'];
  /** Type of the reported entity */
  entityType: CollectReportEntityType;
  /** Optional string note from the reporter. Max 100KB */
  note?: InputMaybe<Scalars['String']['input']>;
  /** ID of the report reason */
  reasonId: Scalars['UUID']['input'];
};

export enum CollectValidPaymentMethod {
  Ach = 'ACH',
  ApplePay = 'APPLE_PAY',
  Card = 'CARD',
  Crypto = 'CRYPTO',
  Funds = 'FUNDS',
  GooglePay = 'GOOGLE_PAY',
  Wire = 'WIRE'
}

export type CollectValidateMfaPayload = {
  __typename?: 'CollectValidateMfaPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectValidateMfaResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectValidateMfaResponse = {
  __typename?: 'CollectValidateMfaResponse';
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

/** Card variant which can be found in catalog */
export type CollectVariant = {
  __typename?: 'CollectVariant';
  dataSource?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  items?: Maybe<CollectItemConnection>;
  matchLevel?: Maybe<CollectMatchLevel>;
  metadata: CollectProductMetadata;
  predictionSource?: Maybe<CollectPredictionSource>;
  product?: Maybe<CollectProduct>;
  title?: Maybe<Scalars['String']['output']>;
};


/** Card variant which can be found in catalog */
export type CollectVariantItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectVariantConnection = {
  __typename?: 'CollectVariantConnection';
  edges?: Maybe<Array<Maybe<CollectVariantEdge>>>;
  pageInfo: PageInfo;
};

export type CollectVariantEdge = {
  __typename?: 'CollectVariantEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectVariant>;
};

export type CollectVaultCategory = {
  __typename?: 'CollectVaultCategory';
  categoryType?: Maybe<CollectVaultCategoryType>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum CollectVaultCategoryType {
  CoinsCurrency = 'COINS_CURRENCY',
  Comics = 'COMICS',
  Other = 'OTHER',
  SportsCards = 'SPORTS_CARDS',
  TradingCardGames = 'TRADING_CARD_GAMES',
  VideoGames = 'VIDEO_GAMES',
  Wax = 'WAX'
}

export type CollectVaultItem = CollectDetailed & {
  __typename?: 'CollectVaultItem';
  activeListing?: Maybe<CollectListing>;
  appraisalValue?: Maybe<Money>;
  appraisalValueDate?: Maybe<Scalars['Date']['output']>;
  canLeaveVault?: Maybe<Scalars['Boolean']['output']>;
  cardGrade?: Maybe<CollectCardGrade>;
  cardLadderSearchUrl?: Maybe<Scalars['String']['output']>;
  cardLadderValue?: Maybe<Money>;
  cardLadderValueDate?: Maybe<Scalars['DateTime']['output']>;
  category?: Maybe<CollectVaultCategory>;
  debugMetadata?: Maybe<Scalars['String']['output']>;
  gradeCert?: Maybe<Scalars['String']['output']>;
  gradingService?: Maybe<CollectGradingService>;
  id: Scalars['UUID']['output'];
  imageMetadata?: Maybe<Array<Maybe<CollectImageMetadata>>>;
  imageSets: Array<Maybe<CollectImageSet>>;
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  insertedAt: Scalars['DateTime']['output'];
  insuredValue?: Maybe<Money>;
  insuredValueDate?: Maybe<Scalars['Date']['output']>;
  integerId: Scalars['Int']['output'];
  listableOnFixedPriceMarketplace?: Maybe<Scalars['Boolean']['output']>;
  listableOnPremierAuction?: Maybe<Scalars['Boolean']['output']>;
  listableOnWeeklyAuction?: Maybe<Scalars['Boolean']['output']>;
  /** This is essentially */
  marketValue?: Maybe<Money>;
  marketValueDate?: Maybe<Scalars['Date']['output']>;
  origin?: Maybe<CollectVaultItemOrigin>;
  ownerValue?: Maybe<Money>;
  pendingPayoutListings?: Maybe<Array<Maybe<CollectListing>>>;
  protoItemUuid?: Maybe<Scalars['UUID']['output']>;
  rotation?: Maybe<Scalars['Int']['output']>;
  /** aka Market Value in UI aka PWCC Value */
  safeMarketValue?: Maybe<Money>;
  serial?: Maybe<Scalars['String']['output']>;
  similarItemTransactions?: Maybe<Array<Maybe<CollectSale>>>;
  soldListing?: Maybe<CollectListing>;
  status?: Maybe<CollectVaultStatus>;
  subtitle?: Maybe<Scalars['String']['output']>;
  suggestedAskingPrice?: Maybe<Money>;
  suggestedAskingPriceRange?: Maybe<CollectPriceRange>;
  suggestedMinimumOfferValue?: Maybe<Money>;
  title: Scalars['String']['output'];
  /**
   * If this vault item can't be listed, this field will contain a string with reason.
   * Otherwise, this field will be null
   */
  unlistableReason?: Maybe<CollectUnableToListError>;
  unsoldListings?: Maybe<Array<Maybe<CollectListing>>>;
  vaultItemGroups?: Maybe<Array<Maybe<CollectVaultItemGroup>>>;
  vaultItemRequest?: Maybe<CollectVaultItemRequest>;
  vaultSubmission?: Maybe<CollectVaultSubmission>;
  video?: Maybe<CollectExternalVideo>;
};


export type CollectVaultItemVaultItemGroupsArgs = {
  vaultItemGroupTypes?: InputMaybe<Array<InputMaybe<CollectVaultItemGroupType>>>;
};

export type CollectVaultItemConnection = {
  __typename?: 'CollectVaultItemConnection';
  edges?: Maybe<Array<Maybe<CollectVaultItemEdge>>>;
  pageInfo: PageInfo;
};

export type CollectVaultItemEdge = {
  __typename?: 'CollectVaultItemEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectVaultItem>;
};

export type CollectVaultItemForRequest = {
  __typename?: 'CollectVaultItemForRequest';
  vaultItem?: Maybe<CollectVaultItem>;
};

export type CollectVaultItemGroup = {
  __typename?: 'CollectVaultItemGroup';
  /** Expiration date of the VaultItemGroup */
  expiresOn?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  /** Date and time the VaultItemGroup was inserted */
  insertedAt: Scalars['DateTime']['output'];
  /** Represents if a share is publically accessible */
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  /** Notes associated with the VaultItemGroup */
  notes?: Maybe<Scalars['String']['output']>;
  /** Number of historical vault items in the group */
  numHistoricalItems: Scalars['Int']['output'];
  /** Number of vault items in the group */
  numItems: Scalars['Int']['output'];
  shortLink?: Maybe<CollectShortLink>;
  /** Title of the VaultItemGroup */
  title: Scalars['String']['output'];
  /** Type of the VaultItemGroup either Folder or share */
  type: CollectVaultItemGroupType;
  updatedAt: Scalars['DateTime']['output'];
  /** URL UUID of the VaultItemGroup */
  urlUuid?: Maybe<Scalars['String']['output']>;
  vaultItems?: Maybe<Array<Maybe<CollectVaultItem>>>;
};

export enum CollectVaultItemGroupSortBy {
  /** Sort by creation time ascending */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Sort by creation time descending */
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** Sort by expiration date ascending */
  ExpirationDtAsc = 'EXPIRATION_DT_ASC',
  /** Sort by expiration date descending */
  ExpirationDtDesc = 'EXPIRATION_DT_DESC',
  /** Sort by title ascending */
  TitleAsc = 'TITLE_ASC',
  /** Sort by title descending */
  TitleDesc = 'TITLE_DESC',
  /** Sort by last updated time ascending */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Sort by last updated time descending */
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export enum CollectVaultItemGroupStatus {
  /** Represents active vault item groups */
  Active = 'ACTIVE',
  /** Represents inactive vault item groups */
  Inactive = 'INACTIVE'
}

/** Represents the 2 types of VaultItemGroup Folders and Shares */
export enum CollectVaultItemGroupType {
  /** Represents Folders */
  Folders = 'FOLDERS',
  /** Represents Shares */
  Shares = 'SHARES'
}

export type CollectVaultItemGroupsConnection = {
  __typename?: 'CollectVaultItemGroupsConnection';
  edges?: Maybe<Array<Maybe<CollectVaultItemGroupsEdge>>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type CollectVaultItemGroupsEdge = {
  __typename?: 'CollectVaultItemGroupsEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectVaultItemGroup>;
};

export enum CollectVaultItemOrigin {
  FixedPrice = 'FIXED_PRICE',
  InstantRips = 'INSTANT_RIPS',
  Premier = 'PREMIER',
  VaultSubmission = 'VAULT_SUBMISSION',
  Weekly = 'WEEKLY'
}

export type CollectVaultItemOwnerValue = {
  __typename?: 'CollectVaultItemOwnerValue';
  id: Scalars['UUID']['output'];
  ownerValue?: Maybe<Money>;
};

export type CollectVaultItemRequest = {
  __typename?: 'CollectVaultItemRequest';
  address?: Maybe<CollectVaultItemRequestAddress>;
  addressType?: Maybe<CollectVaultItemRequestAddressType>;
  createdByEntity?: Maybe<CollectVaultItemRequestCreatedByEntity>;
  createdByEntityType?: Maybe<CollectVaultItemRequestCreatedByEntityTypes>;
  id: Scalars['Int']['output'];
  insertedAt: Scalars['DateTime']['output'];
  invoice?: Maybe<CollectInvoice>;
  items?: Maybe<CollectVaultItemConnection>;
  /** Vault Item Request states based on user context */
  states?: Maybe<CollectVaultItemRequestStates>;
  status: CollectVaultItemRequestStatus;
  toEntity?: Maybe<CollectVaultItemRequestToEntity>;
  toEntityType?: Maybe<CollectVaultItemRequestToEntityTypes>;
  trackingInfo?: Maybe<CollectVaultItemRequestTrackingInfo>;
  type: CollectVaultItemRequestType;
  updatedAt: Scalars['DateTime']['output'];
};


export type CollectVaultItemRequestItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectVaultItemRequestAddress = {
  __typename?: 'CollectVaultItemRequestAddress';
  city: Scalars['String']['output'];
  countryId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1: Scalars['String']['output'];
  street2?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

export enum CollectVaultItemRequestAddressType {
  Alternate = 'ALTERNATE',
  Default = 'DEFAULT',
  TradeShow = 'TRADE_SHOW'
}

export type CollectVaultItemRequestAlternateAddress = {
  city: Scalars['String']['input'];
  countryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type CollectVaultItemRequestCharges = CollectCheckoutCharges | CollectPickUpInPersonCharges | CollectSaleCharges | CollectTradeShowCheckoutCharges | CollectVaultRetrievalCharges;

export type CollectVaultItemRequestCreatedByEntity = CollectFixedPriceSale | CollectPremierSale | CollectUser | CollectWeeklySale;

export enum CollectVaultItemRequestCreatedByEntityTypes {
  EntityTypeAuctionSale = 'ENTITY_TYPE_AUCTION_SALE',
  EntityTypeExtendedSale = 'ENTITY_TYPE_EXTENDED_SALE',
  EntityTypeUser = 'ENTITY_TYPE_USER',
  EntityTypeVaultSale = 'ENTITY_TYPE_VAULT_SALE'
}

export type CollectVaultItemRequestDetails = {
  __typename?: 'CollectVaultItemRequestDetails';
  chargesInfo?: Maybe<CollectVaultItemRequestCharges>;
};

export enum CollectVaultItemRequestSortBy {
  /** Sort by creation time ascending */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Sort by creation time descending */
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** Sort by creating user ascending */
  CreatedByAsc = 'CREATED_BY_ASC',
  /** Sort by creating user descending */
  CreatedByDesc = 'CREATED_BY_DESC',
  /** Sort by status ascending */
  StatusAsc = 'STATUS_ASC',
  /** Sort by status descending */
  StatusDesc = 'STATUS_DESC',
  /** Sort by type ascending */
  TypeAsc = 'TYPE_ASC',
  /** Sort by type descending */
  TypeDesc = 'TYPE_DESC',
  /** Sort by last updated time ascending */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Sort by last updated time descending */
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type CollectVaultItemRequestStates = {
  __typename?: 'CollectVaultItemRequestStates';
  userCanCancel?: Maybe<Scalars['Boolean']['output']>;
  userCanUpdate?: Maybe<Scalars['Boolean']['output']>;
};

export enum CollectVaultItemRequestStatus {
  AwaitingReturn = 'AWAITING_RETURN',
  AwaitingReview = 'AWAITING_REVIEW',
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Open = 'OPEN',
  ReadyToProcess = 'READY_TO_PROCESS',
  ReadyToShip = 'READY_TO_SHIP'
}

export type CollectVaultItemRequestToEntity = CollectAddress | CollectTradeShow | CollectUser;

export enum CollectVaultItemRequestToEntityTypes {
  EntityTypeAddress = 'ENTITY_TYPE_ADDRESS',
  EntityTypeAuctionSchedule = 'ENTITY_TYPE_AUCTION_SCHEDULE',
  EntityTypeEbayAddress = 'ENTITY_TYPE_EBAY_ADDRESS',
  EntityTypeExtendedAuction = 'ENTITY_TYPE_EXTENDED_AUCTION',
  EntityTypeTradeShow = 'ENTITY_TYPE_TRADE_SHOW',
  EntityTypeUser = 'ENTITY_TYPE_USER'
}

export type CollectVaultItemRequestTrackingInfo = {
  __typename?: 'CollectVaultItemRequestTrackingInfo';
  carrier: CollectShippingService;
  trackingNumber: Scalars['String']['output'];
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

export enum CollectVaultItemRequestType {
  AdminSaleTransfer = 'ADMIN_SALE_TRANSFER',
  AdminTransfer = 'ADMIN_TRANSFER',
  AuctionSale = 'AUCTION_SALE',
  Checkout = 'CHECKOUT',
  ExtendedSale = 'EXTENDED_SALE',
  External = 'EXTERNAL',
  FulfillToVault = 'FULFILL_TO_VAULT',
  Grade = 'GRADE',
  PickUpInPerson = 'PICK_UP_IN_PERSON',
  Retrieval = 'RETRIEVAL',
  SendAuction = 'SEND_AUCTION',
  SendExtendedAuction = 'SEND_EXTENDED_AUCTION',
  TradeShowCheckout = 'TRADE_SHOW_CHECKOUT',
  Transfer = 'TRANSFER',
  VaultSale = 'VAULT_SALE',
  Verify = 'VERIFY'
}

export type CollectVaultItemRequestsConnection = {
  __typename?: 'CollectVaultItemRequestsConnection';
  edges?: Maybe<Array<Maybe<CollectVaultItemRequestsEdge>>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type CollectVaultItemRequestsEdge = {
  __typename?: 'CollectVaultItemRequestsEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectVaultItemRequest>;
};

export enum CollectVaultItemState {
  All = 'ALL',
  ArchivedOnly = 'ARCHIVED_ONLY',
  BuyNowListed = 'BUY_NOW_LISTED',
  CheckedOutByOwner = 'CHECKED_OUT_BY_OWNER',
  Historical = 'HISTORICAL',
  OwnershipTransfer = 'OWNERSHIP_TRANSFER',
  PendingMove = 'PENDING_MOVE',
  PremierSubmitted = 'PREMIER_SUBMITTED',
  WeeklySubmitted = 'WEEKLY_SUBMITTED'
}

export type CollectVaultItemsConnection = {
  __typename?: 'CollectVaultItemsConnection';
  edges?: Maybe<Array<Maybe<CollectVaultItemsEdge>>>;
  insuredValue: Money;
  ownerValue: Money;
  ownerValueCount: Scalars['Int']['output'];
  pageInfo: PageInfo;
  safeMarketValue: Money;
  total: Scalars['Int']['output'];
};

export type CollectVaultItemsEdge = {
  __typename?: 'CollectVaultItemsEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectVaultItem>;
};

export type CollectVaultItemsExportPayload = {
  __typename?: 'CollectVaultItemsExportPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectVaultItemsExportResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

/** The response of Vault Items export */
export type CollectVaultItemsExportResponse = {
  __typename?: 'CollectVaultItemsExportResponse';
  vaultItemsExportFileUrl?: Maybe<Scalars['String']['output']>;
};

export type CollectVaultItemsForRequestConnection = {
  __typename?: 'CollectVaultItemsForRequestConnection';
  edges?: Maybe<Array<Maybe<CollectVaultItemsForRequestEdge>>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type CollectVaultItemsForRequestEdge = {
  __typename?: 'CollectVaultItemsForRequestEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectVaultItemForRequest>;
};

export enum CollectVaultItemsForRequestInputType {
  Checkout = 'CHECKOUT',
  PickUpInPerson = 'PICK_UP_IN_PERSON',
  Retrieval = 'RETRIEVAL',
  TradeShowCheckout = 'TRADE_SHOW_CHECKOUT',
  Transfer = 'TRANSFER'
}

export type CollectVaultItemsMarketValueStatistics = {
  __typename?: 'CollectVaultItemsMarketValueStatistics';
  average?: Maybe<Scalars['Float']['output']>;
  categories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ceiling?: Maybe<Scalars['Int']['output']>;
  floor?: Maybe<Scalars['Int']['output']>;
};

export enum CollectVaultRetrievalAddressType {
  Alternate = 'ALTERNATE',
  Default = 'DEFAULT'
}

export type CollectVaultRetrievalAlternateAddress = {
  city: Scalars['String']['input'];
  countryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type CollectVaultRetrievalCharges = {
  __typename?: 'CollectVaultRetrievalCharges';
  additionalFee: Money;
  fulfillmentFee: Money;
  insuredValue: Money;
  needsUnderwriting: Scalars['Boolean']['output'];
  shippingFee?: Maybe<Money>;
};

export type CollectVaultRetrievalSummary = {
  __typename?: 'CollectVaultRetrievalSummary';
  additionalFee: Money;
  fulfillmentFee: Money;
  insuredValue: Money;
  needsUnderwriting: Scalars['Boolean']['output'];
  shippingFee?: Maybe<Money>;
};

export enum CollectVaultSortBy {
  /** Sort by highest value first */
  HighestValue = 'HIGHEST_VALUE',
  /** Sort by lowest value first */
  LowestValue = 'LOWEST_VALUE',
  /** Sort by recently added items */
  RecentlyAdded = 'RECENTLY_ADDED',
  /** Sort by title ascending */
  TitleAsc = 'TITLE_ASC',
  /** Sort by title descending */
  TitleDesc = 'TITLE_DESC'
}

export enum CollectVaultStatus {
  /** @deprecated Deprecated */
  StatusAwaitingAppraisal = 'STATUS_AWAITING_APPRAISAL',
  StatusAwaitingCuration = 'STATUS_AWAITING_CURATION',
  StatusAwaitingGradingFee = 'STATUS_AWAITING_GRADING_FEE',
  StatusAwaitingReview = 'STATUS_AWAITING_REVIEW',
  StatusCurated = 'STATUS_CURATED',
  StatusFulfilled = 'STATUS_FULFILLED',
  StatusGraded = 'STATUS_GRADED',
  StatusLocked = 'STATUS_LOCKED',
  StatusPendingSale = 'STATUS_PENDING_SALE',
  StatusProcessing = 'STATUS_PROCESSING',
  StatusReturned = 'STATUS_RETURNED',
  StatusSentAuction = 'STATUS_SENT_AUCTION',
  StatusSentExchange = 'STATUS_SENT_EXCHANGE',
  StatusSentNft = 'STATUS_SENT_NFT',
  StatusSold = 'STATUS_SOLD',
  StatusTransferred = 'STATUS_TRANSFERRED',
  StatusUnsold = 'STATUS_UNSOLD'
}

export type CollectVaultSubmission = {
  __typename?: 'CollectVaultSubmission';
  curatedItemsCount: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  estimatedValue: Money;
  grader?: Maybe<CollectVaultSubmissionGrader>;
  id: Scalars['UUID']['output'];
  insertedAt?: Maybe<Scalars['DateTime']['output']>;
  integerId: Scalars['Int']['output'];
  intent: CollectVaultSubmissionIntent;
  items?: Maybe<CollectVaultItemConnection>;
  packingSlipUrl?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  questions?: Maybe<Scalars['String']['output']>;
  receiptConfirmedAt?: Maybe<Scalars['DateTime']['output']>;
  receivedAt?: Maybe<Scalars['DateTime']['output']>;
  receivedForGradingAt?: Maybe<Scalars['DateTime']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  status: CollectVaultSubmissionStatus;
  trackingNumber?: Maybe<Scalars['String']['output']>;
};


export type CollectVaultSubmissionItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectVaultSubmissionConnection = {
  __typename?: 'CollectVaultSubmissionConnection';
  edges?: Maybe<Array<Maybe<CollectVaultSubmissionEdge>>>;
  pageInfo: PageInfo;
};

export type CollectVaultSubmissionEdge = {
  __typename?: 'CollectVaultSubmissionEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectVaultSubmission>;
};

export enum CollectVaultSubmissionGrader {
  Beckett = 'BECKETT',
  Cgc = 'CGC',
  CgcAuth = 'CGC_AUTH',
  Mba = 'MBA',
  Psa = 'PSA',
  Sgc = 'SGC'
}

export type CollectVaultSubmissionGradingRate = {
  __typename?: 'CollectVaultSubmissionGradingRate';
  grader: CollectVaultSubmissionGrader;
  max?: Maybe<Money>;
  min: Money;
};

export enum CollectVaultSubmissionIntent {
  Premier = 'PREMIER',
  Vault = 'VAULT',
  Weekly = 'WEEKLY'
}

export type CollectVaultSubmissionProcessingRate = {
  __typename?: 'CollectVaultSubmissionProcessingRate';
  max?: Maybe<Money>;
  min: Money;
};

export type CollectVaultSubmissionRates = {
  __typename?: 'CollectVaultSubmissionRates';
  gradingRates?: Maybe<Array<Maybe<CollectVaultSubmissionGradingRate>>>;
  processingRate: CollectVaultSubmissionProcessingRate;
};

export enum CollectVaultSubmissionStatus {
  Grading = 'GRADING',
  Received = 'RECEIVED',
  Started = 'STARTED',
  Submitted = 'SUBMITTED',
  Unknown = 'UNKNOWN'
}

export type CollectVaultSubmissionSummary = {
  __typename?: 'CollectVaultSubmissionSummary';
  estimatedValue: Money;
  grader?: Maybe<CollectVaultSubmissionGrader>;
  gradingFee?: Maybe<Money>;
  gradingFeePerItem?: Maybe<Money>;
  intent: CollectVaultSubmissionIntent;
  itemsNumber: Scalars['Int']['output'];
  processingFee: Money;
  shippingFee: Money;
  taxFee: Money;
  totalFee: Money;
  vaultId: Scalars['Int']['output'];
};

export type CollectVerifyMfaPayload = {
  __typename?: 'CollectVerifyMfaPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectVerifyPhonePayload = {
  __typename?: 'CollectVerifyPhonePayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectVerifySessionEmailOtpPayload = {
  __typename?: 'CollectVerifySessionEmailOtpPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectWatchListingPayload = {
  __typename?: 'CollectWatchListingPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<CollectWatchListingResponse>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean']['output'];
};

export type CollectWatchListingResponse = {
  __typename?: 'CollectWatchListingResponse';
  listing?: Maybe<CollectListing>;
};

export type CollectWatchlistConnection = {
  __typename?: 'CollectWatchlistConnection';
  edges?: Maybe<Array<Maybe<CollectWatchlistEdge>>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type CollectWatchlistEdge = {
  __typename?: 'CollectWatchlistEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectListing>;
};

export type CollectWeeklyAuction = CollectAuction & {
  __typename?: 'CollectWeeklyAuction';
  /** Does this auction allow flash submissions at the moment */
  allowsFlashSubmissions?: Maybe<Scalars['Boolean']['output']>;
  auctionIntegerId?: Maybe<Scalars['Int']['output']>;
  collectListingTimer: CollectListingTimer;
  /** Same as starts_at, this is coming from auction table ends at, and always in UTC timezone. */
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  integerId?: Maybe<Scalars['Int']['output']>;
  /** Identifies whether an auction is custom */
  isCustom?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  payoutDate?: Maybe<Scalars['Date']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  /**
   * The actual starts_at timestamp of the weekly auction.
   * In application logic we always use the auction starts_at timestamp, instead of the
   * start_date field on auction_schedule table. This timestamp is always in UTC timezone
   */
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  status: CollectAuctionStatus;
  /** Applicable only for custom auctions */
  subtitle?: Maybe<Scalars['String']['output']>;
};

export type CollectWeeklyAuctionBid = {
  __typename?: 'CollectWeeklyAuctionBid';
  status: CollectWeeklyAuctionBidStatus;
  uuid: Scalars['UUID']['output'];
};

export type CollectWeeklyAuctionBidConnection = {
  __typename?: 'CollectWeeklyAuctionBidConnection';
  edges?: Maybe<Array<Maybe<CollectWeeklyAuctionBidEdge>>>;
  pageInfo: PageInfo;
};

export type CollectWeeklyAuctionBidEdge = {
  __typename?: 'CollectWeeklyAuctionBidEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CollectWeeklyAuctionBid>;
};

export enum CollectWeeklyAuctionBidStatus {
  StatusFirst = 'STATUS_FIRST',
  StatusNewHighest = 'STATUS_NEW_HIGHEST',
  StatusNewSecondHighest = 'STATUS_NEW_SECOND_HIGHEST',
  StatusNotProcessed = 'STATUS_NOT_PROCESSED',
  StatusRejected = 'STATUS_REJECTED',
  StatusStale = 'STATUS_STALE',
  StatusUpdatedHighest = 'STATUS_UPDATED_HIGHEST'
}

export type CollectWeeklySale = CollectMarketplaceSale & {
  __typename?: 'CollectWeeklySale';
  buyersPremium?: Maybe<Money>;
  id: Scalars['UUID']['output'];
  integerId: Scalars['Int']['output'];
  /** Not affected by faster listing query optimization */
  listing?: Maybe<CollectListing>;
  purchasePrice?: Maybe<Money>;
  shipping?: Maybe<Money>;
  tax?: Maybe<Money>;
  total?: Maybe<Money>;
};

export type Money = {
  __typename?: 'Money';
  amountInCents: Scalars['Int']['output'];
  currency: Scalars['Currency']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  collectAcceptOffer?: Maybe<CollectFixedPriceAcceptOfferPayload>;
  collectAddCandidateToCollection: CollectAddToCollectionPayload;
  collectAddFixedPriceToSetlist: CollectAddFixedPriceToSetlistPayload;
  collectAddItemToSetlist: CollectAddItemToSetlistPayload;
  collectAddPremierToSetlist: CollectAddPremierToSetlistPayload;
  collectAddProductToSetlist: CollectAddProductToSetlistPayload;
  collectAddToCart?: Maybe<CollectCartPayload>;
  collectAddToCollection: CollectAddToCollectionPayload;
  collectAddVariantToSetlist: CollectAddVariantToSetlistPayload;
  collectAddVaultItemsToGroup?: Maybe<CollectAddVaultItemsToGroupPayload>;
  collectAddWeeklyToSetlist: CollectAddWeeklyToSetlistPayload;
  collectAddressCreate?: Maybe<CollectAddressCreatePayload>;
  collectAddressValidate?: Maybe<CollectAddressValidatePayload>;
  /**
   * Authenticate a user with an authorization code.
   *
   * This is meant to be called by native mobile apps, after completing the first
   * part of the FanID authentication flow.
   */
  collectAuthenticate?: Maybe<CollectTokenSet>;
  /**
   * Cancels several listings in a single batch.
   *
   * When updates for the same listing are specified in this call,
   * all updates will be applied but their order is undefined
   */
  collectBatchCancelListings?: Maybe<CollectBatchCancelListingsPayload>;
  collectBatchCreateListing?: Maybe<CollectBatchCreateListingPayload>;
  collectBatchCreateListingsV2?: Maybe<CollectBatchCreateListingsV2Payload>;
  collectBatchMakeOffers?: Maybe<CollectBatchMakeOffersPayload>;
  /**
   * Updates several listings in a single batch.
   *
   * For detailed description on available options see documentation
   * for type-specific update mutations.
   *
   * When updates for the same listing are specified in this call,
   * all updates will be applied but their order is undefined
   */
  collectBatchUpdateListings?: Maybe<CollectBatchUpdateListingsPayload>;
  collectBoCheckoutListingConfirmIntent?: Maybe<CollectBoCheckoutListingConfirmIntentPayload>;
  collectBoCheckoutListingCreateIntent?: Maybe<CollectBoCheckoutListingCreateIntentPayload>;
  collectCancelListing?: Maybe<CollectCancelListingPayload>;
  collectCancelOffer?: Maybe<CollectFixedPriceOfferPayload>;
  collectCancelVaultItemRequest?: Maybe<CollectCancelVaultItemRequestPayload>;
  collectCancelVaultRetrieval?: Maybe<CollectCancelVaultRetrievalPayload>;
  collectCancelVaultSubmission?: Maybe<CollectCancelVaultSubmissionPayload>;
  /** Create a new item */
  collectCatalogCreateItem?: Maybe<CollectItem>;
  /** Update an existing item */
  collectCatalogUpdateItem?: Maybe<CollectItem>;
  collectCheckoutFulfillmentCharges?: Maybe<CollectFulfillmentChargesPayload>;
  collectCheckoutInvoiceConfirm?: Maybe<CollectCheckoutInvoiceConfirmPayload>;
  /** This creates a client secret, that can be used for client side to generate the payment intent */
  collectCheckoutInvoicePaymentIntent?: Maybe<CollectCheckoutInvoicePaymentIntentPayload>;
  /** This creates an Invoice in case of listing type input and create a Payment Intent for Stripe payment method */
  collectCheckoutPaymentLock?: Maybe<CollectCheckoutPaymentLockPayload>;
  /** This creates a setup intent, that can be used for client side to create payment method */
  collectCheckoutSetupIntent?: Maybe<CollectCheckoutSetupIntentPayload>;
  collectCheckoutV2CompletePayment: CollectCheckoutV2CompletePaymentPayload;
  collectCheckoutV2LockForPayment: CollectCheckoutV2LockForPaymentPayload;
  collectCounterOffer?: Maybe<CollectFixedPriceOfferPayload>;
  collectCreateInvoice?: Maybe<CollectCreateInvoicePayload>;
  /** Create Item Edit Request for a specific user */
  collectCreateItemEditRequest: CollectCreateItemEditRequestPayload;
  collectCreatePayoutRequests?: Maybe<CollectCreatePayoutRequestsPayload>;
  collectCreatePickUpInPerson?: Maybe<CollectCreatePickUpInPersonPayload>;
  collectCreatePlaidLinkToken: CollectCreatePlaidLinkTokenPayload;
  collectCreatePresignedUrl?: Maybe<CollectPresignedUrlPayload>;
  collectCreateReport: CollectCreateReportPayload;
  collectCreateSavedSearch?: Maybe<CollectCreateSavedSearchPayload>;
  collectCreateScanResultFeedback?: Maybe<CollectScanResultFeedbackPayload>;
  collectCreateTradeShowCheckout?: Maybe<CollectCreateTradeShowCheckoutPayload>;
  collectCreateVaultItemGroup?: Maybe<CollectCreateVaultItemGroupPayload>;
  collectCreateVaultRetrieval?: Maybe<CollectCreateVaultRetrievalPayload>;
  collectCreateVaultSubmission?: Maybe<CollectCreateVaultSubmissionPayload>;
  collectCreateVaultTransfer?: Maybe<CollectCreateVaultTransferPayload>;
  collectDeclineOffer?: Maybe<CollectFixedPriceOfferPayload>;
  collectDeleteSavedSearch?: Maybe<CollectDeleteSavedSearchPayload>;
  collectDeleteVaultItemGroup?: Maybe<CollectDeleteVaultItemGroupPayload>;
  /**
   * Exchange a refresh token for a new token pair.
   * @deprecated Use collectRefreshToken mutation instead.
   */
  collectExchangeToken?: Maybe<CollectTokenSet>;
  collectForgetRecentView?: Maybe<CollectActivityForgetRecentViewPayload>;
  /**
   * Issue a new token pair for a user.
   *
   * This is meant to be called by web applications, after completing the first
   * part of the FanID authentication flow.
   */
  collectGetTokenPair?: Maybe<CollectTokenSet>;
  collectLinkPlaidIdentityVerification: Scalars['Boolean']['output'];
  /** Logout the current user. */
  collectLogout?: Maybe<Scalars['Boolean']['output']>;
  collectMakeOffer?: Maybe<CollectFixedPriceOfferPayload>;
  collectMarkOfferAsViewed?: Maybe<CollectMarkOfferAsViewedPayload>;
  collectMarkOfferNotificationAsViewed?: Maybe<CollectMarkOfferNotificationAsViewedPayload>;
  /** Place a list of bids */
  collectMultiBid?: Maybe<CollectBidPayload>;
  collectPing?: Maybe<CollectPingPayload>;
  collectRefreshToken?: Maybe<CollectTokenPair>;
  collectRemoveFixedPriceFromSetlist: CollectRemoveFixedPriceFromSetlistPayload;
  collectRemoveFromCart?: Maybe<CollectCartPayload>;
  collectRemoveFromCollection: CollectRemoveFromCollectionPayload;
  collectRemoveItemFromSetlist: CollectRemoveItemFromSetlistPayload;
  collectRemovePremierFromSetlist: CollectRemovePremierFromSetlistPayload;
  collectRemoveProductFromSetlist: CollectRemoveProductFromSetlistPayload;
  collectRemoveVariantFromSetlist: CollectRemoveVariantFromSetlistPayload;
  collectRemoveVaultItemFromGroup?: Maybe<CollectRemoveVaultItemFromVaultItemGroupPayload>;
  collectRemoveWeeklyFromSetlist: CollectRemoveWeeklyFromSetlistPayload;
  /** Request an OTP via email */
  collectRequestSessionEmailOtp?: Maybe<CollectRequestSessionEmailOtpPayload>;
  collectResendPhoneVerificationCode?: Maybe<CollectResendPhoneVerificationCodePayload>;
  /** Resends verification email for the current user */
  collectResendWelcomeEmail?: Maybe<CollectResendWelcomeEmailPayload>;
  collectTrackView?: Maybe<CollectActivityTrackViewPayload>;
  collectUnwatchListing?: Maybe<CollectWatchListingPayload>;
  collectUpdateFixedPriceListing?: Maybe<CollectUpdateFixedPriceListingPayload>;
  collectUpdateItem: CollectUpdateItemPayload;
  collectUpdateMemberCheckout?: Maybe<CollectUpdateMemberCheckoutPayload>;
  collectUpdateOwnerValues: CollectUpdateOwnerValuesPayload;
  collectUpdatePremierListing?: Maybe<CollectUpdatePremierListingPayload>;
  collectUpdateProfile?: Maybe<CollectUpdateProfile>;
  collectUpdateProfileName?: Maybe<CollectUpdateProfileName>;
  collectUpdateTradeShowCheckout?: Maybe<CollectUpdateTradeShowCheckoutPayload>;
  collectUpdateUserPhone?: Maybe<CollectUpdateUserPhone>;
  collectUpdateVaultItemGroup?: Maybe<CollectUpdateVaultItemGroupPayload>;
  collectUpdateVaultRetrieval?: Maybe<CollectUpdateVaultRetrievalPayload>;
  collectUpdateVaultSubmission?: Maybe<CollectUpdateVaultSubmissionPayload>;
  collectUpdateWeeklyListing?: Maybe<CollectUpdateWeeklyListingPayload>;
  collectUseSavedSearch?: Maybe<CollectUseSavedSearchPayload>;
  collectValidateMfa?: Maybe<CollectValidateMfaPayload>;
  collectVaultItemsExport: CollectVaultItemsExportPayload;
  collectVerifyMfa?: Maybe<CollectVerifyMfaPayload>;
  collectVerifyPhone?: Maybe<CollectVerifyPhonePayload>;
  collectVerifyPremierAuctionApprovalRequirement: Scalars['Boolean']['output'];
  /** Verify an OTP via email */
  collectVerifySessionEmailOtp?: Maybe<CollectVerifySessionEmailOtpPayload>;
  collectWatchListing?: Maybe<CollectWatchListingPayload>;
  createProductDetailFeedback?: Maybe<CollectProductDetailFeedbackPayload>;
  /** Login a user via Marketplace authentication. */
  pwccLogin?: Maybe<CollectMarketplaceAuthResult>;
  /** Logout via PWCCID. */
  pwccLogout?: Maybe<Scalars['Boolean']['output']>;
  /** Refresh a token pair via PWCCID. */
  pwccRefresh?: Maybe<CollectMarketplaceAuthResult>;
};


export type MutationCollectAcceptOfferArgs = {
  input: CollectUpdateOfferInput;
};


export type MutationCollectAddCandidateToCollectionArgs = {
  candidateImages: Array<Scalars['Upload']['input']>;
  candidateTitle: Scalars['String']['input'];
  changes?: InputMaybe<CollectItemChanges>;
};


export type MutationCollectAddFixedPriceToSetlistArgs = {
  fixedPriceId: Scalars['UUID']['input'];
  setlistId?: InputMaybe<Scalars['UUID']['input']>;
  setlistKind?: InputMaybe<CollectSetlistKind>;
};


export type MutationCollectAddItemToSetlistArgs = {
  itemId: Scalars['UUID']['input'];
  setlistId?: InputMaybe<Scalars['UUID']['input']>;
  setlistKind?: InputMaybe<CollectSetlistKind>;
};


export type MutationCollectAddPremierToSetlistArgs = {
  premierId: Scalars['UUID']['input'];
  setlistId?: InputMaybe<Scalars['UUID']['input']>;
  setlistKind?: InputMaybe<CollectSetlistKind>;
};


export type MutationCollectAddProductToSetlistArgs = {
  productId: Scalars['UUID']['input'];
  setlistId?: InputMaybe<Scalars['UUID']['input']>;
  setlistKind?: InputMaybe<CollectSetlistKind>;
};


export type MutationCollectAddToCartArgs = {
  cartId: Scalars['UUID']['input'];
  listingIds: Array<Scalars['UUID']['input']>;
};


export type MutationCollectAddToCollectionArgs = {
  changes?: InputMaybe<CollectItemChanges>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  variantId: Scalars['UUID']['input'];
};


export type MutationCollectAddVariantToSetlistArgs = {
  setlistId?: InputMaybe<Scalars['UUID']['input']>;
  setlistKind?: InputMaybe<CollectSetlistKind>;
  variantId: Scalars['UUID']['input'];
};


export type MutationCollectAddVaultItemsToGroupArgs = {
  vaultItemId: Scalars['ID']['input'];
  vaultItemUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};


export type MutationCollectAddWeeklyToSetlistArgs = {
  setlistId?: InputMaybe<Scalars['UUID']['input']>;
  setlistKind?: InputMaybe<CollectSetlistKind>;
  weeklyId: Scalars['UUID']['input'];
};


export type MutationCollectAddressCreateArgs = {
  ignoreSuggestions?: InputMaybe<Scalars['Boolean']['input']>;
  input: CollectAddressCreateInput;
};


export type MutationCollectAddressValidateArgs = {
  input: CollectAddressValidateInput;
};


export type MutationCollectAuthenticateArgs = {
  authorizationCode: Scalars['String']['input'];
};


export type MutationCollectBatchCancelListingsArgs = {
  ids: Array<CollectListingIdInput>;
};


export type MutationCollectBatchCreateListingArgs = {
  input: CollectBatchCreateListingInput;
};


export type MutationCollectBatchCreateListingsV2Args = {
  inputs: Array<CollectCreateListingInput>;
};


export type MutationCollectBatchMakeOffersArgs = {
  inputs: Array<CollectMakeOfferInput>;
};


export type MutationCollectBatchUpdateListingsArgs = {
  updates: Array<CollectBatchUpdateListingsInput>;
};


export type MutationCollectBoCheckoutListingConfirmIntentArgs = {
  orderId: Scalars['String']['input'];
};


export type MutationCollectBoCheckoutListingCreateIntentArgs = {
  listingId: Scalars['UUID']['input'];
  quantity: Scalars['Int']['input'];
  shipToAddress: CollectBoShippingAddressInput;
  stripeCustomerId: Scalars['String']['input'];
  stripePaymentMethodId: Scalars['String']['input'];
};


export type MutationCollectCancelListingArgs = {
  id: Scalars['UUID']['input'];
  type: CollectListingType;
};


export type MutationCollectCancelOfferArgs = {
  input: CollectUpdateOfferInput;
};


export type MutationCollectCancelVaultItemRequestArgs = {
  id: Scalars['Int']['input'];
};


export type MutationCollectCancelVaultRetrievalArgs = {
  id: Scalars['Int']['input'];
};


export type MutationCollectCancelVaultSubmissionArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationCollectCatalogCreateItemArgs = {
  input: CollectItemInput;
};


export type MutationCollectCatalogUpdateItemArgs = {
  id: Scalars['UUID']['input'];
  input: CollectItemInput;
};


export type MutationCollectCheckoutFulfillmentChargesArgs = {
  input: CollectFulfillmentChargesInput;
};


export type MutationCollectCheckoutInvoiceConfirmArgs = {
  input: CollectCheckoutInvoiceConfirmInput;
};


export type MutationCollectCheckoutInvoicePaymentIntentArgs = {
  input: CollectCheckoutInvoicePaymentIntentInput;
};


export type MutationCollectCheckoutPaymentLockArgs = {
  input: CollectCheckoutPaymentLockInput;
};


export type MutationCollectCheckoutSetupIntentArgs = {
  input: CollectCheckoutSetupIntentInput;
};


export type MutationCollectCheckoutV2CompletePaymentArgs = {
  cart?: InputMaybe<CollectCheckoutV2CompletePaymentCartInput>;
  invoice?: InputMaybe<CollectCheckoutV2CompletePaymentInvoiceInput>;
};


export type MutationCollectCheckoutV2LockForPaymentArgs = {
  cart?: InputMaybe<CollectCheckoutV2LockForPaymentCartInput>;
  invoice?: InputMaybe<CollectCheckoutV2LockForPaymentInvoiceInput>;
  vaultListing?: InputMaybe<CollectCheckoutV2LockForPaymentVaultListingInput>;
};


export type MutationCollectCounterOfferArgs = {
  input: CollectCounterOfferInput;
};


export type MutationCollectCreateInvoiceArgs = {
  input: CollectCreateInvoiceInput;
};


export type MutationCollectCreateItemEditRequestArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  pendingListing?: InputMaybe<Scalars['Boolean']['input']>;
  propertyEdit?: InputMaybe<Scalars['String']['input']>;
  vaultItemId: Scalars['UUID']['input'];
};


export type MutationCollectCreatePayoutRequestsArgs = {
  input: CollectCreatePayoutRequestsInput;
};


export type MutationCollectCreatePickUpInPersonArgs = {
  input: CollectCreatePickUpInPersonInput;
};


export type MutationCollectCreatePlaidLinkTokenArgs = {
  androidPackageName?: InputMaybe<Scalars['String']['input']>;
  profile: CollectPlaidLinkTokenProfile;
  redirectUri?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCollectCreatePresignedUrlArgs = {
  fileType: CollectImageFileType;
};


export type MutationCollectCreateReportArgs = {
  input: CollectUserReport;
};


export type MutationCollectCreateSavedSearchArgs = {
  collectSearchDataInput: CollectSearchDataInput;
};


export type MutationCollectCreateScanResultFeedbackArgs = {
  firstCorrectResult?: InputMaybe<CollectScanFeedbackResultInput>;
  imgKey: Scalars['String']['input'];
  isImageResultCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  isMetadataResultCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  rawResults?: InputMaybe<Array<InputMaybe<CollectScanFeedbackResultInput>>>;
};


export type MutationCollectCreateTradeShowCheckoutArgs = {
  input: CollectCreateTradeShowCheckoutInput;
};


export type MutationCollectCreateVaultItemGroupArgs = {
  expiresOn?: InputMaybe<Scalars['Date']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type?: InputMaybe<CollectVaultItemGroupType>;
  vaultItemUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};


export type MutationCollectCreateVaultRetrievalArgs = {
  input: CollectCreateVaultRetrievalInput;
};


export type MutationCollectCreateVaultSubmissionArgs = {
  input: CollectCreateVaultSubmissionInput;
};


export type MutationCollectCreateVaultTransferArgs = {
  input: CollectCreateVaultTransferInput;
};


export type MutationCollectDeclineOfferArgs = {
  input: CollectUpdateOfferInput;
};


export type MutationCollectDeleteSavedSearchArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationCollectDeleteVaultItemGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCollectExchangeTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationCollectForgetRecentViewArgs = {
  input: CollectActivityTrackViewInput;
};


export type MutationCollectGetTokenPairArgs = {
  accessToken: Scalars['String']['input'];
  applicationClientId: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
  tenantId: Scalars['UUID']['input'];
};


export type MutationCollectLinkPlaidIdentityVerificationArgs = {
  identityVerificationId: Scalars['String']['input'];
};


export type MutationCollectLogoutArgs = {
  accessToken: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
  terminateSession?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCollectMakeOfferArgs = {
  input: CollectMakeOfferInput;
};


export type MutationCollectMarkOfferAsViewedArgs = {
  input: CollectMarkOfferAsViewedInput;
};


export type MutationCollectMarkOfferNotificationAsViewedArgs = {
  input: CollectMarkOfferNotificationAsViewedInput;
};


export type MutationCollectMultiBidArgs = {
  input: CollectMultiBidRequest;
};


export type MutationCollectPingArgs = {
  pingId: Scalars['UUID']['input'];
};


export type MutationCollectRefreshTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationCollectRemoveFixedPriceFromSetlistArgs = {
  fixedPriceId: Scalars['UUID']['input'];
  setlistId?: InputMaybe<Scalars['UUID']['input']>;
  setlistKind?: InputMaybe<CollectSetlistKind>;
};


export type MutationCollectRemoveFromCartArgs = {
  cartId: Scalars['UUID']['input'];
  listingIds: Array<Scalars['UUID']['input']>;
};


export type MutationCollectRemoveFromCollectionArgs = {
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  itemId: Scalars['UUID']['input'];
};


export type MutationCollectRemoveItemFromSetlistArgs = {
  itemId: Scalars['UUID']['input'];
  setlistId?: InputMaybe<Scalars['UUID']['input']>;
  setlistKind?: InputMaybe<CollectSetlistKind>;
};


export type MutationCollectRemovePremierFromSetlistArgs = {
  premierId: Scalars['UUID']['input'];
  setlistId?: InputMaybe<Scalars['UUID']['input']>;
  setlistKind?: InputMaybe<CollectSetlistKind>;
};


export type MutationCollectRemoveProductFromSetlistArgs = {
  productId: Scalars['UUID']['input'];
  setlistId?: InputMaybe<Scalars['UUID']['input']>;
  setlistKind?: InputMaybe<CollectSetlistKind>;
};


export type MutationCollectRemoveVariantFromSetlistArgs = {
  setlistId?: InputMaybe<Scalars['UUID']['input']>;
  setlistKind?: InputMaybe<CollectSetlistKind>;
  variantId: Scalars['UUID']['input'];
};


export type MutationCollectRemoveVaultItemFromGroupArgs = {
  vaultItemGroupId: Scalars['ID']['input'];
  vaultItemIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};


export type MutationCollectRemoveWeeklyFromSetlistArgs = {
  setlistId?: InputMaybe<Scalars['UUID']['input']>;
  setlistKind?: InputMaybe<CollectSetlistKind>;
  weeklyId: Scalars['UUID']['input'];
};


export type MutationCollectTrackViewArgs = {
  input: CollectActivityTrackViewInput;
};


export type MutationCollectUnwatchListingArgs = {
  id: Scalars['UUID']['input'];
  type: CollectListingType;
};


export type MutationCollectUpdateFixedPriceListingArgs = {
  allowOffers?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
  listingPriceInCents?: InputMaybe<Scalars['Int']['input']>;
  marketplaceEventId?: InputMaybe<Scalars['UUID']['input']>;
  marketplaceEventType?: InputMaybe<CollectMarketplaceEventType>;
  rejectPriceThresholdInCents?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCollectUpdateItemArgs = {
  changes: CollectItemChanges;
  itemId: Scalars['UUID']['input'];
};


export type MutationCollectUpdateMemberCheckoutArgs = {
  id: Scalars['Int']['input'];
  input: CollectUpdateMemberCheckoutInput;
};


export type MutationCollectUpdateOwnerValuesArgs = {
  vaultItemsToUpdate: Array<InputMaybe<CollectUpdateOwnerValueData>>;
};


export type MutationCollectUpdatePremierListingArgs = {
  id: Scalars['UUID']['input'];
  marketplaceEventId: Scalars['UUID']['input'];
};


export type MutationCollectUpdateProfileArgs = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  optInSms?: InputMaybe<Scalars['Boolean']['input']>;
  phone: Scalars['String']['input'];
};


export type MutationCollectUpdateProfileNameArgs = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationCollectUpdateTradeShowCheckoutArgs = {
  id: Scalars['Int']['input'];
  input: CollectUpdateTradeShowCheckoutInput;
};


export type MutationCollectUpdateUserPhoneArgs = {
  phone: Scalars['String']['input'];
};


export type MutationCollectUpdateVaultItemGroupArgs = {
  expiresOn?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};


export type MutationCollectUpdateVaultRetrievalArgs = {
  id: Scalars['Int']['input'];
  input: CollectUpdateVaultRetrievalInput;
};


export type MutationCollectUpdateVaultSubmissionArgs = {
  id: Scalars['UUID']['input'];
  input: CollectUpdateVaultSubmissionInput;
};


export type MutationCollectUpdateWeeklyListingArgs = {
  id: Scalars['UUID']['input'];
  marketplaceEventId: Scalars['UUID']['input'];
};


export type MutationCollectUseSavedSearchArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationCollectValidateMfaArgs = {
  code: Scalars['String']['input'];
};


export type MutationCollectVaultItemsExportArgs = {
  itemIds: Array<Scalars['UUID']['input']>;
};


export type MutationCollectVerifyPhoneArgs = {
  code: Scalars['String']['input'];
};


export type MutationCollectVerifyPremierAuctionApprovalRequirementArgs = {
  plaidPublicToken: Scalars['String']['input'];
};


export type MutationCollectVerifySessionEmailOtpArgs = {
  code: Scalars['String']['input'];
};


export type MutationCollectWatchListingArgs = {
  id: Scalars['UUID']['input'];
  type: CollectListingType;
};


export type MutationCreateProductDetailFeedbackArgs = {
  input: CollectProductDetailFeedbackInput;
};


export type MutationPwccLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationPwccRefreshArgs = {
  nonce: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** Get all Premier auctions which user can choose to list their portfolio items */
  collectAcceptingSubmissionsPremierAuctions?: Maybe<Array<Maybe<CollectPremierAuction>>>;
  /** Get all Weekly auctions which user can choose to list their portfolio items */
  collectAcceptingSubmissionsWeeklyAuctions?: Maybe<Array<Maybe<CollectWeeklyAuction>>>;
  collectAlgoliaSearch: Array<CollectAlgoliaSearchResponse>;
  collectAuctions: Array<Maybe<CollectAuction>>;
  /** Lists all auctions this vault item can be listed in */
  collectAvailableAuctions?: Maybe<CollectAuctionConnection>;
  collectBoCheckoutListingCreateDraftOrder?: Maybe<CollectBoCheckoutListingCreateDraftOrderResponse>;
  /** Fetch all distinct category hierarchy */
  collectCategoryHierarchy?: Maybe<Array<Maybe<CollectCategoryHierarchy>>>;
  /**
   * Handles card analysis operations using ChatGPT.
   * Accepts a list of card images and an optional prompt for context.
   * Returns match details, a description of the analysis, and a chat response based on the provided prompt.
   */
  collectChatgptResults?: Maybe<CollectChatgptOperationsResult>;
  /** List of Trade Shows that are available for creating an VaultItem checkout */
  collectCheckoutAvailableTradeShows?: Maybe<Array<Maybe<CollectTradeShow>>>;
  collectCheckoutDetails?: Maybe<CollectCheckoutDetails>;
  collectCheckoutV2Details: CollectCheckoutV2Details;
  collectCheckoutV2PaymentSummary: CollectCheckoutV2PaymentSummary;
  collectCountries: Array<Maybe<CollectCountry>>;
  collectCountriesV2?: Maybe<CollectCountriesV2Payload>;
  collectCurrentUserV2?: Maybe<CollectCurrentUser>;
  collectDetailed?: Maybe<CollectDetailed>;
  /** Get FanID auth params */
  collectFanIdAuthParams: CollectFanIdAuthParams;
  collectGetCart?: Maybe<CollectCart>;
  collectGetFanaticsCollectIdentityKey?: Maybe<Scalars['String']['output']>;
  collectGetOwnershipTransferIdentity?: Maybe<CollectUser>;
  collectGetOwnershipTransferSummary?: Maybe<CollectOwnershipTransferSummary>;
  collectGetPickUpInPersonSummary?: Maybe<CollectPickUpInPersonSummary>;
  collectGetTradeShowCheckoutSummary?: Maybe<CollectTradeShowCheckoutSummary>;
  collectGetVaultItem?: Maybe<CollectVaultItem>;
  collectGetVaultItemRequest?: Maybe<CollectVaultItemRequest>;
  collectGetVaultItemRequestDetails?: Maybe<CollectVaultItemRequestDetails>;
  collectGetVaultRetrievalSummary?: Maybe<CollectVaultRetrievalSummary>;
  collectGetVaultSubmission?: Maybe<CollectVaultSubmission>;
  collectGetVaultSubmissionRates?: Maybe<CollectVaultSubmissionRates>;
  collectGetVaultSubmissionSummary?: Maybe<CollectVaultSubmissionSummary>;
  collectGlobalAuctions?: Maybe<Array<Maybe<CollectAuction>>>;
  collectGlobalAuctionsLongPolling?: Maybe<Array<Maybe<CollectAuction>>>;
  /**
   * Handles Google search operations using the provided card images.
   * Accepts a list of card images and returns search results related to those images.
   * Each result includes a link, snippet, and title for the corresponding search result.
   */
  collectGoogleResults?: Maybe<Array<Maybe<CollectGoogleResult>>>;
  /** Lists all graders available in the system */
  collectGraders: Array<CollectGrader>;
  /** Fetch all grading services */
  collectGradingServices?: Maybe<Array<Maybe<CollectGradingServices>>>;
  collectHomepageAuctionsCalendar?: Maybe<Array<Maybe<CollectCalendarEvent>>>;
  collectInvoice?: Maybe<CollectInvoice>;
  collectInvoiceV2?: Maybe<CollectInvoice>;
  /** Returns a collectItem object by ID */
  collectItem?: Maybe<CollectItem>;
  collectListSavedSearches?: Maybe<CollectSavedSearchConnection>;
  collectListVaultItems: Array<CollectVaultItem>;
  collectListing?: Maybe<CollectListing>;
  collectListings: Array<Maybe<CollectListing>>;
  collectListingsByIntegerId: Array<Maybe<CollectListing>>;
  collectMonolithicSearch: CollectMonolithicSearchResponse;
  collectMonolithicSearchOptions: CollectMonolithicSearchOptions;
  collectMultiScan?: Maybe<CollectMultiscanResponse>;
  collectOffer?: Maybe<CollectFixedPriceListingOfferWithHistory>;
  collectOfferRoom?: Maybe<CollectOfferRoom>;
  collectProduct?: Maybe<CollectProduct>;
  /** Fetch a PSA product by its certification number. */
  collectPsaResult?: Maybe<CollectProduct>;
  /** Fetch a PSA variant by its certification number. */
  collectPsaResultV2?: Maybe<CollectVariant>;
  collectPublicUserV2?: Maybe<CollectPublicUser>;
  collectReasons?: Maybe<Array<Maybe<CollectReportReason>>>;
  collectRelatedListing?: Maybe<Array<Maybe<CollectListing>>>;
  collectScan?: Maybe<Array<Maybe<CollectProduct>>>;
  collectScanV2?: Maybe<Array<Maybe<CollectProduct>>>;
  collectScanV3?: Maybe<Array<Maybe<CollectVariant>>>;
  collectSearch: CollectCatalogSearchResult;
  collectSearchKey?: Maybe<Scalars['String']['output']>;
  collectSearchListing: CollectSearchListing;
  collectSearchProduct: CollectSearchProduct;
  collectSearchSetting: CollectSearchSettings;
  collectSellerFeeConfig?: Maybe<Array<Maybe<CollectSellerFeeRate>>>;
  /** Simplified Seller Fees config */
  collectSellerFeeConfigV2?: Maybe<CollectSellerFeeRateV2>;
  collectSessionValid?: Maybe<CollectSession>;
  collectSuggestedSearchTerms: Array<Scalars['String']['output']>;
  collectTopFanAdditions: Array<Maybe<CollectVariant>>;
  collectVariant?: Maybe<CollectVariant>;
  collectVaultCategories: Array<Maybe<CollectVaultCategory>>;
  collectVaultItemsEditHistory?: Maybe<CollectItemEditRequestConnection>;
  collectVaultItemsForRequest?: Maybe<CollectVaultItemsForRequestConnection>;
  currentUserId?: Maybe<Scalars['String']['output']>;
  /** Check whether the current session is alive. */
  isSessionAlive?: Maybe<Scalars['Boolean']['output']>;
  productDetailFeedback?: Maybe<Array<Maybe<CollectProductDetailFeedback>>>;
};


export type QueryCollectAlgoliaSearchArgs = {
  requests: Array<InputMaybe<CollectAlgoliaSearchRequest>>;
  strategy?: InputMaybe<CollectAlgoliaSearchStrategy>;
};


export type QueryCollectAuctionsArgs = {
  auctionId: Array<InputMaybe<CollectAuctionIdInput>>;
};


export type QueryCollectAvailableAuctionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  type: CollectAuctionType;
  vaultItemId: Scalars['UUID']['input'];
};


export type QueryCollectBoCheckoutListingCreateDraftOrderArgs = {
  listingId: Scalars['UUID']['input'];
  quantity: Scalars['Int']['input'];
  shipToAddress: CollectBoShippingAddressInput;
};


export type QueryCollectChatgptResultsArgs = {
  items: Array<CollectSearchItemsInput>;
  prompt?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCollectCheckoutDetailsArgs = {
  input: CollectCheckoutDetailsInput;
};


export type QueryCollectCheckoutV2DetailsArgs = {
  cart?: InputMaybe<CollectCheckoutV2DetailsCartInput>;
  invoice?: InputMaybe<CollectCheckoutV2DetailsInvoiceInput>;
  vaultListing?: InputMaybe<CollectCheckoutV2DetailsVaultListingInput>;
};


export type QueryCollectCheckoutV2PaymentSummaryArgs = {
  cart?: InputMaybe<CollectCheckoutV2PaymentSummaryCartInput>;
  invoice?: InputMaybe<CollectCheckoutV2PaymentSummaryInvoiceInput>;
};


export type QueryCollectDetailedArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCollectGetFanaticsCollectIdentityKeyArgs = {
  userId: Scalars['String']['input'];
};


export type QueryCollectGetOwnershipTransferIdentityArgs = {
  transferRecipientIdentity: Scalars['String']['input'];
};


export type QueryCollectGetOwnershipTransferSummaryArgs = {
  input: CollectCreateOwnershipTransferInput;
};


export type QueryCollectGetPickUpInPersonSummaryArgs = {
  input: CollectCreatePickUpInPersonInput;
};


export type QueryCollectGetTradeShowCheckoutSummaryArgs = {
  input: CollectCreateTradeShowCheckoutInput;
};


export type QueryCollectGetVaultItemArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCollectGetVaultItemRequestArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCollectGetVaultItemRequestDetailsArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCollectGetVaultRetrievalSummaryArgs = {
  input: CollectCreateVaultRetrievalInput;
};


export type QueryCollectGetVaultSubmissionArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCollectGetVaultSubmissionSummaryArgs = {
  input: CollectCreateVaultSubmissionInput;
};


export type QueryCollectGlobalAuctionsLongPollingArgs = {
  input?: InputMaybe<CollectAuctionIdListInput>;
};


export type QueryCollectGoogleResultsArgs = {
  items: Array<CollectSearchItemsInput>;
};


export type QueryCollectInvoiceArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCollectInvoiceV2Args = {
  input: CollectInvoiceInput;
};


export type QueryCollectItemArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCollectListSavedSearchesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCollectListVaultItemsArgs = {
  ids: Array<Scalars['UUID']['input']>;
};


export type QueryCollectListingArgs = {
  id: Scalars['UUID']['input'];
  type: CollectListingType;
};


export type QueryCollectListingsArgs = {
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  listingIds: Array<InputMaybe<CollectListingIdInput>>;
};


export type QueryCollectListingsByIntegerIdArgs = {
  listingIds: Array<InputMaybe<CollectListingIntegerIdInput>>;
};


export type QueryCollectMonolithicSearchArgs = {
  auction?: InputMaybe<Array<Scalars['UUID']['input']>>;
  authenticator?: InputMaybe<Array<CollectMonolithicSearchSettingAuthenticatorValue>>;
  category?: InputMaybe<Array<Array<CollectMonolithicSearchCategoryFilter>>>;
  collection?: InputMaybe<Array<Scalars['Int']['input']>>;
  eyeAppealGrade?: InputMaybe<Array<CollectMonolithicSearchSettingEyeAppealGrade>>;
  listingType: Array<CollectListingType>;
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  priceRange?: InputMaybe<CollectMonolithicSearchPriceRange>;
  yearRange?: InputMaybe<CollectMonolithicSearchYearRange>;
};


export type QueryCollectMonolithicSearchOptionsArgs = {
  listingType: CollectListingType;
};


export type QueryCollectMultiScanArgs = {
  allowPredictions?: InputMaybe<Scalars['Boolean']['input']>;
  captureType?: InputMaybe<CollectMultiscanCaptureType>;
  imgKey: Scalars['String']['input'];
};


export type QueryCollectOfferArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  integerId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCollectOfferRoomArgs = {
  listingUuid: Scalars['UUID']['input'];
  offerUuid: Scalars['UUID']['input'];
};


export type QueryCollectProductArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCollectPsaResultArgs = {
  certNumber: Scalars['String']['input'];
};


export type QueryCollectPsaResultV2Args = {
  certNumber: Scalars['String']['input'];
};


export type QueryCollectPublicUserV2Args = {
  id: Scalars['UUID']['input'];
};


export type QueryCollectRelatedListingArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  vaultItemId: Scalars['UUID']['input'];
};


export type QueryCollectScanArgs = {
  input: CollectScanRequest;
};


export type QueryCollectScanV2Args = {
  imgKey: Scalars['String']['input'];
};


export type QueryCollectScanV3Args = {
  captureType?: InputMaybe<CollectScanCaptureType>;
  imgKey: Scalars['String']['input'];
};


export type QueryCollectSearchArgs = {
  filterOpts?: InputMaybe<CollectListingFilterOpts>;
  listingFilters?: InputMaybe<CollectListingFilters>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  searchType?: InputMaybe<CollectSearchType>;
  sortBy?: InputMaybe<CollectSearchSortBy>;
};


export type QueryCollectSearchListingArgs = {
  indexName: CollectSearchListingIndexName;
  params: CollectSearchParamsInput;
  prebuiltFilters?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryCollectSearchProductArgs = {
  params: CollectSearchParamsInput;
};


export type QueryCollectSearchSettingArgs = {
  proBuyEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<CollectSearchType>;
};


export type QueryCollectTopFanAdditionsArgs = {
  amount?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCollectVariantArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCollectVaultItemsEditHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCollectVaultItemsForRequestArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids: Array<InputMaybe<Scalars['UUID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  requestType: CollectVaultItemsForRequestInputType;
};


export type QueryIsSessionAliveArgs = {
  applicationClientId: Scalars['String']['input'];
};


export type QueryProductDetailFeedbackArgs = {
  typeId: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  collectAuctionListingUpdates?: Maybe<CollectAuctionAndListingsDelta>;
  collectAuctionUpdates?: Maybe<CollectAuction>;
  collectListingUpdates?: Maybe<CollectListingDelta>;
  collectPing?: Maybe<CollectPingResponse>;
};


export type SubscriptionCollectAuctionListingUpdatesArgs = {
  auctionIds: Array<InputMaybe<CollectAuctionIdInput>>;
};


export type SubscriptionCollectAuctionUpdatesArgs = {
  auctionIds: Array<InputMaybe<CollectAuctionIdInput>>;
};


export type SubscriptionCollectListingUpdatesArgs = {
  listingIds: Array<InputMaybe<CollectListingIdInput>>;
};


export type SubscriptionCollectPingArgs = {
  pingId: Scalars['UUID']['input'];
};

/**
 * Validation messages are returned when mutation input does not meet the requirements.
 *   While client-side validation is highly recommended to provide the best User Experience,
 *   All inputs will always be validated server-side.
 *
 *   Some examples of validations are:
 *
 *   * Username must be at least 10 characters
 *   * Email field does not contain an email address
 *   * Birth Date is required
 *
 *   While GraphQL has support for required values, mutation data fields are always
 *   set to optional in our API. This allows 'required field' messages
 *   to be returned in the same manner as other validations. The only exceptions
 *   are id fields, which may be required to perform updates or deletes.
 */
export type ValidationMessage = {
  __typename?: 'ValidationMessage';
  /** A unique error code for the type of validation used. */
  code: Scalars['String']['output'];
  /**
   * The input field that the error applies to. The field can be used to
   * identify which field the error message should be displayed next to in the
   * presentation layer.
   *
   * If there are multiple errors to display for a field, multiple validation
   * messages will be in the result.
   *
   * This field may be null in cases where an error cannot be applied to a specific field.
   */
  field?: Maybe<Scalars['String']['output']>;
  /**
   * A friendly error message, appropriate for display to the end user.
   *
   * The message is interpolated to include the appropriate variables.
   *
   * Example: `Username must be at least 10 characters`
   *
   * This message may change without notice, so we do not recommend you match against the text.
   * Instead, use the *code* field for matching.
   */
  message?: Maybe<Scalars['String']['output']>;
  /** A list of substitutions to be applied to a validation message template */
  options?: Maybe<Array<Maybe<ValidationOption>>>;
  /**
   * A template used to generate the error message, with placeholders for option substiution.
   *
   * Example: `Username must be at least {count} characters`
   *
   * This message may change without notice, so we do not recommend you match against the text.
   * Instead, use the *code* field for matching.
   */
  template?: Maybe<Scalars['String']['output']>;
};

export type ValidationOption = {
  __typename?: 'ValidationOption';
  /** The name of a variable to be subsituted in a validation message template */
  key: Scalars['String']['output'];
  /** The value of a variable to be substituted in a validation message template */
  value: Scalars['String']['output'];
};

export type WebMarkOfferNotificationAsViewedCcMutationMutationVariables = Exact<{
  input: CollectMarkOfferNotificationAsViewedInput;
}>;


export type WebMarkOfferNotificationAsViewedCcMutationMutation = { __typename?: 'Mutation', collectMarkOfferNotificationAsViewed?: { __typename?: 'CollectMarkOfferNotificationAsViewedPayload', successful: boolean, messages?: Array<{ __typename?: 'ValidationMessage', field?: string | null, message?: string | null, code: string } | null> | null } | null };

export type WebResendWelcomeEmailCcMutationMutationVariables = Exact<{ [key: string]: never; }>;


export type WebResendWelcomeEmailCcMutationMutation = { __typename?: 'Mutation', collectResendWelcomeEmail?: { __typename?: 'CollectResendWelcomeEmailPayload', successful: boolean, result?: boolean | null, messages?: Array<{ __typename?: 'ValidationMessage', field?: string | null, message?: string | null, code: string } | null> | null } | null };

export type WebCurrentUserV2CcQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type WebCurrentUserV2CcQueryQuery = { __typename?: 'Query', collectCurrentUserV2?: { __typename?: 'CollectCurrentUser', tenantId: any, firstName?: string | null, lastName?: string | null, collectPendingInvoicesCount?: number | null, collectPremierAuctionApproved?: boolean | null, kycStatus?: CollectKycStatus | null, email?: { __typename?: 'CollectEmail', address?: string | null, verified?: boolean | null } | null, phone?: { __typename?: 'CollectPhone', number?: string | null, status?: CollectPhoneStatus | null } | null } | null };

export type WebStripeKeyCcQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type WebStripeKeyCcQueryQuery = { __typename?: 'Query', collectCurrentUserV2?: { __typename?: 'CollectCurrentUser', stripePublishableKey: string, tenantId: any } | null };

export type WebUserOffersStatusCcQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type WebUserOffersStatusCcQueryQuery = { __typename?: 'Query', collectCurrentUserV2?: { __typename?: 'CollectCurrentUser', tenantId: any, hasNewOffersFromSellers?: boolean | null, hasNewOffersFromBidders?: boolean | null } | null };

export type WebCurrentUserV2PendingOffersCcQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type WebCurrentUserV2PendingOffersCcQueryQuery = { __typename?: 'Query', collectCurrentUserV2?: { __typename?: 'CollectCurrentUser', tenantId: any, collectPendingOffers?: { __typename?: 'CollectPendingOffer', offersForSeller?: Array<{ __typename?: 'CollectOfferData', offerId?: any | null, listingId?: any | null, listingTitle?: string | null, showNotification?: boolean | null } | null> | null, counterOffersForSeller?: Array<{ __typename?: 'CollectOfferData', offerId?: any | null, listingId?: any | null, listingTitle?: string | null, showNotification?: boolean | null } | null> | null, counterOffersForBuyer?: Array<{ __typename?: 'CollectOfferData', offerId?: any | null, listingId?: any | null, listingTitle?: string | null, showNotification?: boolean | null } | null> | null } | null } | null };

export type OfferDataFragment = { __typename?: 'CollectOfferData', offerId?: any | null, listingId?: any | null, listingTitle?: string | null, showNotification?: boolean | null };

export const OfferDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OfferData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CollectOfferData"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"offerId"}},{"kind":"Field","name":{"kind":"Name","value":"listingId"}},{"kind":"Field","name":{"kind":"Name","value":"listingTitle"}},{"kind":"Field","name":{"kind":"Name","value":"showNotification"}}]}}]} as unknown as DocumentNode<OfferDataFragment, unknown>;
export const WebMarkOfferNotificationAsViewedCcMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"webMarkOfferNotificationAsViewedCCMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CollectMarkOfferNotificationAsViewedInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectMarkOfferNotificationAsViewed"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"successful"}},{"kind":"Field","name":{"kind":"Name","value":"messages"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"code"}}]}}]}}]}}]} as unknown as DocumentNode<WebMarkOfferNotificationAsViewedCcMutationMutation, WebMarkOfferNotificationAsViewedCcMutationMutationVariables>;
export const WebResendWelcomeEmailCcMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"webResendWelcomeEmailCCMutation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectResendWelcomeEmail"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"successful"}},{"kind":"Field","name":{"kind":"Name","value":"messages"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"code"}}]}},{"kind":"Field","name":{"kind":"Name","value":"result"}}]}}]}}]} as unknown as DocumentNode<WebResendWelcomeEmailCcMutationMutation, WebResendWelcomeEmailCcMutationMutationVariables>;
export const WebCurrentUserV2CcQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"webCurrentUserV2CCQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectCurrentUserV2"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tenantId"}},{"kind":"Field","name":{"kind":"Name","value":"email"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"address"}},{"kind":"Field","name":{"kind":"Name","value":"verified"}}]}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"phone"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"number"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}},{"kind":"Field","name":{"kind":"Name","value":"collectPendingInvoicesCount"}},{"kind":"Field","name":{"kind":"Name","value":"collectPremierAuctionApproved"}},{"kind":"Field","name":{"kind":"Name","value":"kycStatus"}}]}}]}}]} as unknown as DocumentNode<WebCurrentUserV2CcQueryQuery, WebCurrentUserV2CcQueryQueryVariables>;
export const WebStripeKeyCcQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"webStripeKeyCCQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectCurrentUserV2"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"stripePublishableKey"}},{"kind":"Field","name":{"kind":"Name","value":"tenantId"}}]}}]}}]} as unknown as DocumentNode<WebStripeKeyCcQueryQuery, WebStripeKeyCcQueryQueryVariables>;
export const WebUserOffersStatusCcQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"webUserOffersStatusCCQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectCurrentUserV2"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tenantId"}},{"kind":"Field","name":{"kind":"Name","value":"hasNewOffersFromSellers"}},{"kind":"Field","name":{"kind":"Name","value":"hasNewOffersFromBidders"}}]}}]}}]} as unknown as DocumentNode<WebUserOffersStatusCcQueryQuery, WebUserOffersStatusCcQueryQueryVariables>;
export const WebCurrentUserV2PendingOffersCcQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"webCurrentUserV2PendingOffersCCQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectCurrentUserV2"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tenantId"}},{"kind":"Field","name":{"kind":"Name","value":"collectPendingOffers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"offersForSeller"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OfferData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"counterOffersForSeller"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OfferData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"counterOffersForBuyer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OfferData"}}]}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OfferData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CollectOfferData"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"offerId"}},{"kind":"Field","name":{"kind":"Name","value":"listingId"}},{"kind":"Field","name":{"kind":"Name","value":"listingTitle"}},{"kind":"Field","name":{"kind":"Name","value":"showNotification"}}]}}]} as unknown as DocumentNode<WebCurrentUserV2PendingOffersCcQueryQuery, WebCurrentUserV2PendingOffersCcQueryQueryVariables>;