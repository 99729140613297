import { useMutation } from '@tanstack/react-query';

import { useApi } from '../../../../ApiClient';
import { useModalContext } from '../../../hooks/useModalContext';
import { EPaymentType } from '../../../states/MyWallet/types';
import { IStripeError } from '../types';

interface IAddPaymentData {
  clientSecret: string;
}
interface IAddPaymentArgs {
  paymentTypes: EPaymentType[];
}

export const useAddPaymentMethod = () => {
  const apiClient = useApi();

  const { checkoutApiUrl } = useModalContext();

  return useMutation<IAddPaymentData, IStripeError, IAddPaymentArgs>({
    mutationKey: ['addPaymentMethod'],
    mutationFn: async (args) => {
      return apiClient!.post<IAddPaymentData>(
        `${checkoutApiUrl}/checkout/setup-intent`,
        args
      );
    },
  });
};
