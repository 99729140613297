import React, { forwardRef, useEffect, useRef } from 'react';

import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';

import { useAddPastSearch } from './PastSearches';
import { getMarketplaceSearchURL } from './utils/helpers';
import { useAuth } from '../Auth';
import { useCollectContext } from '../collect/context';
import { EGTMEvents, ESearchQueryType, useGTMDataLayer } from '../hooks';
import { CloseCircleIcon, MagnifyingGlass } from '../Icons';
import { useMonolithUrls } from '../Navigation/hooks/useMonolithUrls';
import { EColor } from '../Theme';
import { getMarketplaceType } from '../utilities';

interface ISearchInputProps {
  searchText: string;
  setSearchText: (value: string) => void;
  setIsFocused?: (value: boolean) => void;
  setIsOpened?: (value: boolean) => void;
  openModal?: () => void;
  closeModal?: () => void;
  isOpenModal?: boolean;
  pastSearchBaseUrl: string;
}

export const SearchInput = forwardRef<HTMLInputElement, ISearchInputProps>(
  (
    {
      searchText,
      setSearchText,
      setIsFocused,
      setIsOpened,
      openModal,
      closeModal,
      isOpenModal,
      pastSearchBaseUrl,
    },
    ref
  ) => {
    const { onSearch } = useCollectContext();
    const searchInputRef = useRef<HTMLInputElement>(null);
    const monolithUrls = useMonolithUrls();
    const auth = useAuth();
    const isLoggedIn = !!auth?.profile;

    const pushGTMData = useGTMDataLayer();
    const { mutateAsync: addPastSearch } = useAddPastSearch(pastSearchBaseUrl);

    useEffect(() => {
      if (isOpenModal) {
        searchInputRef.current?.focus();
      }
    }, [isOpenModal]);

    const inputKeyDownHandler = async (
      event: React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (event.key === 'Enter') {
        try {
          if (isLoggedIn && searchText.length >= 3) {
            await addPastSearch(searchText);
          }
        } finally {
          pushGTMData({
            event: EGTMEvents.SITE_SEARCH,
            authenticationState: isLoggedIn,
            clickValue: searchText,
            fanaticsCollectUserId: auth?.profile?.userId,
            vaultAccountId: auth?.profile?.vaultAccountId,
            searchResultClicks: 1,
            searchQueryType: ESearchQueryType.USER_SEARCH,
          });

          if (onSearch) {
            onSearch(searchText);
          } else {
            window.location.href = getMarketplaceSearchURL(
              searchText,
              monolithUrls,
              getMarketplaceType()
            );
          }
        }
      }

      if (event.key === 'Escape' || event.key === 'Enter') {
        setIsOpened?.(false);
        searchInputRef.current?.blur();
        setIsFocused?.(false);
        closeModal?.();
      }
    };

    const openSearchModal = () => {
      if (isOpenModal === false) {
        openModal?.();
      }
    };

    const clearSearchHandler = () => {
      searchInputRef.current?.focus();
      openSearchModal();

      setSearchText('');
    };

    return (
      <InputGroup
        _focusWithin={{
          border: `1px solid ${EColor.Black}`,
          boxShadow: `0 0 0 1px ${EColor.Black}`,
          '.chakra-input__left-element .chakra-icon': {
            color: EColor.BrandDark,
          },
        }}
        border={`1px solid ${EColor.Neutral15}`}
        borderRadius="100px"
        ref={ref}
      >
        <InputLeftElement paddingLeft="8px" pointerEvents="none">
          <MagnifyingGlass color={EColor.Black} height="24px" width="24px" />
        </InputLeftElement>

        <Input
          isTruncated
          _focus={{ boxShadow: 'none' }}
          _placeholder={{
            color: EColor.Neutral55,
            fontSize: '20px',
            lineHeight: '26px',
            fontWeight: 400,
            fontFamily: 'ABC Diatype',
          }}
          border="none"
          boxShadow="none"
          color={EColor.Black}
          data-testid="header-search-bar"
          fontSize="20px"
          fontWeight={400}
          height="40px"
          lineHeight="26px"
          paddingLeft={{
            base: isOpenModal ? '16px' : '12px',
            sm: isOpenModal ? '12px' : '24px',
            md: 0,
          }}
          paddingRight={0}
          placeholder="Search for cards, players, and more…"
          ref={searchInputRef}
          // IOS browser issue. If the input font-size is smaller than 16px, IOS will do auto-zoom when focusing the input
          // https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone?page=1&tab=scoredesc#tab-top
          // The hack is to set the input font-size to 16px, increase the other sizes and set transform: scale(0.75)
          transform={{
            base: 'scale(0.75)',
            lg: 'scale(0.75) translateX(-35px)',
          }}
          value={searchText}
          onChange={(event) => {
            setSearchText(event.target.value);
            openSearchModal();
          }}
          onClick={() => {
            pushGTMData({
              event: EGTMEvents.SEARCH_BAR_CLICK,
              searchBarClicks: 1,
              authenticationState: isLoggedIn,
              fanaticsCollectUserId: auth?.profile?.userId,
              vaultAccountId: auth?.profile?.vaultAccountId,
            });

            openSearchModal();
          }}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          onFocus={() => {
            setIsFocused?.(true);
            if (searchText) {
              searchInputRef.current?.select();
            }
          }}
          onKeyDown={inputKeyDownHandler}
        />

        {searchText.length > 0 && (
          <InputRightElement
            data-testid="search-clear"
            h="100%"
            width="fit-content"
          >
            <Box
              as="button"
              lineHeight="10px"
              marginRight="10px"
              onClick={clearSearchHandler}
            >
              <CloseCircleIcon
                _hover={{
                  cursor: 'pointer',
                  color: EColor.BrandDark,
                }}
                as="button"
                boxSize="20px"
                color="brand.lightGray"
                height="16px"
                transition="0.2s"
                width="16px"
              />
            </Box>
          </InputRightElement>
        )}
      </InputGroup>
    );
  }
);
