import React from 'react';

import { Text } from '@chakra-ui/react';

import { PaymentMethodBox } from './PaymentMethodBox';
import { BankAccountIcon, CreditCardIcon } from '../../../../Icons';
import { ETextVariant } from '../../../../Theme';
import { ModalContent, ModalHeader } from '../../../components';
import { useModalStore } from '../../../stores';
import { EModalContent } from '../../../types';

export const ChoosePaymentMethod = () => {
  const modalStore = useModalStore();

  const handleBackToMyWallet = () => {
    modalStore.pop();
  };

  const handleNavigateAddCreditCard = () => {
    modalStore.navigate({ name: EModalContent.ADD_CREDIT_CARD });
  };

  const handleNavigateAddUsBankAccount = () => {
    modalStore.navigate({ name: EModalContent.ADD_US_BANK_ACCOUNT });
  };

  return (
    <ModalContent
      header={
        <ModalHeader onClick={handleBackToMyWallet}>
          Add Payment Method
        </ModalHeader>
      }
      minHeight={{ base: '100%', md: '305px' }}
      p={0}
    >
      <PaymentMethodBox onClick={handleNavigateAddCreditCard}>
        <CreditCardIcon height="24px" width="24px" />
        <Text lineHeight="26px" variant={ETextVariant.BaseParagraph}>
          Credit Card
        </Text>
      </PaymentMethodBox>

      <PaymentMethodBox onClick={handleNavigateAddUsBankAccount}>
        <BankAccountIcon height="24px" width="24px" />
        <Text lineHeight="26px" variant={ETextVariant.BaseParagraph}>
          US Bank Account
        </Text>
      </PaymentMethodBox>
    </ModalContent>
  );
};
