export enum EChannel {
  SMS = 'sms',
  EMAIL = 'email',
}

export enum EVerificationStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  DENIED = 'denied',
}
