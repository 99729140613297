import React, { FC } from 'react';

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
} from '@chakra-ui/react';
import { FieldPathByValue, FieldValues, useController } from 'react-hook-form';

import { formatProps } from './utils';

export interface IFormTextInputProps<TFieldValues extends FieldValues>
  extends InputProps {
  name: FieldPathByValue<TFieldValues, string | undefined | null>;
  controlProps?: Omit<FormControlProps, 'isInvalid'>;
}

export function createFormTextInput<TFieldValues extends FieldValues>() {
  const FormTextInput: FC<IFormTextInputProps<TFieldValues>> = (props) => {
    const { name, type, isRequired, controlProps, variant, ...restProps } =
      props;
    const isBrandVariant = variant === 'brand';

    const { field, fieldState } = useController<TFieldValues, typeof name>({
      name,
    });

    const { value, ...restField } = field;
    const { error } = fieldState;

    formatProps(restProps, { isRequired });

    return (
      <FormControl
        isInvalid={Boolean(error)}
        {...controlProps}
        variant={isBrandVariant ? 'floating' : 'base'}
      >
        <Input
          {...(type === 'password' && {
            font: 'small-caption',
            fontSize: '16px',
            type,
          })}
          font="caption"
          inputMode="text"
          {...restField}
          {...restProps}
          placeholder={isBrandVariant ? '' : restProps.placeholder}
          value={value ?? ''}
          variant={variant ?? 'outline'}
        />
        {isBrandVariant && restProps.placeholder && (
          <FormLabel>{restProps.placeholder}</FormLabel>
        )}
        <FormErrorMessage variant={variant ?? 'base'}>
          {error?.message}
        </FormErrorMessage>
      </FormControl>
    );
  };

  return FormTextInput;
}
